import { useState } from "react";
import styled from "styled-components";
import Row from "../../../components/baseComponents/Row";
import Div from "../../../components/baseComponents/Div";

const StyledErrorMessage = styled(Row)`
  color: ${(props) => props.theme.colors.danger};
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

interface CollapsibleListProps {
  expanded: boolean;
}

const CollapsibleList = styled.div<CollapsibleListProps>`
  max-height: ${(props) => (props.expanded ? "150em" : "25em")};
  overflow: hidden;
  color: ${(props) => props.theme.colors.info};
`;

const ExpandButton = styled.button`
  background: none;
  border: none;
  color: ${(props) => props.theme.colors.info};
  cursor: pointer;
  margin-top: 0.5rem;
`;

const ErrorCSVRowsComponent = ({ errorMessage }: { errorMessage: any }) => {
  const [expanded, setExpanded] = useState(false);

  if (!errorMessage) return <></>; // No worth even parsing

  const safeParseJSON = (jsonString: any) => {
    try {
      return JSON.parse(jsonString.replace(/"/g, "*").replace(/'/g, '"'));
    } catch (error) {
      return { message: jsonString, failed_rows: [] };
    }
  };
  const { message, failed_rows } = safeParseJSON(errorMessage);

  return (
    <StyledErrorMessage justifyContent="center">
      <Div>{message}</Div>
      {failed_rows && failed_rows.length > 0 && (
        <CollapsibleList expanded={expanded}>
          {failed_rows
            .slice(0, expanded ? failed_rows.length : 10)
            .map((row: any, index: any) => (
              <Div key={index}>
                Row {row.row}: {row.reason}
              </Div>
            ))}
        </CollapsibleList>
      )}
      {failed_rows && failed_rows.length > 10 && (
        <ExpandButton onClick={() => setExpanded(!expanded)}>
          {expanded
            ? "Show Less"
            : `Show More (${failed_rows.length - 10} more)`}
        </ExpandButton>
      )}
    </StyledErrorMessage>
  );
};

export default ErrorCSVRowsComponent;
