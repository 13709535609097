import { useState } from "react";
import styled from "styled-components";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import DropdownCard from "../../../components/baseComponents/DropdownCard";
import { GenericObject } from "../../../components/global/ModelInterfaces";
import { dateShortFormatter } from "../../../components/global/utils";
import Button from "../../../components/baseComponents/Button";
import ErrorMessage from "../../../components/baseComponents/ErrorMessage";
import { retrievePrepaymentInviteUrl } from "../../utils/prepaymentUtils";

interface Props {
  inviteInfo: GenericObject;
}

const StyledLabel = styled.span`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const InviteInfo = ({ inviteInfo }: Props) => {
  const [inviteUrl, setInviteUrl] = useState(null);
  const [fetchingInvite, setFetchingInvite] = useState(false);
  const [fetchError, setFetchError] = useState("");
  const getInviteUrl = () => {
    const successCallback = (data: GenericObject) => {
      setInviteUrl(data.invite_url);
      setFetchingInvite(false);
    };
    const failureCallback = (error: string) => {
      setFetchError(error);
    };

    retrievePrepaymentInviteUrl(
      inviteInfo.prepayment_uuid,
      successCallback,
      failureCallback
    );
  };
  return (
    <DropdownCard title="Invite Info">
      {inviteInfo ? (
        <Div width={{ default: 12 / 12 }}>
          <Row>
            <StyledLabel>Tenant Name:</StyledLabel>
            {inviteInfo.tenant_name}
          </Row>
          <Row>
            <StyledLabel>Email:</StyledLabel>
            {inviteInfo.email}
          </Row>
          <Row>
            <StyledLabel>Invited At:</StyledLabel>
            {dateShortFormatter(inviteInfo.invited_at)}
          </Row>
          <Row>
            <StyledLabel>Accepted At:</StyledLabel>
            {inviteInfo.accepted_at
              ? dateShortFormatter(inviteInfo.accepted_at)
              : "N/A"}
          </Row>
          <Row>
            <StyledLabel>Prepayment UUID:</StyledLabel>
            {inviteInfo.prepayment_uuid}
          </Row>
          <Row>
            <StyledLabel>Lease Start Date:</StyledLabel>
            {dateShortFormatter(inviteInfo.lease_start_date)}
          </Row>
          <Row>
            <StyledLabel>Reminders Sent:</StyledLabel>
            {inviteInfo.reminders_sent_count}
          </Row>
          <Row>
            <StyledLabel>Last Reminder Sent:</StyledLabel>
            {inviteInfo.last_reminder_sent
              ? dateShortFormatter(inviteInfo.last_reminder_sent)
              : "N/A"}
          </Row>
          <Row>
            <StyledLabel>Address:</StyledLabel>
            {inviteInfo.street_address}
          </Row>
          <Row>
            <StyledLabel>UUID:</StyledLabel>
            {inviteInfo.uuid}
          </Row>
          <Row>
            <StyledLabel>Invite URL:</StyledLabel>
            {inviteInfo.accepted_at ? (
              <Div>Invite already accepted</Div>
            ) : (
              <>
                {inviteUrl ? (
                  <Div>{inviteUrl}</Div>
                ) : (
                  <Div>
                    {!!fetchError && <ErrorMessage>{fetchError}</ErrorMessage>}
                    <Button onClick={getInviteUrl} loading={fetchingInvite}>
                      Get Invite Url
                    </Button>
                  </Div>
                )}
              </>
            )}
          </Row>
        </Div>
      ) : (
        <Div width={{ default: 12 / 12 }}>No invite found for tenant.</Div>
      )}
    </DropdownCard>
  );
};

export default InviteInfo;
