import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Div from "./Div";
import Row from "./Row";
import Button from "./Button";
import ConfusedCoin from "../../images/ConfusedCoin";

const StyledTitle = styled.span`
  font-size: 3rem;
  font-weight: 700;
`;

const ForbiddenView = () => {
  const history = useHistory();
  return (
    <Row justifyContent="center">
      <Div alignItems="center">
        <ConfusedCoin />
        <StyledTitle>Sorry!</StyledTitle>
        <h1>Looks like this page doesn't exist.</h1>
        <h1>Please return to your dashboard!</h1>
        <Button
          text="Go to Dashboard"
          type="primary"
          onClick={() => {
            history.push("/dashboard");
          }}
        />
      </Div>
    </Row>
  );
};

export default ForbiddenView;
