import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useParams } from "react-router-dom";
import { find } from "lodash";
import { RootState } from "../..";
import {
  Company,
  GenericObject,
} from "../../components/global/ModelInterfaces";
import { useEffect, useState } from "react";
import { setCompanies } from "../../store/nucleusCompanies";
import { retrieveCompanyList } from "./companyUtils";

const useCompanyFromParams = () => {
  const dispatch = useAppDispatch();
  const { companyUUID } = useParams<GenericObject>();
  const companiesSlice: { loading: Boolean; objects: GenericObject } =
    useAppSelector((state: RootState) => state.companies);
  const loadingRedux = companiesSlice.loading;
  const companies = companiesSlice.objects;
  const [loadingCompanies, setLoadingCompanies] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  let company = find(companies, (company) => company.uuid === companyUUID);

  useEffect(() => {
    if (!companies.length) {
      const successCallback = (companies: Company[]) => {
        dispatch(setCompanies(companies));
        setLoadingCompanies(false);
      };
      const failureCallback = () => {
        setErrorMessage("Could not retrieve company list. Please try again.");
        setLoadingCompanies(false);
      };
      retrieveCompanyList(successCallback, failureCallback);
    } else {
      setLoadingCompanies(false);
    }
  }, []);

  return {
    loadingCompanies: loadingRedux || loadingCompanies,
    company: company,
    errorLoading: errorMessage !== "",
    errorMessage: errorMessage,
  };
};

export default useCompanyFromParams;
