import React from "react";

const CouchIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.7999 13.0352V9.80078C27.7999 7.31641 25.7843 5.30078 23.2999 5.30078H8.2999C5.81553 5.30078 3.7999 7.31641 3.7999 9.80078V13.0352C2.15928 13.5508 0.940526 14.9102 0.799901 16.5977C0.706151 17.957 1.22178 19.2695 2.2999 20.1133V24.4258C2.2999 25.5039 3.09678 26.3008 4.1749 26.3008H8.2999C8.4874 26.3008 9.70615 26.3008 9.75303 24.8008H21.7999C21.8468 26.2539 23.1124 26.3008 23.2999 26.3008H27.4249C28.4562 26.3008 29.2999 25.5039 29.2999 24.4258V20.1133C30.3312 19.2695 30.8937 18.0039 30.753 16.5977C30.6124 14.9102 29.3937 13.5508 27.7999 13.0352ZM7.5499 24.0508H4.5499V18.8945L3.9874 18.5664C3.33115 18.1914 3.00303 17.4883 3.0499 16.7852C3.09678 15.8477 4.03428 15.0508 5.1124 15.0508H5.6749C6.70615 15.0508 7.5499 15.8945 7.5499 16.9258V24.0508ZM21.7999 18.0508V22.5508H9.7999V18.0508H21.7999ZM21.9405 15.8008H9.6124C9.14365 14.207 7.7374 13.0352 6.0499 12.8477V9.80078C6.0499 8.58203 7.03428 7.55078 8.2999 7.55078H23.2999C24.5187 7.55078 25.5499 8.58203 25.5499 9.80078V12.8477C23.8155 13.0352 22.4093 14.207 21.9405 15.8008ZM27.5655 18.5664L27.0499 18.8945V24.0508H24.0499V16.9258C24.0499 15.8945 24.8468 15.0508 25.9249 15.0508H26.4874C27.5187 15.0508 28.4562 15.8477 28.503 16.7852C28.5968 17.5352 28.2218 18.1914 27.5655 18.5664Z"
        fill="#2F374B"
      />
    </svg>
  );
};

export default CouchIcon;
