import React from "react";

const EllipsisIcon = () => {
  return (
    <svg
      width="6"
      height="24"
      viewBox="0 0 6 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="ellipsis-v"
        d="M2.8125 8.90625C1.23047 8.90625 0 10.1953 0 11.7188C0 13.3008 1.23047 14.5312 2.8125 14.5312C4.33594 14.5312 5.625 13.3008 5.625 11.7188C5.625 10.1953 4.33594 8.90625 2.8125 8.90625ZM0 2.8125C0 4.39453 1.23047 5.625 2.8125 5.625C4.33594 5.625 5.625 4.39453 5.625 2.8125C5.625 1.28906 4.33594 0 2.8125 0C1.23047 0 0 1.28906 0 2.8125ZM0 20.625C0 22.207 1.23047 23.4375 2.8125 23.4375C4.33594 23.4375 5.625 22.207 5.625 20.625C5.625 19.1016 4.33594 17.8125 2.8125 17.8125C1.23047 17.8125 0 19.1016 0 20.625Z"
        fill="#2F374B"
      />
    </svg>
  );
};

export default EllipsisIcon;
