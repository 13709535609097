import {
  NucleusCompany,
  Option,
} from "../../../components/global/ModelInterfaces";
import DropdownCard from "../../../components/baseComponents/DropdownCard";
import BuildingSearch from "../../pages/BuildingSearch";
import Row from "../../../components/baseComponents/Row";
import Div from "../../../components/baseComponents/Div";
import { useState } from "react";
import Button from "../../../components/baseComponents/Button";
import {
  offboardBuilding,
  offboardCompany,
  confirmOffboardingAmount,
} from "../../utils/companyUtils";
import { useAppDispatch } from "../../../store/hooks";
import { updateCompany } from "../../../store/nucleusCompanies";
import ConfirmationModal from "../../../components/baseComponents/ConfirmationModal";
import { currencyFormatter } from "../../../components/global/utils";
import { noop } from "lodash";

interface Props {
  company: NucleusCompany;
}

const OffboardingTool = ({ company }: Props) => {
  const dispatch = useAppDispatch();
  const [selectedBuilding, setSelectedBuilding] = useState<Option | null>(null);
  const [offboardingBuilding, setOffboardingBuilding] = useState(false);
  const [offboardingCompany, setOffboardingCompany] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [confirmError, setConfirmError] = useState("");
  const [error, setError] = useState("");
  //  This is a safety measure. Never want to accidentally offboard a company unless the button is explicitly pressed and confirmed
  const [companyOffboardSelected, setCompanyOffboardSelected] = useState(false);

  const confirmationFunction = () => {
    // TODO make this safer
    if (selectedBuilding) {
      const offboardSuccessCallback = (updatedCompany: NucleusCompany) => {
        dispatch(updateCompany(updatedCompany));
        setOffboardingBuilding(false);
        setShowConfirm(false);
      };
      const offboardFailureCallback = (err: string) => {
        setOffboardingBuilding(false);
        setConfirmError(err);
      };

      offboardBuilding(
        offboardSuccessCallback,
        offboardFailureCallback,
        selectedBuilding.id
      );
    } else if (companyOffboardSelected) {
      setCompanyOffboardSelected(false);
      const offboardSuccessCallback = (updatedCompany: NucleusCompany) => {
        dispatch(updateCompany(updatedCompany));
        setOffboardingBuilding(false);
        setShowConfirm(false);
      };
      const offboardFailureCallback = (err: string) => {
        setOffboardingBuilding(false);
        setConfirmError(err);
      };
      offboardCompany(
        offboardSuccessCallback,
        offboardFailureCallback,
        company.uuid
      );
    }
  };

  const handleBuildingOffboarding = () => {
    setOffboardingBuilding(true);
    const confirmAmountSuccessCallback = ({
      active_amount,
      pending_amount,
      unit_count,
    }: {
      active_amount: number;
      pending_amount: number;
      unit_count: number;
    }) => {
      setConfirmMessage(
        constructConfirmMessage(active_amount, pending_amount, unit_count)
      );
      setShowConfirm(true);
    };

    const confirmAmountFailureCallback = () => {
      // TODO fill this out
      setOffboardingBuilding(false);
    };
    if (selectedBuilding) {
      confirmOffboardingAmount(
        confirmAmountSuccessCallback,
        confirmAmountFailureCallback,
        selectedBuilding.id,
        null
      );
    }
  };

  const handleCompanyOffboarding = () => {
    setOffboardingCompany(true);
    setCompanyOffboardSelected(true);
    const confirmAmountSuccessCallback = ({
      active_amount,
      pending_amount,
      unit_count,
    }: {
      active_amount: number;
      pending_amount: number;
      unit_count: number;
    }) => {
      setConfirmMessage(
        constructConfirmMessage(active_amount, pending_amount, unit_count)
      );
      setShowConfirm(true);
    };

    const confirmAmountFailureCallback = () => {
      // TODO fill this out
      setOffboardingBuilding(false);
    };
    confirmOffboardingAmount(
      confirmAmountSuccessCallback,
      confirmAmountFailureCallback,
      null,
      company.uuid
    );
  };

  const constructConfirmMessage = (
    active_amount: number,
    pending_amount: number,
    unit_count: number
  ) => {
    return (
      "You are about to offboard " +
      currencyFormatter.format(active_amount) +
      " in active deposits, " +
      currencyFormatter.format(pending_amount) +
      " in pending deposits and " +
      unit_count.toString() +
      " units. This action is irreversible. Engineering will not be able to help you fix this."
    );
  };

  return (
    <DropdownCard title="Offboard" initiallyOpen={false}>
      <Row alignItems="center">
        <Div width={{ sm: 1, lg: 5 / 12 }}>
          <Row>
            <Div width={{ default: 1 }}>
              <BuildingSearch
                onClick={(option: any) => {
                  setSelectedBuilding(option);
                }}
                companyUUID={company.uuid}
              />
            </Div>
            {selectedBuilding && (
              <Row>
                <Div
                  width={{ default: 1 }}
                  pl={{ default: 3 }}
                  pr={{ default: 3 }}
                >
                  <Button
                    onClick={handleBuildingOffboarding}
                    text={`Offboard ${selectedBuilding.label}`}
                    type="warning"
                    loading={offboardingBuilding}
                    disabled={offboardingBuilding || offboardingCompany}
                  />
                </Div>
              </Row>
            )}
          </Row>
        </Div>
        <Div width={{ sm: 1, lg: 7 / 12 }}>
          <Row alignItems="center">
            <Div width={{ default: 1, lg: 2 / 12 }}>OR</Div>
            <Div width={{ default: 1, lg: 10 / 12 }}>
              <Button
                type="danger"
                text={`Offboard ${company.name}`}
                onClick={handleCompanyOffboarding}
                loading={offboardingCompany}
                disabled={offboardingBuilding || offboardingCompany}
              />
            </Div>
          </Row>
        </Div>
      </Row>
      <ConfirmationModal
        confirmationOpen={showConfirm}
        onClose={() => {
          setShowConfirm(false);
          setCompanyOffboardSelected(false);
          setOffboardingBuilding(false);
          setOffboardingCompany(false);
        }}
        onConfirm={confirmationFunction}
        message={confirmMessage}
        errorMessage={confirmError}
      />
    </DropdownCard>
  );
};

export default OffboardingTool;
