import React from "react";

const UpArrowIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.19173 10.4004H11.8075C12.3343 10.4004 12.5978 9.78847 12.2248 9.42986L8.41843 5.76689C8.18756 5.54489 7.81166 5.54489 7.58079 5.76689L3.77438 9.42986C3.40144 9.78847 3.66487 10.4004 4.19173 10.4004Z"
        fill="#222A3E"
      />
    </svg>
  );
};

export default UpArrowIcon;
