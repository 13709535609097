import React from "react";

const EnvelopeIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.375 9C32.8242 9 34 10.1752 34 11.625C34 12.4508 33.6117 13.2273 32.95 13.725L21.05 22.65C20.4266 23.1148 19.5734 23.1148 18.95 22.65L7.05 13.725C6.38899 13.2273 6 12.4508 6 11.625C6 10.1752 7.17523 9 8.625 9H31.375ZM17.9 24.05C19.1469 24.9852 20.8531 24.9852 22.1 24.05L34 15.125V26.5C34 28.4305 32.4305 30 30.5 30H9.5C7.5668 30 6 28.4305 6 26.5V15.125L17.9 24.05Z"
        fill="#2F374B"
      />
    </svg>
  );
};

export default EnvelopeIcon;
