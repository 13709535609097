import { useState } from "react";
import styled from "styled-components";
import Div from "./Div";
import Row from "./Row";
import { GenericObject } from "../global/ModelInterfaces";
import DropdownPlus from "./DropdownPlus";

const StyledTitle = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const StyledLabel = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const StyledContent = styled(Row)`
  font-weight: ${(props) => props.theme.font_weight.normal};
  background-color: ${(props) => props.theme.colors.grey10};
`;

interface Props {
  title: string;
  list: GenericObject;
}

const CollapsibleList = ({ title, list }: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen((oldState) => !oldState);
  };

  return (
    <Div width={{ default: 12 / 12 }}>
      <Row alignItems="center">
        <StyledTitle p={{ default: 0 }}>{title}</StyledTitle>
        <DropdownPlus onToggle={toggleDropdown} />
      </Row>
      <StyledContent>
        {dropdownOpen &&
          Object.entries(list).map(([key, value]: [string, any]) => (
            <Row>
              <StyledLabel>{key + ":"}</StyledLabel>
              {value === null ? "None" : value.toString()}
            </Row>
          ))}
      </StyledContent>
    </Div>
  );
};

export default CollapsibleList;
