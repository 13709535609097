import Div from "./Div";
import Plus from "../../images/Plus";
import Minus from "../../images/Minus";
import { useState } from "react";
import styled from "styled-components";

interface Props {
  onToggle: Function;
}

const StyledDropdownPlus = styled(Div)`
  &:hover {
    cursor: pointer;
  }
`;

const DropdownPlus = ({ onToggle }: Props) => {
  const [open, setOpen] = useState(false);
  const changeState = () => {
    setOpen((oldState) => !oldState);
  };
  const handleToggle = () => {
    onToggle();
    changeState();
  };

  return (
    <StyledDropdownPlus onClick={handleToggle}>
      {open ? <Minus /> : <Plus />}
    </StyledDropdownPlus>
  );
};

export default DropdownPlus;
