import styled from "styled-components";

import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import RentableCard from "../../baseComponents/RentableCard";
import { monthDayYearFormatter } from "../../global/utils";
import { GenericObject } from "../../global/ModelInterfaces";

interface Props {
	enrollment: GenericObject
}

const RentersInsuranceConfirmationCard = ({enrollment}: Props) => {

  const StyledDetailsSection = styled(Row)`
    margin-top: 1rem;
  `;

  const StyledDetailLabel = styled(Div)`
    font-weight: ${(props) => props.theme.font_weight.semibold};
  `;

  return (
    <RentableCard title={"Renters Insurance"}>
      <>
        <Row>
          <Div width={{ default: 1 }}>
            You have successfully enrolled in Renter's Insurance. Your Landlord
            has been notified. If you uploaded proof of insurance you can see it
            in your Documents & Receipts section.
          </Div>
        </Row>
        <StyledDetailsSection>
          <Row>
            <StyledDetailLabel>Enrollment Type:</StyledDetailLabel>
            <Div>{enrollment.enrollment_type}</Div>
          </Row>
          <Row>
            <StyledDetailLabel>Enrollment Date:</StyledDetailLabel>
            <Div>{monthDayYearFormatter(enrollment.enrolled_at)}</Div>
          </Row>
        </StyledDetailsSection>
      </>
    </RentableCard>
  );
};

export default RentersInsuranceConfirmationCard;
