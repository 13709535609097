import { COLORS } from "./colors";
import { FONT_SIZE, FONT_WEIGHT } from "./fonts";
import { css } from "styled-components";

const breakpoints = ["300px", "600px", "960px"];
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];

const box_shadow = {
  dark: "0 0 11px rgba(33, 33, 33, 1)",
  light: `0rem 0rem 2rem ${COLORS.boxShadowGrey}`,
};

const space = {
  0: "0",
  1: ".25rem",
  2: ".5rem",
  3: "1rem",
  4: "1.5rem",
  5: "3rem",
  6: "4rem",
};

const border_radius = {
  XS: ".25rem",
  SM: ".5rem",
  MD: "1rem",
  LG: "1.5rem",
  XL: "2rem",
};

const borders = {
  primarySM: `.125rem solid ${COLORS.primary}`,
  secondarySM: `.125rem solid ${COLORS.backgroundSecondary}`,
  tertiarySM: `.125rem solid ${COLORS.backgroundTertiary}`,
  primaryMD: `.25rem solid ${COLORS.primary}`,
  secondaryMD: `.25rem solid ${COLORS.backgroundSecondary}`,
  tertiaryMD: `.25rem solid ${COLORS.backgroundTertiary}`,
  primaryLG: `.5rem solid ${COLORS.primary}`,
  secondaryLG: `.5rem solid ${COLORS.backgroundSecondary}`,
  tertiaryLG: `.5rem solid ${COLORS.backgroundTertiary}`,
};

const theme = {
  colors: COLORS,
  font_size: FONT_SIZE,
  font_weight: FONT_WEIGHT,
  box_shadow: box_shadow,
  border_radius: border_radius,
  breakpoints,
  space,
  borders,
};

const cssBreakpoints = (cssProp, values, mediaQueryType = "min-width") => {
  const breakpointProps = values.reduce((mediaQueries, value) => {
    let screenBreakpoint;
    if (!!breakpoints[`${Object.keys(value)[0]}`]) {
      screenBreakpoint = breakpoints[`${Object.keys(value)[0]}`];
    } else {
      screenBreakpoint = Object.keys(value)[0];
    }
    const cssPropBreakpoint = Object.values(value)[0];
    return (mediaQueries += `
      @media screen and (${mediaQueryType}: ${screenBreakpoint}){
        ${cssProp}: ${cssPropBreakpoint}
      }
    `);
  }, "");
  return css([breakpointProps]);
};

export { theme, cssBreakpoints };
