import { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import styled from "styled-components";
import Container from "../../components/baseComponents/Container";
import Div from "../../components/baseComponents/Div";
import Row from "../../components/baseComponents/Row";
import Input from "../../components/baseComponents/Input";
import Button from "../../components/baseComponents/Button";
import { GenericObject } from "../../components/global/ModelInterfaces";
import { retrievePrepaymentsByEmail } from "../utils/prepaymentUtils";
import ErrorMessage from "../../components/baseComponents/ErrorMessage";
import { nucleusUserPermissions } from "../../enums/nucleusPermissions";
import useNucleusPermissions from "../../utils/useNucleusPermissions";

const StyledHeader = styled(Row)`
  font-size: ${(props) => props.theme.font_size.headlineMD};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const StyledPrepaymentCard = styled(Div)`
  background-color: ${(props) => props.theme.colors.grey10};
  font-size: ${(props) => props.theme.font_size.headlineXS};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  border-radius: 0.5rem;
  &:hover {
    box-shadow: 0 0 0 2px #d0d0d0;
    background-color: ${(props) => props.theme.colors.grey2};
    cursor: pointer;
  }
`;

const PrepaymentSearch = () => {
  const history = useHistory();
  const { loadingPermissions, prepaymentOverviewPermission } =
    useNucleusPermissions();

  const [email, setEmail] = useState("");
  const [prepayments, setPrepayments] = useState<Array<GenericObject>>([]);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSearch = () => {
    const successCallback = (prepayments: Array<GenericObject>) => {
      setPrepayments(prepayments);
    };
    const failureCallback = () => {
      setErrorMessage(
        "Could not find any prepayments with the provided email. Please try again."
      );
    };
    retrievePrepaymentsByEmail(email, successCallback, failureCallback);
  };

  useEffect(() => {
    const handleEventListener = (e: any) => {
      if (e.key === "Enter") {
        handleSearch();
      }
    };
    // Bind the event listener
    document.addEventListener("keypress", handleEventListener);
    // Unbind the event listener
    return () => {
      document.removeEventListener("keypress", handleEventListener);
    };
  }, [handleSearch]);

  if (
    prepaymentOverviewPermission === nucleusUserPermissions.NONE &&
    !loadingPermissions
  ) {
    return <Redirect to={"/nucleus/forbidden"} />;
  }

  if (prepayments.length > 0) {
    return (
      <Container>
        <Div alignItems="center">
          <Div
            width={{ default: 8 / 12 }}
            mt={{ default: 5 }}
            pl={{ default: 3 }}
            pr={{ default: 3 }}
          >
            <StyledHeader>Please select a prepayment for: {email}</StyledHeader>
          </Div>
          <Div width={{ default: 6 / 12 }} mt={{ default: 5 }}>
            {prepayments.map((prepayment) => {
              return (
                <StyledPrepaymentCard
                  mt={{ default: 2 }}
                  p={{ default: 3 }}
                  onClick={() =>
                    history.push(
                      `/nucleus/prepayment-overview/${prepayment.uuid}`
                    )
                  }
                >
                  <Row justifyContent="space-between">
                    <div>{prepayment.unit.street_address}</div>
                    <div>Lease Start: {prepayment.start_date}</div>
                  </Row>
                  <Row justifyContent="space-between">
                    <div>
                      {prepayment.unit.city}, {prepayment.unit.state},{" "}
                      {prepayment.unit.zip_code}
                    </div>
                    <div>Lease End: {prepayment.end_date}</div>
                  </Row>
                </StyledPrepaymentCard>
              );
            })}
          </Div>
        </Div>
      </Container>
    );
  }

  return (
    <Container>
      <Div alignItems="center">
        <Div
          width={{ default: 8 / 12 }}
          mt={{ default: 5 }}
          pl={{ default: 3 }}
          pr={{ default: 3 }}
        >
          <StyledHeader>Prepayment Overview</StyledHeader>
          <StyledHeader>
            Search for a prepayment by tenant's email.
          </StyledHeader>
        </Div>
        {errorMessage && (
          <Row justifyContent="center" mt={{ default: 5 }} mb={{ default: 3 }}>
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </Row>
        )}
        <Div
          width={{ default: 8 / 12 }}
          alignItems="center"
          justifyContent="center"
        >
          <Row alignItems="center" mt={{ default: 2 }}>
            <Div width={{ default: 1 / 2 }}>
              <Input
                onChange={(e) => setEmail(e.target.value)}
                placeholderText="Enter Email"
              />
            </Div>
            <Div width={{ default: 1 / 2 }}>
              <Button text="Get Prepayments" onClick={handleSearch} />
            </Div>
          </Row>
        </Div>
      </Div>
    </Container>
  );
};

export default PrepaymentSearch;
