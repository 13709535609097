import styled from "styled-components";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import DropdownCard from "../../../components/baseComponents/DropdownCard";
import ErrorMessage from "../../../components/baseComponents/ErrorMessage";
import { GenericObject } from "../../../components/global/ModelInterfaces";
import { some } from "lodash";
import {
  currencyFormatter,
  dateShortFormatter,
} from "../../../components/global/utils";
interface Props {
  paymentInfo: GenericObject;
}
const StyledLabel = styled.span`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const StyledText = styled.span`
  font-size: ${(props) => props.theme.font_size.headlineMD};
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const StyledSubTitle = styled(Row)`
  font-size: ${(props) => props.theme.font_size.headlineXS};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;
const PaymentInfo = ({ paymentInfo }: Props) => {
  const dwollaInfo = paymentInfo?.dwolla_info ?? {};
  const fundingBankInfo = dwollaInfo?.bank_account ?? {};
  const fundingBankInfoError = fundingBankInfo.error ?? "";
  const stripeInfo = paymentInfo?.stripe_info ?? {};
  return (
    <DropdownCard title="Payment Info">
      <Div width={{ default: 12 / 12 }}>
        {some(paymentInfo) ? (
          <>
            <Row>
              <StyledLabel>Payment Method:</StyledLabel>
              {paymentInfo.method}
            </Row>
            <Row>
              <StyledLabel>Date</StyledLabel>
              {dateShortFormatter(paymentInfo.date)}
            </Row>
            <Row>
              <StyledLabel>Tenant Paid Fee:</StyledLabel>
              {paymentInfo.tenant_paid_fee.toString()}
            </Row>
            <Row>
              <StyledLabel>Amount:</StyledLabel>
              {currencyFormatter.format(paymentInfo.amount)}
            </Row>
            <Row>
              <StyledLabel>Fee:</StyledLabel>
              {currencyFormatter.format(paymentInfo.fee)}
            </Row>
            <Row>
              <StyledLabel>Funded By:</StyledLabel>
              {paymentInfo.funded_by}
            </Row>
            {some(stripeInfo) && (
              <>
                <Row>
                  <StyledLabel>Stripe Payment Intent:</StyledLabel>
                  {stripeInfo.payment_intent}
                </Row>
              </>
            )}
            {some(dwollaInfo) && (
              <>
                <Row>
                  <StyledLabel>Dwolla Correlation ID:</StyledLabel>
                  {dwollaInfo.correlation_id}
                </Row>
              </>
            )}
            {paymentInfo.dwolla_info && (
              <Div>
                <Row mt={{ default: 3 }}>
                  <StyledSubTitle>Funding Bank Account Details</StyledSubTitle>
                </Row>
                {fundingBankInfoError ? (
                  <Row>
                    <ErrorMessage>
                      This funding bank account is missing information.
                    </ErrorMessage>
                  </Row>
                ) : (
                  <>
                    <Row>
                      <StyledLabel>Bank Account UUID:</StyledLabel>
                      {fundingBankInfo.uuid}
                    </Row>
                    <Row>
                      <StyledLabel>Bank Name:</StyledLabel>
                      {fundingBankInfo.bank_name}
                    </Row>
                    <Row>
                      <StyledLabel>Account Name:</StyledLabel>
                      {fundingBankInfo.name}
                    </Row>
                    <Row>
                      <StyledLabel>Mask:</StyledLabel>
                      {fundingBankInfo.mask}
                    </Row>
                    <Row>
                      <StyledLabel>Link Token:</StyledLabel>
                      {fundingBankInfo.link_token}
                    </Row>
                    <Row>
                      <StyledLabel>Asset Report Token:</StyledLabel>
                      {fundingBankInfo.asset_report_token}
                    </Row>
                    <Row>
                      <StyledLabel>Pending Confirmation:</StyledLabel>
                      {fundingBankInfo.pending_confirmation
                        ? fundingBankInfo.pending_confirmation.toString()
                        : "N/A"}
                    </Row>
                    <Row>
                      <StyledLabel>Manually Verified:</StyledLabel>
                      {fundingBankInfo.manually_verified
                        ? fundingBankInfo.manually_verified.toString()
                        : "N/A"}
                    </Row>
                    <Row>
                      <StyledLabel>Payment Personal Verified:</StyledLabel>
                      {fundingBankInfo.payment_personal_verified
                        ? fundingBankInfo.payment_personal_verified.toString()
                        : "N/A"}
                    </Row>
                    <Row>
                      <StyledLabel>Plaid Link:</StyledLabel>
                      {fundingBankInfo.plaid_link
                        ? fundingBankInfo.plaidLink
                        : "N/A"}
                    </Row>
                    <Row>
                      <StyledLabel>Owner ID:</StyledLabel>
                      {fundingBankInfo.owner}
                    </Row>
                    <Row>
                      <StyledLabel>Customer Payment ID:</StyledLabel>
                      {fundingBankInfo.customer_payment_id}
                    </Row>
                  </>
                )}
              </Div>
            )}
          </>
        ) : (
          <Row>
            <StyledText>This prepayment has not been funded.</StyledText>
          </Row>
        )}
      </Div>
    </DropdownCard>
  );
};

export default PaymentInfo;
