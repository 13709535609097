import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { theme } from "../../../components/global/theme";
import styled from "styled-components";

import PageLoader from "../../../components/PageLoader";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import Button from "../../../components/baseComponents/Button";
import InsufficientFunds from "../../../components/TenantComponents/InsufficientFunds";
import CalendarIcon from "../../../images/CalendarIcon";
import Checkbox from "../../../components/baseComponents/Checkbox";
import PaymentProcessorRestricted from "../PaymentProcessorRestricted";
import useCalculatePaymentAmounts from "../../../utils/useCalculatePaymentAmounts";
import useGetCurrentPrepayment from "../../../utils/useGetCurrentPrepayment";
import {
  Deposit,
  GenericObject,
  TenantFinancialInfo,
} from "../../../components/global/ModelInterfaces";
import { currencyFormatter } from "../../../components/global/utils";
import { payMonthly } from "../../../utils/depositUtils";
import { updateTenantDeposits } from "../../../store/tenantInfo";
import SaveCardForm from "../SaveCardForm";

const StyledNavigationRow = styled(Row)`
  margin-bottom: 1rem;
`;

const InfoBox = styled(Row)`
  background: ${(props) => props.theme.colors.grey2};
  border-radius: 16px;
  padding: 12px;
  min-height: 8em;
`;

const Price = styled(Div)`
  color: ${(props) => props.theme.colors.primary};
  display: inline;
  & > span {
    font-weight: ${(props) => props.theme.font_weight.bolder};
    font-size: ${(props) => props.theme.font_size.headlineLG};
  }
  margin-top: 0.4rem;
  text-align: center;
`;

const MonthlyPaymentInfo = styled(Row)`
  font-size: ${(props) => props.theme.font_size.bodyMD};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  padding-top: 0.4rem;
`;

const MonthlyPaymentSpan = styled.span`
  color: ${(props) => props.theme.colors.primary};
  padding: 0 0.4rem;
`;

const Details = styled(Row)`
  font-size: ${(props) => props.theme.font_size.bodyMD};
`;

const CreditCardInfoText = styled(Row)`
  font-size: ${(props) => props.theme.font_size.bodyLG};
`;

const LineItem = styled(Row)`
  padding: 0.4rem 0;
  font-size: ${(props) => props.theme.font_weight.headlineXS};
  & > Div {
    padding: 0;
  }
`;

const TotalLineItem = styled(LineItem)`
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const ToSRow = styled(Row)`
  padding: 4px 0;
  font-size: ${(props) => props.theme.font_size.bodySM};
`;

const ErrorMessage = styled.span`
  color: ${(props) => props.theme.colors.danger};
`;

interface Props {
  onBack?: () => void;
  financialInfo: TenantFinancialInfo;
}

const SecurityDepositPaymentMonthly = ({ onBack, financialInfo }: Props) => {
  const { prepaymentUUID } = useParams<GenericObject>();
  const dispatch = useAppDispatch();
  const { prepayment } = useGetCurrentPrepayment(prepaymentUUID);
  const securityDeposit = prepayment.deposits.security_deposit;

  const [paying, setPaying] = useState(false);
  const [tosChecked, setTosChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentError, setPaymentError] = useState(false);
  const [cardAdded, setCardAdded] = useState(false);
  const [showCardForm, setShowCardForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const paymentAmounts: GenericObject = useCalculatePaymentAmounts(prepayment);

  const displayInsufficientFunds = !financialInfo.sufficientFundsMonthlyPayment;
  const paymentProcessorRestricted: Boolean = useAppSelector(
    (state) =>
      state.tenantInfo.objects.personalInfo.payment_processor_restricted
  );
  const handlePayment = () => {
    if (!tosChecked) {
      setErrorMessage("You must agree to our Terms and Services below.");
      return;
    } else if (
      financialInfo.paymentPlanApproval.needs_backup_card &&
      !financialInfo.hasSavedCreditCard &&
      !cardAdded
    ) {
      setShowCardForm(true);
    } else {
      setPaying(true);
      const successCallback = (deposits: Deposit[]) => {
        dispatch(updateTenantDeposits(deposits));
        setPaying(false);
        setLoading(false);
      };
      const failureCallback = () => {
        setPaying(false);
        setPaymentError(true);
        setLoading(false);
      };
      payMonthly(securityDeposit.uuid, successCallback, failureCallback);
    }
  };

  useEffect(() => {
    if (cardAdded) {
      setLoading(true);
      handlePayment();
    }
  }, [cardAdded]);

  if (
    !financialInfo ||
    !financialInfo.paymentPlanApproval ||
    paymentAmounts.loading ||
    loading
  ) {
    return <PageLoader />;
  }
  if (paymentAmounts.error) {
    setErrorMessage(paymentAmounts.error);
    return <ErrorMessage> {errorMessage} </ErrorMessage>;
  }

  if (showCardForm) {
    return (
      <Row justifyContent="center">
        <Div width={{ default: 8 / 12 }}>
          <Row>
            <Div width={{ default: 1, lg: 6 / 12 }}>
              <CreditCardInfoText>
                <Div mr={{ lg: 5 }}>
                  In order to enroll in a payment plan, you will need to add a
                  backup payment method. You can add a credit card to your
                  account using this secure form. Your credit card will never be
                  charged without a Rentable employee reaching out to you first.
                </Div>
              </CreditCardInfoText>
            </Div>
            <Div width={{ default: 1, lg: 6 / 12 }}>
              <SaveCardForm onSave={() => setCardAdded(true)} />
            </Div>
          </Row>
        </Div>
      </Row>
    );
  }

  if (displayInsufficientFunds) {
    return (
      <InsufficientFunds
        onBack={onBack}
        amountDue={paymentAmounts.initialSecurityDepositPaymentTotalWithFee}
      />
    );
  }
  return (
    <Div alignItems="center">
      <Div width={{ sm: 1, md: 3 / 4, lg: 3 / 4 }} justifyContent="center">
        <StyledNavigationRow justifyContent="space-between">
          <a onClick={onBack}>Back</a>
        </StyledNavigationRow>
      </Div>
      <Div width={{ sm: 1, md: 3 / 4, lg: 3 / 4 }} justifyContent="center">
        <h1>Review & Confirm</h1>
        <InfoBox justifyContent="center">
          <Div alignItems="center" width={{ sm: 1, md: 5 / 12, lg: 5 / 12 }}>
            Monthly Cost:
            <Price width={{ sm: 1 }}>
              <span>
                {currencyFormatter.format(paymentAmounts.monthlyAmountWithFee)}
              </span>
            </Price>
          </Div>
        </InfoBox>
        <MonthlyPaymentInfo
          alignItems="center"
          justifyContent="center"
          className="text-center"
        >
          <CalendarIcon color={theme.colors.primary} />
          Total amount over{" "}
          {financialInfo.paymentPlanApproval.approved_number_months} months:
          <MonthlyPaymentSpan>
            {currencyFormatter.format(
              paymentAmounts.totalSecurityDepositAmountWithFee
            )}
          </MonthlyPaymentSpan>
        </MonthlyPaymentInfo>
        <Details>
          Your first payment will be deducted from your linked bank account
          today. Your following payments will be deducted on this day of each
          month until the deposit is paid in full.
        </Details>
        <LineItem justifyContent="space-between" alignItems="center">
          <Div>Platform Fee:</Div>{" "}
          <Div>
            {currencyFormatter.format(
              paymentAmounts.approvedPaymentPlanDefaultFee
            )}
          </Div>
        </LineItem>
        <TotalLineItem justifyContent="space-between" alignItems="center">
          <Div>Today's Total:</Div>{" "}
          <Div>
            {currencyFormatter.format(
              paymentAmounts.initialSecurityDepositPaymentTotalWithFee
            )}
          </Div>
        </TotalLineItem>
        {paymentProcessorRestricted ? (
          <PaymentProcessorRestricted />
        ) : (
          <>
            {paymentError && (
              <ErrorMessage>
                There was an error funding the deposit, please try again or
                contact support@rentable.com if this issue persists.
              </ErrorMessage>
            )}
            <Button
              text={`Confirm & Pay`}
              onClick={handlePayment}
              loading={paying}
              disabled={paying || paymentError}
            />
            {!tosChecked && (
              <div>
                <ErrorMessage>{errorMessage}</ErrorMessage>
              </div>
            )}
            <ToSRow alignItems="center">
              <Div>
                <Checkbox
                  value={tosChecked}
                  onChange={() => setTosChecked((tosChecked) => !tosChecked)}
                />
              </Div>
              <Div width={{ sm: 3 / 4, md: 4 / 5, lg: 4 / 5 }}>
                <span>
                  By clicking Confirm & Pay, you acknowledge and agree to
                  Rentable's{" "}
                  <a
                    href={"https://rentable.com/tenant-terms-of-services"}
                    className="secondary-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Tenant Terms of Use
                  </a>
                  , and specifically the terms for{" "}
                  <a
                    href={"https://rentable.com/payment-plan-terms-of-services"}
                    className="secondary-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Rentable's Payment Plan
                  </a>
                  .
                </span>
              </Div>
            </ToSRow>
          </>
        )}
      </Div>
    </Div>
  );
};

export default SecurityDepositPaymentMonthly;
