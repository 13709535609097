import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { BrowserRouter } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import {
  Provider as RollbarProvider,
  ErrorBoundary as RollbarErrorBoundary,
} from "@rollbar/react";
import { Configuration as RollbarConfiguration } from "rollbar";

import axios from "axios";

import { getCookie } from "./utils/getCookie";
import { setUser } from "./store/auth";

import { theme } from "./components/global/theme";
import { cssBreakpoints } from "./components/global/theme";
import Div from "./components/baseComponents/Div";
import PageLoader from "./components/PageLoader";
import LandlordApp from "./AppLandlord";
import UnauthApp from "./AppUnauth";
import TenantApp from "./AppTenant";
import UnknownErrorPage from "./pages/UnknownErrorPage";
import { getIdentity, getRollbarConfig } from "./utils/authenticationUtils";

import "./interceptors/init";
import { GenericObject } from "./components/global/ModelInterfaces";

const StyledApp = styled(Div)`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const App = () => {
  const csrftoken = getCookie("csrftoken");
  const [loading, setLoading] = useState(true);
  const [rollbarConfig, setRollbarConfig] = useState<RollbarConfiguration>({});
  const [rollbarEnvironment, setRollbarEnvironment] = useState("");
  const [rollbarKey, setRollbarKey] = useState("");
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const currentUser = auth.user;
  const authenticated = auth.authenticated;

  axios.defaults.timeout = 15000;

  const loadUser = () => {
    const token = localStorage.getItem("token");
    if (!authenticated && token) {
      const successCallback = (data: GenericObject) => {
        dispatch(setUser(data));
        setLoading(false);
      };

      getIdentity(successCallback);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    const successCallback = (data: GenericObject) => {
      setRollbarEnvironment(data.environment);
      setRollbarKey(data.api_key);
    };
    getRollbarConfig(successCallback);
  }, []);

  useEffect(() => {
    loadUser();
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      if (window.sessionRewind) {
        window.sessionRewind.identifyUser({ userId: currentUser.email });
      } else {
        window.SessionRewindConfig = Object.assign(
          {},
          window.SessionRewindConfig,
          { userInfo: { userId: currentUser.email } }
        );
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (rollbarKey && rollbarEnvironment) {
      setRollbarConfig({
        accessToken: rollbarKey,
        environment: rollbarEnvironment,
        payload: {
          person: currentUser ?? null,
          context: { email: currentUser?.email },
        },
      });
    }
  }, [currentUser, rollbarKey, rollbarEnvironment]);

  useEffect(() => {
    axios.interceptors.request.use((config: any) => {
      const sessionUrl = window.sessionRewind
        ? window.sessionRewind.getSessionUrl()
        : "";
      Object.assign(config.headers.common, {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken, // This might no longer be needed
        "session-rewind-playback": sessionUrl,
        Accept: "application/json",
      });
      return config;
    });
  }, [csrftoken]);

  if (loading) {
    return (
      <div className="container h-100 d-flex justify-content-center align-items-center">
        <PageLoader />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        {!authenticated && <UnauthApp />}
        <StyledApp
          justifyContent={"flex-start"}
          alignItems={"center"}
          pl={{ sm: 3, lg: 5 }}
          pr={{ sm: 3, lg: 5 }}
        >
          <RollbarProvider config={rollbarConfig}>
            <RollbarErrorBoundary fallbackUI={UnknownErrorPage}>
              {authenticated &&
                (currentUser.role === "landlord" ||
                  currentUser.role === "admin") && <LandlordApp />}
              {authenticated && currentUser.role === "tenant" && <TenantApp />}
            </RollbarErrorBoundary>
          </RollbarProvider>
        </StyledApp>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
