import React from "react";

const TruckCouchIcon = () => {
  return (
    <svg
      width="31"
      height="25"
      viewBox="0 0 31 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.40962 11.957C1.55024 11.9102 1.64399 11.8633 1.78462 11.8633C2.06587 11.7695 2.34712 11.7227 2.67524 11.7227C4.03462 11.7227 5.30024 12.6133 5.76899 13.8789L11.6284 12.2852C11.5346 11.5352 11.6752 10.7852 12.0502 10.1289C12.4721 9.33203 13.2221 8.76953 14.1127 8.53516C14.2065 8.48828 14.3471 8.48828 14.4877 8.48828L14.4409 8.44141C14.019 6.84766 12.3784 5.91016 10.7846 6.33203L3.51899 8.25391C1.92524 8.67578 0.98774 10.3164 1.40962 11.957ZM1.12837 16.6914L1.92524 19.5508L5.53462 18.6133L14.2065 16.2695L14.9096 16.082L15.8002 15.8477V10.1289C15.3784 9.94141 14.9565 9.89453 14.4877 9.98828C13.5034 10.2695 12.894 11.3008 13.1752 12.2852L13.4565 13.3633L6.75337 15.1914L6.19087 15.332L4.73774 15.707L4.45649 14.6289C4.17524 13.5977 3.14399 13.0352 2.15962 13.2695C1.17524 13.5508 0.565865 14.582 0.847115 15.5664L1.12837 16.6914ZM30.8002 3.05078V0.800781H18.8002C17.9565 0.800781 17.3002 1.50391 17.3002 2.30078V18.2852L1.03462 22.6445C0.847115 22.6914 0.753365 22.8789 0.80024 23.1133L1.17524 24.5664C1.22212 24.7539 1.45649 24.8477 1.64399 24.8008L20.3002 19.7852C20.4409 22.5977 22.6909 24.8008 25.5502 24.8008C28.4096 24.8008 30.8002 22.457 30.8002 19.5508C30.8002 16.6914 28.4096 14.3008 25.5502 14.3008C23.4409 14.3008 21.6596 15.5664 20.8159 17.3008H19.5502V3.05078H30.8002ZM25.5502 16.5508C27.1909 16.5508 28.5502 17.9102 28.5502 19.5508C28.5502 21.2383 27.1909 22.5508 25.5502 22.5508C23.8627 22.5508 22.5502 21.2383 22.5502 19.5508C22.5502 17.9102 23.8627 16.5508 25.5502 16.5508Z"
        fill="#2F374B"
      />
    </svg>
  );
};

export default TruckCouchIcon;
