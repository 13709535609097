import React from "react";
const UnitIcon = ({ width = "48", height = "48" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 39 27">
      <path
        d="M34.7499 10.293V6.25C34.7499 3.14453 32.2303 0.625 29.1249 0.625H10.3749C7.26941 0.625 4.74988 3.14453 4.74988 6.25V10.293C2.6991 10.9375 1.17566 12.6367 0.999877 14.7461C0.882689 16.4453 1.52722 18.0859 2.87488 19.1406V24.5312C2.87488 25.8789 3.87097 26.875 5.21863 26.875H10.3749C10.6093 26.875 12.1327 26.875 12.1913 25H27.2499C27.3085 26.8164 28.8905 26.875 29.1249 26.875H34.2811C35.5702 26.875 36.6249 25.8789 36.6249 24.5312V19.1406C37.9139 18.0859 38.6171 16.5039 38.4413 14.7461C38.2655 12.6367 36.7421 10.9375 34.7499 10.293ZM9.43738 24.0625H5.68738V17.6172L4.98425 17.207C4.16394 16.7383 3.75378 15.8594 3.81238 14.9805C3.87097 13.8086 5.04285 12.8125 6.3905 12.8125H7.09363C8.38269 12.8125 9.43738 13.8672 9.43738 15.1562V24.0625ZM27.2499 16.5625V22.1875H12.2499V16.5625H27.2499ZM27.4257 13.75H12.0155C11.4296 11.7578 9.67175 10.293 7.56238 10.0586V6.25C7.56238 4.72656 8.79285 3.4375 10.3749 3.4375H29.1249C30.6483 3.4375 31.9374 4.72656 31.9374 6.25V10.0586C29.7694 10.293 28.0116 11.7578 27.4257 13.75ZM34.4569 17.207L33.8124 17.6172V24.0625H30.0624V15.1562C30.0624 13.8672 31.0585 12.8125 32.4061 12.8125H33.1093C34.3983 12.8125 35.5702 13.8086 35.6288 14.9805C35.746 15.918 35.2772 16.7383 34.4569 17.207Z"
        fill="#2F374B"
      />
    </svg>
  );
};

export default UnitIcon;
