import styled from "styled-components";
import Div from "../../components/baseComponents/Div";
import NucleusLoader from "./NucleusLoader";
import RentManagerPrepaymentTab from "../pages/Integrations/rent_manager/RentManagerPrepaymentTab";
import usePrepaymentFromParams from "../utils/usePrepaymentFromParams";
import integrationType from "../../enums/integrationType";

const StyledLoading = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineLG};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const PrepaymentIntegrationTab = () => {
  const { loadingPrepayments, prepayment } = usePrepaymentFromParams();

  if (loadingPrepayments) {
    return (
      <StyledLoading justifyContent="center" alignItems="center">
        <Div width={{ default: 3 / 12 }}>
          <NucleusLoader loadingText="Loading Integration Details" />
        </Div>
      </StyledLoading>
    );
  }

  return (
    <>
      {prepayment.integration_info.integration_type ===
        integrationType.RENT_MANAGER && <RentManagerPrepaymentTab />}
    </>
  );
};

export default PrepaymentIntegrationTab;
