import axios from "axios";
import { checkResponseStatus } from "./globalUtils";

export const disburseSecurityDeposit = async (
  prepaymentUUID,
  deductions,
  releaseBank,
  signature
) => {
  const deductionData = new FormData();
  deductions.forEach((deduction) => {
    if (deduction.documentation) {
      deductionData.append(deduction.uuid, deduction.documentation);
    }
  });
  deductionData.append("prepayment_uuid", prepaymentUUID);
  deductionData.append("signature", signature);
  deductionData.append("deductions", JSON.stringify(deductions));

  if (deductions.length > 0) {
    deductionData.append("bank_uuid", releaseBank.uuid);
  }
  return axios
    .post("/api/deposit/disburse", deductionData)
    .then((res) => {
      const data = res.data;
      return data.updated_prepayment;
    })
    .catch(() => {
      throw new Error(
        "There was an error with the disbursement process, please try again or contact support@rentable.com"
      );
    });
};

export const getSecurityDepositInfo = async (prepaymentUUID) => {
  const request = {
    prepayment_uuid: prepaymentUUID,
  };

  return axios.post("/api/deposit/security_deposit", request).then((res) => {
    return res.data.security_deposit;
  });
};

export const getLastMonthsRentInfo = async (prepaymentUUID) => {
  const request = {
    prepayment_uuid: prepaymentUUID,
  };

  return axios
    .post("/api/deposit/last_months_rent", request)
    .then((res) => {
      return res.data.last_months_rent;
    })
    .catch(() => {
      // TODO: display error
    });
};

export const disburseLastMonthsRent = async (prepaymentUUID, bankAccount) => {
  const disburseRequest = {
    prepayment_uuid: prepaymentUUID,
    bank_uuid: bankAccount.uuid,
  };
  return axios
    .post("/api/deposit/rent-disburse", disburseRequest)
    .then((res) => {
      const data = res.data;
      return data.updated_prepayment;
    })
    .catch((e) => {
      throw Error(e.data);
    });
};

export const disburseDelayedDisbursement = (
  prepaymentUUID,
  successCallback,
  failureCallback
) => {
  const disburseRequest = {
    prepayment_uuid: prepaymentUUID,
  };
  return axios
    .post("/api/deposit/delayed-disburse", disburseRequest)
    .then((res) => {
      successCallback();
    })
    .catch((e) => {
      failureCallback(e.data);
    });
};

export const disburseViaCheckWithFee = async (
  name,
  mailing_address_1,
  mailing_address_2,
  city,
  state,
  zipcode,
  failureCallback,
  prepaymentUUID
) => {
  await axios
    .post("/api/payments/credit/mail-check", {
      name,
      mailing_address_1,
      mailing_address_2,
      city,
      state,
      zipcode,
      prepayment_uuid: prepaymentUUID,
    })
    .then((res) => {
      window.location.assign(res.data.checkout_session_url);
    })
    .catch((err) => {
      failureCallback(err.message);
    });
};

export const disburseViaCheckWithoutFee = async (
  name,
  mailing_address_1,
  mailing_address_2,
  city,
  state,
  zipcode,
  successCallback,
  failureCallback,
  prepaymentUUID
) => {
  return axios
    .post("/api/deposit/send-check-without-fee", {
      name,
      mailing_address_1,
      mailing_address_2,
      city,
      state,
      zipcode,
      prepayment_uuid: prepaymentUUID,
    })
    .then(() => {
      successCallback();
    })
    .catch((err) => {
      failureCallback(err.message);
    });
};

//TODO get this from backend
export const deductionCategories = [
  { label: "General damage, repairs, or replacements", id: "1" },
  { label: "Cleaning", id: "2" },
  { label: "Rent", id: "3" },
  { label: "Unpaid bills", id: "4" },
  { label: "Other", id: "5" },
];

export const showDeductionCategory = (deduction) => {
  return deductionCategories.find(
    (category) => Number(category.id) === deduction.category
  ).label;
};

export const getTotalDeductionAmount = (deductions) => {
  const amount = deductions.reduce((sum, deduction) => {
    return sum + Math.round(deduction.amount * 100) / 100;
  }, 0);
  return amount;
};

export const calculateRefundAmount = (selectedDeposit, deductions) => {
  const refundAmount =
    selectedDeposit.amount - getTotalDeductionAmount(deductions);
  return Math.round(refundAmount * 100) / 100;
};
