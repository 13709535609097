import { useState } from "react";
import { useAppSelector } from "../../store/hooks";
import styled from "styled-components";
import Div from "../baseComponents/Div";
import Row from "../baseComponents/Row";
import Navbar from "../baseComponents/Navbar";
import XIcon from "../../images/XIcon";
import MenuIcon from "../../images/MenuIcon";
import SearchBar from "../baseComponents/SearchBar";
import { GenericObject } from "../global/ModelInterfaces";

import { COLORS } from "../global/colors";
import { logout } from "../../store/auth";
import { useAppDispatch } from "../../store/hooks";
import { useHistory, NavLink } from "react-router-dom";
import { cssBreakpoints } from "../global/theme";

const StyledMobileMenu = styled(Div)`
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  width: 100vw;
  height: 100vh;
  z-index: 99;
`;

const StyledNavLink = styled(NavLink)`
  color: black;
  font-weight: bold;
  ${cssBreakpoints("padding-left", [{ sm: "1rem" }, { md: "0rem" }])}
  ${cssBreakpoints("font-size", [{ sm: "3.5rem" }, { md: "1.5rem" }])}
`;

const NavbarLandlord = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.auth.user);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleLogout = () => {
    dispatch(logout());
  };
  const menuItems = [
    {
      label: "My Account",
      function: () => {
        history.push("/myaccount");
      },
    },
    {
      label: "Log Out",
      function: handleLogout,
    },
  ];

  if (currentUser.role === "admin") {
    menuItems.splice(1, 0, {
      label: "Admin Settings",
      function: () => {
        history.push("/settings");
      },
    });
  }

  const navLinks = [
    {
      label: "Buildings",
      url: "/dashboard/buildings",
    },
    {
      label: "Deposits",
      url: "/dashboard/deposits",
    },
    {
      label: "Invites",
      url: "/dashboard/pending-invites",
    },
  ];

  return (
    <>
      <Navbar menuItems={menuItems}>
        <>
          <Div
            width={{ default: 1 }}
            display={{ lg: "flex", md: "none", sm: "none", default: "none" }}
          >
            <Row alignItems="center" flexWrap="no-wrap">
              <Div width={{ sm: 0, md: 0, lg: 7 / 12 }}>
                <SearchBar />
              </Div>
              <Div width={{ sm: 0, md: 0, lg: 5 / 12 }}>
                <Row flexWrap="no-wrap" justifyContent="space-around">
                  {navLinks.map((link: GenericObject) => {
                    return (
                      <StyledNavLink
                        to={`${link.url}`}
                        activeStyle={{
                          textDecoration: "underline",
                          textDecorationColor: COLORS.primary,
                        }}
                      >
                        {link.label}
                      </StyledNavLink>
                    );
                  })}
                </Row>
              </Div>
            </Row>
          </Div>
          <Div
            justifyContent="center"
            alignItems="center"
            display={{
              lg: "none",
              md: "flex",
              sm: "none",
              default: "none",
            }}
            width={{ sm: 1 / 6, md: 1 / 8, lg: 0 }}
            onClick={toggleMobileMenu}
          >
            <MenuIcon />
          </Div>
        </>
      </Navbar>
      {mobileMenuOpen && (
        <StyledMobileMenu>
          <Div
            onClick={toggleMobileMenu}
            width={{ default: 1 }}
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
          >
            <XIcon />
          </Div>
          <Div>
            {navLinks?.map((link) => {
              return (
                <Div onClick={toggleMobileMenu}>
                  <StyledNavLink
                    to={`${link.url}`}
                    activeStyle={{
                      textDecoration: "underline",
                      textDecorationColor: COLORS.primary,
                    }}
                  >
                    {link.label}
                  </StyledNavLink>
                </Div>
              );
            })}
          </Div>
        </StyledMobileMenu>
      )}
    </>
  );
};

export default NavbarLandlord;
