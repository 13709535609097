import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import styled from "styled-components";
import { COLORS } from "../global/colors";
import { currencyFormatter } from "../global/utils";
import { RootState } from "../..";
import prepaymentStatuses from "../../enums/prepaymentStatuses";
import Container from "../baseComponents/Container";
import Div from "../baseComponents/Div";
import Col from "../baseComponents/Col";
import Row from "../baseComponents/Row";
import CardComponent from "../baseComponents/CardComponent";
import CalendarIcon from "../../images/CalendarIcon";
import DollarIcon from "../../images/DollarIcon";
import EnvelopeIcon from "../../images/EnvelopeIcon";
import PaymentsDue from "./Dashboard/PaymentsDue";
import UpcomingDisbursements from "./Dashboard/UpcomingDisbursements";
import PageLoader from "../PageLoader";
import { Prepayment, Invite } from "../global/ModelInterfaces";

const StyledLink = styled(Div)`
  cursor: pointer;
  color: ${COLORS.grey40};
  &:hover {
    text-decoration: underline;
  }
`;

const DashboardView = () => {
  const history = useHistory();
  const prepaymentSlice: { loading: Boolean; objects: Prepayment[] } =
    useAppSelector((state: RootState) => state.prepayments);

  const loadingPrepayments = prepaymentSlice.loading;

  const activePrepayments = prepaymentSlice.objects.filter(
    (prepayment: Prepayment) =>
      prepayment.status_id === prepaymentStatuses.ACTIVE
  );

  const inviteSlice: { loading: Boolean; objects: Invite[] } = useAppSelector(
    (state: RootState) => state.invites
  );

  if (loadingPrepayments) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  }

  return (
    <Container>
      <Row justifyContent="center" alignItems="center" pb={{ sm: 3 }}>
        <Col width={{ sm: 1, md: 1, lg: 1 / 3 }}>
          <CardComponent
            icon={<DollarIcon />}
            cardTitle={"Total Amount in Active Deposits"}
            cardMetric={`${currencyFormatter.format(
              activePrepayments.reduce(
                (sumValue: number, prepayment: Prepayment) =>
                  sumValue + parseFloat(prepayment.amount),
                0
              )
            )}`}
            onClick={() => history.push("/dashboard/active-deposits")}
          />
        </Col>
        <Col width={{ sm: 1, md: 1, lg: 1 / 3 }}>
          <CardComponent
            icon={<CalendarIcon />}
            cardTitle={"Active Deposits"}
            cardMetric={activePrepayments.length}
            onClick={() => history.push("/dashboard/active-deposits")}
          />
        </Col>
        <Col width={{ sm: 1, md: 1, lg: 1 / 3 }}>
          <CardComponent
            icon={<EnvelopeIcon />}
            cardTitle={"Invited Tenants"}
            cardMetric={inviteSlice.objects.length}
            onClick={() => history.push("/dashboard/pending-invites")}
          />
        </Col>
      </Row>
      <Row justifyContent="center">
        <Col width={{ sm: 1, md: 1, lg: 1 / 2 }}>
          <PaymentsDue />
          <StyledLink
            mb={{ sm: 1 }}
            onClick={() => {
              history.push("/dashboard/upcoming-payments");
            }}
          >
            View all upcoming payments
          </StyledLink>
        </Col>
        <Col width={{ sm: 1, md: 1, lg: 1 / 2 }}>
          <UpcomingDisbursements />
          <StyledLink
            mb={{ sm: 1 }}
            onClick={() => {
              history.push("/dashboard/active-deposits");
            }}
          >
            View all upcoming disbursements
          </StyledLink>
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardView;
