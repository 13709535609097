const SET_UNITS = "units/SET_UNITS";
const ADD_UNIT = "units/ADD_UNIT";
const UPDATE_UNIT = "units/UPDATE_UNIT";
const DELETE_UNIT = "units/DELETE_UNIT";
const DELETE_UNITS = "units/DELETE_UNITS";

export const setUnits = (units) => {
  return {
    type: SET_UNITS,
    units: units,
  };
};

export const addUnit = (unit) => {
  return {
    type: ADD_UNIT,
    unit: unit,
  };
};

export const updateUnit = (unit) => {
  return {
    type: UPDATE_UNIT,
    unit,
  };
};

export const removeUnit = (unitUUID) => {
  return {
    type: DELETE_UNIT,
    unitUUID,
  };
};

export const removeUnitsByBuildingId = (buildingID) => {
  return {
    type: DELETE_UNITS,
    buildingID,
  };
};


const initialState = {
  loading: true,
  objects: [],
};

export default function unitsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_UNITS:
      return {
        ...state,
        loading: false,
        objects: action.units,
      };
    case ADD_UNIT:
      return {
        ...state,
        objects: state.objects.concat(action.unit),
      };
    case UPDATE_UNIT:
      return {
        ...state,
        objects: state.objects.map((unit) => {
          if (action.unit.uuid === unit.uuid) {
            return action.unit;
          } else return unit;
        }),
      };
    case DELETE_UNIT:
      return {
        ...state,
        objects: state.objects.filter((unit) => unit.uuid !== action.unitUUID),
      };
    case DELETE_UNITS:
      return {
        ...state,
        objects: state.objects.filter((unit) => unit.building_id !== action.buildingID),
      };
    default:
      return state;
  }
}
