import { useEffect, useState } from "react";
import styled from "styled-components";
import Container from "../../components/baseComponents/Container";
import Div from "../../components/baseComponents/Div";
import Row from "../../components/baseComponents/Row";
import { Building, Option } from "../../components/global/ModelInterfaces";
import ErrorMessage from "../../components/baseComponents/ErrorMessage";
import AutocompleteDropdown from "../../components/baseComponents/AutocompleteDropdown";
import PageLoader from "../../components/PageLoader";
import {
  retrieveBuildingList,
  retrieveBuildingByUUID,
} from "../utils/buildingUtils";

const StyledHeader = styled(Row)`
  font-size: ${(props) => props.theme.font_size.headlineMD};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

interface Props {
  companyUUID: string;
  onClick: (option: any) => void;
}

const BuildingSearch = ({ companyUUID, onClick }: Props) => {
  const [selectedBuilding, setSelectedBuilding] = useState<
    Building | undefined
  >();
  const [buildingOptions, setBuildingOptions] = useState<
    {
      label: string;
      identifier: string | undefined;
      id: string;
    }[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const successCallback = (buildings: Building[]) => {
      const newBuildingOptions = buildings.map((building) => {
        return {
          label: building.name,
          id: building.uuid,
          identifier: building.name,
        };
      });
      setBuildingOptions(newBuildingOptions);
      setLoading(false);
    };
    const failureCallback = () => {
      setErrorMessage("Could not retrieve building list. Please try again.");
      setLoading(false);
    };
    retrieveBuildingList(companyUUID, successCallback, failureCallback);
  }, []);

  const buildingOnClick = (option: Option | null) => {
    setErrorMessage("");
    if (!option) {
      setSelectedBuilding(undefined);
    } else {
      const successCallback = (building: Building) => {
        setSelectedBuilding(building);
        onClick(option);
      };
      const failureCallback = (err: string) => {
        setErrorMessage(err);
      };
      retrieveBuildingByUUID(option.id, successCallback, failureCallback);
    }
  };

  if (loading) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  }
  return (
    <>
      <Div width={{ default: 1 }} pl={{ default: 3 }} pr={{ default: 3 }}>
        {buildingOptions.length > 0 && (
          <>
            <StyledHeader pb={{ default: 3 }}>Select a building:</StyledHeader>
            <Div width={{ default: 1 }} mt={{ default: 2 }}>
              <AutocompleteDropdown
                name="Building Name"
                options={buildingOptions}
                placeholder="Enter Building Name"
                onClick={buildingOnClick}
                value={
                  selectedBuilding
                    ? {
                        label: selectedBuilding.name,
                        id: selectedBuilding.uuid,
                      }
                    : null
                }
                error={false}
              />
            </Div>
          </>
        )}
        {buildingOptions.length === 0 && <Div>No Buildings</Div>}
      </Div>
      {errorMessage && (
        <Row justifyContent="center" mt={{ default: 5 }} mb={{ default: 3 }}>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Row>
      )}
    </>
  );
};

export default BuildingSearch;
