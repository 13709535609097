import React, { useState } from "react";
import styled from "styled-components";
import { useAppSelector } from "../../store/hooks";
import { updatePassword } from "../../store/auth";

import Div from "./Div";
import Input from "./Input";
import Button from "./Button";
import Modal from "./Modal";
import PageLoader from "../PageLoader";

interface Props {
  open: boolean;
  onClose: () => void;
}

const StyledTitle = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineMD};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  margin-bottom: 1rem;
`;

const StyledError = styled(Div)`
  color: ${(props) => props.theme.colors.danger};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  margin-bottom: 1rem;
`;

const StyledSuccess = styled(Div)`
  color: ${(props) => props.theme.colors.primary};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  margin-bottom: 1rem;
`;

const StyledButton = styled(Div)`
  margin-top: 1rem;
`;

function PasswordChangeModal({ open, onClose }: Props) {
  const currentUser = useAppSelector((state) => state.auth.user);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const handleSubmit = async () => {
    if (!currentPassword) {
      setErrorMessage("Please enter your current password");
      setSuccessMessage("");
      return;
    }
    if (!newPassword) {
      setErrorMessage("Please enter a new password");
      return;
    }
    setErrorMessage("");
    setLoading(true);
    const successCallback = (successMessage: string) => {
      setSuccessMessage(successMessage);
      setCurrentPassword("");
      setNewPassword("");
      setLoading(false);
    };
    const failureCallback = (errorMessage: string) => {
      setErrorMessage(errorMessage);
      setCurrentPassword("");
      setNewPassword("");
      setLoading(false);
    };
    updatePassword(
      currentPassword,
      newPassword,
      successCallback,
      failureCallback
    );
  };

  if (!currentUser) {
    onClose();
  }

  if (loading)
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  else {
    return (
      <Modal showModal={open} closeModal={onClose}>
        <Div>
          <StyledTitle>Change Password</StyledTitle>
          {errorMessage && (
            <StyledError textAlign="center">{errorMessage}</StyledError>
          )}
          {successMessage && (
            <StyledSuccess textAlign="center">{successMessage}</StyledSuccess>
          )}
          <Input
            type="password"
            label="Current Password"
            onChange={(e) => setCurrentPassword(e.target.value)}
            value={currentPassword}
          />
          <Input
            type="password"
            label="New Password"
            onChange={(e) => setNewPassword(e.target.value)}
            value={newPassword}
          />
          <StyledButton>
            <Button onClick={handleSubmit}>Save</Button>
          </StyledButton>
        </Div>
      </Modal>
    );
  }
}
export default PasswordChangeModal;
