import { useState } from "react";
import styled from "styled-components";
import Button from "../../../../../components/baseComponents/Button";
import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import Input from "../../../../../components/baseComponents/Input";
import { createAssociatedRentManagerPrepayment } from "../../../../utils/rentManagerUtils";
import { GenericObject } from "../../../../../components/global/ModelInterfaces";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";

const StyledInstructions = styled(Div)`
  font-size: ${(props) => props.theme.font_size.bodyMD};
`;

const StyledResult = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
  color: ${(props) => props.theme.colors.success};
  font-size: ${(props) => props.theme.font_size.bodyMD};
`;

const ClearForm = styled(Div)`
  cursor: pointer;
`;

const LinkPrepaymentTool = () => {
  const [rentManagerResourceID, setRentManagerID] = useState("");
  const [prepaymentUUID, setPrepaymentUUID] = useState("");
  const [rentManagerPrepayment, setRentManagerPrepayment] =
    useState<GenericObject | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSearch = () => {
    if (!prepaymentUUID || !rentManagerResourceID) {
      setError(
        "You must provide a prepayment UUID and a rentManagerResourceID before submitting."
      );
      return;
    }
    const successCallBack = (rentManagerPrepayment: GenericObject) => {
      setRentManagerPrepayment(rentManagerPrepayment);
      setLoading(false);
    };
    const failureCallback = (error: string) => {
      setLoading(false);
      setError(error);
    };
    setLoading(true);
    setError("");
    createAssociatedRentManagerPrepayment(
      rentManagerResourceID,
      prepaymentUUID,
      successCallBack,
      failureCallback
    );
  };

  const handleClearForm = () => {
    setPrepaymentUUID("");
    setRentManagerID("");
    setRentManagerPrepayment(null);
  };

  if (rentManagerPrepayment) {
    return (
      <StyledResult>
        <Row>
          <ClearForm
            mt={{ default: 2 }}
            mb={{ default: 2 }}
            onClick={handleClearForm}
          >
            ⬅️ Link a different Rent Manager Prepayment
          </ClearForm>
        </Row>
        <Row>
          <span>New Rent Manager Prepayment Details Below:</span>
        </Row>
        <Row>
          <span>UUID: {rentManagerPrepayment.uuid}</span>
        </Row>
        <Row>
          <span>
            Resource ID: {rentManagerPrepayment.rent_manager_resource_id}
          </span>
        </Row>
        <Row>
          <span>
            Resource Status:{" "}
            {rentManagerPrepayment.rent_manager_resource_status}
          </span>
        </Row>
      </StyledResult>
    );
  }

  return (
    <Div>
      <Row>
        <StyledInstructions>
          This will create a Rent Manager Prepayment with the Tenant/Prospect ID
          provided.
        </StyledInstructions>
      </Row>
      <Row mt={{ default: 3 }}>
        <Div width={{ default: 1 }}>
          <Input
            value={prepaymentUUID}
            onChange={(e) => setPrepaymentUUID(e.target.value)}
            label="Rentable Prepayment UUID"
          />
        </Div>
      </Row>
      <Row mb={{ default: 3 }}>
        <Div width={{ default: 1 }}>
          <Input
            value={rentManagerResourceID}
            onChange={(e) => setRentManagerID(e.target.value)}
            label="Tenant or Prospect ID"
          />
        </Div>
      </Row>
      {error && (
        <Row justifyContent="center" mb={{ default: 3 }}>
          <ErrorMessage>{error}</ErrorMessage>
        </Row>
      )}
      <Row>
        <Div width={{ default: 1 }}>
          <Button
            loading={loading}
            text="Link Prepayment"
            onClick={handleSearch}
          />
        </Div>
      </Row>
    </Div>
  );
};
export default LinkPrepaymentTool;
