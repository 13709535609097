import styled from "styled-components";
import { cssBreakpoints } from "../components/global/theme";

import Div from "../components/baseComponents/Div";
import LoginForm from "../components/UnauthComponents/LoginForm";

const StyledContainer = styled(Div)`
  border-radius: 0.75rem;
  ${cssBreakpoints("border", [
    { sm: "none" },
    { md: "1px solid #cfcfcf" },
    { lg: "1px solid #cfcfcf" },
  ])}

  ${cssBreakpoints("padding", [
    { sm: "1rem" },
    { md: "1.5rem" },
    { lg: "3rem" },
  ])}
  ${cssBreakpoints("margin-top", [
    { sm: "1rem" },
    { md: "1.5rem" },
    { lg: "3rem" },
  ])}
`;

const StyledTitle = styled.h3`
  margin-bottom: 3rem;
`;

const LoginPage = () => {
  return (
    <Div width={{ sm: 1, md: 1, lg: 1 }} alignItems="center">
      <StyledContainer width={{ sm: 1, md: 1, lg: 6 / 12 }} alignItems="center">
        <StyledTitle>
          Welcome back!
          <br />
          Sign into your account.
        </StyledTitle>
        <LoginForm />
      </StyledContainer>
    </Div>
  );
};

export default LoginPage;
