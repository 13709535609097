import { useParams } from "react-router-dom";
import { COLORS } from "../../global/colors";
import styled from "styled-components";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import HoorayCoin from "../../../images/HoorayCoin";
import ChillCoin from "../../../images/ChillCoin";
import useGetCurrentPrepayment from "../../../utils/useGetCurrentPrepayment";
import { GenericObject } from "../../global/ModelInterfaces";
import { cssBreakpoints } from "../../global/theme";
import { registerMonthlyPaymentPlanClick } from "../../../utils/depositUtils";
import Button from "../../baseComponents/Button";
import { BANK_LINK_STEP } from "../../../utils/onboardingUtils";

const Title = styled.h1`
  font-weight: bold;
  margin-bottom: 2rem;
`;

const PaymentPlan = styled(Div)`
  background-color: ${(props) => props.theme.colors.navy};
  border: 1px solid ${(props) => props.theme.colors.navy};
  color: ${(props) => props.theme.colors.white};
  border-radius: 1rem;
  padding: 1rem;
  cursor: pointer;
  ${cssBreakpoints("height", [{ default: "auto" }, { lg: "100%" }])}
  ${cssBreakpoints("margin-bottom", [
    { sm: "1rem" },
    { md: "1rem" },
    { lg: "0" },
  ])}
`;

const FullPayment = styled(Div)`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.grey20};
  border-radius: 1rem;
  padding: 1rem;
  ${cssBreakpoints("height", [{ default: "auto" }, { lg: "100%" }])}
  &:hover span {
    text-decoration: underline;
  }
`;

const PaymentOption = styled(Row)`
  font-weight: bold;
  margin-bottom: 1rem;
`;

const PlanDetails = styled(Row)`
  font-size: smaller;
  margin-top: 0.8rem;
`;

const OptionCard = styled(Div)`
  ${cssBreakpoints("height", [{ default: "auto" }, { lg: "100%" }])}
`;

const LearnMore = styled(Div)`
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;

const BankInfo = styled.div`
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 0.8rem;
  padding: 1rem;
  font-size: smaller;
  margin-top: 0.8rem;
`;

interface Props {
  bankAccount: any;
  canPayMonthly: boolean;
  choosePaymentPlan: () => void;
  choosePayInFull: () => void;
  navigateToStep: (step: string) => void;
}

const PaymentChoice = ({
  bankAccount,
  canPayMonthly,
  choosePaymentPlan,
  choosePayInFull,
  navigateToStep,
}: Props) => {
  const { prepaymentUUID } = useParams<GenericObject>();
  const { prepayment } = useGetCurrentPrepayment(prepaymentUUID);
  const securityDeposit = prepayment.deposits.security_deposit;

  const handleUnapprovedPaymentPlanClick = () => {
    navigateToStep(BANK_LINK_STEP);
    registerMonthlyPaymentPlanClick(canPayMonthly, prepaymentUUID);
  };

  const handleApprovedPaymentPlanClick = () => {
    choosePaymentPlan();
    registerMonthlyPaymentPlanClick(canPayMonthly, prepaymentUUID);
  };

  return (
    <>
      <Title>How would you like to pay for your deposit?</Title>
      <Row>
        <OptionCard width={{ sm: 1, lg: 1 / 2 }}>
          {canPayMonthly ? (
            <PaymentPlan
              width={{ default: 1 }}
              onClick={handleApprovedPaymentPlanClick}
            >
              <PaymentOption>
                <Div>
                  <ChillCoin />
                </Div>
              </PaymentOption>
              <>
                <span>
                  <b>
                    Spread out your payments <br /> with Rentable
                  </b>
                </span>
                <PlanDetails>
                  Rentable Payment Plan <LearnMore>Learn More</LearnMore>
                </PlanDetails>
              </>
            </PaymentPlan>
          ) : (
            <PaymentPlan
              width={{ default: 1 }}
              onClick={handleUnapprovedPaymentPlanClick}
            >
              <PaymentOption>
                <Div>
                  <ChillCoin />
                </Div>
              </PaymentOption>
              <>
                <span>
                  <b>Do you want to pay your deposit over time?</b>
                </span>
                <Div mt={{ default: 3 }}>
                  <Button>Link a different bank account</Button>
                </Div>
              </>
            </PaymentPlan>
          )}
        </OptionCard>
        <OptionCard width={{ sm: 1, lg: 1 / 2 }}>
          <FullPayment
            width={{ default: 1 }}
            onClick={() => {
              choosePayInFull();
            }}
          >
            <PaymentOption justifyContent="space-between">
              <Div>
                <HoorayCoin />
              </Div>
              ${securityDeposit.amount}
            </PaymentOption>
            <span>
              <b>Pay in full</b>
            </span>
            {bankAccount ? (
              <BankInfo>
                <b>
                  {bankAccount.bank_name}
                  &nbsp; •••• &nbsp;
                </b>

                {bankAccount.mask}
              </BankInfo>
            ) : (
              <BankInfo>No bank account linked</BankInfo>
            )}
          </FullPayment>
        </OptionCard>
      </Row>
    </>
  );
};

export default PaymentChoice;
