import { useState } from "react";
import styled from "styled-components";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import Input from "../../../components/baseComponents/Input";
import DateInput from "../../../components/baseComponents/DateInput";
import Checkbox from "../../../components/baseComponents/Checkbox";
import Button from "../../../components/baseComponents/Button";
import { createDeposit } from "../../utils/prepaymentUtils";
import {
  CreateDepositRequest,
  NucleusCompany,
} from "../../../components/global/ModelInterfaces";
import useCompanyFromParams from "../../utils/useCompanyFromParams";

const StyledErrorMessage = styled(Row)`
  color: ${(props) => props.theme.colors.danger};
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const StyledSuccessMessage = styled(Row)`
  color: ${(props) => props.theme.colors.success};
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

interface Props {
  unitUUID: string;
  company: NucleusCompany;
}

const CreateSingleDepositForm = ({ unitUUID, company }: Props) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [endDate, setEndDate] = useState("");
  const [securityDeposit, setSecurityDeposit] = useState("");
  const [lastMonthsRent, setLastMonthsRent] = useState("");
  const [securityDepositInterest, setSecurityDepositInterest] = useState("");
  const [lastMonthsRentInterest, setLastMonthsRentInterest] = useState("");
  const [userPaid, setUserPaid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCreateSingleDeposit = () => {
    setErrorMessage("");
    setSuccessMessage("");
    if (validateForm()) {
      setLoading(true);
      const successCallback = (data: any) => {
        setSuccessMessage(
          "Successfully created prepayment with UUID: " + data.prepayment_uuid
        );
        setLoading(false);
      };
      const failureCallback = (err_data: any) => {
        setErrorMessage(err_data);
        setLoading(false);
      };
      const depositInfo: CreateDepositRequest = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        startDate: startDate,
        phoneNumber: phoneNumber,
        endDate: endDate,
        unitUUID: unitUUID,
        securityDeposit: parseFloat(securityDeposit),
        lastMonthsRent: parseFloat(lastMonthsRent),
        securityDepositInterest: parseFloat(securityDepositInterest),
        lastMonthsRentInterest: parseFloat(lastMonthsRentInterest),
        userPaid: userPaid,
      };
      createDeposit(
        depositInfo,
        company.uuid,
        successCallback,
        failureCallback
      );
    }
  };

  const validateForm = () => {
    if (!email) {
      setErrorMessage("Must include tenant's email");
      return false;
    }
    if (!firstName) {
      setErrorMessage("Must include tenant's first name");
      return false;
    }
    if (!lastName) {
      setErrorMessage("Must include tenant's last name");
      return false;
    }
    if (!startDate) {
      setErrorMessage("Must include a deposit start date");
      return false;
    }
    if (!endDate) {
      setErrorMessage("Must include a deposit end date");
      return false;
    }
    if (!securityDeposit && !lastMonthsRent) {
      setErrorMessage(
        "Must include an amount for security deposit or last month's rent"
      );
      return false;
    }
    return true;
  };

  return (
    <Div>
      <Row>
        <Div width={{ lg: 6 / 12 }}>
          <Input
            type="text"
            label="Tenant Email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Div>
        <Div width={{ lg: 6 / 12 }}>
          <Input
            type="text"
            label="Tenant Phone Number"
            placeholderText="555-555-5555"
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </Div>
      </Row>
      <Row>
        <Div width={{ lg: 6 / 12 }}>
          <Input
            type="text"
            label="Tenant First Name"
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Div>
        <Div width={{ lg: 6 / 12 }}>
          <Input
            type="text"
            label="Tenant Last Name"
            onChange={(e) => setLastName(e.target.value)}
          />
        </Div>
      </Row>
      <Row>
        <Div width={{ lg: 6 / 12 }}>
          <DateInput
            label="Start Date"
            onChange={(date: string) => setStartDate(date)}
          />
        </Div>
        <Div width={{ lg: 6 / 12 }}>
          <DateInput
            label="End Date"
            onChange={(date: string) => setEndDate(date)}
          />
        </Div>
      </Row>
      <Row>
        <Div width={{ lg: 6 / 12 }}>
          <Input
            type="text"
            label="Security Deposit Amount"
            onChange={(e) => setSecurityDeposit(e.target.value)}
          />
        </Div>
        <Div width={{ lg: 6 / 12 }}>
          <Input
            type="text"
            label="Last Month's Rent Amount"
            onChange={(e) => setLastMonthsRent(e.target.value)}
          />
        </Div>
      </Row>
      <Row>
        <Div width={{ lg: 6 / 12 }}>
          <Input
            type="text"
            label="Security Deposit Interest Amount"
            onChange={(e) => setSecurityDepositInterest(e.target.value)}
          />
        </Div>
        <Div width={{ lg: 6 / 12 }}>
          <Input
            type="text"
            label="Last Month's Rent Interest Amount"
            onChange={(e) => setLastMonthsRentInterest(e.target.value)}
          />
        </Div>
      </Row>
      <Row pt={{ lg: 2 }} pb={{ lg: 2 }}>
        <Div width={{ lg: 4 / 12 }} justifyContent="center" alignItems="center">
          <Checkbox
            value={false}
            label="User Already Paid"
            onChange={() => setUserPaid(true)}
          />
        </Div>
        <Div width={{ lg: 4 / 12 }}>
          <Button
            onClick={() => handleCreateSingleDeposit()}
            text="Submit"
            loading={loading}
          />
        </Div>
      </Row>
      {errorMessage && (
        <StyledErrorMessage justifyContent="center">
          {errorMessage}
        </StyledErrorMessage>
      )}
      {successMessage && (
        <StyledSuccessMessage justifyContent="center">
          {successMessage}
        </StyledSuccessMessage>
      )}
    </Div>
  );
};

export default CreateSingleDepositForm;
