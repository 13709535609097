import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import { emailRegex } from "../../utils/accountUtils";
import { setUser } from "../../store/auth";

import Div from "../../components/baseComponents/Div";
import Row from "../../components/baseComponents/Row";
import Input from "../../components/baseComponents/Input";
import PasswordInput from "../../components/baseComponents/PasswordInput";
import Button from "../../components/baseComponents/Button";
import {
  NucleusUser,
  GenericObject,
} from "../../components/global/ModelInterfaces";
import nucleusLogo from "../../images/nucleus_logo.png";
import { retrieveInvite, signUp, validatePassword } from "../utils/auth";
import { getQueryParamsFromString } from "../../utils/globalUtils";

const StyledLeft = styled(Div)`
  height: 100vh;
`;

const StyledRight = styled(Div)`
  background-color: ${(props) => props.theme.colors.nucleusDarkTeal};
  height: 100vh;
`;
const StyledHeader = styled(Row)`
  font-size: ${(props) => props.theme.font_size.headlineLG};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const StyledFailureMessage = styled(Row)`
  color: ${(props) => props.theme.colors.black};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const SignupPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [invite, setInvite] = useState("");
  const [inviteToken, setInviteToken] = useState("");
  const [failureMessage, setFailureMessage] = useState("");
  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const validateSignup = () => {
    const tempErrors = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    };

    if (email.length === 0 || !emailRegex.test(email)) {
      setValidationErrors({
        ...tempErrors,
        email: "Please enter a valid email.",
      });
      return false;
    } else if (!validatePassword(password)) {
      setValidationErrors({
        ...tempErrors,
        password:
          "Your password must contain at least 12 characters and include 1 digit (0-9), 1 symbol, and 1 letter.",
      });
      return false;
    } else if (firstName.length === 0) {
      setValidationErrors({
        ...tempErrors,
        firstName: "Please enter a valid first name.",
      });
      return false;
    } else if (lastName.length === 0) {
      setValidationErrors({
        ...tempErrors,
        lastName: "Please enter a valid last name.",
      });
      return false;
    } else {
      return true;
    }
  };

  const handleSignUp = () => {
    setFailureMessage("");
    setLoading(true);
    const successCallback = (user: NucleusUser) => {
      setLoading(false);
      dispatch(setUser(user));
      history.push("/nucleus/dashboard");
    };
    const failureCallback = (errorMessage: string) => {
      setLoading(false);
      setFailureMessage(errorMessage);
    };
    if (validateSignup()) {
      signUp(
        email,
        password,
        firstName,
        lastName,
        invite,
        inviteToken,
        successCallback,
        failureCallback
      );
    } else {
      setLoading(false);
    }
  };

  const handleInviteRetrieval = (inviteQuery: string, token: string) => {
    const successCallback = (res: any) => {
      if (res.status === 200) {
        const data = res.data;
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setEmail(data.email);
      }
    };
    const failureCallback = (e: any) => {
      setFailureMessage("Invite was not found");
    };
    retrieveInvite(inviteQuery, token, successCallback, failureCallback);
  };

  useEffect(() => {
    const query: GenericObject = getQueryParamsFromString(location.search);
    const queryInvite = query.invite;
    const token = query.token;
    setInvite(queryInvite);
    setInviteToken(token);
    if (queryInvite && token) {
      handleInviteRetrieval(queryInvite, token);
    }
  }, [location.search]);

  useEffect(() => {
    const handleEventListener = (e: any) => {
      if (e.key === "Enter") {
        handleSignUp();
      }
    };
    // Bind the event listener
    document.addEventListener("keypress", handleEventListener);
    // Unbind the event listener
    return () => {
      document.removeEventListener("keypress", handleEventListener);
    };
  }, [handleSignUp]);

  return (
    <Row flexWrap="nowrap">
      <StyledLeft width={{ default: 6 / 12 }} justifyContent="center">
        <StyledHeader justifyContent="center">
          Create your nucleus account.
        </StyledHeader>
        <StyledHeader justifyContent="center">
          <img key={"nucleus_logo"} src={nucleusLogo} height={"256px"} />
        </StyledHeader>
      </StyledLeft>
      <StyledRight width={{ default: 6 / 12 }} justifyContent="center">
        <Row mb={{ default: 5 }}>
          <Div width={{ default: 6 / 12, lg: 6 / 12 }}>
            <Input
              placeholderText="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Div>
          <Div width={{ default: 6 / 12, lg: 6 / 12 }}>
            <Input
              placeholderText="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Div>
        </Row>
        <Row mb={{ default: 5 }}>
          <Div width={{ default: 6 / 12, lg: 6 / 12 }}>
            <Input
              placeholderText="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Div>
          <Div width={{ default: 6 / 12, lg: 6 / 12 }}>
            <PasswordInput
              showLabel={false}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Div>
        </Row>
        {validationErrors && (
          <StyledFailureMessage justifyContent="center">
            <span>{Object.values(validationErrors)}</span>
          </StyledFailureMessage>
        )}
        {failureMessage && (
          <StyledFailureMessage justifyContent="center">
            <span>{failureMessage}</span>
          </StyledFailureMessage>
        )}

        <Row justifyContent="center" mt={{ default: 2 }}>
          <Div width={{ sm: 1, lg: 4 / 12 }} justifyContent="center">
            <Button loading={loading} text="Sign Up" onClick={handleSignUp} />
          </Div>
        </Row>
      </StyledRight>
    </Row>
  );
};

export default SignupPage;
