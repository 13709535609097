import {
  Company,
  GenericObject,
  BankAccount,
} from "../components/global/ModelInterfaces";

const SET_COMPANY = "company/SET_COMPANY";
const UPDATE_DEFAULT_BANK_ACCOUNT = "bank/UPDATE_DEFAULT_BANK_ACCOUNT";

export const setCompany = (company: Company) => {
  return {
    type: SET_COMPANY,
    company,
  };
};

export const updateDefaultBankAccount = (bankAccount: BankAccount) => {
  return {
    type: UPDATE_DEFAULT_BANK_ACCOUNT,
    account: bankAccount,
  };
};
const initialState = {
  loading: true,
  name: "",
  can_charge_fees: false,
  default_bank_account: {},
};

export default function companyReducer(
  state = initialState,
  action: GenericObject
) {
  switch (action.type) {
    case SET_COMPANY:
      return {
        ...state,
        loading: false,
        ...action.company,
      };
    case UPDATE_DEFAULT_BANK_ACCOUNT:
      return {
        ...state,
        default_bank_accounts: action.account,
      };
    default:
      return state;
  }
}
