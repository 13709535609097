import styled, { css } from "styled-components";
import { useState } from "react";
import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import Input from "../../../../../components/baseComponents/Input";
import Button from "../../../../../components/baseComponents/Button";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";
import { dateShortFormatter } from "../../../../../components/global/utils";
import { currencyFormatter } from "../../../../../components/global/utils";
import {
  GenericObject,
  RentManagerLocation,
} from "../../../../../components/global/ModelInterfaces";
import { retrieveTenantDepositCharges } from "../../../../utils/companyUtils";
import LocationSelect from "../components/LocationSelect";

const StyledInstructions = styled(Div)`
  font-size: ${(props) => props.theme.font_size.bodyMD};
`;

const TableStyle = css`
  border: 1px solid ${(props) => props.theme.colors.black};
  border-collapse: collapse;
  padding-left: 10px;
  font-size: ${(props) => props.theme.font_size.bodyMD};
`;

const StyledTableRow = styled.tr`
  table-layout: fixed;
`;

const StyledTableHeader = styled.th`
  ${TableStyle}
`;
const StyledTableSection = styled.td`
  ${TableStyle}
`;

interface Props {
  rentManagerCompany: GenericObject;
}
const DepositChargesTable = ({ rentManagerCompany }: Props) => {
  const [selectedLocation, setSelectedLocation] =
    useState<RentManagerLocation | null>(null);
  const [tenantID, setTenantID] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [depositCharges, setDepositCharges] =
    useState<Array<GenericObject> | null>(null);
  const [tenantDetails, setTenantDetails] = useState<GenericObject | null>(
    null
  );

  const handleSubmit = () => {
    if (!selectedLocation || !tenantID) {
      setErrorMessage("You must input a valid TenantID and choose a Location.");
      return;
    }
    const successCallBack = (tenantResponse: GenericObject) => {
      setTenantDetails(tenantResponse);
      setDepositCharges(tenantResponse.deposit_charges);
      setLoading(false);
    };
    const failureCallback = (errorMessage: string) => {
      if (errorMessage) {
        setErrorMessage(errorMessage);
      } else {
        setErrorMessage(
          "Could not get the charges list with the provided TenantID and location."
        );
      }
      setLoading(false);
    };
    setLoading(true);
    setErrorMessage("");
    setTenantDetails(null);
    retrieveTenantDepositCharges(
      tenantID,
      selectedLocation.uuid,
      successCallBack,
      failureCallback
    );
  };
  return (
    <Div>
      <Row alignItems="flex-start" mt={{ default: 3 }}>
        <Div width={{ default: 4 / 12 }}>
          <Row addSpace={false}>
            <StyledInstructions>
              Retrieve a tenant's security deposit charges.
              <br />
              You must input a TenantID and select a Location to the right.
            </StyledInstructions>
          </Row>
          <Row>
            <Button
              text="Retrieve Tenant's Charges"
              onClick={handleSubmit}
              loading={loading}
            />
          </Row>
        </Div>
        <Div width={{ default: 4 / 12 }}>
          <Input
            value={tenantID}
            type="number"
            onChange={(e) => {
              setTenantID(e.target.value);
            }}
            label="TenantID"
          />
        </Div>
        <Div width={{ default: 4 / 12 }}>
          <LocationSelect
            handleSelect={(selectedLocation: RentManagerLocation) => {
              setSelectedLocation(selectedLocation);
            }}
          />
        </Div>
      </Row>
      {errorMessage && (
        <Row>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Row>
      )}
      {tenantDetails && (
        <Div>
          <Row>
            <Div>Tenant Name: {tenantDetails.name}</Div>
            <Div>
              Current Lease UnitID: {tenantDetails.current_lease_unit_id}
            </Div>
            <Div>
              Security Deposit Held: {tenantDetails.security_deposit_held}
            </Div>
          </Row>
        </Div>
      )}
      {depositCharges && depositCharges.length > 0 && (
        <>
          <table>
            <StyledTableRow>
              <StyledTableHeader>ChargeID</StyledTableHeader>
              <StyledTableHeader>Charge Date</StyledTableHeader>
              <StyledTableHeader>Unit ID</StyledTableHeader>
              <StyledTableHeader>Amount</StyledTableHeader>
              <StyledTableHeader>Amount Allocated</StyledTableHeader>
              <StyledTableHeader>Is Fully Allocated</StyledTableHeader>
            </StyledTableRow>
            {depositCharges.map((charge: GenericObject) => {
              return (
                <StyledTableRow>
                  <StyledTableSection>{charge.ChargeID}</StyledTableSection>
                  <StyledTableSection>
                    {dateShortFormatter(charge.TransactionDate)}
                  </StyledTableSection>
                  <StyledTableSection>{charge.UnitID}</StyledTableSection>
                  <StyledTableSection>
                    {currencyFormatter.format(charge.Amount)}
                  </StyledTableSection>
                  <StyledTableSection>
                    {charge.AmountAllocated
                      ? currencyFormatter.format(charge.AmountAllocated)
                      : "0.00"}
                  </StyledTableSection>
                  <StyledTableSection>
                    {charge.IsFullyAllocated
                      ? charge.IsFullyAllocated.toString()
                      : "False"}
                  </StyledTableSection>
                </StyledTableRow>
              );
            })}
          </table>
        </>
      )}
    </Div>
  );
};

export default DepositChargesTable;
