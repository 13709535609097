import { useState } from "react";
import { useParams } from "react-router-dom";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import Button from "../../../components/baseComponents/Button";
import CurrencyInput from "../../../components/baseComponents/CurrencyInput";
import Dropdown from "../../../components/baseComponents/Dropdown";
import ErrorMessage from "../../../components/baseComponents/ErrorMessage";
import {
  GenericObject,
  Option,
} from "../../../components/global/ModelInterfaces";
import { addDepositToPrepayment } from "../../utils/prepaymentUtils";

interface Props {
  setDeposits: Function;
  depositOptions: Array<Option>;
}

const AddDepositForm = ({ setDeposits, depositOptions }: Props) => {
  const { prepaymentUUID } = useParams<GenericObject>();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [amount, setAmount] = useState(0);
  const [type, setType] = useState<Option | null>(null);

  const validateForm = () => {
    if (!amount || !type) {
      setError("You must fill out the amount and choose a Deposit Type.");
      return false;
    }
    setError("");
    return true;
  };

  const handleAddDeposit = () => {
    const successCallback = (deposits: GenericObject) => {
      setLoading(false);
      setError("");
      setDeposits(deposits);
    };
    const failureCallback = (errorMessage: string) => {
      setLoading(false);
      setError(errorMessage);
    };

    if (validateForm()) {
      setLoading(true);
      addDepositToPrepayment(
        prepaymentUUID,
        amount,
        type,
        successCallback,
        failureCallback
      );
    }
  };

  return (
    <Div>
      <Row mt={{ default: 3 }}>
        <Div width={{ default: 1 }}>
          <CurrencyInput label="Amount" onChange={setAmount} />
        </Div>
      </Row>
      <Row>
        <Div width={{ default: 1 }}>
          <Dropdown
            onClick={(e) => {
              setType(e);
              setError("");
            }}
            name={"Deposit Type"}
            placeholder={"Select a Deposit Type"}
            options={depositOptions}
            value={type}
          />
        </Div>
      </Row>
      {error && (
        <Row>
          <ErrorMessage>{error}</ErrorMessage>
        </Row>
      )}
      <Row mt={{ default: 3 }}>
        <Div width={{ default: 1 }}>
          <Button
            type="secondary"
            text="Create Deposit"
            onClick={handleAddDeposit}
            loading={loading}
          />
        </Div>
      </Row>
    </Div>
  );
};

export default AddDepositForm;
