import { GenericObject } from "../ModelInterfaces";
import rules from "./MARules";
import { toUpper } from "lodash";

export const rulesList = {
  SHOW_STATEMENT_OF_CONDITION: "showStatementOfCondition",
  CAN_CREATE_LAST_MONTHS_RENT: "canCreateLastMonthsRent",
  MUST_SIGN_DEDUCTIONS: "mustSignDeductions",
};

export const defaultRules: GenericObject = {
  showStatementOfCondition: false,
  canCreateLastMonthsRent: false,
  mustSignDeductions: false,
};

interface Props {
  state: string;
  rule: string;
}

export const getStateRule = ({ state, rule }: Props) => {
  switch (toUpper(state)) {
    case "MA":
      return (
        rules[rule as keyof GenericObject] ||
        defaultRules[rule as keyof GenericObject]
      );
  }
};
