import { useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import styled from "styled-components";

import BeatLoader from "react-spinners/BeatLoader";
import PlaidButton from "../PlaidButton";
import Div from "./Div";
import Modal from "./Modal";
import ErrorMessage from "./ErrorMessage";
import useGetBankAccount from "../../utils/useGetBankAccount";
import { removeBankAccount } from "../../store/bankInfo";
import { unlinkBankAccount } from "../../utils/accountUtils";

interface Props {
  showModal: boolean;
  closeModal: () => void;
  includeAssets?: boolean;
}

const StyledContainer = styled(Div)`
  padding: 2.5rem;
  border-radius: 1rem;
  background-color: white;
`;

const StyledTitle = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.bolder};
  font-size: ${(props) => props.theme.font_size.headlineXS};
  margin-bottom: 2rem;
`;

const StyledSuccessMessage = styled(Div)`
  color: ${(props) => props.theme.colors.primary};
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

function UnlinkBankModal({
  showModal,
  closeModal,
  includeAssets = false,
}: Props) {
  const dispatch = useAppDispatch();
  const { bankAccount } = useGetBankAccount();
  const [unlinkLoading, setUnlinkLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const handleSuccess = () => {
    setUnlinkLoading(false);
    setSuccessMessage("Your account was successfully replaced!");
    const successCallback = () => {
      dispatch(removeBankAccount(bankAccount));
    };
    const failureCallback = (errorMessage: string) => {
      setErrorMessage(errorMessage);
    };
    unlinkBankAccount(bankAccount, successCallback, failureCallback);
  };

  const handleFail = (err: Error) => {
    if (err.message) {
      setErrorMessage(err.message);
    } else {
      setErrorMessage(
        "There was an error replacing your Bank Account. Please try again or contact support@rentable.com if you have any questions."
      );
    }
  };

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <StyledContainer justifyContent="center" alignItems="center">
        {errorMessage ? (
          <ErrorMessage>{errorMessage}</ErrorMessage>
        ) : (
          <>
            {successMessage ? (
              <StyledSuccessMessage>{successMessage}</StyledSuccessMessage>
            ) : (
              <>
                <StyledTitle width={{ default: 9 / 12 }} textAlign="center">
                  Are you sure you want to replace your bank account
                  {bankAccount
                    ? `: ${bankAccount.name} - ${bankAccount.mask}`
                    : ""}
                  ?
                </StyledTitle>
                <Div width={{ default: 9 / 12 }} alignItems={"center"}>
                  {!unlinkLoading && (
                    <PlaidButton
                      includeAssets={includeAssets}
                      customText="Replace Account"
                      onSuccess={handleSuccess}
                      onFail={handleFail}
                    />
                  )}
                  {unlinkLoading && <BeatLoader />}
                </Div>
              </>
            )}
          </>
        )}
      </StyledContainer>
    </Modal>
  );
}

export default UnlinkBankModal;
