import { useAppSelector } from "../store/hooks";
import { useParams } from "react-router-dom";
import {
  Prepayment,
  GenericObject,
} from "../components/global/ModelInterfaces";
import { RootState } from "..";
const useGetPrepayment = () => {
  const { prepaymentUUID } = useParams<GenericObject>();
  const prepaymentSlice: { loading: boolean; objects: Prepayment[] } =
    useAppSelector((state: RootState) => state.prepayments);
  const loadingPrepayments = prepaymentSlice.loading;
  const selectedPrepayment = prepaymentSlice.objects.find(
    (prepayment: Prepayment) => prepayment.uuid === prepaymentUUID
  );

  return { selectedPrepayment, loadingPrepayments };
};

export default useGetPrepayment;
