import styled from "styled-components";
import Div from "../baseComponents/Div";
import Row from "../baseComponents/Row";
import { Metric, Prepayment } from "../global/ModelInterfaces";
import { cssBreakpoints } from "../global/theme";
interface Props {
  metrics: Metric[];
  prepayment?: Prepayment;
}

const StyledMetrics = styled(Div)`
  ${cssBreakpoints("margin-bottom", [{ md: "0rem" }])}
`;

const StyledlRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const StyledName = styled.div`
  color: ${(props) => props.theme.colors.grey80};
  ${cssBreakpoints("margin-bottom", [{ sm: "0" }, { md: "0" }])}
  ${cssBreakpoints("font-size", [{ sm: "1rem" }, { md: ".875rem" }])}
  padding: unset;
`;

const StyledValue = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.colors.navy};
  padding: unset;
  ${cssBreakpoints("font-size", [{ sm: "1rem" }, { md: "2rem" }])}
`;

const MetricsOverview = ({ metrics }: Props) => {
  return (
    <StyledMetrics
      justifyContent="space-between"
      display={{ sm: "none", md: "none", lg: "flex" }}
    >
      <Row>
        <Div width={{ sm: 1 / 3, md: 1, lg: 1 }}>
          <StyledlRow>
            {metrics.map((metric: Metric) => {
              return (
                <Div width={{ sm: 1, lg: 1 / metrics.length }}>
                  <StyledName>{metric.name}</StyledName>
                </Div>
              );
            })}
          </StyledlRow>
        </Div>
        <Div width={{ sm: 2 / 3, md: 1, lg: 1 }}>
          <StyledlRow>
            {metrics.map((metric: Metric) => {
              return (
                <>
                  <Div width={{ sm: 1, lg: 1 / metrics.length }}>
                    <Row flexWrap="nowrap" alignItems="center">
                      <StyledValue>{metric.value}</StyledValue>
                    </Row>
                  </Div>
                </>
              );
            })}
          </StyledlRow>
        </Div>
      </Row>
    </StyledMetrics>
  );
};

export default MetricsOverview;
