import styled from "styled-components";
import { DateTime } from "luxon";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import RentableCard from "../../baseComponents/RentableCard";
import InterestDetails from "./InterestDetails";
import { GenericObject } from "../../global/ModelInterfaces";
import { currencyFormatter } from "../../global/utils";
interface Props {
  prepaymentInfo: GenericObject;
  onboardingInfo: GenericObject | undefined;
}

const StyledLabel = styled.span`
  font-weight: 600;
`;

const LeaseDetailsCard = ({ prepaymentInfo, onboardingInfo }: Props) => {
  return (
    <RentableCard title={prepaymentInfo?.street_address}>
      <>
        <Row>
          <span>
            <b>Congratulations! </b>You are all set. See more information about
            your lease below. If you need to change any of your personal
            information click your initials in the top header, or email us at
            support@rentable.com.
          </span>
        </Row>
        <hr />

        <Div
          width={{ sm: 1, lg: 1 }}
          justifyContent="center"
          alignItems="center"
        >
          {prepaymentInfo?.deposits.security_deposit && (
            <Row>
              <StyledLabel>Security Deposit Amount:</StyledLabel>
              {currencyFormatter.format(
                prepaymentInfo?.deposits.security_deposit.amount
              )}
            </Row>
          )}
          {prepaymentInfo?.deposits.last_months_rent && (
            <Row>
              <StyledLabel>Last Month's Rent Amount:</StyledLabel>
              {currencyFormatter.format(
                prepaymentInfo?.deposits.last_months_rent.amount
              )}
            </Row>
          )}
          <Row>
            <StyledLabel>Lease Start Date:</StyledLabel>
            {`${DateTime.fromISO(prepaymentInfo?.start_date).toFormat(
              "MMMM dd, yyyy"
            )}`}
          </Row>
          <Row>
            <StyledLabel>Date Paid:</StyledLabel>
            {`${DateTime.fromISO(onboardingInfo?.payment_completed_at).toFormat(
              "MMMM dd, yyyy"
            )}`}
          </Row>
          {prepaymentInfo?.deposits?.security_deposit && (
            <InterestDetails
              transactions={prepaymentInfo?.deposits?.security_deposit}
              deposit_type="Security Deposit"
            />
          )}
          {prepaymentInfo?.deposits?.last_months_rent && (
            <InterestDetails
              transactions={prepaymentInfo?.deposits?.last_months_rent}
              deposit_type="Last Month's Rent"
            />
          )}
        </Div>
      </>
    </RentableCard>
  );
};

export default LeaseDetailsCard;
