import { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";

import Div from "../baseComponents/Div";
import Row from "../baseComponents/Row";
import Input from "../baseComponents/Input";
import Button from "../baseComponents/Button";
import Checkbox from "../baseComponents/Checkbox";
import ErrorMessage from "../baseComponents/ErrorMessage";
import PageLoader from "../PageLoader";

import { GenericObject } from "../global/ModelInterfaces";
import {
  emailRegex,
  validatePassword,
  passwordErrorMessage,
} from "../../utils/accountUtils";
import { getQueryParamsFromString } from "../../utils/globalUtils";
import { signup } from "../../store/auth";
import { retrieveInvite } from "../../utils/accountUtils";

import PasswordInput from "../baseComponents/PasswordInput";

const StyledButtonRow = styled(Row)`
  margin-top: 2.5rem;
`;

export default function LoginForm() {
  const location = useLocation();
  const history = useHistory();
  const isInitialLoadHandled = useRef(false);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [role, setRole] = useState("tenant");
  const [agree, setAgree] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [invite, setInvite] = useState<string>("");
  const [inviteToken, setInviteToken] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [signupErrors, setSignupErrors] = useState<GenericObject>({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
  });

  const validateSignup = () => {
    const tempErrors = signupErrors;
    if (!agree) {
      setErrorMessage(
        "You must agree to our Terms and Services and Privacy Policy."
      );
      return;
    } else if (firstName.length === 0) {
      setErrorMessage("Please enter a valid first name.");
      setLoading(false);
      setSignupErrors({
        ...tempErrors,
        firstName: true,
      });
      return false;
    } else if (lastName.length === 0) {
      setErrorMessage("Please enter a valid last name.");
      setLoading(false);
      setSignupErrors({
        ...tempErrors,
        lastName: true,
      });
      return false;
    } else if (email.length === 0 || !emailRegex.test(email)) {
      setErrorMessage("Please enter a valid email.");
      setLoading(false);
      setSignupErrors({
        ...tempErrors,
        email: true,
      });
      return false;
    } else if (!validatePassword(password)) {
      setErrorMessage(passwordErrorMessage);
      setLoading(false);
      setSignupErrors({
        ...tempErrors,
        password: true,
      });
      return false;
    } else {
      setLoading(false);
      return true;
    }
  };

  const handleSubmit = () => {
    const failureCallback = (errorMessage: string) => {
      setErrorMessage(errorMessage);
      setSignupErrors({
        firstName: true,
        lastName: true,
        password: true,
        email: true,
      });
      setLoading(false);
    };
    if (validateSignup()) {
      setLoading(true);
      signup(
        firstName,
        lastName,
        email,
        password,
        invite,
        inviteToken,
        failureCallback
      );
    }
  };

  useEffect(() => {
    if (!isInitialLoadHandled.current) {
      const handleInitialLoad = async () => {
        setLoadingInvite(true);
        const query: GenericObject = getQueryParamsFromString(location.search);
        const queryInvite = query.invite;
        const token = query.token;
        setInvite(queryInvite);
        setInviteToken(token);
        if (queryInvite && token) {
          const res = await retrieveInvite(queryInvite, token);
          if (res.status === 200) {
            const data = res.data;
            setFirstName(data.first_name);
            setLastName(data.last_name);
            setEmail(data.email);
            setRole(data.is_tenant ? "tenant" : "landlord");
            setLoadingInvite(false);
          } else {
            history.push("/login");
          }
        } else {
          window.location.assign("https://www.rentable.com/contact");
        }
      };
      handleInitialLoad();
      isInitialLoadHandled.current = true;
    }
  }, [location.search]);

  if (loadingInvite) {
    return <PageLoader />;
  }

  return (
    <Div width={{ sm: 1, md: 1, lg: 1 }} alignItems="center">
      <Row>
        <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
          <Input
            label="First Name"
            value={firstName}
            error={signupErrors.firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Div>
        <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
          <Input
            label="Last Name"
            value={lastName}
            error={signupErrors.lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Div>
      </Row>
      <Row>
        <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
          <Input
            label="Email"
            value={email}
            error={signupErrors.email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Div>
        <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
          <PasswordInput
            error={signupErrors.password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Div>
      </Row>
      <Div width={{ sm: 1, md: 1, lg: 9 / 12 }}>
        <Row alignItems="center">
          <Div width={{ sm: 2 / 12, md: 2 / 12, lg: 1 / 12 }}>
            <Checkbox value={agree} onChange={() => setAgree(!agree)} />
          </Div>
          <Div width={{ sm: 10 / 12, md: 10 / 12, lg: 11 / 12 }}>
            <span>
              By checking this box you agree to our{" "}
              <a
                href={
                  role === "landlord"
                    ? "https://rentable.com/landlord-terms-of-services"
                    : "https://rentable.com/tenant-terms-of-services"
                }
                className="secondary-link"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href={"https://rentable.com/privacy-policy"}
                className="secondary-link"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>{" "}
              as well as our partner{" "}
              <a
                href="https://www.dwolla.com/legal/tos/"
                className="secondary-link"
                target="_blank"
                rel="noreferrer"
              >
                Dwolla's Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="https://www.dwolla.com/legal/privacy/"
                className="secondary-link"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </span>
          </Div>
        </Row>
      </Div>

      <Row justifyContent="center">
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </Row>
      <StyledButtonRow justifyContent="center">
        <Div width={{ sm: 1, lg: 1 / 2 }}>
          <Button
            text="Sign Up"
            loading={loading}
            disabled={loading}
            onClick={handleSubmit}
          />
        </Div>
      </StyledButtonRow>
    </Div>
  );
}
