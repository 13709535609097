import { useState } from "react";

import {
  hasPendingBankAccounts,
  hasPendingManualBankAccounts,
  getLatestBalanceForUser,
} from "../../../utils/accountUtils";

import PlaidButton from "../../PlaidButton";
import Button from "../../baseComponents/Button";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import ReplaceBankModal from "../../baseComponents/ReplaceBankModal";
import styled from "styled-components";
import useGetBankAccount from "../../../utils/useGetBankAccount";
import { cssBreakpoints } from "../../global/theme";
import useGetCurrentPrepayment from "../../../utils/useGetCurrentPrepayment";
import PageLoader from "../../PageLoader";
import prepaymentStatuses from "../../../enums/prepaymentStatuses";
import { Redirect, useParams } from "react-router-dom";
import { GenericObject } from "../../global/ModelInterfaces";

const StyledHeader = styled(Div)`
  margin-bottom: 1rem;
  padding: 0 1rem;
  font-weight: ${(props) => props.theme.font_weight.bolder};
  ${(props) =>
    cssBreakpoints("font-size", [
      { sm: props.theme.font_size.headlineMD },
      { lg: props.theme.font_size.headlineLG },
    ])}
`;

const StyledHelperText = styled(Div)`
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const ButtonRow = styled(Row)`
  margin-top: 1rem;
`;

const StyledButton = styled(Div)`
  ${cssBreakpoints("margin-top", [{ sm: "2rem" }, { lg: "1rem" }])}
`;

const BankDetailRow = styled(Row)`
  padding: 0 1rem;
  font-size: ${(props) => props.theme.font_size.headlineMD};
`;

const MicroDepositText = styled(Div)`
  font-size: ${(props) => props.theme.font_size.bodyLG};
  margin-bottom: 1.5rem;
`;

interface Props {
  nextStep: () => void;
}

const BankLinkStep = ({ nextStep }: Props) => {
  const { bankAccount, bankAccountList, loadingBankAccounts } =
    useGetBankAccount();

  const { prepaymentUUID } = useParams<GenericObject>();
  const { prepayment, loadingTenantInfo } =
    useGetCurrentPrepayment(prepaymentUUID);
  const [replaceAccountOpen, setReplaceAccountOpen] = useState<boolean>(false);
  const [bankLinkSuccess, setBankLinkSuccess] = useState<boolean>(false);

  const handleLinkSuccess = () => {
    setBankLinkSuccess(true);
    getLatestBalanceForUser();
  };

  if (loadingBankAccounts || loadingTenantInfo) {
    return <PageLoader />;
  }

  if (!prepayment) {
    return <Redirect to="/dashboard" />;
  }

  if (bankAccount) {
    return (
      <>
        <Div width={{ sm: 1, md: 1, lg: 9 / 12 }}>
          <StyledHeader alignItems="center">
            Bank Account Information:
          </StyledHeader>
          <BankDetailRow justifyContent="center">
            {bankAccount.bank_name} - {bankAccount.name} • • • •{" "}
            {bankAccount.mask}
          </BankDetailRow>
          {bankAccount.pending_confirmation ? (
            <Row justifyContent="center">
              <Div alignItems="center">
                <MicroDepositText>
                  Please confirm your micro-deposits before continuing.
                </MicroDepositText>
                <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
                  <PlaidButton />
                </Div>
              </Div>
            </Row>
          ) : (
            <ButtonRow>
              <StyledButton width={{ sm: 1, md: 1, lg: 1 / 2 }}>
                <Button
                  type="secondary"
                  onClick={() => {
                    setReplaceAccountOpen(true);
                  }}
                >
                  Replace Bank Account
                </Button>
              </StyledButton>
              <StyledButton width={{ sm: 1, md: 1, lg: 1 / 2 }}>
                <Button onClick={nextStep}>Continue</Button>
              </StyledButton>
            </ButtonRow>
          )}
        </Div>
        <ReplaceBankModal
          showModal={replaceAccountOpen}
          closeModal={() => setReplaceAccountOpen(false)}
          includeAssets={true}
        />
      </>
    );
  }

  return (
    <Div width={{ default: 1 }}>
      <>
        {hasPendingBankAccounts(bankAccountList) ? (
          <Row justifyContent="center">
            <StyledHeader width={{ default: 1 }} alignItems="center">
              {hasPendingManualBankAccounts(bankAccountList)
                ? "Please finish confirming your account using the button below."
                : ""}
            </StyledHeader>
            <ButtonRow>
              <PlaidButton
                onSuccess={handleLinkSuccess}
                canPayByCard={true}
                includeAssets={true}
              />
            </ButtonRow>
          </Row>
        ) : bankLinkSuccess ? (
          <Row justifyContent="center">
            <StyledHeader width={{ default: 1 }} alignItems="center">
              You've successfully linked your bank account. Please click the
              button below to continue to funding your deposit
            </StyledHeader>
            <Div width={{ default: 1, lg: 4 / 12 }}>
              <Button text={"Continue"} onClick={nextStep} />
            </Div>
          </Row>
        ) : (
          <Row justifyContent="center">
            <StyledHeader width={{ default: 1 }} alignItems="center">
              Please link your primary bank account.
            </StyledHeader>
            <StyledHelperText width={{ default: 1 }} alignItems="center">
              The bank account you receive a paycheck to, pay rent out of, etc.
            </StyledHelperText>
            <ButtonRow justifyContent="center">
              <PlaidButton
                onSuccess={handleLinkSuccess}
                canPayByCard={
                  prepayment.status_id === prepaymentStatuses.PENDING
                }
                includeAssets={true}
              />
            </ButtonRow>
          </Row>
        )}
      </>
    </Div>
  );
};

export default BankLinkStep;
