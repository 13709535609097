import { useParams } from "react-router-dom";
import styled from "styled-components";

import Div from "../../baseComponents/Div";
import Modal from "../../baseComponents/Modal";
import Row from "../../baseComponents/Row";
import Button from "../../baseComponents/Button";
import ChillCoin from "../../../images/ChillCoin";
import SquareCheckIcon from "../../../images/SquareCheckIcon";
import DollarIcon from "../../../images/DollarIcon";
import PiggyIcon from "../../../images/PiggyIcon";
import MoneySignIcon from "../../../images/MoneySignIcon";
import useGetCurrentPrepayment from "../../../utils/useGetCurrentPrepayment";
import { COLORS } from "../../global/colors";
import { cssBreakpoints } from "../../global/theme";
import { registerMonthlyPaymentPlanClick } from "../../../utils/depositUtils";
import { GenericObject } from "../../global/ModelInterfaces";
import { currencyFormatter } from "../../global/utils";
interface Props {
  showModal: boolean;
  closeModal: () => void;
  paymentPlanApproval: GenericObject;
  showPaymentPlan: () => void;
}

const StyledTitle = styled(Div)`
  font-weight: 600;
  ${cssBreakpoints("font-size", [{ sm: "1.5rem" }, { md: "2rem" }])}
`;

const StyledModal = styled(Div)`
  color: ${COLORS.white};
  padding: 1rem 0rem;
`;

const StyledRow = styled(Row)`
  margin: 0.5rem 0rem;
`;

const PaymentPlanInfoModal = ({
  showModal,
  closeModal,
  paymentPlanApproval,
  showPaymentPlan,
}: Props) => {
  const { prepaymentUUID } = useParams<GenericObject>();
  const { prepayment } = useGetCurrentPrepayment(prepaymentUUID);
  const securityDeposit = prepayment.deposits.security_deposit;

  const feeAmount =
    ((securityDeposit.amount - paymentPlanApproval.approved_initial_payment) /
      (paymentPlanApproval.approved_number_months - 1)) *
    paymentPlanApproval.approved_fee_rate;

  return (
    <Modal showModal={showModal} variant={"dark"} closeModal={closeModal}>
      <StyledModal width={{ default: 1 }}>
        <StyledRow alignItems="center">
          <Div>
            <ChillCoin />
          </Div>
          <StyledTitle>Pay your deposit over time!</StyledTitle>
        </StyledRow>
        <StyledRow flexWrap="no-wrap" alignItems="center">
          <Div>
            <SquareCheckIcon />
          </Div>
          <Div>Rentable is partnered with your property manager</Div>
        </StyledRow>
        <StyledRow flexWrap="no-wrap" alignItems="center">
          <Div>
            <DollarIcon color={COLORS.primary} />
          </Div>
          <Div>Keep more money in your pocket</Div>
        </StyledRow>
        <StyledRow flexWrap="no-wrap" alignItems="center">
          <Div>
            <MoneySignIcon />
          </Div>
          <Div>Only {currencyFormatter.format(feeAmount)} per month</Div>
        </StyledRow>
        <StyledRow flexWrap="no-wrap" alignItems="center">
          <Div>
            <PiggyIcon />
          </Div>
          <Div>Save up your deposit by the end of your lease</Div>
        </StyledRow>
        <StyledRow justifyContent="center">
          <Div>
            <Button
              onClick={() => {
                registerMonthlyPaymentPlanClick(true, prepaymentUUID);
                closeModal();
                showPaymentPlan();
              }}
            >
              <span>Get started for {currencyFormatter.format(feeAmount)}</span>
            </Button>
          </Div>
        </StyledRow>
      </StyledModal>
    </Modal>
  );
};

export default PaymentPlanInfoModal;
