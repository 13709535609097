import { StatusCodes } from "http-status-codes";
import axios from "axios";
import {
  TOKENKEY,
  TOKENFIELD,
  TOKENPREFIX,
  SPACECHAR,
  LOGINPATH,
} from "../constants";
import {
  AUTH_TOKEN_NAME as NUCLEUS_AUTH_TOKEN,
  SESSION_TOKEN_NAME as NUCLEUS_SESSION_TOKEN,
} from "../../AppNucleus";
import { exceptUrls } from "./UrlExceptions";
import { delete_localstore_item, get_localstore_item } from "../tools";

// Add the token to outgoing request if the url is not in exceptUrls
axios.interceptors.request.use(
  (request) => {
    if (!exceptUrls.includes(request.url)) {
      const token = get_localstore_item(TOKENKEY);
      if (token) {
        request.headers[TOKENFIELD] = TOKENPREFIX + SPACECHAR + token;
      }
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, (error) => {
  // TODO set error message here?
  if (!exceptUrls.includes(error.response.config.url)) {
    // switch should be used when more status codes are added
    if (error.response.status === StatusCodes.FORBIDDEN) {
      window.location.href = "/forbidden";
    } else if (error.response.status === StatusCodes.UNAUTHORIZED) {
      // TODO is possible to save the page state to load it after login?
      if (error.response.config.url.includes("/nucleus")) {
        delete_localstore_item(NUCLEUS_AUTH_TOKEN);
        delete_localstore_item(NUCLEUS_SESSION_TOKEN);
        window.location.href = "/nucleus/login";
      } else {
        delete_localstore_item(TOKENKEY);
        if (window.location.pathname !== LOGINPATH) {
          window.location.href = LOGINPATH;
        }
      }
    }
  }
  return Promise.reject(error.response);
});
