import styled from "styled-components";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import DropdownCard from "../../../components/baseComponents/DropdownCard";
import { GenericObject } from "../../../components/global/ModelInterfaces";
import {
  currencyFormatter,
  dateShortFormatter,
} from "../../../components/global/utils";
import HorizontalDivider from "../../../components/baseComponents/HorizontalDivider";

interface Props {
  deductionsInfo: GenericObject;
}

const StyledLabel = styled.span`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;
const StyledSubTitle = styled(Row)`
  font-size: ${(props) => props.theme.font_size.headlineSM};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;
const StyledText = styled.span`
  font-size: ${(props) => props.theme.font_size.headlineMD};
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const DeductionsInfo = ({ deductionsInfo }: Props) => {
  const renderDepositDetails = (depositDeductionsInfo: GenericObject) => {
    const deductionTransaction = depositDeductionsInfo.deduction_transaction;
    const dwollaTransaction = depositDeductionsInfo.dwolla_transaction;
    const deductionBankAccount = depositDeductionsInfo.bank_account;
    return (
      <Div width={"fill"}>
        <StyledSubTitle>
          {depositDeductionsInfo.type} Deduction Info
        </StyledSubTitle>
        <Div>
          <Row>
            <StyledLabel>
              Deposit Status: {depositDeductionsInfo.status}
            </StyledLabel>
          </Row>
          {deductionTransaction && (
            <>
              <Row>
                <StyledLabel>
                  Disbursement Date:{" "}
                  {dateShortFormatter(deductionTransaction.transaction_date)}
                </StyledLabel>
              </Row>
              <Row>
                <StyledLabel>
                  Amount withheld:{" "}
                  {currencyFormatter.format(deductionTransaction.amount)}
                </StyledLabel>
              </Row>
              <Row>
                <StyledLabel>
                  Deduction Transaction ID: {deductionTransaction.id}
                </StyledLabel>
              </Row>
            </>
          )}
          {dwollaTransaction && (
            <Row>
              <StyledLabel>
                Dwolla Transaction ID: {dwollaTransaction.id}
              </StyledLabel>
            </Row>
          )}
          {deductionBankAccount && (
            <>
              <Row>
                <StyledLabel>
                  Landlord Recipient Bank: {deductionBankAccount.bank_name}
                </StyledLabel>
              </Row>
              <Row>
                <StyledLabel>
                  Landlord Recipient Bank Account Name:{" "}
                  {deductionBankAccount.name}
                </StyledLabel>
              </Row>
              <Row>
                <StyledLabel>
                  Landlord Recipient Bank Account UUID:{" "}
                  {deductionBankAccount.uuid}
                </StyledLabel>
              </Row>
            </>
          )}
        </Div>
      </Div>
    );
  };

  return (
    <DropdownCard title="Deductions Info">
      <Div width={{ default: 12 / 12 }}>
        {deductionsInfo ? (
          <>
            {deductionsInfo.security_deposit && (
              <Row mb={{ default: 4 }}>
                {renderDepositDetails(deductionsInfo.security_deposit)}
              </Row>
            )}
            <HorizontalDivider />
            {deductionsInfo.last_months_rent && (
              <Row mb={{ default: 4 }}>
                {renderDepositDetails(deductionsInfo.last_months_rent)}
              </Row>
            )}
          </>
        ) : (
          <Row>
            <StyledText>This prepayment has no deductions</StyledText>
          </Row>
        )}
      </Div>
    </DropdownCard>
  );
};

export default DeductionsInfo;
