import Container from "./Container";
import Row from "./Row";
import Div from "./Div";

const TestPage = () => {
  return (
    <Container>
      <Row alignItems="center">
        <Div>
          <div>Div 1</div>
          <div>Div 2</div>
        </Div>
        <Div>Div 3</Div>
      </Row>
      <Row alignItems="flex-start">
        <Div>
          <div>Div 4</div>
          <div>Div 5</div>
        </Div>
        <Div>DIv 6</Div>
      </Row>
      <Row>
        <Div
          width={{ default: 1, md: 1 / 2, lg: 1 }}
          display={{ sm: "none", md: "flex" }}
        >
          Div 7
        </Div>
        <Div width={{ default: 1, md: 1 / 2, lg: 1 / 4 }}>Div 8</Div>
      </Row>
      <Row>
        <Div width={{ default: 1, md: 1 / 2 }}>Div 9</Div>
        <Div width={{ default: 1, md: 1 / 2 }}>Div 10</Div>
      </Row>
    </Container>
  );
};

export default TestPage;
