import styled from "styled-components";
import RentableLogo from "../baseComponents/RentableLogo";
import Row from "./Row";
import Div from "../baseComponents/Div";
import Menu from "../baseComponents/Menu";
import { NavLink } from "react-router-dom";
import { MenuItem } from "../global/ModelInterfaces";

const StyledNav = styled(Div)`
  min-width: 100vw;
  min-height: 100px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

interface Props {
  menuItems: MenuItem[];
  children?: JSX.Element;
}

const Navbar = ({ menuItems, children }: Props) => {
  return (
    <StyledNav pl={{ sm: 4, md: 5 }} pr={{ md: 5 }}>
      <NavLink to={"/dashboard"}>
        <RentableLogo />
      </NavLink>
      <Div width={{ default: 1 }}>
        <Row justifyContent="flex-end" flexWrap="no-wrap">
          {children}
          <Div
            justifyContent="center"
            width={{ sm: 1 / 2, md: 1 / 8, lg: 1 / 12 }}
          >
            <Menu menuItems={menuItems} />
          </Div>
        </Row>
      </Div>
    </StyledNav>
  );
};

export default Navbar;
