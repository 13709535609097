import { useEffect, useState } from "react";
import styled from "styled-components";
import { variant, alignItems } from "styled-system";
import BeatLoader from "react-spinners/BeatLoader";

interface StyleProps {
  variant?: string;
  alignItems?: string;
}

const StyledButton = styled.div<StyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  border-radius: ${(props) => props.theme.border_radius.SM};
  color: ${(props) => props.theme.colors.white};
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  padding: 1rem;
  text-align: center;
  white-space: nowrap;
  ${alignItems}

  &:hover {
    cursor: pointer;
  }

  ${(props) =>
    variant({
      //takes a variant prop string and assigns one of the variant styles equal to the prop
      //e.g. --> variant === primary, primary styling is applied
      variants: {
        //_sm === small size
        primary: {
          backgroundColor: props.theme.colors.primary,
          border: "2px solid " + props.theme.colors.primary,
          "&:hover": {
            bg: props.theme.colors.hoverGreen,
          },
          "&:active": {
            bg: props.theme.colors.clickGreen,
          },
        },
        primary_sm: {
          padding: "0.68rem",
          backgroundColor: props.theme.colors.primary,
          border: "2px solid " + props.theme.colors.primary,
          "&:hover": {
            bg: props.theme.colors.hoverGreen,
          },
          "&:active": {
            bg: props.theme.colors.clickGreen,
          },
        },
        secondary: {
          backgroundColor: "none",
          border: "2px solid black",
          color: "black",
          "&:hover": {
            bg: props.theme.colors.hoverGrey,
          },
          "&:active": {
            bg: props.theme.colors.clickGrey,
          },
        },
        secondary_sm: {
          padding: "0.68rem",
          backgroundColor: "none",
          border: "2px solid black",
          color: "black",
          "&:hover": {
            bg: props.theme.colors.hoverGrey,
          },
          "&:active": {
            bg: props.theme.colors.clickGrey,
          },
        },
        primary_slim: {
          padding: "0.1rem",
          backgroundColor: props.theme.colors.primary,
          border: "1px dotted black",
          color: "black",
          "&:hover": {
            bg: props.theme.colors.hoverGrey,
          },
          "&:active": {
            bg: props.theme.colors.grey80,
          },
        },
        secondary_slim: {
          padding: "0.1rem",
          backgroundColor: props.theme.colors.backgroundDisabled,
          border: "1px dotted black",
          color: "gray",
          "&:hover": {
            bg: props.theme.colors.primary25,
          },
          "&:active": {
            bg: props.theme.colors.grey80,
          },
        },

        disabled: {
          backgroundColor: props.theme.colors.backgroundDisabled,
          border: "2px solid " + props.theme.colors.backgroundDisabled,
          color: props.theme.colors.disabledText,
          "&:hover": {
            cursor: "default",
          },
        },
        disabled_sm: {
          padding: "0.68rem",
          backgroundColor: props.theme.colors.backgroundDisabled,
          border: "2px solid " + props.theme.colors.backgroundDisabled,
          color: props.theme.colors.disabledText,
          "&:hover": {
            cursor: "default",
          },
        },
        back: {
          background: "none",
          color: props.theme.colors.black,
          border: "2px solid " + props.theme.colors.black,
          justifyContent: "flex-start",
          fontSize: props.theme.font_size.bodyMD,
        },
        warning: {
          backgroundColor: props.theme.colors.warning,
        },
        danger: {
          backgroundColor: props.theme.colors.danger,
        },
      },
    })}
`;

const StyledText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface Props {
  //takes in button text (optional), button type (required), button disabling (optional),
  //and onClick prop (optional)
  text?: string;
  type?: string;
  disabled?: boolean;
  onDisabledClick?: () => void;
  loading?: boolean;
  onClick?: () => void;
  className?: string;
  alignItems?: string;
  children?: JSX.Element | string;
}

const Button = ({
  text,
  disabled,
  onDisabledClick,
  loading,
  onClick,
  alignItems,
  children,
  className,
  type = "primary",
}: Props) => {
  const [disabledType, setDisabledType] = useState("");

  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
    if (disabled && onDisabledClick) {
      onDisabledClick();
    }
  };

  useEffect(() => {
    //if disabled prop is true, check if the user is using a small or regular sized button
    //and use the appropriate disabled button variant
    if (disabled) {
      setDisabledType(type.includes("_sm") ? "disabled_sm" : "disabled");
    } else {
      setDisabledType("");
    }
  }, [disabled]);

  return (
    <StyledButton
      variant={disabledType ? disabledType : type}
      onClick={handleClick}
      alignItems={alignItems}
      className={className}
    >
      {loading ? <BeatLoader /> : <StyledText>{text || children}</StyledText>}
    </StyledButton>
  );
};
export default Button;
