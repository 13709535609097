import { Redirect, useHistory } from "react-router-dom";
import styled from "styled-components";
import Container from "../../components/baseComponents/Container";
import Div from "../../components/baseComponents/Div";
import Row from "../../components/baseComponents/Row";
import Button from "../../components/baseComponents/Button";
import { testThrowError } from "../utils/adminUtils";
import { useState } from "react";
import ErrorView from "../../components/baseComponents/ErrorView";
import useNucleusPermissions from "../../utils/useNucleusPermissions";
import { nucleusUserPermissions } from "../../enums/nucleusPermissions";
import NucleusLoader from "../components/NucleusLoader";
import BackButton from "../../components/baseComponents/BackButton";

const StyledHeader = styled(Row)`
  font-size: ${(props) => props.theme.font_size.headlineLG};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const StyledLoading = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineLG};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const AdminPage = () => {
  const history = useHistory();
  const [hasError, setHasError] = useState(false);
  const { loadingPermissions, adminSettingsPermission } =
    useNucleusPermissions();

  const handleThrowError = () => {
    const failureCallback = (err: any) => {
      setHasError(true);
    };
    testThrowError(failureCallback);
  };

  if (loadingPermissions) {
    return (
      <Row justifyContent="center">
        <StyledLoading width={{ default: 3 / 12 }}>
          <NucleusLoader />
        </StyledLoading>
      </Row>
    );
  }

  if (adminSettingsPermission !== nucleusUserPermissions.WRITE) {
    return <Redirect to={"/nucleus/forbidden"} />;
  }

  if (hasError) {
    return (
      <Div pt={{ default: 5 }} pl={{ default: 5 }}>
        <Div onClick={() => setHasError(false)}>
          <BackButton returnUrl="/nucleus/admin" text="Go Back" />
        </Div>
        <ErrorView />
      </Div>
    );
  }

  return (
    <Container>
      <StyledHeader
        justifyContent="center"
        mt={{ default: 5 }}
        mb={{ default: 5 }}
      >
        Admin Tools
      </StyledHeader>
      <Row justifyContent="center">
        <Div width={{ default: 4 / 12 }} mt={{ default: 3 }}>
          <Button onClick={() => history.push("/nucleus/admin/invite-user")}>
            <>Invite User</>
          </Button>
        </Div>
      </Row>
      <Row justifyContent="center">
        <Div width={{ default: 4 / 12 }} mt={{ default: 3 }}>
          <Button onClick={handleThrowError}>
            <>Throw a Test Error</>
          </Button>
        </Div>
      </Row>
      <Row justifyContent="center">
        <Div width={{ default: 4 / 12 }} mt={{ default: 3 }}>
          <Button
            onClick={() => history.push("/nucleus/admin/send-test-email")}
          >
            <>Send Test Email</>
          </Button>
        </Div>
      </Row>
    </Container>
  );
};

export default AdminPage;
