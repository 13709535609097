const SET_LANDLORDS = "admin/SET_LANDLORDS";

export const setLandlords = (landlords) => {
  return {
    type: SET_LANDLORDS,
    landlords: landlords,
  };
};

const initialState = {
  loading: true,
  objects: [],
};

export default function landlordsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LANDLORDS:
      return {
        ...state,
        loading: false,
        objects: action.landlords,
      };
    default:
      return state;
  }
}
