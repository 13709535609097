import styled from "styled-components";
import { cssBreakpoints } from "../global/theme";
import Div from "./Div";

const StyledListItemTitleBody = styled(Div)`
  padding: 1.5rem;
`;

const StyledTitle = styled(Div)`
  font-weight: bold;
  color: ${(props) => props.theme.colors.navy};
  ${cssBreakpoints("font-size", [{ sm: "1rem" }, { md: "1.25rem" }])}
`;

const StyledSubtitle = styled(Div)`
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${(props) => props.theme.colors.grey80};
  ${cssBreakpoints("font-size", [{ sm: "1rem" }, { md: "1.125rem" }])}
`;

interface Props {
  title: string;
  details?: Array<string>;
}

const ListItemTitle = ({ title, details }: Props) => {
  return (
    <StyledListItemTitleBody justifyContent="center">
      <StyledTitle>{title}</StyledTitle>
      {details &&
        details.length > 0 &&
        details?.map((detail) => {
          return <StyledSubtitle>{detail}</StyledSubtitle>;
        })}
    </StyledListItemTitleBody>
  );
};

export default ListItemTitle;
