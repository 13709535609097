import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { RootState } from "../..";
import styled from "styled-components";
import { linkBankToBuilding } from "../../utils/buildingUtils";
import { setDefaultBank } from "../../utils/feeUtils";
import ErrorMessage from "../baseComponents/ErrorMessage";
import Div from "../baseComponents/Div";
import Row from "../baseComponents/Row";
import Container from "../baseComponents/Container";
import BackButton from "../baseComponents/BackButton";
import DropdownListItem from "../baseComponents/DropdownListItem";
import { useMemo, useState, useEffect } from "react";
import {
  BankAccount,
  Building,
  GenericObject,
} from "../global/ModelInterfaces";
import { updateBuildingLocal } from "../../store/buildings";
import { updateDefaultBankAccount } from "../../store/company";

const BuildingsTitle = styled(Div)`
  color: ${(props) => props.theme.colors.navy};
  padding: 36px 0;
  font-size: ${(props) => props.theme.font_size.headlineLG};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const DefaultBankTitle = styled(Div)`
  color: ${(props) => props.theme.colors.navy};
  padding: 24px 0;
  font-size: ${(props) => props.theme.font_size.headlineXS};
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const DefaultBankComponent = styled(Div)`
  color: ${(props) => props.theme.colors.navy};
  padding: 16px;
  margin-bottom: 24px;
  border: 1px solid ${(props) => props.theme.colors.navy};
  border-radius: ${(props) => props.theme.border_radius.SM};
`;

const BanktoBuildingLink = () => {
  const dispatch = useAppDispatch();

  const buildingSlice: {
    loading: Boolean;
    objects: Building[];
  } = useAppSelector((state: RootState) => state.buildings);
  const buildings = buildingSlice.objects;
  const [error, setError] = useState("");
  const banksSlice = useAppSelector((state: RootState) => state.bankInfo);
  const defaultBank = useAppSelector(
    (state: RootState) => state.companies.default_bank_account
  );

  const [displayDefaultBank, setDisplayDefaultBank] = useState(defaultBank);
  useEffect(() => {
    setDisplayDefaultBank(defaultBank);
  }, [defaultBank]);
  const banks = banksSlice.objects;

  const bankSearchOptions = useMemo(
    () =>
      banks.map((bank: BankAccount) => {
        return {
          id: bank.uuid,
          label: bank.bank_name + " " + bank.mask,

          uuid: bank.uuid,
          name: bank.name,
          bank_name: bank.bank_name,
          mask: bank.mask,
        };
      }),
    [banks]
  );

  const addDefaultBankSuccessCallback = (bank: BankAccount) => {
    dispatch(updateDefaultBankAccount(bank));
  };
  const addDefaultBankFailureCallback = () => {
    setError("Could not add default bank");
  };
  const addDefaultBank = (bank: GenericObject) => {
    const bankUuid = bank.id;
    setDefaultBank(
      bankUuid,
      addDefaultBankSuccessCallback,
      addDefaultBankFailureCallback
    );
    setDisplayDefaultBank(bank);
  };

  const addBankToBuildingSuccessCallback = (building: Building) => {
    dispatch(updateBuildingLocal(building));
  };

  const addBankToBuilding = (building: Building, bank: GenericObject) => {
    const building_uuid = building.uuid;
    const bank_uuid = bank.key;
    linkBankToBuilding(
      building_uuid,
      bank_uuid,
      addBankToBuildingSuccessCallback
    );
  };

  const retrieveExistingBank = (building: Building) => {
    const linkedBank = building.bank_account_uuid
      ? banks.find((bank: BankAccount) => {
          return bank.uuid === building.bank_account_uuid;
        })
      : null;
    if (linkedBank) {
      return {
        key: linkedBank.uuid,
        title: linkedBank.name,
        values: [linkedBank.bank_name, linkedBank.mask],
      };
    } else {
      return defaultBank
        ? {
            key: defaultBank.uuid,
            title: "Company Default Bank",
            values: [defaultBank.bank_name, defaultBank.mask],
          }
        : null;
    }
  };

  const formatDefaultBankAccount = (defaultBank: BankAccount) => {
    if (!defaultBank) {
      return [];
    }

    const defaultbankToDisplay = {
      key: defaultBank.uuid,
      title: defaultBank.name,
      values: [defaultBank.bank_name, defaultBank.mask],
    };

    return [defaultbankToDisplay];
  };

  return (
    <Container>
      <Div>
        <BackButton returnUrl="/settings" text="Settings Home" />
        <Row justifyContent="center">
          <Div width={{ sm: 1, md: 6 / 12, lg: 10 / 12 }}>
            <Row justifyContent="space-between">
              <BuildingsTitle>Link Banks to Buildings</BuildingsTitle>
            </Row>
            <Row>
              <DefaultBankComponent width={{ sm: 1, md: 6 / 12, lg: 10 / 12 }}>
                <DefaultBankTitle>
                  Select a Default Bank Account:
                </DefaultBankTitle>
                <DropdownListItem
                  key={defaultBank?.uuid || null}
                  title={defaultBank?.bank_name || "No default bank set"}
                  onAdd={(bank) => {
                    addDefaultBank(bank);
                    setDisplayDefaultBank(bank);
                  }}
                  existingSelections={formatDefaultBankAccount(
                    displayDefaultBank
                  )}
                  searchOptions={bankSearchOptions}
                  searchPlaceholderText={"Add New Default Company Bank"}
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </DefaultBankComponent>
            </Row>
            {buildings.map((building: Building) => {
              const selectedBank = retrieveExistingBank(building);
              const existingSelections = selectedBank ? [selectedBank] : null;

              return (
                <DropdownListItem
                  key={building.id}
                  title={building.name}
                  details={[
                    building.address_1,
                    building.city +
                      ", " +
                      building.state +
                      " " +
                      building.zip_code,
                  ]}
                  onAdd={(bank) => addBankToBuilding(building, bank)}
                  existingSelections={existingSelections}
                  searchOptions={bankSearchOptions}
                  searchPlaceholderText={"Add Bank to Building"}
                />
              );
            })}
          </Div>
        </Row>
      </Div>
    </Container>
  );
};

export default BanktoBuildingLink;
