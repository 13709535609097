// React & Hooks
import { useEffect, useState } from "react";
import { Redirect, useParams, useHistory } from "react-router-dom";
import useGetCurrentPrepayment from "../../../utils/useGetCurrentPrepayment";

// Styled Components
import styled from "styled-components";

// Utils
import { currencyFormatter } from "../../global/utils";
import { GenericObject } from "../../global/ModelInterfaces";
import { cssBreakpoints } from "../../global/theme";
import {
  disburseViaCheckWithFee,
  disburseViaCheckWithoutFee,
} from "../../../utils/disbursementUtils";

// Base Components
import BackButton from "../../baseComponents/BackButton";
import Button from "../../baseComponents/Button";
import Div from "../../baseComponents/Div";
import ErrorMessage from "../../baseComponents/ErrorMessage";
import ForwardingAddressForm from "./ForwardingAddressForm";
import Label from "../../baseComponents/Label";
import Modal from "../../baseComponents/Modal";
import PageLoader from "../../PageLoader";
import Row from "../../baseComponents/Row";

const StyledTitle = styled(Row)`
  font-size: ${(props) => props.theme.font_size.headlineSM};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  ${cssBreakpoints("margin-top", [{ sm: "2rem" }, { md: "0" }])}
`;

const StyledLineItem = styled(Row)`
  ${cssBreakpoints("white-space", [{ sm: "normal" }, { md: "nowrap" }])}
`;

const CheckDisbursementView = () => {
  const { prepaymentUUID } = useParams<GenericObject>();
  const history = useHistory();
  const { loadingTenantInfo, prepayment, offboarding } =
    useGetCurrentPrepayment(prepaymentUUID);
  const prepaymentInfo = prepayment;

  const [error, setError] = useState("");
  const [forwardingAddress, setForwardingAddress] = useState<GenericObject>({
    name: prepaymentInfo ? prepaymentInfo.tenant_name : "",
    streetAddress: "",
    unitAddress: "",
    city: "",
    state: "",
    zipcode: "",
  });
  const [addressErrors, setAddressErrors] = useState<GenericObject>({});
  const [fetchingCheckoutSession, setFetchingCheckoutSession] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendCheckModalOpen, setSendCheckModalOpen] = useState(false);

  const validateAddress = () => {
    let tmpErrors = addressErrors;
    if (!forwardingAddress.name) {
      tmpErrors = { ...tmpErrors, name: true };
    }
    if (!forwardingAddress.streetAddress) {
      tmpErrors = { ...tmpErrors, streetAddress: true };
    }
    if (!forwardingAddress.city) {
      tmpErrors = { ...tmpErrors, city: true };
    }
    if (!forwardingAddress.state) {
      tmpErrors = { ...tmpErrors, state: true };
    }
    if (!forwardingAddress.zipcode) {
      tmpErrors = { ...tmpErrors, zipcode: true };
    }
    setAddressErrors(tmpErrors);
    if (Object.keys(tmpErrors).some((key: string) => !!tmpErrors[key])) {
      setError("Please enter all the required fields before continuing.");
    }
    return !Object.keys(tmpErrors).some((key: string) => !!tmpErrors[key]);
  };

  const clearError = (key: string) => {
    if (key === "all") {
      setAddressErrors({});
    } else {
      let tmpErrors = addressErrors;
      tmpErrors[key] = false;
      setAddressErrors(tmpErrors);
    }
  };

  const handlePayment = async () => {
    if (validateAddress()) {
      setFetchingCheckoutSession(true);

      const failureCallback = (message: string) => {
        setFetchingCheckoutSession(false);
        setError(message);
      };

      await disburseViaCheckWithFee(
        forwardingAddress.name,
        forwardingAddress.streetAddress,
        forwardingAddress.unitAddress,
        forwardingAddress.city,
        forwardingAddress.state,
        forwardingAddress.zipcode,
        failureCallback,
        prepaymentUUID
      );
    }
  };

  const handleSendCheck = () => {
    setLoading(true);
    const successCallback = () => {
      setLoading(false);
      history.push(`/tenant/offboarding/check-success/${prepaymentUUID}`);
    };
    const failureCallback = (errorMessage: string) => {
      setLoading(false);
      setError(errorMessage);
    };

    disburseViaCheckWithoutFee(
      forwardingAddress.name,
      forwardingAddress.streetAddress,
      forwardingAddress.unitAddress,
      forwardingAddress.city,
      forwardingAddress.state,
      forwardingAddress.zipcode,
      successCallback,
      failureCallback,
      prepaymentUUID
    );
  };

  useEffect(() => {
    if (prepaymentInfo) {
      setForwardingAddress((forwardingAddress) => {
        return { ...forwardingAddress, name: prepaymentInfo.tenant_name };
      });
    }
  }, [prepaymentInfo]);

  if (loadingTenantInfo || fetchingCheckoutSession) {
    return <PageLoader />;
  }

  if (!offboarding && !loadingTenantInfo) {
    return <Redirect to={`/dashboard`} />;
  }

  return (
    <Div alignItems="center">
      <Row>
        <BackButton text="Go Back" returnUrl="/tenant/offboarding" />
      </Row>
      <Div width={{ default: 1, lg: 10 / 12 }}>
        <StyledTitle justifyContent="center" alignItems="flex-start">
          Receive Your Funds Via Mail
        </StyledTitle>
        <Row mt={{ default: 3 }}>
          {parseFloat(offboarding.returned_deposit_amount) > 0 && (
            <LineItem
              title={"Security Deposit Amount"}
              amount={offboarding.returned_deposit_amount}
            />
          )}
          {parseFloat(offboarding.security_deposit_interest) > 0 && (
            <LineItem
              title={"Security Deposit Interest"}
              amount={offboarding.security_deposit_interest}
            />
          )}
          {parseFloat(offboarding.last_months_rent_amount) > 0 && (
            <LineItem
              title={"Last Month's Rent Amount"}
              amount={offboarding.returned_last_months_rent_amount}
            />
          )}
          {parseFloat(offboarding.last_months_rent_interest) > 0 && (
            <LineItem
              title={"Last Month's Rent Interest"}
              amount={offboarding.last_months_rent_interest}
            />
          )}
          <Row alignItems="end" mt={{ default: 3 }}>
            <Label width={{ default: 8 / 12, lg: 6 / 12 }}>
              Total amount to be returned:
            </Label>
            <Div>
              {currencyFormatter.format(offboarding.total_amount_to_disburse)}
            </Div>
          </Row>
          {offboarding.check_sending_fee > 0 && (
            <Row alignItems="end" mt={{ default: 3 }}>
              <Label width={{ default: 8 / 12, lg: 6 / 12 }}>Fee Amount:</Label>
              <Div>
                {currencyFormatter.format(offboarding.check_sending_fee)}
              </Div>
            </Row>
          )}
        </Row>
        {error && (
          <Row justifyContent="center" mt={{ default: 3 }}>
            <ErrorMessage>{error}</ErrorMessage>
          </Row>
        )}
        <Row>
          <Div width={{ default: 1 }} mt={{ default: 3 }}>
            <ForwardingAddressForm
              forwardingAddress={forwardingAddress}
              updateForwardingAddress={(updatedAddress: GenericObject) => {
                setForwardingAddress(updatedAddress);
                clearError("all");
                setError("");
              }}
              addressErrors={addressErrors}
            />
          </Div>
          <Row justifyContent="center" mt={{ default: 3 }}>
            <Div width={{ default: 6 / 12 }}>
              {offboarding.check_sending_fee > 0 ? (
                <>
                  <Button text="Continue to Payment" onClick={handlePayment} />
                </>
              ) : (
                <>
                  <Button
                    text="Continue"
                    onClick={() => {
                      if (validateAddress()) {
                        setSendCheckModalOpen(true);
                      }
                    }}
                  />
                  <SendCheckModal
                    open={sendCheckModalOpen}
                    closeModal={() => setSendCheckModalOpen(false)}
                    forwardingAddress={forwardingAddress}
                    confirmSend={handleSendCheck}
                    loading={loading}
                  />
                </>
              )}
            </Div>
          </Row>
        </Row>
      </Div>
    </Div>
  );
};

interface LineItemProps {
  title: string;
  amount: string;
}

const LineItem = ({ title, amount }: LineItemProps) => {
  return (
    <StyledLineItem alignItems="end">
      <Label width={{ default: 8 / 12, lg: 6 / 12 }}>{title}:</Label>
      <Div>{currencyFormatter.format(parseFloat(amount))}</Div>
    </StyledLineItem>
  );
};

interface ModalProps {
  open: boolean;
  closeModal: () => void;
  forwardingAddress: GenericObject;
  confirmSend: () => void;
  loading: boolean;
}
const SendCheckModal = ({
  open,
  closeModal,
  forwardingAddress,
  confirmSend,
  loading,
}: ModalProps) => {
  return (
    <Modal showModal={open} closeModal={closeModal}>
      <Div>
        <Row>Send check to the following address:</Row>
        <Row>{forwardingAddress.name}</Row>
        <Row>
          {forwardingAddress.streetAddress}
          {forwardingAddress.unit && `, ${forwardingAddress.unit}`}
        </Row>
        <Row>
          {forwardingAddress.city}, {forwardingAddress.state},{" "}
          {forwardingAddress.zipcode}
        </Row>
        <Row mt={{ default: 3 }}>
          <Button text="Send Check" onClick={confirmSend} loading={loading} />
        </Row>
      </Div>
    </Modal>
  );
};

export default CheckDisbursementView;
