import { useState } from "react";
import styled from "styled-components";
import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import Input from "../../../../../components/baseComponents/Input";
import Button from "../../../../../components/baseComponents/Button";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";
import AutocompleteDropdown from "../../../../../components/baseComponents/AutocompleteDropdown";
import HorizontalDivider from "../../../../../components/baseComponents/HorizontalDivider";
import { Option } from "../../../../../components/global/ModelInterfaces";
import Checkbox from "../../../../../components/baseComponents/Checkbox";
import { YardiCompanyData } from "../interfaces/YardiCompanyInterface";

const StyledInstructions = styled.span`
  font-size: ${(props) => props.theme.font_size.bodyLG};
`;

/*
 * Form II. Accounting settings
 *  Collects the accounting settings for the Yardi integration
 */
const SetYardiAccountingForm = ({
  yardiCompanySettings,
  foundBuildings,
  glAccountsOptions,
  onCompletedCallback,
}: {
  yardiCompanySettings: YardiCompanyData;
  foundBuildings: number;
  glAccountsOptions: Option[];
  onCompletedCallback: Function;
}) => {
  const [error, setError] = useState("");
  const [yardiBankId, setYardiBankId] = useState("");
  const [depositAccount, setDepositAccount] = useState<Option | null>(null);
  const [payoutAccount, setPayoutAccount] = useState<Option | null>(null);
  const [revenueAccount, setRevenueAccount] = useState<Option | null>(null);
  const [companyNotes, setCompanyNotes] = useState("");
  const [rntbCharge, setRntbCharge] = useState(false);

  const depositAccountOnClick = (option: Option) => {
    setError("");
    setDepositAccount(option);
  };

  const payoutAccountOnClick = (option: Option) => {
    setError("");
    setPayoutAccount(option);
  };

  const revenueAccountOnClick = (option: Option) => {
    setError("");
    setRevenueAccount(option);
  };

  const validateFieldsAndSubmit = () => {
    // Validate fields and call back to the parent component
    setError("");
    if (!yardiBankId)
      setError("You must provide a cash account bank id before submitting.");
    else if (!depositAccount)
      setError("You must select Deposit Account before submitting.");
    else if (!payoutAccount)
      setError("You must select Deposit Payable Account before submitting.");
    else if (!revenueAccount)
      setError("You must select the Revenue before submitting.");
    else if (!rntbCharge) setError('Verify "rntbdepo" was added to Yardi DB.');
    else
      onCompletedCallback({
        ...yardiCompanySettings,
        yardiBankId: yardiBankId,
        companyNotes: companyNotes,
        depositAccount: depositAccount ? depositAccount["label"] : "",
        disbursementAccount: payoutAccount ? payoutAccount["label"] : "",
        revenueAccount: revenueAccount ? revenueAccount["label"] : "",
      });
  };

  return (
    <Div width={{ default: 1 }}>
      <Div>Found: {foundBuildings} buildings.</Div>
      <HorizontalDivider />
      <Div width={{ default: 1 }} mb={{ default: 3 }}>
        <StyledInstructions>
          <h2>Step II. Accounting settings</h2>
        </StyledInstructions>
      </Div>
      <Row>
        <Div width={{ default: 12 / 12 }} mb={{ default: 3 }}>
          <Checkbox
            label="Charge rntbdepo was added to the client's DB"
            value={rntbCharge}
            onChange={(e) => {
              setError("");
              setRntbCharge(e.target.checked);
            }}
          />
        </Div>
      </Row>

      <StyledInstructions>Accounts</StyledInstructions>
      <Row>
        <Div width={{ default: 12 / 12 }} mb={{ default: 3 }}>
          <Input
            label="Cash account bank id (gab)"
            value={yardiBankId}
            onChange={(e) => {
              setError("");
              setYardiBankId(e.target.value);
            }}
            error={!yardiBankId && !!error}
          />
        </Div>
      </Row>
      <Row>
        <Div width={{ default: 12 / 12 }} mt={{ default: 2 }}>
          <AutocompleteDropdown
            name="Deposit Account (2250-0000)"
            options={glAccountsOptions}
            placeholder="Specify GL account for deposits"
            onClick={depositAccountOnClick}
            value={
              depositAccount
                ? {
                    label: depositAccount.label,
                    id: depositAccount.id,
                  }
                : null
            }
            error={!depositAccount && !!error}
          />
        </Div>
      </Row>
      <Row>
        <Div width={{ default: 12 / 12 }} mt={{ default: 2 }}>
          <AutocompleteDropdown
            name="Payout Account (2270-0000)"
            options={glAccountsOptions}
            placeholder="Specify GL account for Payable Deposits"
            onClick={payoutAccountOnClick}
            value={
              payoutAccount
                ? {
                    label: payoutAccount.label,
                    id: payoutAccount.id,
                  }
                : null
            }
            error={!payoutAccount && !!error}
          />
        </Div>
      </Row>
      <Row>
        <Div width={{ default: 12 / 12 }} mt={{ default: 2 }}>
          <AutocompleteDropdown
            name="Revenue Account (1106-0000)"
            options={glAccountsOptions}
            placeholder="Specify GL Revenue account for deductions"
            onClick={revenueAccountOnClick}
            value={
              revenueAccount
                ? {
                    label: revenueAccount.label,
                    id: revenueAccount.id,
                  }
                : null
            }
            error={!revenueAccount && !!error}
          />
        </Div>
      </Row>

      <Row>
        <Div width={{ default: 12 / 12 }} mb={{ default: 3 }}>
          <Input
            label="Notes"
            value={companyNotes}
            onChange={(e) => {
              setError("");
              setCompanyNotes(e.target.value);
            }}
          />
        </Div>
      </Row>

      {error && (
        <Div mb={{ default: 3 }}>
          <ErrorMessage>{error}</ErrorMessage>
        </Div>
      )}
      <Div width={{ default: 1 }}>
        <Button text="Create Integration" onClick={validateFieldsAndSubmit} />
      </Div>
    </Div>
  );
};

export default SetYardiAccountingForm;
