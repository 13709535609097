import React from "react";

export default function DocumentIcon() {
  return (
    <svg
      width="20"
      height="29"
      viewBox="0 0 30 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.125 31.25H16.875C16.5297 31.25 16.25 31.5297 16.25 31.875V33.125C16.25 33.4703 16.5297 33.75 16.875 33.75H23.125C23.4703 33.75 23.75 33.4703 23.75 33.125V31.875C23.75 31.5297 23.4703 31.25 23.125 31.25ZM6.25 18.75V26.25C6.25 26.9406 6.80937 27.5 7.5 27.5H22.5C23.1906 27.5 23.75 26.9406 23.75 26.25V18.75C23.75 18.0594 23.1906 17.5 22.5 17.5H7.5C6.80937 17.5 6.25 18.0594 6.25 18.75ZM8.75 20H21.25V25H8.75V20ZM28.893 7.65469L22.3391 1.10156C21.6359 0.398438 20.6828 0 19.6914 0H3.74922C1.67969 0.0078125 0 1.6875 0 3.75703V36.2508C0 38.3203 1.67969 40 3.74922 40H26.2445C28.3148 40 30 38.3203 30 36.2508V10.3102C30 9.31875 29.5961 8.35781 28.893 7.65469ZM19.9961 4.06172L25.9406 10.0055H19.9961V4.06172ZM26.25 36.2508H3.74922V3.75703H16.2469V11.8805C16.2469 12.9195 17.0828 13.7547 18.1219 13.7547H26.25V36.2508ZM6.875 8.75H13.125C13.4703 8.75 13.75 8.47031 13.75 8.125V6.875C13.75 6.52969 13.4703 6.25 13.125 6.25H6.875C6.52969 6.25 6.25 6.52969 6.25 6.875V8.125C6.25 8.47031 6.52969 8.75 6.875 8.75ZM6.875 13.75H13.125C13.4703 13.75 13.75 13.4703 13.75 13.125V11.875C13.75 11.5297 13.4703 11.25 13.125 11.25H6.875C6.52969 11.25 6.25 11.5297 6.25 11.875V13.125C6.25 13.4703 6.52969 13.75 6.875 13.75Z"
        fill="#2F374B"
      />
    </svg>
  );
}
