const ADD_PREPAYMENT = "prepayments/ADD_PREPAYMENTS";
const UPDATE_PREPAYMENT = "prepayments/UPDATE_PREPAYMENT";
const UPDATE_PREPAYMENT_SECTION = "prepayments/UPDATE_SECTION";
const UPDATE_INTEGRATION_INFO_SECTION =
  "prepayments/UPDATE_INTEGRATION_INFO_SECTION";

const DELETE_PREPAYMENT = "prepayments/DELETE_PREPAYMENTS";

// Adds Prepayment to objects list
export const addPrepayment = (prepayment) => {
  return {
    type: ADD_PREPAYMENT,
    prepayment,
  };
};

// Updates existing Prepayment
export const updatePrepayment = (prepayment) => {
  return {
    type: UPDATE_PREPAYMENT,
    prepayment,
  };
};

// Updates Prepayment Section
export const updatePrepaymentSection = (
  prepaymentUUID,
  sectionKey,
  updatedInfo
) => {
  return {
    type: UPDATE_PREPAYMENT_SECTION,
    prepaymentUUID,
    sectionKey,
    updatedInfo,
  };
};

// Updates Integration Info Section
export const updatePrepaymentIntegrationInfo = (
  prepaymentUUID,
  sectionKey,
  updatedInfo
) => {
  return {
    type: UPDATE_INTEGRATION_INFO_SECTION,
    prepaymentUUID,
    sectionKey,
    updatedInfo,
  };
};

// Deletes existing Prepayment
export const deletePrepayment = (uuid) => {
  return {
    type: DELETE_PREPAYMENT,
    uuid,
  };
};

const initialState = {
  loading: true,
  objects: [],
};

export default function prepaymentReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_PREPAYMENT:
      return {
        ...state,
        loading: false,
        objects: state.objects.concat(action.prepayment),
      };
    case UPDATE_PREPAYMENT:
      return {
        ...state,
        loading: false,
        objects: state.objects.map((prepayment) => {
          if (action.prepayment.uuid === prepayment.uuid) {
            return action.prepayment;
          } else return prepayment;
        }),
      };
    case UPDATE_PREPAYMENT_SECTION:
      return {
        ...state,
        loading: false,
        objects: state.objects.map((prepayment) => {
          if (
            action.prepaymentUUID === prepayment.general_info.prepayment_uuid
          ) {
            let tempPrepayment = prepayment;
            tempPrepayment[action.sectionKey] = action.updatedInfo;
            return tempPrepayment;
          } else return prepayment;
        }),
      };
    case UPDATE_INTEGRATION_INFO_SECTION:
      return {
        ...state,
        loading: false,
        objects: state.objects.map((prepayment) => {
          if (
            action.prepaymentUUID === prepayment.general_info.prepayment_uuid
          ) {
            let tempPrepayment = prepayment;
            tempPrepayment["integration_info"][action.sectionKey] =
              action.updatedInfo;
            return tempPrepayment;
          } else return prepayment;
        }),
      };
    case DELETE_PREPAYMENT:
      return {
        ...state,
        loading: false,
        objects: state.objects.filter(
          (prepayment) => prepayment.uuid !== action.uuid
        ),
      };
    default:
      return state;
  }
}
