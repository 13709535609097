import { useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../global/colors";
import { v4 as uuid } from "uuid";
import Div from "../../baseComponents/Div";
import Button from "../../baseComponents/Button";
import DeductionRow from "./DeductionRow";
import DeductionCard from "./DeductionCard";
import { Deduction } from "../../global/ModelInterfaces";
import { cssBreakpoints } from "../../global/theme";

const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.875rem;
  color: ${COLORS.grey};
`;

const StyledButton = styled(Div)`
  white-space: nowrap;
  margin-bottom: 1.5rem;
`;

const StyledDescription = styled(Div)`
  font-size: ${(props) => props.theme.font_size.bodyMD};
`;

interface Props {
  deductions: Deduction[];
  updateDeductions: Function;
}

const Deductions = ({ deductions, updateDeductions }: Props) => {
  const [pendingDeduction, setPendingDeduction] = useState<Deduction | null>(
    null
  );

  const closeDeductionCard = () => {
    setPendingDeduction(null);
  };

  const removeDeduction = (uuid: string) => {
    updateDeductions((deductions: Deduction[]) => {
      return deductions.filter((deduction) => {
        return deduction.uuid !== uuid;
      });
    });
  };

  const addPendingDeduction = () => {
    const pendingDeduction: Deduction = {
      uuid: uuid(),
      amount: null,
      category: 0,
      documentation: null,
      description: null,
    };
    setPendingDeduction(pendingDeduction);
  };

  const addDeduction = () => {
    const deductionsCopy = [...deductions];
    if (pendingDeduction) {
      deductionsCopy.push(pendingDeduction);
      updateDeductions(deductionsCopy);
    }
  };

  return (
    <Div>
      <StyledTitle>Deductions</StyledTitle>
      <StyledDescription mb={{ default: 3 }}>
        Deductions are taken from the tenant's security deposit and go to the
        landlord's bank account.
      </StyledDescription>
      {deductions.length > 0 &&
        deductions.map((deduction) => {
          return (
            <DeductionRow
              deduction={deduction}
              removeDeduction={removeDeduction}
            />
          );
        })}
      {pendingDeduction && (
        <DeductionCard
          pendingDeduction={pendingDeduction}
          addDeduction={addDeduction}
          closeDeductionCard={closeDeductionCard}
        />
      )}
      <StyledButton width={{ lg: 1 / 3, sm: 1 }}>
        <Button
          type="secondary"
          text={"Add Deduction"}
          onClick={addPendingDeduction}
          disabled={!!pendingDeduction}
        />
      </StyledButton>
    </Div>
  );
};

export default Deductions;
