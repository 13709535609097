import React, { useState, useEffect } from "react";
import { noop } from "lodash";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  loadPlaidLinkToken,
  loadUpdatePlaidLinkToken,
  handlePlaidSuccess,
  hasPendingManualBankAccounts,
  getPendingManualBankAccounts,
  hasPendingBankAccounts,
  getPendingBankAccounts,
  linkProcessorToBank,
} from "../utils/accountUtils";
import { usePlaidLink, PlaidLinkError } from "react-plaid-link";
import BeatLoader from "react-spinners/BeatLoader";
import ErrorMessage from "./baseComponents/ErrorMessage";
import { GenericObject } from "./global/ModelInterfaces";
import Button from "./baseComponents/Button";
import useGetBankAccount from "../utils/useGetBankAccount";
import { useParams } from "react-router-dom";

interface Props {
  onSuccess?: () => void;
  onFail?: (e: Error) => void;
  canPayByCard?: boolean;
  includeAssets?: boolean;
  updateMode?: boolean;
  customText?: string;
  type?: string;
}

const PlaidButton = ({
  onSuccess = noop,
  onFail = noop,
  canPayByCard = false,
  includeAssets = true,
  updateMode = false,
  customText = "Link Account",
  type,
}: Props) => {
  const dispatch = useAppDispatch();
  const { prepaymentUUID } = useParams<GenericObject>();
  const { bankAccountList, loadingBankAccounts } = useGetBankAccount();
  const [plaidLinkToken, setPlaidLinkToken] = useState("");
  const [fundingLoading, setFundingLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showManualMessage, setShowManualMessage] = useState(false);
  const [closedAtLeastOnce, setClosedAtLeastOnce] = useState(false);
  const [currentIncludeAssets, setCurrentIncludeAssets] =
    useState(includeAssets);

  const onFailHandler = (e: Error) => {
    // Show error a message instead of the button element
    setErrorMessage(`${e.message}`);

    onFail(e);
  };

  const linkNewAccount = async (_: string, metaData: GenericObject) => {
    setFundingLoading(true);
    handlePlaidSuccess(metaData, dispatch, currentIncludeAssets)
      .then((data) => {
        if (data.account.pending_confirmation) {
          setPlaidLinkToken(data.account.plaid_link);
        }
        onSuccess();
      })
      .catch((err) => {
        onFailHandler(err);
      })
      .finally(() => {
        setFundingLoading(false);
      });
  };

  const onExit = async (event: PlaidLinkError | null) => {
    setFundingLoading(false);
    // If the micro-deposits are already verified, link Dwolla
    if (event?.error_code === "MICRODEPOSITS_ALREADY_VERIFIED") {
      setFundingLoading(true);
      const account = getPendingBankAccounts(bankAccountList)[0];
      const res = await linkProcessorToBank(account.uuid);
      const data = await res(dispatch);
      return data;
    } else if (
      currentIncludeAssets &&
      !hasPendingBankAccounts(bankAccountList)
    ) {
      setClosedAtLeastOnce(true);
    }
  };

  const initializePlaidToken = () => {
    if (hasPendingManualBankAccounts(bankAccountList)) {
      const pendingAccounts = getPendingManualBankAccounts(bankAccountList);
      setPlaidLinkToken(pendingAccounts[0].plaid_link);
    } else if (updateMode) {
      loadUpdatePlaidLinkToken(bankAccountList[0].uuid).then((result) => {
        setPlaidLinkToken(result.link_token);
      });
    } else {
      loadPlaidLinkToken({ includeAssets: currentIncludeAssets })
        .then((result) => {
          setPlaidLinkToken(result.link_token);
        })
        .catch(() => {
          const err = new Error(
            "We are experiencing issues with our Bank link provider. Please try again later."
          );
          onFailHandler(err);
        });
    }
  };

  const handleBankHelp = () => {
    setCurrentIncludeAssets(false);
    initializePlaidToken();
    setShowManualMessage(true);
  };

  const { open, ready } = usePlaidLink({
    token: plaidLinkToken,
    onSuccess: updateMode ? onSuccess : linkNewAccount,
    onExit: onExit,
  });

  const handleNoBank = () => {
    window.location.assign("/pay-with-card/" + prepaymentUUID);
  };

  const renderButtonText = () => {
    if (fundingLoading) {
      return <BeatLoader />;
    } else if (updateMode) {
      return <div>Update Your Account</div>;
    } else if (bankAccountList && hasPendingBankAccounts(bankAccountList)) {
      return <div>Confirm Deposits</div>;
    } else {
      return <div>{customText}</div>;
    }
  };

  useEffect(() => {
    if (!loadingBankAccounts) {
      initializePlaidToken();
      setFundingLoading(false);
    }
  }, [currentIncludeAssets]);

  useEffect(() => {
    if (!loadingBankAccounts) {
      initializePlaidToken();
    }
  }, [bankAccountList]);

  if (
    hasPendingBankAccounts(bankAccountList) &&
    !hasPendingManualBankAccounts(bankAccountList)
  ) {
    return (
      <div>
        Your automatic deposits are processing. <br />
        We will send you an email once this bank account is ready to go.
      </div>
    );
  }

  return (
    <div>
      {errorMessage ? (
        <ErrorMessage textAlign={"center"}>{errorMessage}</ErrorMessage>
      ) : (
        <>
          {!plaidLinkToken || !ready || loadingBankAccounts ? (
            <BeatLoader />
          ) : (
            <div className="d-flex flex-column justify-content-center align-items-center font-medium">
              {showManualMessage && (
                <div>
                  If you are having trouble linking via Plaid, try linking your
                  account manually:
                  <ol>
                    <li>Click the Link Account button below again</li>
                    <li>
                      Scroll down to the bottom on the bank selection screen
                    </li>
                    <li>
                      Click "Link with account numbers" and follow the
                      instructions
                    </li>
                  </ol>
                </div>
              )}
              <Button type={type} onClick={() => open()} disabled={!ready}>
                {renderButtonText()}
              </Button>
              {closedAtLeastOnce && (
                <a className="text-muted h6 mt-2" onClick={handleBankHelp}>
                  Having trouble linking your bank?
                </a>
              )}
              {canPayByCard && !hasPendingBankAccounts(bankAccountList) && (
                <a className="text-muted h6 mt-2" onClick={handleNoBank}>
                  I don't have a US bank account
                </a>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PlaidButton;
