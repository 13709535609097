import { useState, useEffect } from "react";
import styled from "styled-components";
import { first } from "lodash";
import { cssBreakpoints } from "../../../components/global/theme";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import Button from "../../../components/baseComponents/Button";
import FileDragAndDrop from "../../../components/baseComponents/FileDragAndDrop";
import DropdownCard from "../../../components/baseComponents/DropdownCard";
import { uploadSureReport } from "../../utils/companyUtils";

const StyledSubtitle = styled(Div)`
  ${(props) =>
    cssBreakpoints("font-size", [
      { sm: props.theme.font_size.headlineSM },
      { md: props.theme.font_size.headlineMD },
    ])}
  font-weight: ${(props) => props.theme.font_weight.semibold};
  text-align: center;
`;

const StyledSuccessMessage = styled(Div)`
  color: ${(props) => props.theme.colors.success};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const StyledErrorMessage = styled(Div)`
  color: ${(props) => props.theme.colors.danger};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const SureReportUpload = () => {
  const [reportFile, setReportFile] = useState<File | null>(null);
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const handleUpload = () => {
    const successCallback = (res: any) => {
      setSuccess("Successfully uploaded Sure report data.");
      setReportFile(null);
    };
    const failureCallback = (err: any) => {
      setError("An error occurred: " + err);
      setReportFile(null);
    };
    if (!reportFile) {
      setError("You must select a file to be uploaded first.");
      return;
    }
    setSuccess("");
    setError("");
    setSubmitted(true);
    uploadSureReport(reportFile, successCallback, failureCallback);
  };

  const resetDisplayValues = () => {
    // clear display messages and reset file submission state to show buttom
    setSubmitted(false);
    setSuccess("");
    setError("");
  };

  return (
    <DropdownCard title="Upload Sure Report" initiallyOpen={false}>
      <Row justifyContent="center">
        <StyledSubtitle mb={{ default: 3, lg: 3 }}>
          Sure Reporting Upload
        </StyledSubtitle>
      </Row>
      <Row justifyContent="center">
        <Div width={{ lg: 6 / 12 }}>
          <FileDragAndDrop
            onUpload={(e) => {
              setReportFile(
                first(e.target.files || e.dataTransfer.files) || null
              );
              e.target.value = null; // resets event value so file can be uploaded again if removed
              resetDisplayValues();
            }}
            onRemove={() => {
              setReportFile(null);
              resetDisplayValues();
            }}
          />
        </Div>
      </Row>
      <Row justifyContent="center" mt={{ lg: 2 }}>
        {success && <StyledSuccessMessage>{success}</StyledSuccessMessage>}
        {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
      </Row>
      {reportFile && !submitted && (
        <>
          <Row justifyContent="center">
            <Div width={{ lg: 6 / 12 }} mt={{ default: 3, lg: 3 }}>
              <Button text="Submit Sure Report" onClick={handleUpload} />
            </Div>
          </Row>
        </>
      )}
    </DropdownCard>
  );
};

export default SureReportUpload;
