import styled from "styled-components";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import RentableCard from "../../baseComponents/RentableCard";
import Button from "../../baseComponents/Button";
import { useHistory } from "react-router-dom";

const StyledButtonContainer = styled(Div)`
  margin-top: 1rem;
`;

interface Props {
  prepaymentUUID: string;
}

const AdditionalFundsRequestCard = ({ prepaymentUUID }: Props) => {
  const history = useHistory();
  const handleClick = () => {
    history.push(`/tenant/additional-funds/${prepaymentUUID}`);
  };

  return (
    <RentableCard title={"Additional Funds Request"}>
      <>
        <Row>
          <Div width={{ default: 1 }}>
            Your Property Manager has requested additional funds on one or more
            of your deposits. Please click the button below to finish funding
            your deposits.
          </Div>
        </Row>
        <Row justifyContent="center">
          <StyledButtonContainer
            width={{ default: 1, lg: 3 / 4 }}
            alignItems="center"
          >
            <Button
              onClick={handleClick}
              type="secondary"
              text="Fund Deposits"
            />
          </StyledButtonContainer>
        </Row>
      </>
    </RentableCard>
  );
};

export default AdditionalFundsRequestCard;
