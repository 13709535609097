import { useAppSelector } from "../store/hooks";
import { first } from "lodash";
import { BankAccount } from "../components/global/ModelInterfaces";

const useGetBankAccount = () => {
  const bankAccountSlice: { loading: boolean; objects: BankAccount[] } =
    useAppSelector((state) => state.bankInfo);
  const loadingBankAccounts = bankAccountSlice.loading;
  const bankAccountList = bankAccountSlice.objects;
  const bankAccount: BankAccount | undefined = first(bankAccountList);
  const isLastAccount = bankAccountList.length == 1;

  return { bankAccount, bankAccountList, loadingBankAccounts, isLastAccount };
};

export default useGetBankAccount;
