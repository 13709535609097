import axios from "axios";
import { getCookie } from "./getCookie";

export const americanStates = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const createUnits = async (
  unitList,
  address1,
  city,
  americanState,
  zipcode,
  buildingUUID = null
) => {
  const unitRequest = {
    unit_list: unitList,
    address_1: address1,
    city: city,
    state: americanState,
    zip_code: zipcode,
    building_uuid: buildingUUID,
  };
  return axios
    .post("/api/unit/create", unitRequest)
    .then((res) => {
      const data = res.data;
      return data.new_unit_list;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateUnitAddress2 = async (unit, uuid) => {
  const unitRequest = {
    address_2: unit,
    unit_uuid: uuid,
  };

  return axios
    .post("/api/unit/update", unitRequest)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteUnit = async (unitUUID, onSuccess, onError) => {
  const csrftoken = getCookie("csrftoken");
  const buildingRequest = {
    unit_uuid: unitUUID,
  };

  axios
    .post("/api/unit/delete", buildingRequest, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err?.data);
    });
};

// Handle buildings
export const createBuilding = async (name, address, city, state, zipcode) => {
  const buildingRequest = {
    address: address,
    city: city,
    state: state,
    zipcode: zipcode,
    name: name,
  };

  return axios
    .post("/api/building/create", buildingRequest)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateBuilding = async (
  name,
  address1,
  city,
  state,
  zipcode,
  uuid
) => {
  const buildingRequest = {
    address_1: address1,
    city: city,
    state: state,
    zipcode: zipcode,
    name: name,
    building_uuid: uuid,
  };

  return axios
    .post("/api/building/update", buildingRequest)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteBuilding = async (uuid, onSuccess, onError) => {
  const csrftoken = getCookie("csrftoken");
  const buildingRequest = {
    building_uuid: uuid,
  };

  axios
    .post("/api/building/delete", buildingRequest, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err?.data);
    });
};
