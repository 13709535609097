import Row from "../../baseComponents/Row";
import Div from "../../baseComponents/Div";
import styled from "styled-components";
import { DateTime } from "luxon";
import { InterestTransactions } from "../../global/ModelInterfaces";
import { currencyFormatter } from "../../global/utils";
interface Props {
  transactions: InterestTransactions;
  deposit_type: string;
}

const StyledLabel = styled.span`
  font-weight: 600;
`;

const InterestDetails = ({ transactions, deposit_type }: Props) => {
  return (
    <>
      {(transactions.total_accrued_interest ||
        transactions.total_accrued_interest === 0) && (
        <Row>
          <StyledLabel>{deposit_type} Interest Accrual:</StyledLabel>
          {currencyFormatter.format(transactions.total_accrued_interest)}
        </Row>
      )}
      {transactions.latest_interest_disbursal_date && (
        <Row>
          <StyledLabel>{deposit_type} Interest Disbursement:</StyledLabel>
          {transactions.latest_interest_disbursal_amount} - on{" "}
          {`${DateTime.fromISO(
            transactions.latest_interest_disbursal_date
          ).toFormat("MMMM dd, yyyy")}`}
        </Row>
      )}
    </>
  );
};

export default InterestDetails;
