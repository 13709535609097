import styled from "styled-components";
import Div from "../../components/baseComponents/Div";
import Row from "../../components/baseComponents/Row";
import { ReactElement } from "react";

const StyledHeader = styled(Row)`
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.colors.white};
  border-bottom: 3px solid ${(props) => props.theme.colors.backgroundPrimary};
`;

const StyledText = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineSM};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

interface Props {
  overviewHeader: string;
  overviewName: string;
  LeftSubHeader: string;
  rightSubHeader: string;
  tabs?: ReactElement;
}

const OverviewHeader = ({
  overviewHeader,
  overviewName,
  LeftSubHeader,
  rightSubHeader,
  tabs,
}: Props) => {
  return (
    <StyledHeader alignItems="end" pt={{ default: 3 }}>
      <Div width={{ lg: 8 / 12 }}>
        <Row>
          <Div>
            <StyledText>{overviewHeader}</StyledText>
            <StyledText>{overviewName}</StyledText>
          </Div>
        </Row>
        <Row mb={{ lg: 1 }}>
          <Div>
            <StyledText>{LeftSubHeader}</StyledText>
            <StyledText>{rightSubHeader}</StyledText>
          </Div>
        </Row>
      </Div>
      {tabs && <Div width={{ lg: 4 / 12 }}>{tabs}</Div>}
    </StyledHeader>
  );
};

export default OverviewHeader;
