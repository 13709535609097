interface Props {
  width?: string;
  height?: string;
}
const EyeClose = ({ width = "40", height = "40" }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="#222A3E"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.4962 33.0318L32 37.5355L35.5355 34L3.53553 2L0 5.53553L5.36438 10.8999C3.28706 12.9987 1.54342 15.5377 0.241629 18.3957C0.0827689 18.7492 0 19.1398 0 19.5359C0 19.9321 0.0827689 20.3226 0.241629 20.6762C4.0076 28.9426 11.4632 34.5355 20 34.5355C22.6262 34.5355 25.1503 34.0058 27.4962 33.0318ZM24.5927 30.1282L22.3151 27.8507C21.6316 28.0884 20.9181 28.2201 20.1942 28.2381C18.5665 28.2784 16.9687 27.7416 15.6272 26.7035C14.2857 25.6655 13.2685 24.179 12.7197 22.4545C12.2569 21.0002 12.147 19.439 12.3944 17.9299L7.87062 13.4062C6.13072 15.0959 4.63543 17.1608 3.47705 19.5355C6.86871 26.4887 13.1493 30.7855 20 30.7855C21.5702 30.7855 23.1104 30.5598 24.5927 30.1282ZM39.7583 20.6754C38.5676 23.2895 37.0072 25.6368 35.1599 27.6244L32.6628 25.1273C34.1759 23.5437 35.4848 21.6641 36.5229 19.5355C33.1312 12.5824 26.8513 8.28553 20 8.28553C18.7271 8.28553 17.4739 8.43384 16.2554 8.71994L13.2735 5.73805C15.3985 4.95652 17.6586 4.53553 20 4.53553C28.5368 4.53553 35.9923 10.1285 39.7583 18.3949C39.9172 18.7484 39.9999 19.139 39.9999 19.5351C39.9999 19.9313 39.9172 20.3219 39.7583 20.6754ZM27.3754 16.7279C27.7134 17.8572 27.8404 19.0456 27.7555 20.2201L18.5111 10.9757C19.0002 10.8581 19.4988 10.7943 20 10.7855C21.6281 10.78 23.2166 11.3509 24.5403 12.4174C25.864 13.484 26.8559 14.992 27.3754 16.7279Z"
        fill="#222A3E"
      />
    </svg>
  );
};

export default EyeClose;
