interface Props {
  width?: string;
  height?: string;
}

const SquareCheckIcon = ({ width = "40", height = "40" }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.2222 7H9.77778C8.23611 7 7 8.25 7 9.77778V29.2222C7 30.75 8.23611 32 9.77778 32H29.2222C30.7639 32 32 30.75 32 29.2222V9.77778C32 8.25 30.7639 7 29.2222 7ZM16.7222 26.4444L9.77778 19.5L11.7361 17.5417L16.7222 22.5139L27.2639 11.9722L29.2222 13.9444L16.7222 26.4444Z"
        fill="#1EB88D"
      />
    </svg>
  );
};

export default SquareCheckIcon;
