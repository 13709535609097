import { useState } from "react";
import Button from "../../../components/baseComponents/Button";
import Checkbox from "../../../components/baseComponents/Checkbox";
import ConfirmationModal from "../../../components/baseComponents/ConfirmationModal";
import Div from "../../../components/baseComponents/Div";
import ErrorMessage from "../../../components/baseComponents/ErrorMessage";
import Input from "../../../components/baseComponents/Input";
import Row from "../../../components/baseComponents/Row";
import { editPaymentPlanApproval } from "../../utils/paymentPlanUtils";
import SuccessMessage from "../../../components/baseComponents/SuccessMessage";
import { useDispatch } from "react-redux";
import { updatePrepaymentSection } from "../../../store/nucleusPrepayments";

interface Props {
  prepaymentUUID: string;
}

const EditPaymentPlanApprovalForm = ({ prepaymentUUID }: Props) => {
  const dispatch = useDispatch();
  const [approvedMonths, setApprovedMonths] = useState<number | null>(null);
  const [initialAmount, setInitialAmount] = useState<number | null>(null);
  const [requireBackupCard, setRequireBackupCard] = useState(false);
  const [confirmApprove, setConfirmApprove] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validateForm = () => {
    if (!approvedMonths) {
      setErrorMessage("Please enter a valid number of months.");
      return false;
    } else if (!initialAmount) {
      setErrorMessage("Please enter a valid initial amount.");
    } else {
      return true;
    }
  };

  const handleOpenModal = () => {
    if (validateForm()) {
      setConfirmApprove(true);
      setErrorMessage("");
    }
  };

  const handleApprove = () => {
    const successCallback = (res: any) => {
      dispatch(
        updatePrepaymentSection(
          prepaymentUUID,
          "payment_plan_info",
          res.payment_plan_info
        )
      );
      setConfirmApprove(false);
      setSuccessMessage("Payment Plan Approved!");
      setErrorMessage("");
      setApprovedMonths(null);
      setInitialAmount(null);
      setRequireBackupCard(false);
    };
    const failureCallback = (errorMessage: string) => {
      setConfirmApprove(false);
      setSuccessMessage("");
      setErrorMessage(errorMessage);
    };
    editPaymentPlanApproval(
      prepaymentUUID,
      approvedMonths,
      initialAmount,
      requireBackupCard,
      successCallback,
      failureCallback
    );
  };

  return (
    <Div>
      <Row>
        <Div width={{ default: 1, md: 12 / 12 }}>
          <Input
            type="number"
            label="Approved Months"
            value={String(approvedMonths)}
            onChange={(e) => setApprovedMonths(Number(e.target.value))}
          />
        </Div>
        <Div width={{ default: 1, md: 12 / 12 }} mb={{ default: 3 }}>
          <Input
            type="number"
            label="Initial Amount"
            value={String(initialAmount)}
            onChange={(e) => setInitialAmount(Number(e.target.value))}
          />
        </Div>
        <Div width={{ default: 1, md: 12 / 12 }}>
          <Row justifyContent="flex-start">
            <Checkbox
              value={requireBackupCard}
              onChange={() => {
                setRequireBackupCard((requireBackupCard) => !requireBackupCard);
              }}
            />
            Require Backup Credit Card?
          </Row>
        </Div>
        <Div width={{ default: 12 / 12 }} mt={{ default: 3 }}>
          <Button type="primary" text="Approve" onClick={handleOpenModal} />
        </Div>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
      </Row>
      <ConfirmationModal
        confirmationOpen={confirmApprove}
        onClose={() => setConfirmApprove(false)}
        onConfirm={handleApprove}
        message="Are you sure you would like to approve the payment plan for this user?"
      />
    </Div>
  );
};

export default EditPaymentPlanApprovalForm;
