import { useState } from "react";
import Div from "../../baseComponents/Div";
import { GenericObject } from "../../global/ModelInterfaces";
import ExtraPaymentButton from "./ExtraPaymentButton";
import HoorayCoin from "../../../images/HoorayCoin";
import useCheckFinancials from "../../../utils/useCheckFinancials";
import Row from "../../baseComponents/Row";
import ErrorMessage from "../../baseComponents/ErrorMessage";
import styled from "styled-components";
import prepaymentStatuses from "../../../enums/prepaymentStatuses";

interface Props {
  prepaymentInfo: GenericObject;
  onSuccess: (paymentAmount: number) => void;
}

const StyledPaymentButtonRow = styled(Row)`
  margin-top: 1rem;
`;

const ExtraPayment = ({ prepaymentInfo, onSuccess }: Props) => {
  const { financialInfo, loadingFinancialInfo, financialInfoError } =
    useCheckFinancials();
  const [errorMessage, setErrorMessage] = useState("");
  const onPaymentSuccess = (paymentAmount: number) => {
    onSuccess(paymentAmount);
  };

  if (!financialInfo) {
    return (
      <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
        <ErrorMessage>{financialInfoError}</ErrorMessage>
      </Div>
    );
  }
  return (
    <>
      {/* Check that the deposit is active, disbursing or closed */}
      {[
        prepaymentStatuses.ACTIVE,
        prepaymentStatuses.DISBURSING,
        prepaymentStatuses.CLOSED,
      ].includes(prepaymentInfo.deposits.security_deposit.status_id) && (
        <>
          {prepaymentInfo.deposits.security_deposit.payment_plan
            .remaining_balance > 0 && (
            <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
              <Row>
                <Div>
                  To make an extra payment to your deposit, please choose one of
                  the following options:
                </Div>
              </Row>
              <StyledPaymentButtonRow>
                <Div width={{ sm: 1, md: 1, lg: 6 / 12 }}>
                  <ExtraPaymentButton
                    text="Pay Monthly"
                    onPaymentSuccess={onPaymentSuccess}
                    paymentPlan={
                      prepaymentInfo.deposits.security_deposit.payment_plan
                    }
                    paymentType="monthly"
                    prepaymentUUID={prepaymentInfo.prepayment_uuid}
                    disabled={!financialInfo.sufficientFundsMonthlyPayment}
                    loading={loadingFinancialInfo}
                    onClick={() => setErrorMessage("")}
                    onDisabledClick={() =>
                      setErrorMessage(
                        "Your linked bank does not have sufficient funds for a monthly payment. Contact support@rentable.com for assistance."
                      )
                    }
                  />
                </Div>
                <Div width={{ sm: 1, md: 1, lg: 6 / 12 }}>
                  <ExtraPaymentButton
                    text="Pay in Full"
                    onPaymentSuccess={onPaymentSuccess}
                    paymentPlan={
                      prepaymentInfo.deposits.security_deposit.payment_plan
                    }
                    paymentType="full"
                    prepaymentUUID={prepaymentInfo.prepayment_uuid}
                    disabled={!financialInfo.sufficientFundsFullPayment}
                    loading={loadingFinancialInfo}
                    onClick={() => setErrorMessage("")}
                    onDisabledClick={() =>
                      setErrorMessage(
                        "Your linked bank does not have sufficient funds to pay your remaining deposit amount. Contact support@rentable.com for assistance."
                      )
                    }
                  />
                </Div>
              </StyledPaymentButtonRow>
              {errorMessage && (
                <Row>
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                </Row>
              )}
            </Div>
          )}
          {prepaymentInfo.deposits.security_deposit.payment_plan
            .remaining_balance == 0 && (
            <Div
              width={{ sm: 1, md: 1, lg: 1 / 2 }}
              justifyContent="center"
              alignItems="center"
            >
              <HoorayCoin />
              You're payment plan is completely paid off!
            </Div>
          )}
        </>
      )}
      {prepaymentInfo.deposits.security_deposit.status_id ==
        prepaymentStatuses.PROCESSING && (
        <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
          Your initial payment is still processing, once that is complete you
          will be able to make early payments on your deposit here without any
          fees!
        </Div>
      )}
    </>
  );
};

export default ExtraPayment;
