import styled from "styled-components";
import { useState } from "react";
import Row from "../baseComponents/Row";
import Div from "../baseComponents/Div";
import ListItem from "../baseComponents/ListItem";
import DropdownCaret from "../baseComponents/DropdownCaret";
import CreateFeeCard from "./CreateFeeCard";

import { PendingFee } from "../global/ModelInterfaces";
import { currencyFormatter } from "../global/utils";

const StyledHeader4 = styled.div`
  font-weight: ${(props) => props.theme.font_weight.bolder};
  font-size: ${(props) => props.theme.font_size.headlineSM};
`;
const StyledFeeCard = styled(Row)`
  border: 1px solid black;
  border-radius: 8px;
  padding: 16px;
  margin-left: 1em;
  margin-right: 1em;
`;

interface Props {
  fees: PendingFee[];
  onAdd: (fee: PendingFee) => void;
  onRemove: (uuid: string) => void;
}
const FeeCard = ({ fees, onAdd, onRemove }: Props) => {
  const [openDropdown, setOpenDropdown] = useState(false);

  const handleDropdownChange = () => {
    setOpenDropdown((openDropdown) => !openDropdown);
  };

  return (
    <StyledFeeCard>
      <Row>
        <Div width={{ sm: 3 / 12, lg: 1 / 12 }} onClick={handleDropdownChange}>
          <DropdownCaret open={openDropdown} />
        </Div>
        <StyledHeader4>Fees</StyledHeader4>
      </Row>
      {openDropdown && (
        <Row>
          <Div width={{ default: 1 }}>
            {fees.map((fee) => {
              const feeMetrics = [
                ["Amount", currencyFormatter.format(fee.amount)],
              ];
              // use - to keep format looking good
              fee.description
                ? feeMetrics.push(["description", fee.description])
                : feeMetrics.push(["description", "-"]);
              return (
                <ListItem
                  title={fee.type}
                  key={fee.uuid}
                  metrics={feeMetrics}
                  onDelete={() => onRemove(fee.uuid)}
                />
              );
            })}
          </Div>
          <CreateFeeCard onAdd={onAdd} />
        </Row>
      )}
    </StyledFeeCard>
  );
};
export default FeeCard;
