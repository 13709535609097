import Container from "../../../../../components/baseComponents/Container";

interface Props {
  onClick?: () => void;
}

const YardiLogo = ({ onClick }: Props) => {
  return (
    <Container>
      <img
        src="https://rentable-public-assets.s3.us-east-2.amazonaws.com/yardi.png"
        onClick={onClick}
        alt="Yardi"
        style={{
          cursor: onClick ? "pointer" : "default",
          marginRight: "0.5em",
          verticalAlign: "middle",
          height: "1.6em",
          width: "8em  ",
        }}
      />
    </Container>
  );
};

export default YardiLogo;
