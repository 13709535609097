import { useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch } from "../../../../store/hooks";
import Div from "../../../../components/baseComponents/Div";
import Row from "../../../../components/baseComponents/Row";
import DetailsSection from "./CompanyTabSections/DetailsSection";
import LocationsSection from "./CompanyTabSections/LocationsSection";
import ToolsSection from "./CompanyTabSections/ToolsSection";
import ErrorMessage from "../../../../components/baseComponents/ErrorMessage";
import NucleusLoader from "../../../components/NucleusLoader";
import useCompanyFromParams from "../../../utils/useCompanyFromParams";
import { updateCompanySection } from "../../../../store/nucleusCompanies";
import { getRentManagerCompanyDetails } from "../../../utils/rentManagerUtils";
import { GenericObject } from "../../../../components/global/ModelInterfaces";

const StyledLoading = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineLG};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;
const RentManagerTab = () => {
  const dispatch = useAppDispatch();
  const { loadingCompanies, company } = useCompanyFromParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    if (company && !company.integration_info) {
      const successCallback = (integrationDetails: GenericObject) => {
        dispatch(
          updateCompanySection(
            company.uuid,
            "integration_info",
            integrationDetails
          )
        );
        setLoading(false);
      };
      const failureCallback = () => {
        setError(
          "There was an error in retrieving the Rent Manager details of this company, please try again later."
        );
        setLoading(false);
      };
      getRentManagerCompanyDetails(
        company.uuid,
        successCallback,
        failureCallback
      );
    } else {
      setLoading(false);
    }
  }, [company]);

  if (loadingCompanies || loading) {
    return (
      <Row justifyContent="center">
        <StyledLoading
          width={{ default: 1 }}
          alignItems="center"
          justifyContent="center"
        >
          <Div width={{ default: 1 / 12 }}>
            <NucleusLoader loadingText="Loading Rent Manager Details" />
          </Div>
        </StyledLoading>
      </Row>
    );
  }

  if (error) {
    return (
      <Row mt={{ default: 5 }} justifyContent="center">
        <ErrorMessage>{error}</ErrorMessage>
      </Row>
    );
  }

  return (
    <Row mt={{ default: 3 }}>
      <DetailsSection details={company.integration_info} />
      <LocationsSection details={company.integration_info} />
      <ToolsSection />
    </Row>
  );
};

export default RentManagerTab;
