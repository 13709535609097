import axios from "axios";

export const getAsyncTaskList = (
  successCallback: Function,
  failureCallback: Function,
  task_uuid: string[],
  limit?: number
) => {
  const url = "/nucleus/api/get-async-task-list";

  axios
    .post(url, { task_uuid: task_uuid, limit: limit })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err.data);
    });
};
