import axios from "axios";

export const testThrowError = (failureCallback: Function) => {
  const url = "/nucleus/api/throw-test-error";

  axios
    .post(url)
    .then((resp) => {})
    .catch((err) => {
      failureCallback(err.data);
    });
};

export const sendTestEmail = (
  email: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/send-test-email";

  axios
    .post(url, { email: email })
    .then((resp) => {
      const data = resp.data;
      successCallback(data);
    })
    .catch((err) => {
      failureCallback(err.data);
    });
};
