import { useHistory } from "react-router-dom";
import { first } from "lodash";
import styled from "styled-components";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import PageLoader from "../../PageLoader";
import CouchIcon from "../../../images/CouchIcon";
import ConfusedCoin from "../../../images/ConfusedCoin";
import { useAppSelector } from "../../../store/hooks";
import { RootState } from "../../..";
import { cssBreakpoints } from "../../global/theme";
import { snakeCaseConvert, monthDayYearFormatter } from "../../global/utils";
import { GenericObject, Prepayment } from "../../global/ModelInterfaces";

const StyledTitle = styled(Div)`
  ${cssBreakpoints("font-size", [{ sm: "2rem" }, { md: "2.5rem" }])}
`;

const StyledPrepaymentCard = styled(Div)`
  background-color: ${(props) => props.theme.colors.grey10};
  border-radius: 0.5rem;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.grey5};
    box-shadow: ${(props) => props.theme.box_shadow.light};
  }
`;

const StyledAddress = styled(Row)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const Dashboard = () => {
  const history = useHistory();
  const tenantInfoSlice: { loading: Boolean; objects: GenericObject } =
    useAppSelector((state: RootState) => state.tenantInfo);

  const loadingTenantInfo = tenantInfoSlice.loading;
  const prepaymentList = tenantInfoSlice.objects.prepaymentInfo;

  const navigateToPrepayment = (prepayment: Prepayment) => {
    history.push(`/dashboard/${prepayment.uuid}`);
  };

  if (loadingTenantInfo) {
    return <PageLoader />;
  } else if (!prepaymentList || prepaymentList.length === 0) {
    return (
      <Div width={{ sm: 1, md: 1, lg: 1 / 3 }} alignItems="center">
        <ConfusedCoin />
        There is a problem displaying your leases. Please refresh the page or
        reach out to support@rentable.com if this problem persists.
      </Div>
    );
  } else if (prepaymentList.length === 1) {
    const prepayment: GenericObject | undefined = first(prepaymentList);
    history.push(`/dashboard/${prepayment?.uuid}`);
  }

  return (
    <Div>
      <StyledTitle>Welcome to Rentable!</StyledTitle>
      <Div mb={{ default: 4 }}>Select a lease to get started.</Div>
      {prepaymentList.map((prepayment: Prepayment) => {
        return (
          <StyledPrepaymentCard
            mb={{ default: 2 }}
            mt={{ default: 2 }}
            p={{ sm: 1, md: 3 }}
            onClick={() => {
              navigateToPrepayment(prepayment);
            }}
          >
            <Row alignItems="center">
              <Div width={{ sm: 2 / 12, md: 3 / 12 }}>
                <CouchIcon />
              </Div>
              <Div width={{ sm: 10 / 12, md: 9 / 12 }}>
                <StyledAddress>
                  {prepayment.street_address}, {prepayment.city},{" "}
                  {prepayment.state}
                </StyledAddress>
                {Object.keys(prepayment.deposits).map((key: string) => {
                  return (
                    <Row>
                      {snakeCaseConvert(key)} Amount:{" "}
                      {prepayment.deposits[key].amount}
                    </Row>
                  );
                })}
                <Row>
                  Lease: {monthDayYearFormatter(prepayment.start_date)}{" "}
                  {prepayment.end_date && (
                    <> - {monthDayYearFormatter(prepayment.end_date)}</>
                  )}{" "}
                </Row>
              </Div>
            </Row>
          </StyledPrepaymentCard>
        );
      })}
    </Div>
  );
};

export default Dashboard;
