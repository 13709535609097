import styled from "styled-components";
import Div from "../../baseComponents/Div";
import RadioButton from "../../baseComponents/RadioButton";
import Button from "../../baseComponents/Button";
import Row from "../../baseComponents/Row";
import { reportCreditScore } from "../../../utils/onboardingUtils";
import { useState } from "react";
import ErrorMessage from "../../baseComponents/ErrorMessage";

interface Props {
  onSuccess: () => void;
}

const StyledTitle = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineMD};
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const StyledRadioRow = styled(Row)`
  margin-top: 1rem;
`;

const ButtonRow = styled(Row)`
  margin-top: 1rem;
`;

const CreditSelfReport = ({ onSuccess }: Props) => {
  const [score, setScore] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const submitCreditScore = () => {
    reportCreditScore(score, onSuccess, handleFailure);
  };
  const handleFailure = () => {
    setError("Unable to report your credit. Please try again in a few minutes");
  };
  return (
    <Row>
      <Div width={{ default: 1 }}>
        <Row>
          <StyledTitle width={{ default: 1 }} alignItems={"center"}>
            Credit Reporting
          </StyledTitle>
        </Row>
        <Row justifyContent="center">
          <Div width={{ default: 1, lg: 6 / 12 }} alignItems={"center"}>
            <Row>
              <Div>
                Please select the range that best describes your credit score.
                If you are unsure, select "I don't know". Then click the "Next"
                button
              </Div>
            </Row>
            <StyledRadioRow>
              <RadioButton
                name="creditScore"
                label="750+"
                value="750+"
                onSelect={setScore}
              />
              <RadioButton
                name="creditScore"
                label="700-749"
                value="700-749"
                onSelect={setScore}
              />
              <RadioButton
                name="creditScore"
                label="650-699"
                value="650-699"
                onSelect={setScore}
              />
              <RadioButton
                name="creditScore"
                label="600-649"
                value="600-649"
                onSelect={setScore}
              />
              <RadioButton
                name="creditScore"
                label="Below 600"
                value="Below 600"
                onSelect={setScore}
              />
              <RadioButton
                name="creditScore"
                label="I don't know"
                value="I don't know"
                onSelect={setScore}
              />
            </StyledRadioRow>
          </Div>
        </Row>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <ButtonRow justifyContent="center">
          <Div width={{ default: 1, lg: 6 / 12 }} alignItems={"center"}>
            <Button
              onClick={submitCreditScore}
              text={"Next"}
              disabled={!score}
            />
          </Div>
        </ButtonRow>
      </Div>
    </Row>
  );
};

export default CreditSelfReport;
