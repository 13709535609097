import React from "react";
// TODO: UPDATE WITH ZACH'S DESIGN
const BuildingIcon = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 32">
      <path
        d="M5.5 6.5C5.5 5.9475 5.9475 5.5 6.5 5.5H9.5C10.05 5.5 10.5 5.9475 10.5 6.5V9.5C10.5 10.05 10.05 10.5 9.5 10.5H6.5C5.9475 10.5 5.5 10.05 5.5 9.5V6.5ZM17.5 5.5C18.05 5.5 18.5 5.9475 18.5 6.5V9.5C18.5 10.05 18.05 10.5 17.5 10.5H14.5C13.95 10.5 13.5 10.05 13.5 9.5V6.5C13.5 5.9475 13.95 5.5 14.5 5.5H17.5ZM5.5 14.5C5.5 13.95 5.9475 13.5 6.5 13.5H9.5C10.05 13.5 10.5 13.95 10.5 14.5V17.5C10.5 18.05 10.05 18.5 9.5 18.5H6.5C5.9475 18.5 5.5 18.05 5.5 17.5V14.5ZM17.5 13.5C18.05 13.5 18.5 13.95 18.5 14.5V17.5C18.5 18.05 18.05 18.5 17.5 18.5H14.5C13.95 18.5 13.5 18.05 13.5 17.5V14.5C13.5 13.95 13.95 13.5 14.5 13.5H17.5ZM0 4C0 1.79063 1.79063 0 4 0H20C22.2062 0 24 1.79063 24 4V28C24 30.2062 22.2062 32 20 32H4C1.79063 32 0 30.2062 0 28V4ZM3 4V28C3 28.55 3.4475 29 4 29H9V25C9 23.3438 10.3438 22 12 22C13.6562 22 15 23.3438 15 25V29H20C20.55 29 21 28.55 21 28V4C21 3.4475 20.55 3 20 3H4C3.4475 3 3 3.4475 3 4Z"
        fill="#2F374B"
      />
    </svg>
  );
};

export default BuildingIcon;
