import { useState } from "react";
import { useAppDispatch } from "../../../store/hooks";
import styled from "styled-components";

import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import CollapsibleList from "../../../components/baseComponents/CollapsibleList";
import DropdownCard from "../../../components/baseComponents/DropdownCard";
import Button from "../../../components/baseComponents/Button";
import ErrorMessage from "../../../components/baseComponents/ErrorMessage";
import useNucleusPermissions from "../../../utils/useNucleusPermissions";

import { GenericObject } from "../../../components/global/ModelInterfaces";
import {
  currencyFormatter,
  dateShortFormatter,
} from "../../../components/global/utils";
import { markDisbursementCheckCashed } from "../../utils/prepaymentUtils";
import { nucleusUserPermissions } from "../../../enums/nucleusPermissions";
import { updatePrepaymentSection } from "../../../store/nucleusPrepayments";
import ManuallySendCheckForm from "../Forms/ManuallySendCheckForm";
import ButtonToForm from "../ButtonToForm";

interface Props {
  disbursementInfo: GenericObject;
  prepaymentUUID: string;
}
const TableHeader = styled(Row)`
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  color: ${(props) => props.theme.colors.white};
`;
const StyledLabel = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;
const StyledText = styled.span`
  font-size: ${(props) => props.theme.font_size.headlineMD};
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const DisbursementInfo = ({ disbursementInfo, prepaymentUUID }: Props) => {
  const dispatch = useAppDispatch();
  const [markingCheckCashed, setMarkingCheckCashed] = useState(false);
  const [checkError, setCheckError] = useState("");
  const { prepaymentOverviewPermission } = useNucleusPermissions();
  const markCheckCashed = () => {
    setMarkingCheckCashed(true);
    const successCallback = (res: any) => {
      setMarkingCheckCashed(false);
      dispatch(
        updatePrepaymentSection(
          prepaymentUUID,
          "disbursement_info",
          res.disbursement_info
        )
      );
    };
    const failureCallback = (errorMessage: string) => {
      setCheckError(errorMessage);
      setMarkingCheckCashed(false);
    };
    markDisbursementCheckCashed(
      disbursementInfo.lob_check.uuid,
      prepaymentUUID,
      successCallback,
      failureCallback
    );
  };

  if (disbursementInfo === null) {
    return (
      <DropdownCard title="Disbursement Info">
        <Row>
          <StyledText>This deposit was not disbursed yet.</StyledText>
        </Row>
      </DropdownCard>
    );
  }

  return (
    <DropdownCard title="Disbursement Info">
      {disbursementInfo.amount > 0 ? (
        <Row>
          <Div width={"fill"}>
            <TableHeader>Security Deposit Info</TableHeader>
            <Row>
              <StyledLabel>Status:</StyledLabel>
              {disbursementInfo.status}
            </Row>
            <Row>
              <StyledLabel>Disbursement Date:</StyledLabel>
              {!!disbursementInfo.delayed_disbursement &&
              !disbursementInfo.delayed_disbursement.disbursed_at
                ? "Pending Delayed Disbursement"
                : dateShortFormatter(disbursementInfo.disbursement_date)}
            </Row>
            <Row>
              <StyledLabel>Amount Refunded to Tenant:</StyledLabel>
              {currencyFormatter.format(disbursementInfo.amount)}
            </Row>
            {disbursementInfo.bank_account && (
              <CollapsibleList
                title="Tenant Bank Account"
                list={disbursementInfo.bank_account}
              />
            )}
            {disbursementInfo.dwolla_transaction && (
              <Row>
                <StyledLabel>Dwolla Transanction:</StyledLabel>
                {disbursementInfo.dwolla_transaction}
              </Row>
            )}
            {disbursementInfo.stripe_transaction && (
              <Row>
                <StyledLabel>Stripe Transanction:</StyledLabel>
                {disbursementInfo.stripe_transaction}
              </Row>
            )}
            {disbursementInfo.lob_check && (
              <>
                <Row>
                  <StyledLabel>Lob Check:</StyledLabel>
                </Row>
                <Row>
                  <Div>Lob ID: {disbursementInfo.lob_check.lob_id}</Div>
                </Row>
                <Row>
                  <Div>
                    Check Cashed:{" "}
                    {disbursementInfo.lob_check.check_is_cashed
                      ? "True"
                      : "False"}
                  </Div>
                </Row>
                {disbursementInfo.lob_check.fee && (
                  <>
                    <Row>
                      <Div>
                        Fee Payment Intent:{" "}
                        {disbursementInfo.lob_check.fee.payment_intent}
                      </Div>
                    </Row>
                    <Row>
                      <Div>
                        Fee Amount:{" "}
                        {currencyFormatter.format(
                          disbursementInfo.lob_check.fee.amount
                        )}
                      </Div>
                    </Row>
                  </>
                )}
              </>
            )}
            <Row>
              <StyledLabel>Withheld Amount:</StyledLabel>
              {currencyFormatter.format(disbursementInfo.withheld_amount)}
            </Row>
            {disbursementInfo.deductions.length > 0 && (
              <Row>
                <StyledLabel>Deductions:</StyledLabel>
                <TableHeader>
                  <StyledLabel width={{ default: 2 / 12 }}>ID:</StyledLabel>
                  <StyledLabel width={{ default: 2 / 12 }}>UUID:</StyledLabel>
                  <StyledLabel width={{ default: 2 / 12 }}>Amount:</StyledLabel>
                  <StyledLabel width={{ default: 2 / 12 }}>
                    Category:
                  </StyledLabel>
                  <StyledLabel width={{ default: 2 / 12 }}>
                    Description:
                  </StyledLabel>
                  <StyledLabel width={{ default: 2 / 12 }}>
                    Documentation Link:
                  </StyledLabel>
                </TableHeader>
                {disbursementInfo.deductions.map((deduction: GenericObject) => (
                  <Row>
                    {Object.entries(deduction).map(([key, value]) => (
                      <Div width={{ default: 2 / 12 }}>{value}</Div>
                    ))}
                  </Row>
                ))}
              </Row>
            )}
          </Div>
          {prepaymentOverviewPermission === nucleusUserPermissions.WRITE && (
            //   Write tools
            <>
              {!!disbursementInfo.lob_check &&
                !disbursementInfo.lob_check.check_is_cashed && (
                  <Div width={{ default: 12 / 12, lg: 6 / 12 }}>
                    {checkError && <ErrorMessage>{checkError}</ErrorMessage>}
                    <Button
                      onClick={markCheckCashed}
                      loading={markingCheckCashed}
                    >
                      Mark Check Cashed
                    </Button>
                  </Div>
                )}
              {!!disbursementInfo.delayed_disbursement &&
                !disbursementInfo.delayed_disbursement.disbursed_at && (
                  <Div width={{ default: 12 / 12, lg: 6 / 12 }}>
                    <ButtonToForm
                      formTitle="Send Check Manually"
                      buttonText="Send Check Manually"
                    >
                      <ManuallySendCheckForm
                        delayedDisbursement={
                          disbursementInfo.delayed_disbursement
                        }
                        prepaymentUUID={prepaymentUUID}
                      />
                    </ButtonToForm>
                  </Div>
                )}
            </>
          )}
        </Row>
      ) : (
        <Row>
          <StyledText>No funds were returned to the tenant.</StyledText>
        </Row>
      )}
    </DropdownCard>
  );
};

export default DisbursementInfo;
