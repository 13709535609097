import { useEffect, useState, useRef } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { isEmpty, find, map } from "lodash";
import { useAppDispatch } from "../../../store/hooks";
import { checkOnboardingComplete } from "../../../utils/tenantUtils";
import { updateTenantOnboarding } from "../../../store/tenantInfo";
import {
  INTRO_STEP,
  BANK_LINK_STEP,
  MAKE_PAYMENT_STEP,
  RENTERS_INSURANCE_STEP,
  PERSONAL_INFO_STEP,
  allStepsCompleted,
  completeStep,
  findNextIncompleteStep,
  checkStepCompleted,
} from "../../../utils/onboardingUtils";

import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import BankLinkStep from "./BankLinkStep";
import PersonalInfo from "./PersonalInfo";
import IntroductionStep from "./IntroductionStep";
import PaymentStep from "./PaymentStep";
import RentersInsuranceStep from "./RentersInsuranceStep";
import ProgressBar from "./ProgressBar";
import RentableCard from "../../baseComponents/RentableCard";
import { GenericObject } from "../../global/ModelInterfaces";
import PageLoader from "../../PageLoader";
import useGetCurrentPrepayment from "../../../utils/useGetCurrentPrepayment";

const TenantOnboarding = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { prepaymentUUID } = useParams<GenericObject>();
  const { loadingTenantInfo, prepayment, onboarding } =
    useGetCurrentPrepayment(prepaymentUUID);

  const [currentSessionStep, setCurrentSessionStep] = useState(INTRO_STEP);
  const [currentNavigationSteps, setCurrentNavigationSteps] = useState([
    { step: INTRO_STEP, active: true },
    { step: BANK_LINK_STEP, active: false },
    { step: PERSONAL_INFO_STEP, active: false },
    { step: RENTERS_INSURANCE_STEP, active: false },
    { step: MAKE_PAYMENT_STEP, active: false },
  ]);
  const findCurrentSessionStep = () => {
    const currentSessionStep = find(currentNavigationSteps, function (step) {
      return step.active == true;
    });
    if (currentSessionStep) {
      return currentSessionStep.step;
    } else {
      return INTRO_STEP;
    }
  };

  const navigateToNextIncompleteStep = () => {
    const nextIncompleteStep = findNextIncompleteStep(onboarding);
    const newCurrentSteps = map(currentNavigationSteps, function (step) {
      if (step.step === nextIncompleteStep) {
        return { ...step, active: true };
      } else {
        return { ...step, active: false };
      }
    });
    setCurrentNavigationSteps(newCurrentSteps);
  };

  const navigateToSpecificStep = (step: string) => {
    const newCurrentSteps = map(currentNavigationSteps, function (currentStep) {
      if (currentStep.step === step) {
        return { ...currentStep, active: true };
      } else {
        return { ...currentStep, active: false };
      }
    });
    setCurrentNavigationSteps(newCurrentSteps);
  };

  const handleNext = async () => {
    const currentStep = findCurrentSessionStep();
    if (!checkStepCompleted(currentStep, onboarding)) {
      const updatedOnboarding = await completeStep(
        currentStep,
        onboarding.uuid
      );
      dispatch(updateTenantOnboarding(updatedOnboarding));
    } else {
      handleStepChange();
    }
  };

  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    handleStepChange();
  }, [onboarding]);

  useEffect(() => {
    setCurrentSessionStep(findCurrentSessionStep());
  }, [currentNavigationSteps]);

  const handleStepChange = () => {
    if (allStepsCompleted(onboarding)) {
      history.push(`/dashboard/${prepaymentUUID}`);
    } else {
      navigateToNextIncompleteStep();
    }
  };

  const renderOnboardingStep = () => {
    switch (currentSessionStep) {
      case INTRO_STEP:
      default:
        return <IntroductionStep nextStep={handleNext} />;
      case BANK_LINK_STEP:
        return <BankLinkStep nextStep={handleNext} />;
      case PERSONAL_INFO_STEP:
        return <PersonalInfo nextStep={handleNext} />;
      case RENTERS_INSURANCE_STEP:
        return <RentersInsuranceStep nextStep={handleNext} />;
      case MAKE_PAYMENT_STEP:
        return (
          <PaymentStep
            nextStep={handleNext}
            navigateToStep={navigateToSpecificStep}
          />
        );
    }
  };

  if (loadingTenantInfo || isEmpty(onboarding) || isEmpty(prepayment)) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  }

  if (!onboarding || checkOnboardingComplete(onboarding)) {
    return <Redirect to={`/dashboard`} />;
  }

  return (
    <Row justifyContent="center">
      <Div width={{ md: 2 / 3 }}>
        <ProgressBar onboarding={onboarding} />
        <RentableCard>{renderOnboardingStep()}</RentableCard>
      </Div>
    </Row>
  );
};

export default TenantOnboarding;
