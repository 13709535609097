import { useState } from "react";
import styled from "styled-components";
import { first } from "lodash";
import { cssBreakpoints } from "../../../components/global/theme";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import DropdownCard from "../../../components/baseComponents/DropdownCard";
import Button from "../../../components/baseComponents/Button";
import CSVButton from "../../../components/baseComponents/CSVButton";
import FileUpload from "../../../components/baseComponents/FileUpload";
import BeatLoader from "react-spinners/BeatLoader";
import ErrorMessage from "../../../components/baseComponents/ErrorMessage";
import SuccessMessage from "../../../components/baseComponents/SuccessMessage";
import { uploadUnitCSV } from "../../utils/unitUtils";

const StyledSubtitle = styled(Div)`
  ${(props) =>
    cssBreakpoints("font-size", [
      { sm: props.theme.font_size.headlineSM },
      { md: props.theme.font_size.headlineMD },
    ])}
  font-weight: ${(props) => props.theme.font_weight.semibold};
  text-align: center;
`;

const bulkUnitTemplateColumns = [
  {
    building_name: "My Building",
    building_uuid: "OPTIONAL, ONLY INCLUDE IF BUILDING EXISTS",
    address_1: "123 Main St",
    address_2: "#2",
    city: "Miami",
    state: "FL",
    zip_code: "33176",
  },
];

interface Props {
  companyUUID: string;
}

const CreateUnits = ({ companyUUID }: Props) => {
  const [csvUploaded, setCSVUploaded] = useState(false);
  const [csvFile, setCSVFile] = useState<File | null>();

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [rowErrors, setRowErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCSVUpload = () => {
    setErrorMessage("");
    setSuccessMessage("");
    setRowErrors([]);
    setCSVUploaded(false);
    if (!csvFile) {
      setErrorMessage("No file was uploaded.");
      return;
    }
    setLoading(true);
    const successCallback = (data: any) => {
      setLoading(false);
      setSuccessMessage(data);
    };
    const failureCallback = (err_data: any) => {
      setLoading(false);
      if (err_data.message) {
        setErrorMessage(err_data.message);
      } else {
        setErrorMessage(err_data);
      }
      if (err_data.failed_rows) {
        setRowErrors(err_data.failed_rows);
      }
    };
    uploadUnitCSV(csvFile, companyUUID, successCallback, failureCallback);
  };

  return (
    <DropdownCard title="Create Unit(s)" initiallyOpen={false}>
      <Row justifyContent="center" pt={{ lg: 3 }} pb={{ lg: 3 }}>
        <StyledSubtitle>Bulk Unit CSV Upload</StyledSubtitle>
      </Row>
      <Row justifyContent="center" pb={{ lg: 3 }}>
        <Div>
          <FileUpload
            defaultText="Upload Unit CSV"
            onUpload={(e) => {
              setCSVFile(first(e.target.files));
              setCSVUploaded(true);
            }}
            onRemove={() => {
              setCSVFile(null);
              setCSVUploaded(false);
            }}
          />
        </Div>
        {csvUploaded && (
          <Div>
            <Button onClick={() => handleCSVUpload()} text="Upload CSV" />
          </Div>
        )}
      </Row>
      {loading && (
        <Div alignItems="center">
          <BeatLoader />
          <span>Creating Units...</span>
        </Div>
      )}
      {successMessage && (
        <SuccessMessage alignItems="center">{successMessage}</SuccessMessage>
      )}
      {errorMessage && (
        <ErrorMessage alignItems="center">{errorMessage}</ErrorMessage>
      )}
      {rowErrors &&
        rowErrors.map((error: any) => {
          return (
            <ErrorMessage alignItems="center" mt={{ lg: 2 }}>
              Row {error.row}, {error.reason}
            </ErrorMessage>
          );
        })}

      <Row justifyContent="center" pt={{ lg: 3 }}>
        <Div>
          <CSVButton
            data={bulkUnitTemplateColumns}
            fileName="bulk_units_template.csv"
          >
            Download Base Template
          </CSVButton>
        </Div>
      </Row>
    </DropdownCard>
  );
};

export default CreateUnits;
