const ADD_BANK_ACCOUNT = "bank/ADD_BANK_ACCOUNT";
const REMOVE_BANK_ACCOUNT = "bank/REMOVE_BANK_ACCOUNT";
const SET_BANK_ACCOUNTS = "bank/SET_BANK_ACCOUNTS";
const UPDATE_BANK_ACCOUNT = "bank/UPDATE_BANK_ACCOUNT";

export const addBankAccount = (account) => {
  return {
    type: ADD_BANK_ACCOUNT,
    account,
  };
};

export const removeBankAccount = (account) => {
  return {
    type: REMOVE_BANK_ACCOUNT,
    account,
  };
};

export const setBankAccounts = (accounts) => {
  return {
    type: SET_BANK_ACCOUNTS,
    bank_accounts: accounts,
  };
};

export const updateBankAccount = (account, field, newValue) => {
  return {
    type: UPDATE_BANK_ACCOUNT,
    account: account,
    field: field,
    newValue: newValue,
  };
};

const initialState = {
  loading: true,
  objects: [],
};

export default function bankInfoReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_BANK_ACCOUNT:
      return {
        ...state,
        loading: false,
        objects: [...state.objects.concat(action.account)],
      };
    case SET_BANK_ACCOUNTS:
      return {
        ...state,
        loading: false,
        objects: action.bank_accounts,
      };
    case REMOVE_BANK_ACCOUNT:
      return {
        ...state,
        objects: state.objects.filter(
          (account) => account.uuid !== action.account.uuid
        ),
      };
    case UPDATE_BANK_ACCOUNT:
      return {
        ...state,
        objects: state.objects.map((account) => {
          if (account.uuid === action.account.uuid) {
            let tempAccount = account;
            tempAccount[action.field] = action.newValue;
            return tempAccount;
          } else return account;
        }),
      };
    default:
      return state;
  }
}
