import styled from "styled-components";

import Div from "../../components/baseComponents/Div";
import Button from "../../components/baseComponents/Button";
import ConfusedCoin from "../../images/ConfusedCoin";
import Modal from "../../components/baseComponents/Modal";
import useCountdown from "../../utils/useCountdown";
import { refreshSessionToken } from "../utils/auth";
import { useState } from "react";

const StyledText = styled(Div)`
  font-size: ${(props) => props.theme.font_size.bodyLG};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

interface Props {
  showModal: boolean;
}
const SessionModal = ({ showModal }: Props) => {
  const [refreshing, setRefreshing] = useState(false);
  const timeLeft = useCountdown(60);

  return (
    <Modal showModal={showModal}>
      <Div width={{ default: 6 / 12 }} alignItems="center">
        <ConfusedCoin />
        <StyledText>Hey are you still working?</StyledText>
        <StyledText>If not you'll be logged out in:</StyledText>
        {!refreshing && (
          <StyledText>00:{String(timeLeft).padStart(2, "0")}</StyledText>
        )}
        <Div mt={{ default: 5 }}>
          <Button
            text="Keep going!"
            onClick={() => {
              setRefreshing(true);
              refreshSessionToken();
            }}
            loading={refreshing}
          />
        </Div>
      </Div>
    </Modal>
  );
};

export default SessionModal;
