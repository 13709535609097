import { useEffect, useState } from "react";
import styled from "styled-components";
import Container from "../../components/baseComponents/Container";
import Div from "../../components/baseComponents/Div";
import Row from "../../components/baseComponents/Row";
import { Unit, Option } from "../../components/global/ModelInterfaces";
import ErrorMessage from "../../components/baseComponents/ErrorMessage";
import AutocompleteDropdown from "../../components/baseComponents/AutocompleteDropdown";
import PageLoader from "../../components/PageLoader";
import { retrieveUnitList, retrieveUnitByUUID } from "../utils/unitUtils";

const StyledHeader = styled(Row)`
  font-size: ${(props) => props.theme.font_size.headlineMD};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

interface Props {
  buildingUUID: string;
  onClick: (option: any) => void;
}

const UnitSearch = ({ buildingUUID, onClick }: Props) => {
  const [selectedUnit, setSelectedUnit] = useState<Unit>();
  const [unitOptions, setUnitOptions] = useState<
    {
      label: string;
      identifier: string;
      id: string;
    }[]
  >([]);

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const successCallback = (units: Unit[]) => {
      const newUnitOptions = units.map((unit) => {
        return {
          label: unit.street_address,
          id: unit.uuid,
          identifier: unit.uuid,
        };
      });
      setUnitOptions(newUnitOptions);
      setLoading(false);
    };
    const failureCallback = () => {
      setErrorMessage("Could not retrieve unit list. Please try again.");
      setLoading(false);
    };
    retrieveUnitList(buildingUUID, successCallback, failureCallback);
  }, []);

  const UnitOnClick = (option: Option) => {
    setErrorMessage("");
    if (!option) {
      setErrorMessage("No option selected");
    } else {
      const successCallback = (unit: Unit) => {
        setSelectedUnit(unit);
        onClick(option);
      };
      const failureCallback = (err: string) => {
        setErrorMessage(err);
      };
      retrieveUnitByUUID(option.id, successCallback, failureCallback);
    }
  };

  if (loading) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  }
  return (
    <Container>
      <Div width={{ default: 1 }} pl={{ default: 3 }} pr={{ default: 3 }}>
        {unitOptions.length > 0 && (
          <>
            <StyledHeader>Select a unit:</StyledHeader>
            <Div width={{ default: 1 }} mt={{ default: 2 }}>
              <AutocompleteDropdown
                name="Unit Name"
                options={unitOptions}
                placeholder="Enter Unit Name"
                onClick={UnitOnClick}
                value={
                  selectedUnit
                    ? {
                        label: selectedUnit.street_address,
                        id: selectedUnit.uuid,
                      }
                    : null
                }
                error={false}
              />
            </Div>
          </>
        )}
        {unitOptions.length === 0 && <Div>No Units</Div>}
      </Div>
      {errorMessage && (
        <Row justifyContent="center" mt={{ default: 5 }} mb={{ default: 3 }}>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Row>
      )}
    </Container>
  );
};

export default UnitSearch;
