import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { useAppDispatch } from "./store/hooks";
import { loadInitialTenantData } from "./utils/initialization";

import NavbarTenant from "./components/TenantComponents/NavbarTenant";
import RoutesTenant from "./components/TenantComponents/RoutesTenant";

function TenantApp() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadInitialTenantData());
  }, []);
  return (
    <BrowserRouter>
      <NavbarTenant />
      <RoutesTenant />
    </BrowserRouter>
  );
}

export default TenantApp;
