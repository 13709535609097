import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../..";
import styled from "styled-components";
import TitleHeader from "../baseComponents/TitleHeader";
import BuildingIcon from "../../images/BuildingIcon";
import ListItem from "../baseComponents/ListItem";
import Button from "../baseComponents/Button";
import BackButton from "../baseComponents/BackButton";
import Container from "../baseComponents/Container";
import Row from "../baseComponents/Row";
import Div from "../baseComponents/Div";
import PageLoader from "../PageLoader";
import { Building, Unit } from "../global/ModelInterfaces";
import { cssBreakpoints } from "../global/theme";

const StyledRow = styled(Row)`
  ${cssBreakpoints("margin-top", [{ sm: "2rem" }, { md: "2rem" }, { lg: "0" }])}
`;

const BuildingsView = () => {
  const history = useHistory();
  const buildingSlice: { loading: Boolean; objects: Building[] } =
    useAppSelector((state: RootState) => state.buildings);
  const loadingBuildings = buildingSlice.loading;
  const buildings = buildingSlice.objects;

  const unitSlice: { loading: Boolean; objects: Unit[] } = useAppSelector(
    (state: RootState) => state.units
  );

  const navigateToBuilding = (selected: string) => {
    history.push(`/dashboard/building/${selected}`);
  };
  if (loadingBuildings) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  }
  return (
    <Container>
      <BackButton returnUrl="/dashboard" text="Go to Dashboard" />
      <StyledRow justifyContent="center">
        <Div alignItems="center" width={{ sm: 1, md: 1, lg: 8 / 12 }}>
          <Row justifyContent="space-between">
            <Div width={{ sm: 3 / 5, lg: 6 / 12 }}>
              <TitleHeader title={"Buildings"} />
            </Div>
            <Div width={{ sm: 2 / 5, lg: 1 / 3 }}>
              <Button
                text={"Add Building"}
                type={"primary"}
                onClick={() => history.push("/dashboard/building/create")}
              />
            </Div>
          </Row>
          <Row justifyContent="center">
            <Div width={{ default: 1 }}>
              {buildings.map((building: Building) => {
                const buildingUnits = unitSlice.objects.filter(
                  (unit: Unit) => unit.building_id === building.id
                );
                return (
                  <ListItem
                    key={building.uuid}
                    title={building.name}
                    details={[
                      building.address_1,
                      `${buildingUnits.length} Units`,
                    ]}
                    Icon={BuildingIcon}
                    iconHeight="48"
                    iconWidth="48"
                    onClick={() => navigateToBuilding(building.uuid)}
                  />
                );
              })}
            </Div>
          </Row>
        </Div>
      </StyledRow>
    </Container>
  );
};

export default BuildingsView;
