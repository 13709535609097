import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import Div from "../components/baseComponents/Div";
import Row from "../components/baseComponents/Row";
import RentableCard from "../components/baseComponents/RentableCard";
import Button from "../components/baseComponents/Button";
import PageLoader from "../components/PageLoader";

const StyledHeader = styled.h3`
  padding: 1rem;
`;

const StyledText = styled(Div)`
  padding: 1rem;
`;
const CardPaymentConfirmed = () => {
  const history = useHistory();
  const { prepaymentUUID } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  });

  if (loading) {
    return <PageLoader />;
  }

  return (
    <Div width={{ sm: 1, md: 2 / 3, lg: 2 / 5 }}>
      <RentableCard>
        <StyledHeader>Credit Card Payment Confirmed</StyledHeader>
        <StyledText>
          We have successfully processed your payment! Please continue your
          onboarding by clicking the button below.
        </StyledText>
        <Row justifyContent="center">
          <Div width={{ default: 3 / 4 }}>
            <Button
              text="Return to Onboarding"
              onClick={() => {
                history.push(`/tenant/onboarding/${prepaymentUUID}`);
              }}
            />
          </Div>
        </Row>
      </RentableCard>
    </Div>
  );
};

export default CardPaymentConfirmed;
