import { useHistory } from "react-router-dom";
import Div from "../components/baseComponents/Div";
import ConfusedCoin from "../images/ConfusedCoin";
import Button from "../components/baseComponents/Button";

const MissingPage = () => {
  return (
    <Div justifyContent="center" alignItems="center" textAlign="center">
      <ConfusedCoin />
      <h3>
        It looks like something went wrong!
        <br />
        Please go back and try again, or contact support@rentable.com if this
        issue persists.
      </h3>
      <Div width={{ lg: 1 / 4, sm: 1 }}>
        <Button
          text={"Go back"}
          onClick={() => window.location.assign("/dashboard")}
        />
      </Div>
    </Div>
  );
};

export default MissingPage;
