import axios from "axios";

export const retrieveBuildingByUUID = (
  uuid: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/retrieve-building-by-uuid";

  axios
    .post(url, {
      building_uuid: uuid,
    })
    .then((resp) => {
      const data = resp.data;
      successCallback(data.building);
    })
    .catch((err: any) => {
      failureCallback(err.data);
    });
};

export const retrieveBuildingList = (
  companyUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/retrieve-building-list";

  axios
    .post(url, {
      company_uuid: companyUUID,
    })
    .then((resp) => {
      const data = resp.data;
      successCallback(data.buildings);
    })
    .catch(() => {
      failureCallback();
    });
};

export const addLandlordToBuilding = async (
  buildingUUID: string,
  landlordID: string,
  callBackSuccess: Function,
  failureCallback: Function
) => {
  const request = {
    building_uuid: buildingUUID,
    landlord_id: landlordID,
  };
  axios
    .post("/nucleus/api/add-landlord-to-building", request)
    .then((res) => {
      callBackSuccess(res.data.values);
    })
    .catch(() => {
      failureCallback();
    });
};

export const removeLandlordFromBuilding = async (
  buildingUUID: string,
  landlordID: string,
  callBackSuccess: Function,
  failureCallback: Function
) => {
  const request = {
    building_uuid: buildingUUID,
    landlord_id: landlordID,
  };
  axios
    .post("/nucleus/api/remove-landlord-from-building", request)
    .then((res) => {
      callBackSuccess(res.data.values);
    })
    .catch(() => {
      failureCallback();
    });
};
