import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useAppSelector } from "../../store/hooks";
import { useHistory } from "react-router-dom";
import { emailRegex } from "../../utils/accountUtils";
import { setUser } from "../../store/auth";

import Div from "../../components/baseComponents/Div";
import Row from "../../components/baseComponents/Row";
import Input from "../../components/baseComponents/Input";
import PasswordInput from "../../components/baseComponents/PasswordInput";
import Button from "../../components/baseComponents/Button";
import { logIn } from "../utils/auth";
import { NucleusUser } from "../../components/global/ModelInterfaces";
import nucleusLogo from "../../images/nucleus_logo.png";

const StyledLeft = styled(Div)`
  height: 100vh;
`;

const StyledRight = styled(Div)`
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  height: 100vh;
`;
const StyledHeader = styled(Row)`
  font-size: ${(props) => props.theme.font_size.headlineLG};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const StyledForgotPassword = styled.a`
  color: ${(props) => props.theme.colors.nucleusRadioActiveGreen};
`;

const StyledFailureMessage = styled(Row)`
  color: ${(props) => props.theme.colors.black};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const LoginPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useAppSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [failureMessage, setFailureMessage] = useState("");
  const [loginErrors, setLoginErrors] = useState({
    email: "",
    password: "",
  });

  const validateLogin = () => {
    const tempErrors = {
      email: "",
      password: "",
    };
    if (
      (email.length === 0 || !emailRegex.test(email)) &&
      password.length === 0
    ) {
      setLoginErrors({
        ...tempErrors,
        email: "Please enter a valid email and a password.",
      });
      return false;
    }
    if (emailRegex.test(email) && password.length === 0) {
      setLoginErrors({
        ...tempErrors,
        password: "Please enter a password.",
      });
      return false;
    }
    if ((email.length === 0 || !emailRegex.test(email)) && password) {
      setLoginErrors({
        ...tempErrors,
        email: "Please enter a valid email.",
      });
      return false;
    }
    return true;
  };

  const handleLoginAttempt = () => {
    setFailureMessage("");
    const successCallback = (user: NucleusUser) => {
      setLoading(false);
      dispatch(setUser(user));
      history.push("/nucleus/dashboard");
    };
    const failureCallback = (errorMessage: string) => {
      setFailureMessage(errorMessage);
      setLoading(false);
    };
    if (validateLogin()) {
      setLoading(true);
      logIn(email, password, successCallback, failureCallback);
    }
  };

  useEffect(() => {
    const handleEventListener = (e: any) => {
      if (e.key === "Enter") {
        handleLoginAttempt();
      }
    };
    // Bind the event listener
    document.addEventListener("keypress", handleEventListener);
    // Unbind the event listener
    return () => {
      document.removeEventListener("keypress", handleEventListener);
    };
  }, [handleLoginAttempt]);

  useEffect(() => {
    if (currentUser && currentUser.nucleus_key) {
      history.push("/nucleus/dashboard");
    }
  }, [currentUser]);

  return (
    <Row flexWrap="nowrap">
      <StyledLeft width={{ default: 6 / 12 }} justifyContent="center">
        <StyledHeader justifyContent="center" mb={{ default: 5 }}>
          Log in to Nucleus!
        </StyledHeader>
        <StyledHeader justifyContent="center">
          <img key={"nucleus_logo"} src={nucleusLogo} height={"256px"} />
        </StyledHeader>
      </StyledLeft>
      <StyledRight width={{ default: 6 / 12 }} justifyContent="center">
        <Row mb={{ default: 5 }}>
          <Div width={{ default: 6 / 12, lg: 6 / 12 }}>
            <Input
              placeholderText="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Div>
          <Div width={{ default: 6 / 12, lg: 6 / 12 }}>
            <PasswordInput
              showLabel={false}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Div>
              <StyledForgotPassword href="/password/reset">
                Forgot password?
              </StyledForgotPassword>
            </Div>
          </Div>
        </Row>
        {loginErrors && (
          <StyledFailureMessage justifyContent="center">
            <span>{Object.values(loginErrors)}</span>
          </StyledFailureMessage>
        )}
        {failureMessage && (
          <StyledFailureMessage justifyContent="center">
            <span>{failureMessage}</span>
          </StyledFailureMessage>
        )}

        <Row justifyContent="center" mt={{ default: 2 }}>
          <Div width={{ sm: 1, lg: 4 / 12 }} justifyContent="center">
            <Button
              text="Log In"
              onClick={handleLoginAttempt}
              loading={loading}
            />
          </Div>
        </Row>
      </StyledRight>
    </Row>
  );
};

export default LoginPage;
