import { useState } from "react";
import Button from "../../../../../components/baseComponents/Button";
import Checkbox from "../../../../../components/baseComponents/Checkbox";
import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import usePrepaymentFromParams from "../../../../utils/usePrepaymentFromParams";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";

interface Props {
  createRentManagerDepositTransaction: Function;
  onSuccess: Function;
}
const DepositTransactionTool = ({
  createRentManagerDepositTransaction,
  onSuccess,
}: Props) => {
  const { prepayment } = usePrepaymentFromParams();
  const [ignoreManagementFeeBackDate, setIgnoreManagementFeeBackDate] =
    useState(false);
  const [ignoreHardCloseDate, setIgnoreHardCloseDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleCreateTransactions = () => {
    const successCallback = (data: any) => {
      setLoading(false);
      onSuccess(data);
    };
    const failureCallback = (error: string) => {
      setLoading(false);
      setError(error);
    };
    setLoading(true);
    setError("");
    createRentManagerDepositTransaction(
      prepayment.general_info.prepayment_uuid,
      ignoreManagementFeeBackDate,
      ignoreHardCloseDate,
      successCallback,
      failureCallback
    );
  };
  return (
    <Div>
      <Row mt={{ default: 3 }}>
        <span>Ignore Management Fee Back Date</span>
        <Div>
          <Checkbox
            value={ignoreManagementFeeBackDate}
            onChange={() =>
              setIgnoreManagementFeeBackDate(
                (ignoreManagementFeeBackDate) => !ignoreManagementFeeBackDate
              )
            }
          />
        </Div>
      </Row>
      <Row mb={{ default: 3 }}>
        <span>Ignore Hard Close Date</span>
        <Div>
          <Checkbox
            value={ignoreHardCloseDate}
            onChange={() =>
              setIgnoreHardCloseDate(
                (ignoreHardCloseDate) => !ignoreHardCloseDate
              )
            }
          />
        </Div>
      </Row>
      {error && (
        <Row mb={{ default: 3 }}>
          <ErrorMessage>{error}</ErrorMessage>
        </Row>
      )}
      <Row>
        <Div width={{ default: 1 }}>
          <Button
            onClick={handleCreateTransactions}
            loading={loading}
            text="Create Transaction"
          />
        </Div>
      </Row>
    </Div>
  );
};

export default DepositTransactionTool;
