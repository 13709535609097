import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Div from "../baseComponents/Div";
import Button from "../baseComponents/Button";
import BanditIcon from "../../images/BanditIcon";
import { COLORS } from "../global/colors";

const StyledText = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineSM};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  margin: 2rem 0;
`;

const ForbiddenUnauthView = () => {
  const history = useHistory();
  return (
    <Div>
      <Div>
        <BanditIcon color={COLORS.grey} />
      </Div>
      <StyledText>
        Hold it right there!
        <br />
        You don't have access to see this page.
      </StyledText>
      <Button text="Go Back" onClick={() => history.push("/login")}></Button>
    </Div>
  );
};

export default ForbiddenUnauthView;
