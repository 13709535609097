import { setBankAccounts } from "../store/bankInfo";
import { setUnits } from "../store/units";
import { setBuildings } from "../store/buildings";
import { setPrepayments } from "../store/prepayments";
import { setInvites } from "../store/invite";
import { setTenantInfo } from "../store/tenantInfo";
import { setCompany } from "../store/company";

const axios = require("axios").default;

const dispatchDataIntoRedux = async (action, url, dispatch) => {
  return axios.get(url).then((res) => {
    dispatch(action(res.data.values));
  });
};

export const loadInitialLandlordData = () => {
  return (dispatch) => {
    dispatchDataIntoRedux(setUnits, "/api/unit/dashboard", dispatch);
    dispatchDataIntoRedux(setPrepayments, "/api/deposit/dashboard", dispatch);
    dispatchDataIntoRedux(
      setBankAccounts,
      "/api/payments/funding/sources",
      dispatch
    );
    dispatchDataIntoRedux(setBuildings, "/api/building/dashboard", dispatch);
    dispatchDataIntoRedux(setInvites, "/api/invite/dashboard", dispatch);
    dispatchDataIntoRedux(setCompany, "/api/company/dashboard", dispatch);
  };
};

export const loadInitialTenantData = () => {
  return (dispatch) => {
    dispatchDataIntoRedux(setTenantInfo, "/api/tenant/dashboard", dispatch);
    dispatchDataIntoRedux(
      setBankAccounts,
      "/api/payments/funding/sources",
      dispatch
    );
  };
};
