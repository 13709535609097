import { createStore, compose, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import auth from "./auth";
import bankInfo from "./bankInfo";
import tenantInfo from "./tenantInfo";
import units from "./units";
import buildings from "./buildings";
import prepayments from "./prepayments";
import invites from "./invite";
import landlordbuildings from "./landlordbuildings";
import landlords from "./admin/landlords";
import companies from "./company";

const appReducer = combineReducers({
  auth,
  bankInfo,
  prepayments,
  units,
  buildings,
  landlordbuildings,
  landlords,
  invites,
  tenantInfo,
  companies,
});

const rootReducer = (state, action) => {
  if (action.type === "authentication/REMOVE_USER") {
    state = undefined;
  }
  return appReducer(state, action);
};

let storeEnhancer;

if (process.env.NODE_ENV !== "production") {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  storeEnhancer = composeEnhancers(applyMiddleware(thunk));
} else {
  storeEnhancer = applyMiddleware(thunk);
}

export default function configureStore(initialState) {
  return createStore(rootReducer, initialState, storeEnhancer);
}
