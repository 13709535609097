import { NUCLEUS_COLORS } from "./colors";
import { FONT_SIZE, FONT_WEIGHT } from "../../components/global/fonts";

const breakpoints = ["300px", "600px", "960px"];
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];

const box_shadow = {
  dark: "0 0 11px rgba(33, 33, 33, 1)",
  light: "0rem 0rem 2rem rgba(0, 0, 0, 0.12)",
};

const space = {
  0: "0",
  1: ".25rem",
  2: ".5rem",
  3: "1rem",
  4: "1.5rem",
  5: "3rem",
  6: "4rem",
  7: "5rem",
  8: "12rem",
  9: "13rem",
  10: "15rem",
};

const border_radius = {
  XS: ".25rem",
  SM: ".5rem",
  MD: "1rem",
  LG: "1.5rem",
  XL: "2rem",
};

const borders = {
  primarySM: `.125rem solid ${NUCLEUS_COLORS.primary}`,
  secondarySM: `.125rem solid ${NUCLEUS_COLORS.backgroundSecondary}`,
  tertiarySM: `.125rem solid ${NUCLEUS_COLORS.backgroundTertiary}`,
  primaryMD: `.25rem solid ${NUCLEUS_COLORS.primary}`,
  secondaryMD: `.25rem solid ${NUCLEUS_COLORS.backgroundSecondary}`,
  tertiaryMD: `.25rem solid ${NUCLEUS_COLORS.backgroundTertiary}`,
  primaryLG: `.5rem solid ${NUCLEUS_COLORS.primary}`,
  secondaryLG: `.5rem solid ${NUCLEUS_COLORS.backgroundSecondary}`,
  tertiaryLG: `.5rem solid ${NUCLEUS_COLORS.backgroundTertiary}`,
};

const nucleusTheme = {
  colors: NUCLEUS_COLORS,
  font_size: FONT_SIZE,
  font_weight: FONT_WEIGHT,
  box_shadow: box_shadow,
  border_radius: border_radius,
  breakpoints,
  space,
  borders,
};

export default nucleusTheme;
