import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Button from "./baseComponents/Button";
import BeatLoader from "react-spinners/BeatLoader";
import { markBankValidated } from "../utils/accountUtils";
import { updateBankAccount } from "../store/bankInfo";
import { useDispatch } from "react-redux";
import useGetBankAccount from "../utils/useGetBankAccount";

export default function PersonalVerificationComponent() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [customerId, setCustomerId] = useState("");
  const [dwollaConfigured, setDwollaConfigured] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [certified, setCertified] = useState(false);
  const { bankAccount, loadingBankAccounts } = useGetBankAccount();
  const configureDwolla = async (bankAccountUUID) => {
    const dwolla = window.dwolla;
    await axios
      .post("/api/payments/dwolla/tokens", { bankAccount: bankAccountUUID })
      .then((res) => {
        if (res.data.access_token) {
          setCustomerId(res.data.customer_id);
          dwolla.configure({
            environment: res.data.environment,
            token: () => Promise.resolve({ token: res.data.access_token }),
            success: (res) =>
              Promise.resolve(res).then(async (res) => {
                if (res.status) {
                  setCertified(true);
                  const validated = markBankValidated(
                    bankAccountUUID,
                    "payment_personal_verified"
                  );
                  if (validated) {
                    dispatch(
                      updateBankAccount(
                        bankAccount,
                        "payment_personal_verified",
                        true
                      )
                    );
                  }
                } else if (res.location) {
                  determineCertification(res.location);
                }
              }),
            error: (err) => Promise.resolve(console.log(err)),
          });
          setDwollaConfigured(true);
        }
      })
      .catch(() => {
        if (bankAccount) {
          setErrorMessage(
            "Could not configure dwolla, please try again in a few minutes."
          );
        }
      });
  };

  const determineCertification = async (customerUrl) => {
    const res = await axios.post("/api/payments/dwolla/customer", {
      customerUrl,
    });
    if (res) {
      setCertified(true);
    } else {
      setErrorMessage(
        "Could not get dwolla customer, please try again in a few minutes."
      );
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//cdn.dwolla.com/v2/dwolla-web.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    if (bankAccount) {
      configureDwolla(bankAccount.uuid);
    }
  }, [bankAccount]);

  return (
    <div className="text-center pt-2 w-50">
      {errorMessage && <div className="error text-danger">{errorMessage}</div>}
      {dwollaConfigured ? (
        <div>
          {customerId && !certified && (
            <div className="mt-3 px-5 pt-2 bg-light rounded">
              <h3>Customer Verification</h3>
              <dwolla-customer-update
                customerId={customerId}
                terms="https://www.rentable.com/landlord-terms-of-services"
                privacy="https://www.rentable.com/privacy-policy"
              ></dwolla-customer-update>
            </div>
          )}
          {certified && (
            <div className="py-3">
              <h3 className="pb-2">You're all set!</h3>
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  text="Back to onboarding"
                  onClick={() => history.push("/dashboard")}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        loadingBankAccounts && (
          <div className="mt-5">
            <BeatLoader />
          </div>
        )
      )}
    </div>
  );
}
