const SET_LANDLORDBUILDINGS = "buildings/SET_LANDLORDBUILDINGS";
const ADD_LANDLORDBUILDING = "buildings/ADD_LANDLORDBUILDING";
const UPDATE_LANDLORDBUILDING = "buildings/UPDATE_LANDLORDBUILDING";

export const setLandlordBuildings = (landlordbuildings) => {
  return {
    type: SET_LANDLORDBUILDINGS,
    landlordbuildings: landlordbuildings,
  };
};

export const addLandlordBuildingStore = (landlordbuilding) => {
  return {
    type: ADD_LANDLORDBUILDING,
    landlordbuilding: landlordbuilding,
  };
};

export const updateLandlordBuildingStore = (landlordbuilding) => {
  return {
    type: UPDATE_LANDLORDBUILDING,
    landlordbuilding,
  };
};

const initialState = {
  loading: true,
  objects: [],
};

export default function landlordbuildingsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LANDLORDBUILDINGS:
      return {
        ...state,
        loading: false,
        objects: action.landlordbuildings,
      };
    case ADD_LANDLORDBUILDING:
      return {
        ...state,
        objects: state.objects.concat(action.landlordbuilding),
      };
    case UPDATE_LANDLORDBUILDING:
      return {
        ...state,
        objects: state.objects.map((landlordbuilding) => {
          if (action.landlordbuilding.id === landlordbuilding.id) {
            return action.landlordbuilding;
          } else return landlordbuilding;
        }),
      };
    default:
      return state;
  }
}
