import Div from "../baseComponents/Div";
import ConfusedCoin from "../../images/ConfusedCoin";
import ErrorMessage from "../baseComponents/ErrorMessage";

const PaymentProcessorRestricted = () => {
  return (
    <Div alignItems="center">
      <ErrorMessage>
        Your account has been restricted by our payment processor please reach
        out to support@rentable.com so we can help you out.
      </ErrorMessage>
    </Div>
  );
};

export default PaymentProcessorRestricted;
