import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../..";
import styled from "styled-components";

import Div from "../baseComponents/Div";
import Row from "../baseComponents/Row";
import Container from "../baseComponents/Container";
import BackButton from "../baseComponents/BackButton";
import PlaidButton from "../PlaidButton";
import BankAccountRow from "./AdminBankAccountRow";

import { BankAccount } from "../global/ModelInterfaces";

const BuildingsTitle = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineMD};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  color: ${(props) => props.theme.colors.navy};
  padding: 2.25rem 0;
`;

const BankAccountsView = () => {
  const history = useHistory();
  const currentUser = useAppSelector((state: RootState) => state.auth.user);
  const bankAccountList = useAppSelector(
    (state: RootState) => state.bankInfo.objects
  );

  useEffect(() => {
    if (currentUser.role !== "admin") {
      history.push("/forbidden");
    }
  });

  return (
    <Container>
      <Div>
        <BackButton returnUrl="/settings" text="Settings Home" />
        <Row justifyContent="center">
          <Div width={{ sm: 1, md: 1, lg: 9 / 12 }}>
            <Row justifyContent="space-between">
              <Div width={{ sm: 1, md: 1, lg: 8 / 12 }} justifyContent="center">
                <BuildingsTitle>All Company Bank Accounts</BuildingsTitle>
              </Div>
              <Div width={{ sm: 1, md: 1, lg: 4 / 12 }} justifyContent="center">
                <PlaidButton customText="Link a new bank account" />
              </Div>
            </Row>
            {bankAccountList.map((bankAccount: BankAccount) => {
              return <BankAccountRow bankAccount={bankAccount} />;
            })}
          </Div>
        </Row>
      </Div>
    </Container>
  );
};

export default BankAccountsView;
