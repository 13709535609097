import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import PersonalVerificationComponent from "../components/PersonalVerification";

export default function PersonalVerifyPage() {
	const currentUser = useSelector((state) => state.auth.user);
	if (!currentUser) return <Redirect to={`/login`} />;

  return (
    <div className="businessVerifyContainer d-flex flex-column align-items-center py-5 w-100 h-100">
		<h3>Verify Your Identity</h3>
		<PersonalVerificationComponent />
    </div>
  );
}
