import axios from "axios";
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "./store/hooks";
import styled, { ThemeProvider } from "styled-components";
import nucleusTheme from "./nucleus/utils/theme";

import Div from "./components/baseComponents/Div";
import Row from "./components/baseComponents/Row";
import NucleusLoader from "./nucleus/components/NucleusLoader";
import RoutesNucleus from "./nucleus/components/RoutesNucleus";
import SessionModal from "./nucleus/components/SessionModal";
import SideNavbar from "./nucleus/components/SideNavbar";

import { setUser, updateAuth } from "./store/auth";
import { GenericObject } from "./components/global/ModelInterfaces";
import { getNucleusIdentity, refreshSessionToken } from "./nucleus/utils/auth";

export const AUTH_TOKEN_NAME = "nucAuthToken";
export const SESSION_TOKEN_NAME = "nucSessionToken";

const IDENTITY_CHECK_POLL_RATE_IN_MS = 5000;
const IDENTITY_CHECK_MINIMUM_SECONDS_TO_LOGOUT = 60;

const StyledNavBarContainer = styled(Div)<{ isOpen: boolean }>`
  position: fixed;
  width: ${(props) =>
    props.isOpen ? props.theme.space[8] : props.theme.space[7]};
`;

const StyledMainContainer = styled(Div)<{ isOpen: boolean }>`
  width: ${(props) =>
    props.isOpen
      ? `calc(100% - ${props.theme.space[8]})`
      : `calc(100% - ${props.theme.space[7]})`};
  margin-left: ${(props) =>
    props.isOpen ? props.theme.space[8] : props.theme.space[7]};
`;

const NucleusApp = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const authenticated = auth.authenticated;
  const secondsToLogout = auth.seconds_to_logout;

  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const loadUser = () => {
    const token = localStorage.getItem(AUTH_TOKEN_NAME);
    if (!authenticated && token) {
      const successCallback = (data: GenericObject) => {
        dispatch(setUser(data));
        setLoading(false);
      };

      getNucleusIdentity(token, successCallback);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    axios.interceptors.request.use(
      (request) => {
        const token = localStorage.getItem(AUTH_TOKEN_NAME);
        const session = localStorage.getItem(SESSION_TOKEN_NAME);
        Object.assign(request.headers, {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authentication: token,
          "Nucleus-Session": session,
        });
        return request;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    loadUser();
  }, []);

  // Check the user's session every 5 seconds
  useEffect(() => {
    const token = localStorage.getItem(AUTH_TOKEN_NAME);
    if (authenticated && token) {
      setLoading(false);
      const interval = setInterval(() => {
        const successCallback = (data: GenericObject) => {
          dispatch(updateAuth(data));
        };
        getNucleusIdentity(token, successCallback);
      }, IDENTITY_CHECK_POLL_RATE_IN_MS);

      // Clear interval on unmount
      return () => clearInterval(interval);
    }
  }, [auth]);

  // Refresh the token if page is refreshed and user is authenticated
  useEffect(() => {
    if (authenticated) {
      refreshSessionToken();
    }
  }, [authenticated]);

  if (loading) {
    return (
      <Row justifyContent="center" mt={{ default: 5 }}>
        <Div width={{ default: 3 / 12 }} mt={{ default: 5 }}>
          <NucleusLoader />
        </Div>
      </Row>
    );
  }

  return (
    <BrowserRouter>
      <ThemeProvider theme={nucleusTheme}>
        <Row flexWrap="nowrap" addSpace={false}>
          {authenticated ? (
            <>
              <StyledNavBarContainer isOpen={isSidebarOpen}>
                <SideNavbar onOpenSidebar={setIsSidebarOpen} />
              </StyledNavBarContainer>
              {secondsToLogout <= IDENTITY_CHECK_MINIMUM_SECONDS_TO_LOGOUT && (
                <SessionModal showModal={true} />
              )}
              <StyledMainContainer isOpen={isSidebarOpen}>
                <RoutesNucleus />
              </StyledMainContainer>
            </>
          ) : (
            <RoutesNucleus />
          )}
        </Row>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default NucleusApp;
