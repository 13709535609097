import { useEffect, useState } from "react";
import styled from "styled-components";
import Div from "./Div";
import Row from "./Row";
import AutocompleteDropdown from "./AutocompleteDropdown";
import BuildingPermissionsDropdown from "./BuildingPermissionsDropdown";
import LandlordPermissionsCard from "./LandlordPermissionsCard";
import { Landlord, LandlordBuilding, Option } from "../global/ModelInterfaces";
const StyledTitle = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineMD};
`;

interface Props {
  landlordBuildings: LandlordBuilding[];
  landlords: Landlord[];
  onAdd: Function;
  onRemove: Function;
}

const BuildingManagement = ({
  landlordBuildings,
  landlords,
  onAdd,
  onRemove,
}: Props) => {
  const [selectedBuilding, setSelectedBuilding] =
    useState<LandlordBuilding | null>(null);
  const [selectedLandlord, setSelectedLandlord] = useState<Landlord | null>(
    null
  );

  const landlordOptions = landlords.map((landlord: Landlord) => {
    return {
      id: landlord.id.toString(),
      label: landlord.full_name,
    };
  });

  const buildingOptions = landlordBuildings.map((building) => {
    return {
      id: building.uuid,
      label: building.name,
    };
  });

  const onLandlordSelect = (option: Option) => {
    setSelectedBuilding(null);
    if (!!option) {
      const landlord = landlords.find(
        (landlord: Landlord) => landlord.id.toString() === option.id
      );
      setSelectedLandlord(landlord || null);
    }
  };

  const onBuildingSelect = (option: Option) => {
    setSelectedLandlord(null);
    if (!!option) {
      const building = landlordBuildings.find(
        (building: LandlordBuilding) => building.uuid === option.id
      );
      setSelectedBuilding(building || null);
    }
  };

  useEffect(() => {
    if (selectedLandlord) {
      setSelectedBuilding(null);
      const landlord = landlords.find(
        (landlord: Landlord) => landlord.id === selectedLandlord.id
      );
      setSelectedLandlord(landlord || null);
    }
  }, [landlordBuildings]);

  useEffect(() => {
    if (selectedBuilding) {
      setSelectedLandlord(null);
      const building = landlordBuildings.find(
        (building: LandlordBuilding) => building.uuid === selectedBuilding.uuid
      );
      setSelectedBuilding(building || null);
    }
  }, [landlordBuildings]);

  return (
    <Row addSpace={false}>
      <Div width={{ default: 1 }}>
        <Row mb={{ default: 5 }}>
          <StyledTitle width={{ default: 4 / 12 }}>
            Search for Property Manager or Building Name
          </StyledTitle>
          <Div width={{ default: 4 / 12 }}>
            <AutocompleteDropdown
              options={landlordOptions}
              name="Search for Property Manager Name"
              placeholder=""
              onClick={onLandlordSelect}
              value={null}
              error={false}
              limitedTo={5}
            />
          </Div>
          <Div width={{ default: 4 / 12 }}>
            <AutocompleteDropdown
              options={buildingOptions}
              name="Search for Building Name"
              placeholder=""
              onClick={onBuildingSelect}
              value={
                selectedBuilding
                  ? {
                      label: selectedBuilding.name,
                      id: selectedBuilding.uuid,
                    }
                  : null
              }
              error={false}
              limitedTo={5}
            />
          </Div>
        </Row>
        {selectedLandlord && (
          <Row>
            <LandlordPermissionsCard
              landlord={selectedLandlord}
              buildings={landlordBuildings}
              onAdd={onAdd}
              onRemove={onRemove}
            />
          </Row>
        )}
        {selectedBuilding && (
          <Row>
            <BuildingPermissionsDropdown
              landlords={landlords}
              building={selectedBuilding}
              onAdd={onAdd}
              onRemove={onRemove}
            />
          </Row>
        )}
      </Div>
    </Row>
  );
};

export default BuildingManagement;
