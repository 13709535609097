import { Route, Switch } from "react-router-dom";

import LoginPage from "../../pages/LoginPage";
import SignUpPage from "../../pages/SignUpPage";
import PasswordResetView from "../../pages/PasswordResetView";
import PasswordResetConfirmView from "../../pages/PasswordResetConfirmView";
import ForbiddenUnauthview from "./ForbiddenUnauth";

const RoutesUnauth = () => {
  return (
    <Switch>
      <Route exact path="/user/signup" component={SignUpPage} />
      <Route exact path="/signup" component={SignUpPage} />
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/password/reset" component={PasswordResetView} />
      <Route
        exact
        path="/password-reset/:uidb64/:token"
        component={PasswordResetConfirmView}
      />
      <Route exact path="/forbidden" component={ForbiddenUnauthview} />
      <Route exact path="*" component={LoginPage} />
    </Switch>
  );
};

export default RoutesUnauth;
