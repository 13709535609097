import { TenantPrepaymentInfo } from "../components/global/ModelInterfaces";
import useCheckFinancials from "./useCheckFinancials";

const useCalculatePaymentAmounts = (prepayment: TenantPrepaymentInfo) => {
  const securityDeposit = prepayment.deposits.security_deposit;
  const lastMonthsRent = prepayment.deposits.last_months_rent;
  const { financialInfo, loadingFinancialInfo, financialInfoError } =
    useCheckFinancials();

  const getLastMonthsRentWithFees = () => {
    return lastMonthsRent
      ? (
          Number(lastMonthsRent.amount) + Number(lastMonthsRent.dwolla_fee)
        ).toFixed(2)
      : 0;
  };

  if (financialInfo && !loadingFinancialInfo) {
    const initialPayment = parseFloat(
      financialInfo.paymentPlanApproval.approved_initial_payment
    );
    const approvedPaymentPlanDefaultFee = parseFloat(
      financialInfo.paymentPlanApproval.approved_default_fee
    );
    const getMonthlyFee = () => {
      return (
        getMonthlyAmount() * financialInfo.paymentPlanApproval.approved_fee_rate
      );
    };
    const getInitialFee = () => {
      return (
        initialPayment * financialInfo.paymentPlanApproval.approved_fee_rate
      );
    };
    const getMonthlyAmountWithFee = () => {
      return getMonthlyAmount() + getMonthlyFee();
    };

    const getMonthlyAmount = () => {
      return securityDeposit
        ? (securityDeposit.amount - initialPayment) /
            (financialInfo.paymentPlanApproval.approved_number_months - 1)
        : 0;
    };

    const getTotalSecurityDepositAmountWithFee = () => {
      return (
        getInitialSecurityDepositPaymentTotalWithFees() +
        getMonthlyAmountWithFee() *
          (financialInfo.paymentPlanApproval.approved_number_months - 1)
      );
    };

    const getInitialSecurityDepositPaymentTotalWithFees = () => {
      if (!securityDeposit) return 0; // No security deposit — no fees
      if (securityDeposit.dwolla_fee) {
        return initialPayment + securityDeposit.dwolla_fee + getInitialFee();
      }
      return initialPayment + approvedPaymentPlanDefaultFee + getInitialFee();
    };

    const getSecurityDepositWithFees = () => {
      return securityDeposit
        ? (
            Number(securityDeposit.amount) + Number(securityDeposit.dwolla_fee)
          ).toFixed(2)
        : 0;
    };

    const getInitialPaymentTotalWithFees = () => {
      return (
        Number(getInitialSecurityDepositPaymentTotalWithFees()) +
        Number(getLastMonthsRentWithFees())
      ).toFixed(2);
    };

    const getFullPaymentTotalWithFees = () => {
      return (
        Number(getLastMonthsRentWithFees()) +
        Number(getSecurityDepositWithFees())
      ).toFixed(2);
    };

    const getAmountDueWithFees = () => {
      return Number(financialInfo.amountToPay).toFixed(2);
    };

    return {
      loading: false,
      lastMonthsRentWithFees: getLastMonthsRentWithFees(),
      monthlyAmountWithFee: getMonthlyAmountWithFee(),
      totalSecurityDepositAmountWithFee: getTotalSecurityDepositAmountWithFee(),
      initialSecurityDepositPaymentTotalWithFee:
        getInitialSecurityDepositPaymentTotalWithFees(),
      initialPaymentTotalWithFee: getInitialPaymentTotalWithFees(),
      fullPaymentTotalWithFee: getFullPaymentTotalWithFees(),
      approvedPaymentPlanDefaultFee: approvedPaymentPlanDefaultFee,
      totalAmountDueWithFee: getAmountDueWithFees(),
    };
  } else if (financialInfoError) {
    return {
      loading: false,
      error: financialInfoError,
    };
  } else {
    return {
      loading: loadingFinancialInfo,
    };
  }
};

export default useCalculatePaymentAmounts;
