import React from "react";

const DownArrowIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.81923 5.2H11.7812C12.332 5.2 12.6074 5.91391 12.2175 6.33229L8.23806 10.6058C7.99669 10.8648 7.6037 10.8648 7.36233 10.6058L3.38291 6.33229C2.99302 5.91391 3.26842 5.2 3.81923 5.2Z"
        fill="#222A3E"
      />
    </svg>
  );
};

export default DownArrowIcon;
