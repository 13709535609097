import { useState } from "react";
import { useAppDispatch } from "../../../store/hooks";
import styled from "styled-components";

import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import Input from "../../../components/baseComponents/Input";
import Button from "../../../components/baseComponents/Button";
import ErrorMessage from "../../../components/baseComponents/ErrorMessage";
import SuccessMessage from "../../../components/baseComponents/SuccessMessage";
import { updatePrepaymentUnit } from "../../utils/prepaymentUtils";
import { updatePrepaymentSection } from "../../../store/nucleusPrepayments";
import { validate as isValidUUID } from "uuid";

interface Props {
  prepaymentUUID: string;
}

const MovePrepaymentToNewUnitForm = ({ prepaymentUUID }: Props) => {
  const dispatch = useAppDispatch();
  const [newUnit, setNewUnit] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const handleUpdatePrepaymentUnit = () => {
    setSuccess("");
    setError("");
    if (validateForm()) {
      const successCallback = (res: any) => {
        setNewUnit("");
        setSuccess("Successfully updated unit!");
        dispatch(
          updatePrepaymentSection(
            prepaymentUUID,
            "general_info",
            res.general_info
          )
        );
      };
      const failureCallback = (err: any) => {
        setError(`Internal Error: ${err}`);
      };
      updatePrepaymentUnit(
        prepaymentUUID,
        newUnit,
        successCallback,
        failureCallback
      );
    }
  };

  const validateForm = () => {
    let valid = true;
    if (!newUnit) {
      setError("A unit UUID is needed to move the prepayment.");
      valid = false;
    } else if (!isValidUUID(newUnit)) {
      setError("The UUID entered is not a valid format.");
      valid = false;
    }
    return valid;
  };

  return (
    <Div>
      <Row mt={{ default: 3 }}>
        <Div width={{ default: 1 }}>
          <Input
            label="New Unit UUID"
            placeholderText="ex: 1234abcd-5ef6-7890-gh12-3ij456klm78n"
            onChange={(e) => setNewUnit(e.target.value)}
            value={newUnit}
          />
        </Div>
      </Row>
      <Row justifyContent="center" mt={{ default: 3 }}>
        <Div width={{ default: 8 / 12 }}>
          <Button
            type="secondary"
            text="Submit"
            onClick={() => handleUpdatePrepaymentUnit()}
          />
        </Div>
      </Row>
      <Row mt={{ default: 3 }}>
        {success && <SuccessMessage>{success}</SuccessMessage>}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Row>
    </Div>
  );
};

export default MovePrepaymentToNewUnitForm;
