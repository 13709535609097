const SET_BUILDINGS = "buildings/SET_BUILDINGS";
const ADD_BUILDING = "buildings/ADD_BUILDING";
const UPDATE_BUILDING = "buildings/UPDATE_BUILDING";
const DELETE_BUILDING = "buildings/DELETE_BUILDING";

export const setBuildings = (buildings) => {
  return {
    type: SET_BUILDINGS,
    buildings: buildings,
  };
};

export const addBuilding = (building) => {
  return {
    type: ADD_BUILDING,
    building: building,
  };
};

export const deleteBuildingLocal = (building) => {
  return {
    type: DELETE_BUILDING,
    building: building,
  };
};

export const updateBuildingLocal = (building) => {
  return {
    type: UPDATE_BUILDING,
    building,
  };
};

const initialState = {
  loading: true,
  objects: [],
};

export default function buildingsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_BUILDINGS:
      action.buildings.forEach((building) => {
        if (building.is_inactive) {
          building.name = `[Inactive] ${building.name}`;
        }
      });
      return {
        ...state,
        loading: false,
        objects: action.buildings,
      };

    case ADD_BUILDING:
      return {
        ...state,
        objects: state.objects.concat(action.building),
      };

    case DELETE_BUILDING:
      return {
        ...state,
        objects: state.objects.filter(
          (building) => action.building.uuid !== building.uuid
        ),
      };

    case UPDATE_BUILDING:
      return {
        ...state,
        objects: state.objects.map((building) => {
          if (action.building.uuid === building.uuid) {
            return action.building;
          } else return building;
        }),
      };

    default:
      return state;
  }
}
