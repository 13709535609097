import styled from "styled-components";
import { useState, useEffect } from "react";
import { useAppDispatch } from "../../../store/hooks";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import DateInput from "../../../components/baseComponents/DateInput";
import Checkbox from "../../../components/baseComponents/Checkbox";
import Button from "../../../components/baseComponents/Button";
import ErrorMessage from "../../../components/baseComponents/ErrorMessage";
import SuccessMessage from "../../../components/baseComponents/SuccessMessage";
import { updatePrepaymentDates } from "../../utils/prepaymentUtils";
import { updatePrepaymentSection } from "../../../store/nucleusPrepayments";

interface Props {
  prepaymentUUID: string;
  currentStartDate: string;
  currentEndDate: string;
}

const UpdatePrepaymentDatesForm = ({
  prepaymentUUID,
  currentStartDate,
  currentEndDate,
}: Props) => {
  const dispatch = useAppDispatch();
  const [newStartDate, setNewStartDate] = useState("");
  const [newEndDate, setNewEndDate] = useState("");
  const [monthToMonth, setMonthToMonth] = useState(!currentEndDate);
  const [confirmDisabled, setConfirmDisabled] = useState<boolean>(true);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const validateDates = () => {
    const startDate = newStartDate ? newStartDate : currentStartDate;
    const endDate = newEndDate ? newEndDate : currentEndDate;

    if (startDate && monthToMonth) {
      return true;
    } else if (startDate && endDate) {
      return !(startDate > endDate);
    } else return false;
  };

  const handleUpdatePrepaymentDates = () => {
    setSuccess("");
    setError("");
    if (!confirmDisabled) {
      const successCallback = (res: any) => {
        setSuccess("Successfully updated lease dates!");
        setNewStartDate("");
        setNewEndDate("");
        setMonthToMonth(false);
        dispatch(
          updatePrepaymentSection(
            prepaymentUUID,
            "general_info",
            res.general_info
          )
        );
      };
      const failureCallback = (err: any) => {
        setError(`Internal Error: ${err}`);
      };
      updatePrepaymentDates(
        prepaymentUUID,
        successCallback,
        failureCallback,
        newStartDate,
        newEndDate,
        monthToMonth
      );
    }
  };

  const checkValidation = () => {
    setError("");
    if (
      !newStartDate &&
      !newEndDate &&
      ((monthToMonth && !currentEndDate) || (!monthToMonth && currentEndDate))
    ) {
      setError("Please make a change before submitting.");
      return false;
    }

    if (!validateDates()) {
      setError("Invalid dates: start date must be before end date.");
      return false;
    }

    if (!monthToMonth && !newEndDate && !currentEndDate) {
      setError("Please enter a new end date.");
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (success) {
      return;
    }
    const valid = checkValidation();
    setConfirmDisabled(!valid);
  }, [newStartDate, newEndDate, monthToMonth]);

  return (
    <Div mt={{ default: 3 }}>
      <Row>
        <Div width={{ default: 1 }}>
          <DateInput
            label="New Start Date"
            onChange={(date: string) => setNewStartDate(date)}
          />
        </Div>
      </Row>
      <Row>
        <Div width={{ default: 1 }}>
          <DateInput
            label="New End Date"
            onChange={(date: string) => setNewEndDate(date)}
          />
        </Div>
      </Row>
      <Row>
        <Div width={{ default: 1 }} justifyContent="center">
          <Checkbox
            label="Month to Month?"
            value={monthToMonth}
            onChange={() => setMonthToMonth(!monthToMonth)}
          />
        </Div>
      </Row>
      <Row justifyContent="center" mt={{ default: 3 }}>
        <Div width={{ default: 8 / 12 }}>
          <Button
            type="secondary"
            text="Submit"
            onClick={() => handleUpdatePrepaymentDates()}
            disabled={confirmDisabled}
          />
        </Div>
      </Row>
      <Row mt={{ default: 3 }} justifyContent="center">
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {success && <SuccessMessage>{success}</SuccessMessage>}
      </Row>
    </Div>
  );
};

export default UpdatePrepaymentDatesForm;
