import axios from "axios";
import React, { useState, useEffect } from "react";
import { getCookie } from "../utils/getCookie";
export default function BusinessVerificationComponent() {
  const [dwollaConfigured, setDwollaConfigured] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [certified, setCertified] = useState(false);
  const [clientToken, setClientToken] = useState("");
  const [environment, setEnvironment] = useState("");
  const [beneficialOwnershipCertified, setBeneficialOwnershipCertified] =
    useState(false);
  const [awaitingBeneficialOwnershipCert, setAwaitingBeneficialOwnershipCert] =
    useState(false);

  const configureDwolla = async () => {
    const csrftoken = getCookie("csrftoken");
    await axios
      .post("/api/payments/dwolla/customer-create-tokens", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
          "X-Requested-With": "Dwolla-Drop-Ins-Library",
        },
      })
      .then((res) => {
        if (res.data.token) {
          setClientToken(res.data.token);
          setEnvironment(res.data.environment);
        }
      })
      .catch(() => {
        setErrorMessage(
          "Could not configure dwolla, please try again in a few minutes."
        );
      });
  };

  const checkBeneficialOwnershipCertNeeded = async (res) => {
    if (res.location) {
      const customerUrl = res.location;
      await axios
        .post("/api/payments/dwolla/customer", { customerUrl })
        .then((res) => {
          if (res.data._links["certify-beneficial-ownership"]) {
            setAwaitingBeneficialOwnershipCert(true);
            beneficialOwnershipCertification(
              res.data._links["certify-beneficial-ownership"].href
            );
          }
        })
        .catch(() => {
          setErrorMessage("Could not get dwolla customer.");
        });
    }
  };

  const beneficialOwnershipCertification = async (requestUrl) => {
    await axios
      .post("api/payments/dwolla/certify-beneficial-ownership", {
        beneficial_ownership_cert_url: requestUrl,
      })
      .then((res) => {
        if (res.data.status === "certified") {
          console.log("successfully certified beneficial ownership!");
          setBeneficialOwnershipCertified(true);
        }
      })
      .catch(() => {
        setErrorMessage("Could not certify beneficial ownership.");
      });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//cdn.dwolla.com/v2.1.8/dwolla-web.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    configureDwolla();
  }, []);

  useEffect(() => {
    if (window.dwolla && clientToken && environment) {
      window.dwolla.configure({
        environment: environment,
        token: () => Promise.resolve({ token: clientToken }),
        success: (res) =>
          Promise.resolve(res).then((res) =>
            checkBeneficialOwnershipCertNeeded(res.response)
          ),
        error: (err) => Promise.resolve(console.log(err)),
      });
      setDwollaConfigured(true);
      setCertified(true);
    }
  }, [dwollaConfigured, clientToken, environment]);

  return (
    <div className="text-center pt-2 w-50">
      {errorMessage && <div className="error text-danger">{errorMessage}</div>}
      <div>
        {certified && (
          <div className="mt-3 px-5 pt-2 bg-light rounded">
            <h3>Customer Creation</h3>
            <dwolla-business-vcr
              terms="https://www.rentable.com/landlord-terms-of-services"
              privacy="https://www.rentable.com/privacy-policy"
            ></dwolla-business-vcr>
          </div>
        )}
        {awaitingBeneficialOwnershipCert && (
          <div className="mt-2">
            Also certifying beneficial ownership, please do not refresh or leave
            the page...
          </div>
        )}
        {beneficialOwnershipCertified && (
          <div className="mt-2 text-success">
            Beneficial Ownership Successfully Verified!
          </div>
        )}
      </div>
    </div>
  );
}
