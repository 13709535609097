import styled from "styled-components";

import Div from "../components/baseComponents/Div";
import UpdateAccountForm from "../components/baseComponents/UpdateAccountForm";

import { cssBreakpoints } from "../components/global/theme";

const StyledAccountPage = styled(Div)`
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1rem 0;
`;

const StyledHeader = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.bolder};
  ${cssBreakpoints("margin", [{ sm: "0" }, { md: "1rem 0" }])};
  ${cssBreakpoints("font-size", [{ sm: "1.5rem" }, { md: "3rem" }])};
`;

const StyledDescription = styled(Div)`
  padding: 0.5rem;
  ${cssBreakpoints("margin-bottom", [
    { sm: "1rem" },
    { md: "1rem" },
    { lg: "0" },
  ])}
  ${cssBreakpoints("font-size", [{ sm: ".75rem" }, { md: "1.25rem" }])}
`;

const AccountPage = () => {
  return (
    <StyledAccountPage
      width={{ sm: 1, md: 1, lg: 9 / 12 }}
      justifyContent="center"
    >
      <Div alignItems="center" justifyContent="around">
        <StyledHeader alignItems="center">ACCOUNT INFORMATION</StyledHeader>
        <StyledDescription alignItems="center">
          Update any field and press confirm to save your changes.
        </StyledDescription>
      </Div>
      <Div alignItems="center" width={{ default: 1 }}>
        <UpdateAccountForm />
      </Div>
    </StyledAccountPage>
  );
};

export default AccountPage;
