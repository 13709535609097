import { useAppSelector } from "../../store/hooks";
import { useParams } from "react-router-dom";
import { find } from "lodash";
import { RootState } from "../..";
import { GenericObject } from "../../components/global/ModelInterfaces";

const usePrepaymentFromParams = () => {
  const { prepaymentUUID } = useParams<GenericObject>();
  const prepaymentsSlice: { loading: Boolean; objects: GenericObject } =
    useAppSelector((state: RootState) => state.prepayments);
  const loadingPrepayments = prepaymentsSlice.loading;
  const prepayments = prepaymentsSlice.objects;
  const prepayment = find(prepayments, function (prepayment) {
    return prepayment.general_info.prepayment_uuid === prepaymentUUID;
  });

  return { loadingPrepayments, prepayment };
};

export default usePrepaymentFromParams;
