import React from "react";

export default function BanditIcon({ color }) {
    return (
        <svg 
            width="360" 
            height="300" 
            viewBox="0 0 718 500" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect 
                width="700" 
                height="500" 
                fill="white"
            />
            <rect 
                x="60.1496" 
                y="224.121" 
                width="498.77" 
                height="99.7235" 
                rx="10" 
                fill="#2F374B"
            />
            <path 
                d="M67.1745 224.121C54.1326 224.121 41.6249 229.374 32.4029 238.725C23.1809 248.076 18 260.759 18 273.983C18 287.207 23.1809 299.89 32.4029 309.24C41.6249 318.591 54.1326 323.845 67.1745 323.845L67.1745 273.983L67.1745 224.121Z" 
                fill="#2F374B"
            />
            <path 
                d="M550.891 323.845C563.933 323.845 576.44 318.591 585.662 309.24C594.884 299.89 600.065 287.207 600.065 273.983C600.065 260.759 594.884 248.076 585.662 238.725C576.44 229.374 563.933 224.121 550.891 224.121L550.891 273.983L550.891 323.845Z" 
                fill="#2F374B"
            />
            <path 
                d="M520.784 225.149C520.784 195.582 515.268 166.305 504.551 138.988C493.834 111.672 478.125 86.8517 458.323 65.9446C438.52 45.0376 415.011 28.4533 389.137 17.1385C363.264 5.82365 335.533 -1.29241e-06 307.527 0C279.522 1.29241e-06 251.791 5.82366 225.918 17.1385C200.044 28.4533 176.535 45.0376 156.732 65.9447C136.929 86.8517 121.221 111.672 110.504 138.988C99.7867 166.305 94.2706 195.582 94.2706 225.149L307.527 225.149H520.784Z" 
                fill="#2F374B"
            />
            <path 
                fill-rule="evenodd" 
                clip-rule="evenodd" 
                d="M28 344.298C22.4772 344.298 18 348.775 18 354.298V466.116C18 471.639 22.4772 476.116 28 476.116H555.485C558.438 476.116 561.093 474.836 562.923 472.8L640.651 417.542C645.698 413.954 645.698 406.46 640.651 402.872L562.923 347.614C561.093 345.578 558.438 344.298 555.485 344.298H28Z" 
                fill="#2F374B"
            />
            <ellipse 
                cx="217.038" 
                cy="409.667" 
                rx="50.2335" 
                ry="54.5639" 
                fill="white"
            />
            <ellipse 
                cx="380.06" 
                cy="409.667" 
                rx="50.2335" 
                ry="54.5639" 
                fill="white"
            />
            <path 
                d="M634.442 416.763C632.212 412.106 636.685 407.007 640.769 409.55L684.15 436.55C686.882 438.25 687.413 442.466 685.213 444.974L665.517 467.427C663.316 469.936 659.618 469.33 658.127 466.216L634.442 416.763Z" 
                fill="#2F374B"
            />
            <path 
                d="M640.651 413.389C636.1 411.865 636.1 405.429 640.651 403.906L688.535 387.875C691.774 386.791 695.122 389.201 695.122 392.616V424.678C695.122 428.094 691.774 430.504 688.535 429.42L640.651 413.389Z" 
                fill="#2F374B"
            />
        </svg>
    );
}