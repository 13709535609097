interface Props {
  width?: string;
  height?: string;
}

const BankIcon = ({ width = "40", height = "40" }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.4 32.7333H33.8667V29C33.8667 28.1163 33.1503 27.4 32.2667 27.4H31.2V17.2667H28V27.4H24.8V17.2667H21.6V27.4H18.4V17.2667H15.2V27.4H12V17.2667H8.8V27.4H7.73333C6.84967 27.4 6.13333 28.1163 6.13333 29V32.7333H5.6C4.71633 32.7333 4 33.4497 4 34.3333V35.4C4 35.5414 4.05619 35.6771 4.15621 35.7771C4.25623 35.8771 4.39188 35.9333 4.53333 35.9333H35.4667C35.6081 35.9333 35.7438 35.8771 35.8438 35.7771C35.9438 35.6771 36 35.5414 36 35.4V34.3333C36 33.4497 35.2837 32.7333 34.4 32.7333ZM30.6667 32.7333H9.33333V30.6H30.6667V32.7333ZM35.4845 11.2039L21.0119 5.1642C20.3551 4.94527 19.6449 4.94527 18.9881 5.1642L4.51553 11.2039C4.36393 11.2615 4.23343 11.3639 4.14136 11.4975C4.04929 11.631 3.99999 11.7894 4 11.9516V13.8C4 14.2418 4.3582 14.6 4.8 14.6H6.13333V15.4C6.13333 15.8418 6.49153 16.2 6.93333 16.2H33.0667C33.5085 16.2 33.8667 15.8418 33.8667 15.4V14.6H35.2C35.6418 14.6 36 14.2418 36 13.8V11.9516C36 11.6195 35.7949 11.3219 35.4845 11.2039ZM7.2 13L20 8.2L32.8 13H7.2Z"
        fill="#2F374B"
      />
    </svg>
  );
};

export default BankIcon;
