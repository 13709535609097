import axios from "axios";
import { passwordRegex } from "../../utils/accountUtils";
import { noop } from "lodash";

const AUTH_TOKEN_NAME = "nucAuthToken";
const SESSION_TOKEN_NAME = "nucSessionToken";

export const logIn = (
  email: string,
  password: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/log-in/";
  axios
    .post(url, { email: email, password: password })
    .then((resp) => {
      setSession(resp.data.auth_token, resp.data.session_token);
      successCallback(resp.data);
    })
    .catch((e) => {
      failureCallback(e.data);
    });
};

export const getNucleusIdentity = (
  nucleusAuthToken: string,
  successCallback: Function,
  failureCallback: Function = noop
) => {
  const url = "/nucleus/api/identity/";
  axios
    .post(url, {
      auth_token: nucleusAuthToken,
    })
    .then((resp) => {
      successCallback(resp.data);
    })
    .catch((e) => {
      failureCallback(e);
    });
};

export const refreshSessionToken = (
  successCallback: Function = noop,
  failureCallback: Function = noop
) => {
  const url = "/nucleus/api/refresh-session-token";
  axios
    .post(url)
    .then((resp) => {
      successCallback(resp.data);
    })
    .catch((e) => {
      failureCallback(e);
    });
};

export const logOut = (
  email: string,
  successCallback: Function,
  failureCallback: Function = noop
) => {
  const url = "/nucleus/api/log-out/";
  axios
    .post(url, {
      email: email,
    })
    .then(() => {
      localStorage.removeItem(AUTH_TOKEN_NAME);
      localStorage.removeItem(SESSION_TOKEN_NAME);
      // Remove currentUser from redux using this
      successCallback();
    })
    .catch((e) => failureCallback(e));
};

export const signUp = (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  invite: string,
  inviteToken: string,
  successCallback: Function,
  failureCallback: Function
) => {
  //TODO: add input validation for empty strings also route newly created user to dash.
  const url = "/nucleus/api/sign-up/";
  axios
    .post(url, {
      email: email,
      password: password,
      first_name: firstName,
      last_name: lastName,
      invite: invite,
      inviteToken: inviteToken,
    })
    .then((resp) => {
      setSession(resp.data.auth_token, resp.data.session_token);
      successCallback(resp.data);
    })
    .catch((e) => failureCallback(e.response.data));
};

export const setSession = (authToken: string, sessionToken: string) => {
  localStorage.setItem(AUTH_TOKEN_NAME, authToken);
  localStorage.setItem(SESSION_TOKEN_NAME, sessionToken);
};

export const sendInvite = (
  email: string,
  firstName: string,
  lastName: string,
  permissionsRole: number,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/send-invite/";

  axios
    .post(url, {
      email: email,
      first_name: firstName,
      last_name: lastName,
      permissions_role: permissionsRole,
    })
    .then((resp) => {
      successCallback();
    })
    .catch((e) => {
      failureCallback(`There was an error creating the invite: ${e.data}`);
    });
};

export const retrieveInvite = async (
  inviteUuid: string,
  token: string,
  successCallback: Function,
  failureCallback: Function
) => {
  return axios
    .post("/nucleus/api/get-invite/", {
      inviteUuid: inviteUuid,
      inviteToken: token,
    })
    .then((res: any) => {
      successCallback(res);
    })
    .catch((err) => {
      failureCallback(err);
    });
};

export const testAuth = async () => {
  return axios
    .post("/nucleus/api/test/", {})
    .then((res: any) => {
      console.log(res);
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const validatePassword = (password: string) => {
  return password.length >= 12 && passwordRegex.test(password);
};
