import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Input from "./Input";
import { formatDateAsValue } from "../global/utils";

const StyledInput = styled(Input)`
  caret-color: transparent;
  font-size: 16px;
  letter-spacing: 0.1rem;
`;

interface Props {
  defaultValue?: string;
  backgroundColor?: string;
  label?: string;
  onChange: Function;
  value?: string;
  error?: boolean;
}

const DateInput = ({
  defaultValue,
  label,
  backgroundColor,
  onChange,
  error = false,
}: Props) => {
  const [displayValue, setDisplayValue] = useState<string>("__/__/____");
  const ref = useRef<any>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayValue(formatDate(e.target.value));
  };

  const formatDate = (dateString: string) => {
    let tmpDate = onlyDateNumbers(dateString);
    tmpDate = tmpDate + "_".repeat(8 - tmpDate.length);
    return (
      tmpDate.slice(0, 2) + "/" + tmpDate.slice(2, 4) + "/" + tmpDate.slice(4)
    );
  };
  const handleBackspace = (value: string) => {
    let tmpDate = value;
    tmpDate = onlyDateNumbers(tmpDate);
    tmpDate = tmpDate.slice(0, -1);

    return formatDate(tmpDate);
  };

  const onlyDateNumbers = (date: string) => {
    return date.replace(/[^\d]/g, "").substring(0, 8);
  };

  const validateCurrentDate = () => {
    return (
      onlyDateNumbers(displayValue).length === 8 && Date.parse(displayValue)
    );
  };

  useEffect(() => {
    // Bind the event listener
    const currentRef = ref.current;
    document.addEventListener("keydown", function (event) {
      const { key, target } = event;
      if (key === "Backspace" && currentRef.contains(target)) {
        setDisplayValue((displayValue) => handleBackspace(displayValue));
      }
    });
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("keydown", (event) => {
        const { key, target } = event;
        if (key === "Backspace" && currentRef.contains(target)) {
          setDisplayValue((displayValue) => handleBackspace(displayValue));
        }
      });
    };
  }, []);

  useEffect(() => {
    if (validateCurrentDate()) {
      onChange(formatDateAsValue(displayValue));
    } else {
      // Clear date for parent component so we don't need to validate
      onChange(null);
    }
  }, [displayValue]);
  return (
    <div ref={ref}>
      <StyledInput
        defaultValue={defaultValue}
        helpText={"mm/dd/yyyy"}
        label={label}
        backgroundColor={backgroundColor}
        onChange={handleChange}
        value={displayValue}
        error={error}
      />
    </div>
  );
};

export default DateInput;
