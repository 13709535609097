import { useEffect, useState } from "react";

const useCountdown = (initialTime: number) => {
  const [time, setTime] = useState(initialTime);
  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (time > 0) {
        setTime((prevTime) => prevTime - 1);
      }
    }, 1000);

    return () => clearTimeout(timerInterval);
  }, [time]);

  return time;
};

export default useCountdown;
