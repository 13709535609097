import styled from "styled-components";
import { capitalize } from "lodash";
import Div from "./Div";
import { useEffect, useState } from "react";

const StyledAvatar = styled(Div)`
  height: 2rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.font_size.bodyLG};
  font-weight: ${(props) => props.theme.font_weight.bolder};
  color: ${(props) => props.theme.colors.white};
`;

interface Props {
  name: string;
}

const UserInitialsAvatar = ({ name }: Props) => {
  const [userInitials, setUserInitials] = useState("");

  useEffect(() => {
    if (name) {
      const split_name = name.split(" ");
      const first_initial = capitalize(split_name[0]).charAt(0);
      const second_initial = capitalize(split_name[1]).charAt(0);
      setUserInitials(first_initial + second_initial);
    }
  }, [name]);

  return (
    <StyledAvatar
      justifyContent="center"
      alignItems="center"
      width={{ default: "2rem" }}
    >
      <span>{userInitials}</span>
    </StyledAvatar>
  );
};

export default UserInitialsAvatar;
