import React, { useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import { loadPlaidLinkToken } from "../utils/accountUtils";

import Div from "./baseComponents/Div";
import Button from "./baseComponents/Button";

interface Props {
  onSuccess: (link_session_id: string) => void;
}

const IDVerificationButton = ({ onSuccess }: Props) => {
  const [plaidLinkToken, setPlaidLinkToken] = useState("");
  useEffect(() => {
    loadPlaidLinkToken({ idVerification: true }).then((res) => {
      setPlaidLinkToken(res.link_token);
    });
  }, []);
  const { open, ready } = usePlaidLink({
    token: plaidLinkToken,
    onSuccess: (public_token, metadata) => {
      onSuccess(metadata.link_session_id);
    },
  });
  return (
    <Div width={{ default: 1 }}>
      <Button onClick={() => open()} disabled={!ready}>
        Verify Identity
      </Button>
    </Div>
  );
};

export default IDVerificationButton;
