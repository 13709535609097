import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { RootState } from "../..";

import BuildingManagement from "../baseComponents/BuildingManagement";
import Div from "../baseComponents/Div";
import Row from "../baseComponents/Row";
import Container from "../baseComponents/Container";
import BackButton from "../baseComponents/BackButton";
import PageLoader from "../PageLoader";

import { LandlordBuilding, Landlord } from "../global/ModelInterfaces";
import {
  getLandlordBuildings,
  addLandlordToBuilding,
  removeLandlordFromBuilding,
} from "../../utils/buildingUtils";
import {
  setLandlordBuildings,
  updateLandlordBuildingStore,
} from "../../store/landlordbuildings";
import { setLandlords } from "../../store/admin/landlords";

const BuildingsView = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.auth.user);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [buildingPermissions, setBuildingPermissions] = useState<
    LandlordBuilding[] | null
  >();

  const landlordBuildingSlice: {
    loading: Boolean;
    objects: LandlordBuilding[];
  } = useAppSelector((state: RootState) => state.landlordbuildings);
  const landlordBuildingsObjects = landlordBuildingSlice.objects;

  const landlordsSlice: {
    loading: Boolean;
    objects: Landlord[];
  } = useAppSelector((state: RootState) => state.landlords);
  const landlords = landlordsSlice.objects;

  useEffect(() => {
    getLandlordBuildings().then((res) => {
      dispatch(setLandlordBuildings(res.data.values));
      dispatch(setLandlords(res.data.landlords));
      setDataLoaded(true);
    });
  }, [dataLoaded]);

  useEffect(() => {
    if (currentUser.role !== "admin") {
      history.push("/forbidden");
    }
  });

  useEffect(() => {
    if (!!landlordBuildingsObjects) {
      setBuildingPermissions(landlordBuildingsObjects);
    }
  }, [landlordBuildingsObjects]);

  const handleAddLandlord = (
    buildingUUID: string,
    landlordID: string,
    failureCallback: Function
  ) => {
    const successCallback = (building: LandlordBuilding) => {
      dispatch(updateLandlordBuildingStore(building));
    };

    addLandlordToBuilding(
      buildingUUID,
      landlordID,
      successCallback,
      failureCallback
    );
  };

  const handleRemoveLandlord = (
    buildingUUID: string,
    landlordID: string,
    failureCallback: Function
  ) => {
    const successCallback = (building: LandlordBuilding) => {
      dispatch(updateLandlordBuildingStore(building));
    };
    removeLandlordFromBuilding(
      buildingUUID,
      landlordID,
      successCallback,
      failureCallback
    );
  };

  if (!buildingPermissions) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  }

  return (
    <Container>
      <Div>
        <Row addSpace={false}>
          <BackButton returnUrl="/settings" text="Settings Home" />
        </Row>
        <Row mt={{ default: 5 }}>
          <BuildingManagement
            landlordBuildings={buildingPermissions}
            landlords={landlords}
            onAdd={handleAddLandlord}
            onRemove={handleRemoveLandlord}
          />
        </Row>
      </Div>
    </Container>
  );
};

export default BuildingsView;
