import styled from "styled-components";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import prepaymentStatuses from "../../enums/prepaymentStatuses";
import Div from "../baseComponents/Div";
import Row from "../baseComponents/Row";
import PageLoader from "../PageLoader";
import BankAccountSelect from "../BankAccountSelect";
import BackButton from "../baseComponents/BackButton";
import FundDeposits from "./FundDeposits";
import FundAdditionalRequests from "./FundAdditionalRequests";
import { GenericObject, BankAccount } from "../global/ModelInterfaces";
import {
  getPrepaymentDetails,
  hasPendingAdditionalFundsRequests,
} from "../../utils/depositUtils";
import { updatePrepayment } from "../../store/prepayments";

import Container from "../baseComponents/Container";
import { getStreetAddress } from "../../utils/globalUtils";
import useGetPrepayment from "../../utils/useGetPrepayment";

const StyledTitle = styled(Div)`
  line-height: 3.25rem;
  font-size: ${(props) => props.theme.font_size.headlineLG};
  font-weight: ${(props) => props.theme.font_weight.bolder};
  color: ${(props) => props.theme.colors.grey};
`;

const StyledDivider = styled(Div)`
  height: 1px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.16);
`;

const StyledSubTitle = styled(Div)`
  line-height: 1.875rem;
  font-size: ${(props) => props.theme.font_size.headlineSM};
  font-weight: ${(props) => props.theme.font_weight.bolder};
  color: ${(props) => props.theme.colors.grey};
`;

const StyledBankText = styled(Div)`
  line-height: 1.5rem;
  font-size: ${(props) => props.theme.font_size.headlineXS};
  color: ${(props) => props.theme.colors.grey};
`;

const StyledSummaryText = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.bolder};
  color: ${(props) => props.theme.colors.grey};
`;

const FundPrepaymentView = () => {
  const dispatch = useAppDispatch();
  const [bankAccount, setBankAccount] = useState<BankAccount>();
  const { selectedPrepayment, loadingPrepayments } = useGetPrepayment();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const missingDeposit = () => {
    if (selectedPrepayment) {
      return !selectedPrepayment.deposits;
    }
    return true;
  };

  useEffect(() => {
    if (selectedPrepayment) {
      if (missingDeposit()) {
        const successCallback = (details: GenericObject) => {
          const updatedPrepayment = {
            ...selectedPrepayment,
            ...details,
          };
          dispatch(updatePrepayment(updatedPrepayment));
          setLoading(false);
        };
        const errorCallback = (error: GenericObject) => {
          setError(error.data);
          setLoading(false);
        };
        getPrepaymentDetails(
          selectedPrepayment.uuid,
          successCallback,
          errorCallback
        );
      } else {
        setLoading(false);
      }
    }
  }, [selectedPrepayment]);

  const udpateBankAccount = (e: any) => {
    setBankAccount(e.value);
  };

  if (loading || loadingPrepayments) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  } else if (!selectedPrepayment) {
    return <Redirect to={"/forbidden"} />;
  } else if (
    (selectedPrepayment.status_id !== prepaymentStatuses.PENDING ||
      !selectedPrepayment.deposits) &&
    !hasPendingAdditionalFundsRequests(selectedPrepayment.deposits)
  ) {
    return <Redirect to={`/dashboard/tenant/${selectedPrepayment.uuid}`} />;
  }

  return (
    <Container>
      <BackButton text={"Back to Deposit"} />
      <Row justifyContent="center">
        <Div width={{ default: 1 / 2 }}>
          <StyledTitle mb={{ default: "1rem" }}>Fund Deposit</StyledTitle>
          <StyledSubTitle mb={{ default: "1.5rem" }}>
            Deposit Details:
          </StyledSubTitle>
          <Row mb={{ default: "1.5rem" }} justifyContent="space-between">
            <Div>Address:</Div>
            <StyledSummaryText>
              {getStreetAddress(
                selectedPrepayment?.address_1,
                selectedPrepayment?.address_2
              )}
            </StyledSummaryText>
          </Row>
          <Row mb={{ default: "1.5rem" }} justifyContent="space-between">
            <Div>Tenant:</Div>
            <StyledSummaryText>
              {selectedPrepayment?.tenant_name}
            </StyledSummaryText>
          </Row>
          <StyledDivider mb={{ default: "1.5rem" }} />
          <StyledBankText mb={{ default: "1.5rem" }}>
            Please choose which bank account you would like to fund the deposit
            with.
          </StyledBankText>
          <Row mb={{ default: "1.5rem" }}>
            <Div width={{ default: 1 / 2 }}>
              <BankAccountSelect
                disabled={false}
                onSelectAccount={(e: any) => {
                  udpateBankAccount(e);
                }}
                defaultValue={bankAccount}
              />
            </Div>
          </Row>
          <StyledDivider mb={{ default: "1.5rem" }} />
          {selectedPrepayment?.status_id === prepaymentStatuses.PENDING && (
            <FundDeposits bankAccount={bankAccount} />
          )}
          {hasPendingAdditionalFundsRequests(selectedPrepayment?.deposits) && (
            <FundAdditionalRequests bankAccount={bankAccount} />
          )}
        </Div>
      </Row>
    </Container>
  );
};

export default FundPrepaymentView;
