import { useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import styled from "styled-components";
import useGetPrepayment from "../../utils/useGetPrepayment";
import Div from "../baseComponents/Div";
import Row from "../baseComponents/Row";
import Button from "../baseComponents/Button";
import ErrorMessage from "../baseComponents/ErrorMessage";
import prepaymentStatuses from "../../enums/prepaymentStatuses";
import { makePayment } from "../../utils/depositUtils";
import { currencyFormatter } from "../global/utils";
import { BankAccount, Prepayment } from "../global/ModelInterfaces";
import { updatePrepayment } from "../../store/prepayments";

const StyledSubTitle = styled(Div)`
  line-height: 1.875rem;
  color: ${(props) => props.theme.colors.grey};
  font-size: ${(props) => props.theme.font_size.headlineSM};
  font-weight: ${(props) => props.theme.font_weight.bolder};
  color: ${(props) => props.theme.colors.grey};
`;

const StyledSummaryText = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.bolder};
  color: ${(props) => props.theme.colors.grey};
`;

interface Props {
  bankAccount?: BankAccount;
}

const FundDeposits = ({ bankAccount }: Props) => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState("");
  const [funding, setFunding] = useState(false);

  const { selectedPrepayment } = useGetPrepayment();

  const getDepositsTotal = () => {
    if (selectedPrepayment) {
      let amount = 0;
      const securityDeposit = selectedPrepayment.deposits.security_deposit;
      const lastMonthsRent = selectedPrepayment.deposits.last_months_rent;
      amount +=
        securityDeposit &&
        securityDeposit.status_id === prepaymentStatuses.PENDING
          ? parseFloat(securityDeposit.amount) +
            parseFloat(securityDeposit.dwolla_fee)
          : 0;
      amount +=
        lastMonthsRent &&
        lastMonthsRent.status_id === prepaymentStatuses.PENDING
          ? parseFloat(lastMonthsRent.amount) +
            parseFloat(lastMonthsRent.dwolla_fee)
          : 0;

      return amount;
    } else {
      return 0;
    }
  };

  const handlePayment = () => {
    if (!bankAccount) {
      setError("Please select a bank account.");
      return;
    }
    setFunding(true);
    const paymentSuccessCallback = (prepayment: Prepayment) => {
      dispatch(updatePrepayment(prepayment));
    };
    const paymentFailureCallback = (error: string) => {
      setFunding(false);
      setError(error);
    };
    makePayment(
      selectedPrepayment?.uuid,
      bankAccount,
      paymentSuccessCallback,
      paymentFailureCallback
    );
  };

  return (
    <Div>
      {selectedPrepayment?.deposits.security_deposit?.amount &&
        selectedPrepayment?.deposits.security_deposit?.status_id ===
          prepaymentStatuses.PENDING && (
          <Row mb={{ default: "1.5rem" }} justifyContent="space-between">
            <Div>Security Deposit Amount:</Div>
            <StyledSummaryText>
              {currencyFormatter.format(
                selectedPrepayment?.deposits.security_deposit?.amount
              )}
            </StyledSummaryText>
          </Row>
        )}
      {!!selectedPrepayment?.deposits.security_deposit?.dwolla_fee &&
        selectedPrepayment?.deposits.security_deposit?.status_id ===
          prepaymentStatuses.PENDING && (
          <Row mb={{ default: "1.5rem" }} justifyContent="space-between">
            <Div>Security Deposit Processor Fee:</Div>
            <StyledSummaryText>
              {currencyFormatter.format(
                selectedPrepayment.deposits.security_deposit.dwolla_fee
              )}
            </StyledSummaryText>
          </Row>
        )}
      {selectedPrepayment?.deposits.last_months_rent?.amount &&
        selectedPrepayment?.deposits.last_months_rent?.status_id ===
          prepaymentStatuses.PENDING && (
          <Row mb={{ default: "1.5rem" }} justifyContent="space-between">
            <Div>Last Month's Rent Amount:</Div>
            <StyledSummaryText>
              {currencyFormatter.format(
                selectedPrepayment?.deposits.last_months_rent?.amount
              )}
            </StyledSummaryText>
          </Row>
        )}
      {!!selectedPrepayment?.deposits.last_months_rent?.dwolla_fee &&
        selectedPrepayment?.deposits.last_months_rent?.status_id ===
          prepaymentStatuses.PENDING && (
          <Row mb={{ default: "1.5rem" }} justifyContent="space-between">
            <Div>Last Month's Rent Processor Fee:</Div>
            <StyledSummaryText>
              {currencyFormatter.format(
                selectedPrepayment.deposits.last_months_rent?.dwolla_fee
              )}
            </StyledSummaryText>
          </Row>
        )}
      <Row mb={{ default: "1.5rem" }} justifyContent="space-between">
        <StyledSubTitle mb={{ default: "1.5rem" }}>Total:</StyledSubTitle>
        <StyledSubTitle mb={{ default: "1.5rem" }}>
          {currencyFormatter.format(getDepositsTotal())}
        </StyledSubTitle>
      </Row>
      {error && (
        <Row mb={{ default: "1.5rem" }} alignItems="center">
          <ErrorMessage>{error}</ErrorMessage>
        </Row>
      )}
      <Button
        disabled={!bankAccount || funding}
        type="primary"
        text={`Fund Deposit`}
        onClick={handlePayment}
        loading={funding}
      />
    </Div>
  );
};

export default FundDeposits;
