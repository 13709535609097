import { useEffect, useState } from "react";
import styled from "styled-components";
import { first } from "lodash";
import { cssBreakpoints } from "../../../components/global/theme";
import BeatLoader from "react-spinners/BeatLoader";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import DropdownCard from "../../../components/baseComponents/DropdownCard";
import CreateSingleDepositForm from "../Forms/CreateSingleDepositForm";
import Button from "../../../components/baseComponents/Button";
import CSVButton from "../../../components/baseComponents/CSVButton";
import FileUpload from "../../../components/baseComponents/FileUpload";
import BuildingSearch from "../../pages/BuildingSearch";
import UnitSearch from "../../pages/UnitSearch";
import {
  BankAccount,
  NucleusCompany,
  Option,
  GenericObject,
} from "../../../components/global/ModelInterfaces";
import Checkbox from "../../../components/baseComponents/Checkbox";
import BankAccountSelect from "../../../components/BankAccountSelect";
import ErrorMessage from "../../../components/baseComponents/ErrorMessage";
import SuccessMessage from "../../../components/baseComponents/SuccessMessage";
import {
  uploadDepositCSV,
  retrieveDepositCSVFields,
} from "../../utils/prepaymentUtils";
import ErrorCSVRowsComponent from "./ErrorCSVRowsComponent";

const StyledSubtitle = styled(Div)`
  ${(props) =>
    cssBreakpoints("font-size", [
      { sm: props.theme.font_size.headlineSM },
      { md: props.theme.font_size.headlineMD },
    ])}
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

// This absolute default CSV columns,
// used only when backend fails.
const defaultCSVColumns = {
  paid: "TRUE",
  tenant_email: "john.doe@rentable.com",
  tenant_first_name: "John",
  tenant_last_name: "Doe",
  phone: "123-456-7890",
  unit_uuid: "1234a5bc-d6ef-7899-01g2-hijk3l45m67n",
  start_date: "02/20/2023",
  end_date: "01/04/2034",
  security_deposit_amount: "100",
  last_months_rent_amount: "0",
  security_deposit_interest_amount: "4.50",
  last_months_rent_interest_amount: "0",
  rent_manager_tenant_id: "",
};

interface Props {
  company: NucleusCompany;
}

const CreateDeposits = ({ company }: Props) => {
  const [building, setBuilding] = useState<Option>();
  const [unit, setUnit] = useState<Option>();
  const [csvTemplateFields, setCsvTemplateFields] = useState<GenericObject[]>([
    defaultCSVColumns,
  ]);

  const [templateReady, setTemplateReady] = useState(false);
  const [csvUploaded, setCSVUploaded] = useState(false);
  const [csvFile, setCSVFile] = useState<File | null>();
  const [fundDeposits, setFundDeposits] = useState(false);
  const [bankAccount, setBankAccount] = useState<BankAccount>();

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [rowErrors, setRowErrors] = useState([]);

  const handleCSVUpload = () => {
    setErrorMessage("");
    setSuccessMessage("");
    setRowErrors([]);
    setCSVUploaded(false);
    if (!csvFile) {
      setErrorMessage("No file was uploaded.");
      return;
    }
    const successCallback = (data: any) => {
      setSuccessMessage(data);
      setFundDeposits(false);
      setBankAccount(undefined);
    };
    const failureCallback = (err_data: any) => {
      if (err_data.message) {
        setErrorMessage(err_data);
      } else {
        setErrorMessage(err_data);
      }
      if (err_data.failed_rows) {
        setRowErrors(err_data.failed_rows);
      }
      setFundDeposits(false);
      setBankAccount(undefined);
    };
    uploadDepositCSV(
      csvFile,
      company.uuid,
      fundDeposits,
      bankAccount ? bankAccount.uuid : "",
      successCallback,
      failureCallback
    );
  };

  const handleOnCheck = () => {
    setFundDeposits((fundDeposits) => !fundDeposits);
    setBankAccount(undefined);
  };

  /**
   * Sets up the CSV template columns that based on the company-specific fields from the backend.
   * If the backend call fails, it uses default fields for the CSV template.
   *
   * @param companyUUID The unique identifier for the company for which to retrieve fields.
   */
  const getDepositTemplateColumns = (companyUUID: string) => {
    setTemplateReady(false);
    const successCallback = (data: any) => {
      setTemplateReady(true);
      setCsvTemplateFields([data]);
    };
    const failureCallback = (error: string) => {
      setErrorMessage(error);
    };
    // Retrieve CSV fields according company integration settings
    retrieveDepositCSVFields(companyUUID, successCallback, failureCallback);
  };

  useEffect(() => {
    getDepositTemplateColumns(company.uuid);
  }, [company.uuid]);

  return (
    <DropdownCard title="Create Deposit(s)" initiallyOpen={false}>
      <Row justifyContent="space-between">
        <Div width={{ default: 12 / 12, lg: 6 / 12 }}>
          <StyledSubtitle pb={{ lg: 2 }}>Single Deposit</StyledSubtitle>
          <Row>
            <Div width={{ default: 1, lg: 8 / 12 }}>
              <BuildingSearch
                onClick={(option: any) => {
                  setBuilding(undefined);
                  setBuilding(option);
                  setUnit(undefined);
                }}
                companyUUID={company.uuid}
              />
            </Div>
          </Row>
          {building && (
            <Row>
              <Div width={{ default: 1, lg: 8 / 12 }}>
                <UnitSearch
                  onClick={(option: any) => setUnit(option)}
                  buildingUUID={building.id}
                />
              </Div>
            </Row>
          )}
          {building && unit && (
            <Row>
              <CreateSingleDepositForm
                company={company}
                unitUUID={unit?.id || ""}
              />
            </Row>
          )}
        </Div>
        <Div width={{ default: 12 / 12, lg: 6 / 12 }}>
          <StyledSubtitle pb={{ lg: 4 }}>
            Bulk Deposit Upload w/ CSV
          </StyledSubtitle>
          <Row pb={{ default: 3 }}>
            <FileUpload
              defaultText="Upload Deposit CSV"
              onUpload={(e) => {
                setCSVFile(first(e.target.files));
                setCSVUploaded(true);
              }}
              onRemove={() => {
                setCSVFile(null);
                setCSVUploaded(false);
              }}
            />
          </Row>
          {csvUploaded && (
            <Div>
              <Row pb={{ default: 2 }}>
                <Checkbox
                  label="Fund Deposits"
                  value={fundDeposits}
                  onChange={() => handleOnCheck()}
                />
              </Row>
              {fundDeposits && (
                <Div pb={{ default: 3 }}>
                  {company.bank_info.length !== 0 ? (
                    <BankAccountSelect
                      disabled={false}
                      onSelectAccount={(e: any) => {
                        setBankAccount(e["value"]);
                      }}
                      defaultValue={bankAccount}
                      bankAccounts={company.bank_info}
                    />
                  ) : (
                    "This company has not linked to its bank account yet."
                  )}
                </Div>
              )}
              <Row>
                <Button
                  disabled={fundDeposits && bankAccount === undefined}
                  onClick={() => handleCSVUpload()}
                  text="Upload CSV"
                />
              </Row>
            </Div>
          )}

          {errorMessage && (
            <Div justifyContent="center">
              <ErrorCSVRowsComponent errorMessage={errorMessage} />
            </Div>
          )}
          {successMessage && (
            <SuccessMessage justifyContent="center">
              {successMessage}
            </SuccessMessage>
          )}
          {rowErrors &&
            rowErrors.map((error: any) => {
              return (
                <ErrorMessage mt={{ lg: 2 }}>
                  Row {error.row}, {error.reason}
                </ErrorMessage>
              );
            })}
          <Row pt={{ lg: 4 }} justifyContent="center">
            {templateReady ? (
              <Div width={{ lg: 6 / 12 }}>
                <CSVButton
                  data={csvTemplateFields}
                  fileName="bulk_deposits_template.csv"
                >
                  Download CSV
                </CSVButton>
              </Div>
            ) : (
              <BeatLoader loading={true} />
            )}
          </Row>
        </Div>
      </Row>
    </DropdownCard>
  );
};

export default CreateDeposits;
