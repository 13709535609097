import { useState, useEffect } from "react";
import { useAppDispatch } from "../../../../../store/hooks";
import { useParams } from "react-router-dom";
import usePrepaymentFromParams from "../../../../utils/usePrepaymentFromParams";
import styled from "styled-components";
import { some } from "lodash";
import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import Button from "../../../../../components/baseComponents/Button";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";
import DropdownCard from "../../../../../components/baseComponents/DropdownCard";
import BarLoader from "../../../../../components/baseComponents/BarLoader";
import prepaymentStatuses from "../../../../../enums/prepaymentStatuses";
import {
  currencyFormatter,
  dateShortFormatter,
} from "../../../../../components/global/utils";
import { updatePrepaymentIntegrationInfo } from "../../../../../store/nucleusPrepayments";
import { GenericObject } from "../../../../../components/global/ModelInterfaces";
import {
  getRefundDetails,
  createTenantRefundTransactions,
} from "../../../../utils/rentManagerUtils";

const StyledLabel = styled.span`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const DepositRefundSection = () => {
  const dispatch = useAppDispatch();
  const { prepaymentUUID } = useParams<GenericObject>();
  const { prepayment } = usePrepaymentFromParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const handleCreateRefundTransactions = () => {
    setLoading(true);
    if (!some(prepayment.integration_info.user_defined_values.tenantValues)) {
      setError(
        "The Tenant User Defined values for this prepayment were not loaded properly, please refresh the page and try again."
      );
      setLoading(false);
      return;
    }
    const useOpenCharges =
      prepayment.integration_info.user_defined_values.tenantValues
        .rentableSecurityDepositUseOpenCharges === "Yes";
    const successCallback = (refundDetails: GenericObject) => {
      setLoading(false);
      dispatch(
        updatePrepaymentIntegrationInfo(
          prepaymentUUID,
          "refund_details",
          refundDetails
        )
      );
    };
    const failureCallback = (errorMessage: string) => {
      setError(errorMessage);
      setLoading(false);
    };
    createTenantRefundTransactions(
      prepaymentUUID,
      useOpenCharges,
      successCallback,
      failureCallback
    );
  };

  const renderCreateRefundButton = () => {
    return (
      [prepaymentStatuses.DISBURSING, prepaymentStatuses.CLOSED].includes(
        prepayment.deposits_info.security_deposit.status_id
      ) &&
      Number(prepayment.integration_info.refund_details.security_deposit_held) >
        0 &&
      !prepayment.integration_info.refund_details.transaction_id
    );
  };
  useEffect(() => {
    if (!prepayment.integration_info.details) {
      setLoading(true);
      const successCallback = (refundDetails: GenericObject) => {
        dispatch(
          updatePrepaymentIntegrationInfo(
            prepaymentUUID,
            "refund_details",
            refundDetails
          )
        );
        setLoading(false);
      };
      const failureCallback = (errorMessage: string) => {
        setError(errorMessage);
        setLoading(false);
      };
      getRefundDetails(prepaymentUUID, successCallback, failureCallback);
    }
  }, []);

  if (loading) {
    return (
      <Row justifyContent="center" mb={{ default: 5 }}>
        <BarLoader loadingText="Loading Refund Details" />
      </Row>
    );
  }

  return (
    <DropdownCard title={"Deposit Refund Details"} initiallyOpen={false}>
      {error && (
        <Row justifyContent="center">
          <ErrorMessage>{error}</ErrorMessage>
        </Row>
      )}
      <Row>
        <Div width={"fill"}>
          <Row>
            <StyledLabel>Rent Manager Security Deposit Held:</StyledLabel>
            {currencyFormatter.format(
              prepayment.integration_info.refund_details.security_deposit_held
            )}
          </Row>
          <Row>
            <StyledLabel>Rent Manager Refund Transaction ID:</StyledLabel>
            {prepayment.integration_info.refund_details.refund_transaction_id
              ? prepayment.integration_info.refund_details.refund_transaction_id
              : "N/A"}
          </Row>
          <Row>
            <StyledLabel>Rent Manager Refund Transaction Date:</StyledLabel>
            {prepayment.integration_info.refund_details.refund_transaction_date
              ? dateShortFormatter(
                  prepayment.integration_info.refund_details
                    .refund_transaction_date
                )
              : "N/A"}
          </Row>
        </Div>
        {renderCreateRefundButton() && (
          <Div width={{ default: 6 / 12 }}>
            <Div mb={{ default: 2 }}>
              This tenant's security deposit is either disbursing or has been
              closed, but the Rent Manager refund did not get created. Use the
              button below to create the refund transactions in the Rent Manager
              System.
            </Div>
            <Button
              onClick={handleCreateRefundTransactions}
              loading={loading}
              text="Process Rent Manager Refund"
            />
          </Div>
        )}
      </Row>
    </DropdownCard>
  );
};

export default DepositRefundSection;
