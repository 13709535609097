import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../../store/hooks";
import styled from "styled-components";
import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import DropdownCard from "../../../../../components/baseComponents/DropdownCard";
import BarLoader from "../../../../../components/baseComponents/BarLoader";
import Button from "../../../../../components/baseComponents/Button";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";
import usePrepaymentFromParams from "../../../../utils/usePrepaymentFromParams";
import { updatePrepaymentSection } from "../../../../../store/nucleusPrepayments";
import { updatePrepaymentIntegrationInfo } from "../../../../../store/nucleusPrepayments";
import { GenericObject } from "../../../../../components/global/ModelInterfaces";
import {
  getPrepaymentDetails,
  updatePrepaymentDetails,
} from "../../../../utils/rentManagerUtils";

const StyledLabel = styled.span`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const DetailsSection = () => {
  const dispatch = useAppDispatch();
  const { prepaymentUUID } = useParams<GenericObject>();
  const { prepayment } = usePrepaymentFromParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!prepayment.integration_info.details) {
      setLoading(true);
      const successCallback = (details: GenericObject) => {
        dispatch(
          updatePrepaymentIntegrationInfo(prepaymentUUID, "details", details)
        );
        setLoading(false);
      };
      const failureCallback = (errorMessage: string) => {
        setError(errorMessage);
        setLoading(false);
      };
      getPrepaymentDetails(prepaymentUUID, successCallback, failureCallback);
    }
  }, []);

  const handleDetailsUpdate = () => {
    const successCallback = (
      details: GenericObject,
      generalInfo: GenericObject
    ) => {
      setLoading(false);
      dispatch(
        updatePrepaymentIntegrationInfo(prepaymentUUID, "details", details)
      );
      dispatch(
        updatePrepaymentSection(prepaymentUUID, "general_info", generalInfo)
      );
    };
    const failureCallback = (errorMessage: string) => {
      if (errorMessage) {
        setError(errorMessage);
      } else {
        setError(
          "There was an issue refreshing the details of this Rent Manager Prepayment, please refresh the page."
        );
      }
      setLoading(false);
    };
    setLoading(true);
    updatePrepaymentDetails(prepaymentUUID, successCallback, failureCallback);
  };

  if (loading) {
    return (
      <Row justifyContent="center" mt={{ default: 5 }} mb={{ default: 5 }}>
        <BarLoader loadingText="Loading Details" />
      </Row>
    );
  }

  return (
    <DropdownCard title={"Details"}>
      <Div width={{ default: 12 / 12 }}>
        {error ? (
          <Row>
            <ErrorMessage>{error}</ErrorMessage>
          </Row>
        ) : (
          <Row>
            <Div width={{ default: 9 / 12 }}>
              <Row>
                <StyledLabel>Rent Manager Prepayment UUID:</StyledLabel>
                {
                  prepayment.integration_info.details
                    .rent_manager_prepayment_uuid
                }
              </Row>
              <Row>
                <StyledLabel>
                  Rent Manager Resource(Tenant/Prospect) ID:
                </StyledLabel>
                {prepayment.integration_info.details.rent_manager_resource_id}
              </Row>
              <Row>
                <StyledLabel>Rent Manager Resource Status:</StyledLabel>
                {
                  prepayment.integration_info.details
                    .rent_manager_resource_status
                }
              </Row>
              <Row>
                <StyledLabel>Rent Manager Property ID:</StyledLabel>
                {prepayment.integration_info.details.rent_manager_property_id}
              </Row>
              <Row>
                <StyledLabel>Rent Manager Unit ID:</StyledLabel>
                {prepayment.integration_info.details.rent_manager_unit_id}
              </Row>
            </Div>
            <Div width={{ default: 3 / 12 }} justifyContent="center">
              <Button onClick={handleDetailsUpdate} text="Refresh Details" />
            </Div>
          </Row>
        )}
      </Div>
    </DropdownCard>
  );
};

export default DetailsSection;
