import { useState, useEffect } from "react";
import styled from "styled-components";
import Div from "./Div";
import Row from "./Row";
import Input from "./Input";
import FilterIcon from "../../images/FilterIcon";
import XButton from "./XButton";
import { animated, useSpring } from "@react-spring/web";
import { GenericObject } from "../global/ModelInterfaces";
import { includes } from "lodash";

interface StyleProps {
  hidden?: boolean;
}

const StyledMenu = styled(Div)<StyleProps>`
  border-radius: ${(props) => props.theme.border_radius.SM};
  background-color: ${(props) => props.theme.colors.grey10};
  ${(props) => props.hidden && "visibility: hidden"}
`;

const StyledFilterButton = styled(Div)<StyleProps>`
  min-height: 3rem;
  cursor: pointer;
  ${(props) => props.hidden && "visibility: hidden"}
`;

const StyledFilterCounter = styled(Div)`
  color: ${(props) => props.theme.colors.white};
  font-weight: ${(props) => props.theme.font_weight.bolder};
  background-color: ${(props) => props.theme.colors.danger};
  height: 1.5rem;
  border-radius: ${(props) => props.theme.border_radius.MD};
`;

const StyledAnimation = styled(animated.div)`
  border-radius: ${(props) => props.theme.border_radius.SM};
  color: ${(props) => props.theme.colors.navy};
  background-color: ${(props) => props.theme.colors.grey10};
  border: 3px solid ${(props) => props.theme.colors.grey};
  font-size: ${(props) => props.theme.font_size.bodyLG};
  cursor: pointer;
`;

interface Props {
  list: Array<any>;
  filters: Array<string>;
  onFilterUpdate: (results: Array<any>) => void;
  onFilterClear: () => void;
}

const InputFilters = ({
  list,
  filters,
  onFilterUpdate,
  onFilterClear,
}: Props) => {
  const emptyFilters = filters.map((filter) => {
    return {
      key: filter.replace(/ /g, "_").toLowerCase(),
      value: "",
    };
  });
  const [showFilters, setShowFilters] = useState(false);
  const [filterOption, setFilterOption] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filterCount, setFilterCount] = useState(0);
  const [appliedFilters, setAppliedFilters] =
    useState<Array<GenericObject>>(emptyFilters);

  const AnimatedStyles = useSpring({
    config: { tension: 600, friction: 45 },
    width: showFilters ? "15rem" : "8rem",
  });

  useEffect(() => {
    handleFilterApplication();
    setShowFilters(false);
    setFilterValue("");
  }, [appliedFilters]);

  // update the value of the chosen filter
  const updateFilters = () => {
    const newFilters = appliedFilters.map((object) => {
      if (object.key === filterOption) {
        return { ...object, value: filterValue };
      }
      return object;
    });
    setAppliedFilters(newFilters);
  };

  // remove chosen filter and re-apply active filters to list
  const removeFilter = (key: string) => {
    let appliedFilterCount = 0;
    const newFilters = appliedFilters.map((filter) => {
      // removes selected filter by setting the value = ""
      if (filter.key === key) {
        filter.value = "";
      }
      appliedFilterCount =
        filter.value !== "" ? appliedFilterCount + 1 : appliedFilterCount;
      return filter;
    });
    setAppliedFilters(newFilters);
  };

  // apply the filters to the list and pass the filtered list back to parent component
  const handleFilterApplication = () => {
    let appliedFilterCount = 0;
    let filteredOptions: Array<GenericObject> = list;
    appliedFilters.forEach((filter: GenericObject) => {
      let tempFilteredOptions: Array<GenericObject> = [];
      if (!!filter.value) {
        // if filter value matches object value from list, add to the filtered list
        filteredOptions.forEach((object) => {
          includes(
            object[filter.key].toLowerCase(),
            filter.value.toLowerCase()
          ) && tempFilteredOptions.push(object);
        });
        appliedFilterCount++;
        filteredOptions = tempFilteredOptions; // use the newly filtered list to continue the filter application process
      }
    });
    onFilterUpdate(filteredOptions);
    setFilterCount(appliedFilterCount);
  };

  return (
    <Row alignItems="center">
      <Div>
        {filterCount > 0 && (
          <>
            Applied Filters:
            {appliedFilters.map((filter) => {
              if (filter.value !== "") {
                return (
                  <Row alignItems="center">
                    <Div>
                      {filter.key}: {filter.value}
                    </Div>
                    <XButton
                      onClick={() => removeFilter(filter.key)}
                      width="1.5rem"
                    />
                  </Row>
                );
              }
            })}
          </>
        )}
      </Div>
      <StyledAnimation style={AnimatedStyles}>
        <StyledMenu hidden={!showFilters} p={{ lg: "1rem" }}>
          <Row
            justifyContent="space-between"
            alignItems="center"
            mb={{ lg: 1 }}
          >
            <span>Filter By:</span>

            <XButton onClick={() => setShowFilters(false)} width="1.5rem" />
          </Row>
          <select
            value={filterOption}
            onChange={(e) => setFilterOption(e.target.value)}
          >
            <option selected value={""}>
              --- Select Filter Option ---
            </option>
            {filters.map((filter) => {
              return (
                <option value={filter.replace(/ /g, "_").toLowerCase()}>
                  {filter}
                </option>
              );
            })}
          </select>
          {filterOption && (
            <>
              <span>Value:</span>
              <Input
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilterValue(e.target.value);
                }}
              />
              <Row justifyContent="space-between">
                <Div onClick={updateFilters}>Apply</Div>
                <Div
                  onClick={() => {
                    setAppliedFilters(emptyFilters);
                    setFilterCount(0);
                    setFilterOption("");
                    setFilterValue("");
                    onFilterClear();
                  }}
                >
                  Clear
                </Div>
              </Row>
            </>
          )}
        </StyledMenu>
        <StyledFilterButton
          hidden={showFilters}
          width={{ default: 1 }}
          justifyContent="center"
          onClick={() => setShowFilters(true)}
        >
          <Row flexWrap="nowrap" alignItems="center">
            <span>Filters</span>
            {filterCount > 0 ? (
              <StyledFilterCounter
                alignItems="center"
                justifyContent="center"
                mt={{ lg: 1 }}
                mb={{ lg: 1 }}
                width={{ lg: "1.5rem" }}
              >
                {filterCount}
              </StyledFilterCounter>
            ) : (
              <Div>
                <FilterIcon width="28" height="28" />
              </Div>
            )}
          </Row>
        </StyledFilterButton>
      </StyledAnimation>
    </Row>
  );
};
export default InputFilters;
