import styled from "styled-components";
import Div from "../../components/baseComponents/Div";

const StyledLoader = styled(Div)`
  text-align: center;
`;
interface Props {
  loadingText?: string;
}
const NucleusLoader = ({ loadingText }: Props) => {
  return (
    <StyledLoader alignItems="center">
      <img
        width={"256px"}
        height={"256px"}
        src="https://rentable-public-assets.s3.us-east-2.amazonaws.com/nucleus_logo_loading.gif"
      />
      <h3>{loadingText ? <>{loadingText}</> : <>Loading...</>}</h3>
    </StyledLoader>
  );
};

export default NucleusLoader;
