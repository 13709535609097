import styled from "styled-components";

const StyledUpCaret = styled.svg`
  fill: ${(props) => props.theme.colors.dropdownCaret};
`;

interface Props {
  width?: string;
  height?: string;
}

const UpCaret = ({ width = "24", height = "24" }: Props) => {
  return (
    <StyledUpCaret
      width={width}
      height={height}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.4803 26H29.5197C30.8369 26 31.4954 24.4702 30.5631 23.5737L21.0471 14.4162C20.4699 13.8613 19.5301 13.8613 18.9529 14.4162L9.43694 23.5737C8.50458 24.4702 9.16315 26 10.4803 26Z" />
    </StyledUpCaret>
  );
};

export default UpCaret;
