import styled from "styled-components";
import Row from "../../components/baseComponents/Row";

const StyledTabSection = styled(Row)`
  font-size: ${(props) => props.theme.font_size.headlineSM};
  border-top-right-radius: ${(props) => props.theme.border_radius.MD};
  border-top-left-radius: ${(props) => props.theme.border_radius.MD};
  padding-left: ${(props) => props.theme.space[2]};
  padding-right: ${(props) => props.theme.space[2]};
  padding-top: ${(props) => props.theme.space[2]};
`;
interface Props {
  children: any;
}
const TabSection = ({ children }: Props) => {
  return (
    <StyledTabSection addSpace={false} flexWrap={"nowrap"}>
      {children}
    </StyledTabSection>
  );
};

export default TabSection;
