import Div from "../../baseComponents/Div";
import OffboardingIntro from "./OffboardingIntro";
import DisbursementChoice from "./DisbursementChoice";
import useGetCurrentPrepayment from "../../../utils/useGetCurrentPrepayment";
import PageLoader from "../../PageLoader";
import { useParams } from "react-router-dom";
import { GenericObject } from "../../global/ModelInterfaces";

const TenantOffboarding = () => {
  const { prepaymentUUID } = useParams<GenericObject>();
  const { loadingTenantInfo } = useGetCurrentPrepayment(prepaymentUUID);

  if (loadingTenantInfo) {
    return <PageLoader />;
  }
  return (
    <Div alignItems="center">
      <OffboardingIntro />
      <Div width={{ sm: 1, md: 1, lg: 10 / 12 }}>
        <DisbursementChoice />
      </Div>
    </Div>
  );
};

export default TenantOffboarding;
