import axios from "axios";

export const editPaymentPlanApproval = (
  prepaymentUUID: string,
  approvedMonths: number | null,
  initialAmount: number | null,
  requireBackupCard: boolean,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/edit-plan-approval";

  axios
    .post(url, {
      prepayment_uuid: prepaymentUUID,
      approved_months: approvedMonths,
      initial_amount: initialAmount,
      needs_backup_card: requireBackupCard,
    })
    .then((resp) => {
      const data = resp.data;
      successCallback(data);
    })
    .catch((error) => {
      failureCallback(error.data);
    });
};

export const editExistingPaymentPlan = (
  approvedMonths: number | null,
  prepaymentUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/change-existing-plan";

  axios
    .post(url, {
      approved_months: approvedMonths,
      prepayment_uuid: prepaymentUUID,
    })
    .then((resp) => {
      const data = resp.data;
      successCallback(data);
    })
    .catch((error) => {
      failureCallback(error.data);
    });
};
