import styled from "styled-components";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import DropdownCard from "../../../components/baseComponents/DropdownCard";
import { GenericObject } from "../../../components/global/ModelInterfaces";
import Col from "../../../components/baseComponents/Col";
import {
  currencyFormatter,
  dateShortFormatter,
} from "../../../components/global/utils";
interface Props {
  partialRefundInfo: GenericObject;
}
const TableHeader = styled(Row)`
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  color: ${(props) => props.theme.colors.white};
`;
const StyledLabel = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;
const StyledText = styled.span`
  font-size: ${(props) => props.theme.font_size.headlineMD};
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const PartialRefundInfo = ({ partialRefundInfo }: Props) => {
  if (partialRefundInfo.length === 0) {
    return (
      <DropdownCard title="Partial Refund Info" initiallyOpen={false}>
        <Div width={{ default: 12 / 12 }}>
          <Row>
            <StyledText>This prepayment has no partial refunds.</StyledText>
          </Row>
        </Div>
      </DropdownCard>
    );
  }
  return (
    <DropdownCard title="Partial Refund Info" initiallyOpen={false}>
      <Div width={{ default: 12 / 12 }}>
        <TableHeader>
          <StyledLabel width={{ default: 0.5 / 12 }}>ID:</StyledLabel>
          <StyledLabel width={{ default: 4 / 12 }}>UUID:</StyledLabel>
          <StyledLabel width={{ default: 2 / 12 }}>
            Transaction Date:
          </StyledLabel>
          <StyledLabel width={{ default: 1 / 12 }}>Amount:</StyledLabel>
          <StyledLabel width={{ default: 2 / 12 }}>Fee Amount:</StyledLabel>
          <StyledLabel width={{ default: 2.5 / 12 }}>
            Dwolla Transaction ID:
          </StyledLabel>
        </TableHeader>
        <Row>
          <Col width={{ default: 0.5 / 12 }}>
            {partialRefundInfo.map((refund: GenericObject) => (
              <Row>{refund.id}</Row>
            ))}
          </Col>
          <Col width={{ default: 4 / 12 }}>
            {partialRefundInfo.map((refund: GenericObject) => (
              <Row>{refund.uuid}</Row>
            ))}
          </Col>
          <Col width={{ default: 2 / 12 }}>
            {partialRefundInfo.map((refund: GenericObject) => (
              <Row>{dateShortFormatter(refund.transaction_date)}</Row>
            ))}
          </Col>
          <Col width={{ default: 1 / 12 }}>
            {partialRefundInfo.map((refund: GenericObject) => (
              <Row>{currencyFormatter.format(refund.amount)}</Row>
            ))}
          </Col>
          <Col width={{ default: 2 / 12 }}>
            {partialRefundInfo.map((refund: GenericObject) => (
              <Row>{currencyFormatter.format(refund.fee_amount)}</Row>
            ))}
          </Col>
          <Col width={{ default: 2.5 / 12 }}>
            {partialRefundInfo.map((refund: GenericObject) => (
              <Row>{refund.dwolla_transaction_id}</Row>
            ))}
          </Col>
        </Row>
      </Div>
    </DropdownCard>
  );
};

export default PartialRefundInfo;
