import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Div from "../baseComponents/Div";
import Button from "../baseComponents/Button";
import TenantItem from "./TenantItem";
import ErrorMessage from "../baseComponents/ErrorMessage";
import { Unit, Tenant } from "../global/ModelInterfaces";

const StyledHeader = styled(Div)`
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
`;

const StyledInvitations = styled(Div)`
  margin-bottom: 1rem;
`;

interface Props {
  selectedUnit: Unit | null;
  tenantArray: Tenant[];
  errors: string | null;
}
const InviteSuccessView = ({ selectedUnit, tenantArray, errors }: Props) => {
  const history = useHistory();
  return (
    <>
      <StyledHeader>
        {tenantArray.length > 0 && (
          <span>
            Success!
            <br />
            {tenantArray.length > 1
              ? " Your invitations have been sent to your tenants."
              : " Your invitation has been sent to your tenant."}
          </span>
        )}
      </StyledHeader>
      <StyledInvitations>
        {tenantArray.map((tenant: Tenant) => {
          return <TenantItem tenant={tenant} />;
        })}
      </StyledInvitations>
      {errors && errors.length > 0 && (
        <ErrorMessage>The following errors occurred: {errors}</ErrorMessage>
      )}
      <Button
        type={"primary"}
        text={"Return to Unit"}
        onClick={() => history.push(`/dashboard/unit/${selectedUnit?.uuid}`)}
      />
    </>
  );
};

export default InviteSuccessView;
