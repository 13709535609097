interface Props {
  width?: string;
  height?: string;
  color?: string;
}

const UserIcon = ({
  width = "100%",
  height = "100%",
  color = "#2F374B",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 19H11C4.925 19 0 23.925 0 30C0 31.1044 0.895625 32 2 32H26C27.1044 32 28 31.1044 28 30C28 23.925 23.075 19 17 19ZM3.06188 29C3.55563 25.0562 6.925 22 11 22H17C21.0725 22 24.4438 25.0594 24.9375 29H3.06188ZM14 16C18.4181 16 22 12.4181 22 8C22 3.58187 18.4181 0 14 0C9.58187 0 6 3.58187 6 8C6 12.4187 9.58125 16 14 16ZM14 3C16.7569 3 19 5.24312 19 8C19 10.7569 16.7569 13 14 13C11.2431 13 9 10.7563 9 8C9 5.24312 11.2437 3 14 3Z"
        fill={color}
      />
    </svg>
  );
};

export default UserIcon;
