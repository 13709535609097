import styled from "styled-components";
import Div from "./Div";

const StyledSuccess = styled(Div)`
  color: ${(props) => props.theme.colors.success};
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const SuccessMessage = ({ ...props }) => {
  return <StyledSuccess {...props} />;
};

export default SuccessMessage;
