import { useState } from "react";

import styled from "styled-components";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import RentableCard from "../../baseComponents/RentableCard";
import PlaidButton from "../../PlaidButton";
import useGetBankAccount from "../../../utils/useGetBankAccount";
import ReplaceBankModal from "../../baseComponents/ReplaceBankModal";
import Button from "../../baseComponents/Button";
import { GenericObject } from "../../global/ModelInterfaces";

const PlaidButtonRow = styled(Row)`
  margin-top: 1em;
`;
interface Props {
  prepaymentInfo: GenericObject;
}
const BankAccountCard = ({ prepaymentInfo }: Props) => {
  const [replaceAccountOpen, setReplaceAccountOpen] = useState<boolean>(false);
  const { bankAccount } = useGetBankAccount();

  return (
    <>
      <RentableCard title={"Bank Account"}>
        <>
          {!bankAccount && !prepaymentInfo.paid_by_credit_card && (
            <Div alignItems="center">
              <p>
                We noticed you don't have a bank account linked. We won't be
                able to automatically disburse your deposit back to you unless
                you have a bank account associated with your Rentable account.
                Please link a bank account using the button below:
              </p>
              <PlaidButton
                type="secondary"
                customText="Link Bank Account"
                includeAssets={false}
              />
            </Div>
          )}
          {bankAccount && (
            <Div>
              {bankAccount.pending_confirmation ? (
                <>
                  <Div>
                    Please confirm your micro-deposits before continuing.
                  </Div>
                  <Div width={{ sm: 1, md: 1, lg: 1 }}>
                    <PlaidButton />
                  </Div>
                </>
              ) : (
                <Div>
                  <Row>Bank Name: {bankAccount.bank_name}</Row>
                  <Row>
                    Account: {bankAccount.name} •••• {bankAccount.mask}
                  </Row>
                  {prepaymentInfo.deposits.security_deposit?.is_paid_monthly ? (
                    <>
                      If you would like to update your bank account please reach
                      out at support@rentable.com.
                    </>
                  ) : (
                    <PlaidButtonRow justifyContent="center">
                      <Button
                        type="secondary"
                        text="Replace Bank Account"
                        onClick={() => setReplaceAccountOpen(true)}
                      />
                    </PlaidButtonRow>
                  )}
                </Div>
              )}
            </Div>
          )}
        </>
      </RentableCard>
      <ReplaceBankModal
        showModal={replaceAccountOpen}
        closeModal={() => setReplaceAccountOpen(false)}
      />
    </>
  );
};

export default BankAccountCard;
