import styled from "styled-components";
import Div from "../../components/baseComponents/Div";

const StyledTabOption = styled(Div)<CompanyOverviewStyleProps>`
  cursor: pointer;
  text-align: center;
  border-top-right-radius: ${(props) => props.theme.border_radius.MD};
  border-top-left-radius: ${(props) => props.theme.border_radius.MD};
  color: ${(props) => props.theme.colors.white};
  border: 3px solid ${(props) => props.theme.colors.backgroundPrimary};
  border-bottom: unset;
  white-space: nowrap;
  font-weight: ${(props) => props.theme.font_weight.semibold};

  ${(props) =>
    ({ selected }) =>
      selected &&
      `background-color: ${props.theme.colors.backgroundPrimary};
        `};
  ${(props) =>
    ({ selected }) =>
      !selected &&
      `
      color: ${props.theme.colors.backgroundPrimary};
      border-bottom-right-radius: unset;
      border-bottom-left-radius: unset;
  `};
`;

interface CompanyOverviewStyleProps {
  selected?: boolean;
}

interface Props {
  name: string;
  active: boolean;
  onClick: () => void;
}

const Tab = ({ name, active, onClick }: Props) => {
  return (
    <StyledTabOption
      pt={{ default: 1 }}
      pb={{ default: 1 }}
      pl={{ default: 2 }}
      pr={{ default: 2 }}
      ml={{ default: 1 }}
      mr={{ default: 1 }}
      onClick={onClick}
      selected={active}
    >
      {name}
    </StyledTabOption>
  );
};
export default Tab;
