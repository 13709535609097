import { useState } from "react";
import styled from "styled-components";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import Button from "../../../components/baseComponents/Button";
import Input from "../../../components/baseComponents/Input";
import ErrorMessage from "../../../components/baseComponents/ErrorMessage";
import Checkbox from "../../../components/baseComponents/Checkbox";
import { inviteLandlordToCompany } from "../../utils/companyUtils";
import { useParams } from "react-router-dom";
import { GenericObject } from "../../../components/global/ModelInterfaces";
import { emailRegex } from "../../../utils/accountUtils";
import ConfirmationModal from "../../../components/baseComponents/ConfirmationModal";

const StyledLabel = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.bolder};
  font-size: ${(props) => props.theme.font_size.headlineMD};
`;

const StyledSubtitle = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
  font-size: ${(props) => props.theme.font_size.headlineXS};
`;

const SuccessMessageContainer = styled(Div)`
  overflow-wrap: break-word;
`;

const InviteLandlordForm = () => {
  const { companyUUID } = useParams<GenericObject>();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const [confirmInvite, setConfirmInvite] = useState(false);
  const [successInvite, setSuccessInvite] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [inviteURL, setInviteURL] = useState<string>("");

  const validateForm = () => {
    if (email.length !== 0 && !emailRegex.test(email)) {
      // if user typed something in the email field but they entered an invalid email address
      setErrorMessage("Please enter a valid email.");
      return false;
    } else if (email.length === 0 && sendEmail) {
      // if user selected 'Send Email' but didn't enter an email address
      setErrorMessage("Please enter an email address to send the invite to.");
      return false;
    } else {
      return true;
    }
  };

  const handleOpenModal = () => {
    if (validateForm()) {
      setConfirmInvite(true);
      setSuccessInvite(false);
      setErrorMessage("");
    }
  };

  const handleInvite = () => {
    const successCallback = (inviteInfo: GenericObject) => {
      setConfirmInvite(false);
      setSuccessInvite(true);
      setSuccessMessage(sendEmail ? "Email Successfully Sent!" : "Success!");
      setErrorMessage("");
      setInviteURL(inviteInfo.url);
      setEmail("");
      setFirstName("");
      setLastName("");
      setSendEmail(false);
      setIsAdmin(false);
    };
    const failureCallback = (errorMessage: string) => {
      setConfirmInvite(false);
      setSuccessInvite(false);
      setErrorMessage(errorMessage);
      setInviteURL("");
    };
    inviteLandlordToCompany(
      successCallback,
      failureCallback,
      companyUUID,
      sendEmail,
      isAdmin,
      email,
      firstName,
      lastName
    );
  };

  return (
    <Div>
      <Row>
        <Div width={{ default: 12 / 12 }} mt={{ default: 3 }}>
          <Input
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Div>
        <Div width={{ default: 1, md: 6 / 12 }}>
          <Input
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Div>
        <Div width={{ default: 1, md: 6 / 12 }} mb={{ default: 3 }}>
          <Input
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Div>
        <Div width={{ default: 1, md: 6 / 12 }}>
          <Row justifyContent="flex-start">
            <Checkbox
              value={sendEmail}
              onChange={() => {
                setSendEmail((sendEmail) => !sendEmail);
              }}
            />
            <Div>Send Email</Div>
          </Row>
        </Div>
        <Div width={{ default: 1, md: 6 / 12 }}>
          <Row justifyContent="flex-start">
            <Checkbox
              value={isAdmin}
              onChange={() => {
                setIsAdmin((isAdmin) => !isAdmin);
              }}
            />
            <Div>Make Admin</Div>
          </Row>
        </Div>
        <Div width={{ default: 12 / 12 }} mt={{ default: 3 }}>
          <Button type="primary" text="Invite" onClick={handleOpenModal} />
        </Div>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Row>
      <ConfirmationModal
        confirmationOpen={confirmInvite}
        onClose={() => setConfirmInvite(false)}
        onConfirm={handleInvite}
        message="Are you sure you would like to invite user as a landlord?"
      />
      {successInvite && (
        <Div
          textAlign="start"
          pt={{ default: 4 }}
          pl={{ default: 3 }}
          pr={{ default: 3 }}
        >
          <StyledLabel textAlign="center">{successMessage}</StyledLabel>
          <StyledSubtitle>{"Invite URL: \n"}</StyledSubtitle>
          <SuccessMessageContainer>{inviteURL}</SuccessMessageContainer>
          <Div mt={{ default: 3 }}>
            <Button
              type="primary"
              text="Close"
              onClick={() => setSuccessInvite(false)}
            />
          </Div>
        </Div>
      )}
    </Div>
  );
};

export default InviteLandlordForm;
