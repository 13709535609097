import styled from "styled-components";
import { variant } from "styled-system";
import Container from "../baseComponents/Container";
import Div from "../baseComponents/Div";
import Row from "../baseComponents/Row";
import XIcon from "../../images/XIcon";
import Label from "../baseComponents/Label";
import DropdownCaret from "../baseComponents/DropdownCaret";
import { useState } from "react";
import { Tenant, DepositList, Fee } from "../global/ModelInterfaces";
import { navigateToTenant, currencyFormatter } from "../global/utils";
import { useHistory } from "react-router-dom";
import { cssBreakpoints } from "../global/theme";
import depositTypes from "../../enums/depositTypes";

const StyledTitle = styled.div`
  color: ${(props) => props.theme.colors.navy};
  font-weight: 700;
  font-size: 1.125em;
`;
interface StyledDivProps {
  clickable?: boolean;
}
const StyledDiv = styled(Div)<StyledDivProps>`
  background-color: ${(props) => props.theme.colors.grey2};
  box-shadow: 0em 0.25em 0.25em rgba(0, 0, 0, 0.25);
  border-radius: 1em;

  margin: 0.625em 0em;
  ${variant({
    prop: "clickable",
    variants: {
      true: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  })}
  ${cssBreakpoints("padding", [{ sm: "1.5rem" }, { md: "1.5rem" }])}
`;

const StyledSubtitle = styled.div`
  color: ${(props) => props.theme.colors.grey60};
  font-weight: 400;
  ${cssBreakpoints("font-size", [{ sm: ".65rem" }, { md: ".75rem" }])}
`;

const StyledMetrics = styled.div`
  color: ${(props) => props.theme.colors.grey40};
  font-weight: 700;
  font-size: 0.75em;
  left: 0em;
  top: 0em;
`;

const StyledMetricValue = styled(Div)`
  font-weight: 400;
  font-size: 1em;
  left: 0em;
`;

const CloseButton = styled(Div)`
  cursor: pointer;
`;

const StyledIndentedDiv = styled(Div)`
  padding-left: 1.5em;
`;

const IndentedCaretDiv = styled(Div)`
  padding-left: 0.5em;
`;
interface Props {
  tenant: Tenant;
  onDelete?: (uuid: string) => void;
  clickable?: boolean;
  showDropDownCaret?: boolean;
}

const TenantItem = ({
  tenant,
  onDelete,
  clickable = true,
  showDropDownCaret = false,
}: Props) => {
  const history = useHistory();
  const [openDropdown, setOpenDropdown] = useState(false);

  const handleDropdown = () => {
    setOpenDropdown((openDropdown) => !openDropdown);
  };
  const renderDepositName = (deposit: DepositList) => {
    if (deposit.type === depositTypes.SECURITY_DEPOSIT) {
      return <StyledMetrics>Deposit</StyledMetrics>;
    }
    if (deposit.type === depositTypes.LAST_MONTHS_RENT) {
      return <StyledMetrics>Last Month</StyledMetrics>;
    }
  };
  return (
    <Container>
      <StyledDiv
        width={{ default: 1 }}
        clickable={clickable}
        onClick={() => clickable && navigateToTenant(tenant.uuid, history)}
      >
        <Row>
          <Div width={{ sm: 1, md: 7 / 12, lg: 7 / 12 }}>
            <Row>
              <StyledTitle>{tenant.name}</StyledTitle>
            </Row>
            <Row>
              <StyledSubtitle>{tenant.email}</StyledSubtitle>
            </Row>
            {showDropDownCaret && (
              <IndentedCaretDiv
                width={{ sm: 1, lg: 1 / 12 }}
                onClick={handleDropdown}
              >
                <DropdownCaret open={openDropdown} />
              </IndentedCaretDiv>
            )}
          </Div>
          <Div width={{ sm: 1, md: 4 / 12, lg: 4 / 12 }}>
            <Row alignItems="center" flexWrap="no-wrap">
              <Div>
                <Label>Total Deposits:</Label>
                <StyledMetricValue>
                  {`${currencyFormatter.format(
                    tenant.depositList.reduce(
                      (sumValue: number, deposit: DepositList) =>
                        sumValue + Number(deposit.amount),
                      0
                    )
                  )}`}
                </StyledMetricValue>
              </Div>
              {tenant?.feeList.length !== 0 && (
                <Div>
                  <Label>Total Fees</Label>
                  <StyledMetricValue>
                    {`${currencyFormatter.format(
                      tenant.feeList.reduce(
                        (sumValue: number, fee: Fee) => sumValue + fee.amount,
                        0
                      )
                    )}`}
                  </StyledMetricValue>
                </Div>
              )}
              {onDelete && (
                <CloseButton
                  width={{ default: 1 / 3 }}
                  alignItems="flex-end"
                  onClick={() => onDelete(tenant.uuid)}
                >
                  <XIcon />
                </CloseButton>
              )}
            </Row>
          </Div>
        </Row>
        <>
          {openDropdown && (
            <Div>
              <Div>
                <Row>
                  {tenant.depositList.map((deposit: DepositList) => {
                    return (
                      <StyledIndentedDiv>
                        <Label>{renderDepositName(deposit)}</Label>
                        <StyledMetricValue>
                          {currencyFormatter.format(deposit.amount)}
                        </StyledMetricValue>
                      </StyledIndentedDiv>
                    );
                  })}
                </Row>
              </Div>
              <Div>
                <Row>
                  {tenant.feeList.map((fee: Fee) => {
                    return (
                      <StyledIndentedDiv>
                        <StyledMetrics>{fee.type}</StyledMetrics>
                        <StyledMetricValue>
                          {currencyFormatter.format(fee.amount)}
                        </StyledMetricValue>
                      </StyledIndentedDiv>
                    );
                  })}
                </Row>
              </Div>
            </Div>
          )}
        </>
      </StyledDiv>
    </Container>
  );
};

export default TenantItem;
