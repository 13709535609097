import React from "react";

export default function DocumentIcon() {
  return (
    <svg
      width="20"
      height="29"
      viewBox="0 0 30 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 20.1094V17.9766C22.5 17.4738 22.0781 17.0625 21.5625 17.0625H8.4375C7.92188 17.0625 7.5 17.4738 7.5 17.9766V20.1094C7.5 20.6121 7.92188 21.0234 8.4375 21.0234H21.5625C22.0781 21.0234 22.5 20.6121 22.5 20.1094ZM21.5625 14.625H8.4375C7.92188 14.625 7.5 14.2137 7.5 13.7109V11.5781C7.5 11.0754 7.92188 10.6641 8.4375 10.6641H21.5625C22.0781 10.6641 22.5 11.0754 22.5 11.5781V13.7109C22.5 14.2137 22.0781 14.625 21.5625 14.625ZM30 28.9529V3.65625C30 1.6377 28.3203 0 26.25 0H3.75C1.67969 0 0 1.6377 0 3.65625V35.3438C0 37.3623 1.67969 39 3.75 39H19.6953C20.6875 39 21.6406 38.6115 22.3438 37.926L28.8984 31.5352C29.6016 30.8572 30 29.9203 30 28.9529ZM20 35.0467V29.25H25.9453L20 35.0467ZM26.25 3.65625V25.5938H18.125C17.0859 25.5938 16.25 26.4088 16.25 27.4219V35.3438H3.75V3.65625H26.25Z"
        fill="#2F374B"
      />
    </svg>
  );
}
