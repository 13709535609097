interface Props {
  width?: string;
  height?: string;
}

const MoneySignIcon = ({ width = "40", height = "40" }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.4833 18.5C18.5167 17.5166 17.0833 16.9 17.0833 15.3333C17.0833 13.6333 18.9333 13.0166 20.1 13.0166C22.2833 13.0166 23.0833 14.6666 23.2667 15.25L25.9 14.1333C25.65 13.4 24.5333 10.95 21.4667 10.4166V8.33331H18.55V10.4333C14.2167 11.3666 14.1833 15.1833 14.1833 15.3666C14.1833 19.15 17.9333 20.2166 19.7667 20.8833C22.4 21.8166 23.5667 22.6666 23.5667 24.2666C23.5667 26.15 21.8167 26.95 20.2667 26.95C17.2333 26.95 16.3667 23.8333 16.2667 23.4666L13.5 24.5833C14.55 28.2333 17.3 29.2166 18.5333 29.5166V31.6666H21.45V29.6C22.3167 29.45 26.4833 28.6166 26.4833 24.2333C26.5 21.9166 25.4833 19.8833 21.4833 18.5Z"
        fill="#1EB88D"
      />
      <path
        d="M20.0002 3.33331C10.8002 3.33331 3.3335 10.8 3.3335 20C3.3335 29.2 10.8002 36.6666 20.0002 36.6666C29.2002 36.6666 36.6668 29.2 36.6668 20C36.6668 10.8 29.2002 3.33331 20.0002 3.33331ZM20.0002 33.3333C12.6502 33.3333 6.66683 27.35 6.66683 20C6.66683 12.65 12.6502 6.66665 20.0002 6.66665C27.3502 6.66665 33.3335 12.65 33.3335 20C33.3335 27.35 27.3502 33.3333 20.0002 33.3333Z"
        fill="#1EB88D"
      />
    </svg>
  );
};

export default MoneySignIcon;
