// React, State Management and Hooks
import { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import useGetPrepayment from "../../utils/useGetPrepayment";
import { useAppDispatch } from "../../store/hooks";
import { updatePrepayment } from "../../store/prepayments";
import styled from "styled-components";

// Components
import BackButton from "../baseComponents/BackButton";
import Button from "../baseComponents/Button";
import Checkbox from "../baseComponents/Checkbox";
import ConfirmationModal from "../baseComponents/ConfirmationModal";
import Container from "../baseComponents/Container";
import DateInput from "../baseComponents/DateInput";
import Div from "../baseComponents/Div";
import ErrorMessage from "../baseComponents/ErrorMessage";
import CurrencyInput from "../baseComponents/CurrencyInput";
import PageLoader from "../PageLoader";
import Row from "../baseComponents/Row";

// Utilities, Enums and Interfaces
import { createRenewal } from "../../utils/depositUtils";
import { currencyFormatter, dateShortFormatter } from "../global/utils";
import { getStreetAddress } from "../../utils/globalUtils";
import { cssBreakpoints } from "../global/theme";
import { Prepayment } from "../global/ModelInterfaces";

const StyledContainer = styled(Div)`
  border: ${(props) => `1px solid ${props.theme.colors.black}`};
  padding: 1rem;
  border-radius: 0.5rem;
  ${cssBreakpoints("margin-bottom", [{ sm: "2rem" }])}
`;
const StyledTitle = styled(Row)`
  ${cssBreakpoints("margin-bottom", [{ sm: "2rem" }])}
`;

const StyledDetails = styled(Row)`
  margin-bottom: 2rem;
`;

const StyledLabel = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const MonthToMonthText = styled(Row)`
  font-size: ${(props) => props.theme.font_size.bodySM};
  color: ${(props) => props.theme.colors.grey60};
  margin-top: 0.5rem;
`;

const StyledButton = styled(Button)`
  ${cssBreakpoints("margin-top", [{ sm: "2rem" }])}
`;

const RenewLeaseView = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { selectedPrepayment, loadingPrepayments } = useGetPrepayment();
  const securityDeposit = selectedPrepayment?.deposits.security_deposit;
  const lastMonthsRent = selectedPrepayment?.deposits.last_months_rent;
  const previousEndDate = selectedPrepayment?.end_date;
  const previousStartDate = selectedPrepayment?.start_date;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const [securityDepositAmount, setSecurityDepositAmount] = useState(0.0);
  const [lastMonthsRentAmount, setLastMonthsRentAmount] = useState(0.0);
  const [newDepositEndDate, setNewDepositEndDate] = useState<string | null>(
    null
  );
  const [monthToMonth, setMonthToMonth] = useState<boolean>(!previousEndDate);
  const [validDates, setValidDates] = useState<boolean>(false);

  const handleRenewal = () => {
    setLoading(true);
    const successCallback = (updatedPrepayment: Prepayment) => {
      dispatch(updatePrepayment(updatedPrepayment));
      history.push(`/dashboard/tenant/${updatedPrepayment.uuid}`);
      setLoading(false);
    };
    const errorCallBack = (errorMessage: string) => {
      setError(errorMessage);
      setLoading(false);
    };
    createRenewal(
      selectedPrepayment?.uuid,
      newDepositEndDate,
      monthToMonth,
      securityDepositAmount,
      lastMonthsRentAmount,
      successCallback,
      errorCallBack
    );
    return;
  };

  const validateDates = () => {
    if (previousEndDate && monthToMonth) {
      return true;
    } else if (!previousEndDate && previousStartDate && newDepositEndDate) {
      return previousStartDate < newDepositEndDate;
    } else if (!monthToMonth && previousEndDate && newDepositEndDate) {
      return previousEndDate < newDepositEndDate;
    } else if (!previousEndDate && monthToMonth) {
      return true;
    } else if (!monthToMonth && !previousEndDate && !newDepositEndDate) {
      return false;
    }
    return false;
  };

  const validateAmounts = () => {
    if (securityDepositAmount && securityDepositAmount < 0) {
      setError("Please enter a valid security deposit increase amount.");
      return false;
    } else if (lastMonthsRentAmount && lastMonthsRentAmount < 0) {
      setError("Please enter a valid last months rent increase amount.");
      return false;
    }
    setError("");
    return true;
  };

  const handleOpen = () => {
    if (validateAmounts()) {
      setConfirmationOpen(true);
    }
  };

  const renderConfirmationText = () => {
    let confirmationText = `New End Date: `;
    if (newDepositEndDate) {
      confirmationText += `${newDepositEndDate} \n\n`;
    } else if (monthToMonth) {
      confirmationText += `Month to Month \n\n`;
    }
    if (securityDeposit) {
      confirmationText += `Security Deposit Amount: `;
      confirmationText += `${currencyFormatter.format(
        Number(securityDeposit.amount) + Number(securityDepositAmount)
      )} \n`;
    }
    if (lastMonthsRent) {
      confirmationText += `Last Month's Rent Amount: `;
      confirmationText += `${currencyFormatter.format(
        Number(lastMonthsRent.amount) + Number(lastMonthsRentAmount)
      )} \n`;
    }
    return confirmationText;
  };

  if (!selectedPrepayment) {
    return <Redirect to="/dashboard" />;
  } else if (loadingPrepayments) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  } else {
    return (
      <>
        <Container>
          <BackButton text="Back" />
          <Row justifyContent="center">
            <StyledContainer width={{ sm: 1, md: 1, lg: 5 / 12 }}>
              <StyledTitle>
                <Div width={{ default: 1 }} alignItems={"center"}>
                  <h2>Renew Lease</h2>
                </Div>
              </StyledTitle>
              <StyledDetails>
                <Row justifyContent="space-between">
                  <StyledLabel>Tenant Name:</StyledLabel>
                  <Div>{selectedPrepayment.tenant_name}</Div>
                </Row>
                <Row justifyContent="space-between">
                  <StyledLabel>Address:</StyledLabel>
                  <Div>
                    {getStreetAddress(
                      selectedPrepayment.address_1,
                      selectedPrepayment.address_2
                    )}
                  </Div>
                </Row>
                <Row justifyContent="space-between">
                  <StyledLabel>Current Start Date:</StyledLabel>
                  <Div>{dateShortFormatter(selectedPrepayment.start_date)}</Div>
                </Row>
                <Row justifyContent="space-between">
                  <StyledLabel>Current End Date:</StyledLabel>
                  <Div>
                    {selectedPrepayment.end_date ? (
                      <>{dateShortFormatter(selectedPrepayment.end_date)}</>
                    ) : (
                      <>Month to Month</>
                    )}
                  </Div>
                </Row>
                {securityDeposit && securityDeposit.amount && (
                  <Row justifyContent="space-between">
                    <StyledLabel>Current Security Deposit Amount:</StyledLabel>
                    <Div>{securityDeposit.amount}</Div>
                  </Row>
                )}
                {lastMonthsRent && lastMonthsRent.amount && (
                  <Row justifyContent="space-between">
                    <StyledLabel>Current Last Month's Rent Amount:</StyledLabel>
                    <Div>{lastMonthsRent.amount}</Div>
                  </Row>
                )}
              </StyledDetails>
              <Row justifyContent="center">
                {!validDates && (
                  <Div width={{ default: 1 }}>
                    <Row>
                      <Div
                        width={{ sm: 1, md: 1 / 2, lg: 6 / 12 }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <span>
                          Please enter a new lease end date or mark the lease
                          Month to Month.
                        </span>
                      </Div>
                      <Div
                        width={{ sm: 1, md: 1 / 2, lg: 6 / 12 }}
                        justifyContent="center"
                      >
                        <DateInput
                          label={"New End Date"}
                          onChange={(date: string) => {
                            setNewDepositEndDate(date);
                            setMonthToMonth(false);
                          }}
                        />
                        <MonthToMonthText
                          alignItems="flex-start"
                          justifyContent="flex-start"
                          flexWrap="nowrap"
                          addSpace={false}
                        >
                          <Div>Lease is Month to Month.</Div>
                          <Div ml={{ default: 3 }}>
                            <Checkbox
                              value={monthToMonth}
                              onChange={() => {
                                setMonthToMonth(
                                  (monthToMonth) => !monthToMonth
                                );
                                setNewDepositEndDate(null);
                              }}
                            />
                          </Div>
                        </MonthToMonthText>
                      </Div>
                    </Row>
                    <Row justifyContent="center">
                      <Div
                        width={{ default: 1, lg: 8 / 12 }}
                        mt={{ default: 3 }}
                      >
                        <Row justifyContent="center">
                          <ErrorMessage>{error}</ErrorMessage>
                        </Row>
                        <StyledButton
                          type="secondary_sm"
                          disabled={!validateDates()}
                          text={`Continue`}
                          onClick={() => {
                            setValidDates(true);
                          }}
                        />
                      </Div>
                    </Row>
                  </Div>
                )}
                {validDates && (
                  <Div>
                    <Row addSpace={false}>
                      <BackButton
                        text="Edit Dates"
                        onClick={() => {
                          setValidDates(false);
                          setError("");
                        }}
                      />
                    </Row>
                    <Row mt={{ default: 5 }} mb={{ default: 3 }}>
                      <span>
                        If you would like to increase the deposit amount please
                        in put an amount.
                      </span>
                    </Row>
                    <Row>
                      {securityDeposit && (
                        <Div width={{ sm: 1, lg: 6 / 12 }}>
                          <CurrencyInput
                            label="Security Deposit Increase Amount"
                            onChange={setSecurityDepositAmount}
                          />
                        </Div>
                      )}
                      {lastMonthsRent && (
                        <Div width={{ sm: 1, lg: 6 / 12 }}>
                          <CurrencyInput
                            label="Last Month's Rent Increase Amount"
                            onChange={setLastMonthsRentAmount}
                          />
                        </Div>
                      )}
                      <Row justifyContent="center">
                        <Div width={{ default: 1, lg: 10 / 12 }}>
                          <StyledButton
                            type="primary"
                            text={`Renew Lease`}
                            onClick={handleOpen}
                            loading={loading}
                          />
                        </Div>
                      </Row>
                    </Row>
                  </Div>
                )}
              </Row>
            </StyledContainer>
          </Row>
        </Container>
        <ConfirmationModal
          confirmationOpen={confirmationOpen}
          message={renderConfirmationText()}
          title={"Renewal Details"}
          onClose={() => setConfirmationOpen(false)}
          errorMessage={error}
          onConfirm={handleRenewal}
        />
      </>
    );
  }
};

export default RenewLeaseView;
