import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { loadInitialLandlordData } from "./utils/initialization";
import { useAppDispatch } from "./store/hooks";

import NavbarLandlord from "./components/LandlordComponents/NavbarLandlord";
import RoutesLandlord from "./components/LandlordComponents/RoutesLandlord";

const LandlordApp = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadInitialLandlordData());
  }, []);
  return (
    <BrowserRouter>
      <NavbarLandlord />
      <RoutesLandlord />
    </BrowserRouter>
  );
};

export default LandlordApp;
