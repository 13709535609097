import styled from "styled-components";
import { Redirect } from "react-router-dom";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import Label from "../../baseComponents/Label";
import { cssBreakpoints } from "../../global/theme";
import { currencyFormatter } from "../../global/utils";

const StyledLineItem = styled(Row)`
  margin-top: 0.5rem;
  ${cssBreakpoints("white-space", [{ sm: "normal" }, { md: "nowrap" }])}
`;

interface SummaryProps {
  offboarding: any;
}
const DisbursementSummary = ({ offboarding }: SummaryProps) => {
  if (!offboarding) {
    return <Redirect to={`/dashboard`} />;
  }

  return (
    <Row mt={{ default: 2 }}>
      {parseFloat(offboarding.returned_deposit_amount) > 0 && (
        <LineItem
          title={"Security Deposit Amount"}
          amount={offboarding.returned_deposit_amount}
        />
      )}
      {parseFloat(offboarding.security_deposit_interest) > 0 && (
        <LineItem
          title={"Security Deposit Interest"}
          amount={offboarding.security_deposit_interest}
        />
      )}
      {parseFloat(offboarding.returned_last_months_rent_amount) > 0 && (
        <LineItem
          title={"Last Month's Rent Amount"}
          amount={offboarding.returned_last_months_rent_amount}
        />
      )}
      {parseFloat(offboarding.last_months_rent_interest) > 0 && (
        <LineItem
          title={"Last Month's Rent Interest"}
          amount={offboarding.last_months_rent_interest}
        />
      )}
      <Row alignItems="end" mt={{ default: 2 }}>
        <Label width={{ default: 8 / 12, lg: 6 / 12 }}>
          Total Amount to be Returned:
        </Label>
        <Div>
          {currencyFormatter.format(offboarding.total_amount_to_disburse)}
        </Div>
      </Row>
    </Row>
  );
};
interface LineItemProps {
  title: string;
  amount: string;
}

const LineItem = ({ title, amount }: LineItemProps) => {
  return (
    <StyledLineItem alignItems="end">
      <Label width={{ default: 8 / 12, lg: 6 / 12 }}>{title}:</Label>
      <Div>{currencyFormatter.format(parseFloat(amount))}</Div>
    </StyledLineItem>
  );
};

export default DisbursementSummary;
