import Row from "../components/baseComponents/Row";
import Div from "../components/baseComponents/Div";
import Button from "../components/baseComponents/Button";
import ConfusedCoin from "../images/ConfusedCoin";
import styled from "styled-components";

const StyledMessage = styled(Div)`
  margin: 1rem 0;
  font-size: ${(props) => props.theme.font_size.headlineSM};
`;

const StyledContainer = styled(Div)`
  padding: 2rem;
`;

const UnknownErrorPage = () => {
  return (
    <StyledContainer width={{ default: 1, lg: 4 / 12 }}>
      <Row justifyContent="center">
        <ConfusedCoin />
      </Row>
      <Row justifyContent="center">
        <StyledMessage textAlign="center">
          Something went wrong! If this issue continues, please reach out to
          support@rentable.com for help
        </StyledMessage>
      </Row>
      <Row justifyContent="center">
        <Div width={{ default: 1 }}>
          <Button
            onClick={() => {
              window.location.href = "/dashboard";
            }}
          >
            Return to Dashboard
          </Button>
        </Div>
      </Row>
    </StyledContainer>
  );
};

export default UnknownErrorPage;
