import axios from "axios";

export const getLatestBalance = (
  currentTimestamp: string,
  email: string,
  successCallback: Function,
  failureCallback: Function
) => {
  requestPlaidUpdate(email, successCallback, failureCallback);
  pollLatestBalance(currentTimestamp, email, successCallback, failureCallback);
};

const pollLatestBalance = (
  currentTimestamp: string,
  email: string,
  successCallback: Function,
  failureCallback: Function
) => {
  // Polls balance each second until error or timeStamp changed
  let currentPoll = 0; // In seconds
  let pollDuration = 30;
  const intervalId = setInterval(function () {
    // Fetches balance from the server every second
    if (currentPoll === pollDuration) {
      // Stop polling
      clearInterval(intervalId);
    } else {
      currentPoll += 1;
    }

    const url = "/nucleus/api/latest-balance/poll";

    axios
      .post(url, {
        email: email,
      })
      .then((resp: any) => {
        const data = resp.data;
        if (currentPoll === pollDuration) {
          successCallback({
            isPlaidSuccess: true,
            latestBalance: data.latest_balance + " (Time out)",
            balanceUpdatedAt: data.balance_updated_at,
            stillPolling: false,
          });
        } else if (
          currentTimestamp &&
          currentTimestamp !== data.balance_updated_at
        ) {
          successCallback({
            isPlaidSuccess: true,
            latestBalance: data.latest_balance,
            balanceUpdatedAt: data.balance_updated_at,
            stillPolling: false,
          });
          // Stop polling
          clearInterval(intervalId);
        } else {
          successCallback({
            isPlaidSuccess: true,
            latestBalance: data.latest_balance,
            balanceUpdatedAt: data.balance_updated_at,
            stillPolling: true,
          });
        }
      })
      .catch((error) => {
        failureCallback(error.data.error);
        // Stop polling
        clearInterval(intervalId);
      });
  }, 1000); // polling interval is 1000ms (or 1 second)
};

const requestPlaidUpdate = (
  email: string,
  successCallback: Function,
  failureCallback: Function
) => {
  // Request to UPDATE the the balance (once)
  const url = "/nucleus/api/latest-balance/update";

  axios
    .post(url, {
      email: email,
    })
    .then((resp) => {
      const data = resp.data;
      successCallback({
        isPlaidSuccess: data.success,
        latestBalance: "",
        balanceUpdated_At: "",
        stillPolling: true,
      });
    })
    .catch((error) => {
      failureCallback(error);
    });
};

export const unlinkBankAccount = (
  bankAccountUUID: string,
  email: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/unlink-user-bank";

  axios
    .post(url, {
      bank_uuid: bankAccountUUID,
      email: email,
    })
    .then((resp) => {
      successCallback();
    })
    .catch((error) => {
      failureCallback(error.data.error);
    });
};
