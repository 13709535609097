import styled from "styled-components";
import Container from "../../components/baseComponents/Container";
import Div from "../../components/baseComponents/Div";
import Row from "../../components/baseComponents/Row";
import NucleusLoader from "../components/NucleusLoader";
import nucleusLogo from "../../images/nucleus_logo.png";
import useNucleusPermissions from "../../utils/useNucleusPermissions";

const StyledHeader = styled(Row)`
  font-size: ${(props) => props.theme.font_size.headlineLG};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;
const Dashboard = () => {
  const { loadingPermissions } = useNucleusPermissions();

  if (loadingPermissions) {
    return (
      <Container>
        <Row justifyContent="center" mt={{ default: 5 }}>
          <Div width={{ default: 2 / 12 }}>
            <NucleusLoader />
          </Div>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Div justifyContent="center" alignItems="center">
        <StyledHeader
          justifyContent="center"
          mt={{ default: 5 }}
          mb={{ default: 1 }}
        >
          Welcome to Nucleus!
        </StyledHeader>
        <Div width={{ default: 3 / 12 }}>
          <img key={"nucleus_logo"} src={nucleusLogo} />
        </Div>
        <Div>Please use the navigation bar on the left</Div>
      </Div>
    </Container>
  );
};

export default Dashboard;
