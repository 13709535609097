import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import Div from "../components/baseComponents/Div";
import Row from "../components/baseComponents/Row";
import Button from "../components/baseComponents/Button";
import ErrorMessage from "../components/baseComponents/ErrorMessage";

import { RootState } from "..";
import { handlePasswordReset, logout } from "../store/auth";
import { cssBreakpoints } from "../components/global/theme";
import { GenericObject } from "../components/global/ModelInterfaces";
import PasswordInput from "../components/baseComponents/PasswordInput";
import { validatePassword, passwordErrorMessage } from "../utils/accountUtils";

const StyledContainer = styled(Div)`
  border-radius: 0.75rem;
  ${cssBreakpoints("border", [
    { sm: "none" },
    { md: "1px solid #cfcfcf" },
    { lg: "1px solid #cfcfcf" },
  ])}

  ${cssBreakpoints("padding", [
    { sm: "1.5rem" },
    { md: "1.5rem" },
    { lg: "1rem" },
  ])}

  ${cssBreakpoints("margin-top", [
    { sm: "1.5rem" },
    { md: "1.5rem" },
    { lg: "1rem" },
  ])}
`;

const StyledText = styled(Row)`
  margin-top: 1rem;
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const StyledRow = styled(Row)`
  margin: 1rem 0;
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const StyledSuccess = styled(Div)`
  color: ${(props) => `${props.theme.colors.success}`};
  margin-bottom: 1rem;
`;

const PasswordResetConfirmView = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state: RootState) => state.auth.user);
  const history = useHistory();

  const [newPassword, setNewPassword] = useState("");
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [resetError, setResetError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  let { uidb64 } = useParams<GenericObject>();
  let { token } = useParams<GenericObject>();

  if (currentUser) {
    dispatch(logout());
  }

  const handleSubmit = async () => {
    if (!validatePassword(newPassword)) {
      setResetError(passwordErrorMessage);
    } else {
      setPasswordLoading(true);
      const successCallback = () => {
        setPasswordLoading(false);
        setSuccessMessage("Password successfully updated.");
      };

      const failureCallback = (errorMessage: string) => {
        setPasswordLoading(false);
        setResetError(errorMessage);
      };

      handlePasswordReset(
        uidb64,
        token,
        newPassword,
        successCallback,
        failureCallback
      );
    }
  };

  return (
    <StyledContainer width={{ sm: 1, md: 1, lg: 4 / 12 }}>
      <StyledRow>
        {successMessage ? (
          <>
            <StyledSuccess>{successMessage}</StyledSuccess>
            <Button
              text="Return To Login"
              onClick={() => history.push("/login")}
            />
          </>
        ) : (
          <>
            <StyledText>
              <span>Please enter your new password.</span>
            </StyledText>
            <StyledRow>
              <Div width={{ sm: 1, md: 1, lg: 1 }}>
                <PasswordInput
                  onChange={(e) => setNewPassword(e.target.value)}
                  error={!!resetError}
                />
              </Div>
            </StyledRow>
            {resetError && (
              <StyledRow>
                <ErrorMessage>{resetError}</ErrorMessage>
              </StyledRow>
            )}
            <Button
              text="Reset Password"
              onClick={handleSubmit}
              loading={passwordLoading}
            />
          </>
        )}
      </StyledRow>
    </StyledContainer>
  );
};

export default PasswordResetConfirmView;
