import { useState } from "react";
import styled from "styled-components";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import Button from "../../../components/baseComponents/Button";
import Input from "../../../components/baseComponents/Input";
import ErrorMessage from "../../../components/baseComponents/ErrorMessage";
import SuccessMessage from "../../../components/baseComponents/SuccessMessage";
import { createCompany } from "../../utils/companyUtils";
import { useHistory } from "react-router-dom";
import {
  GenericObject,
  NucleusCompany,
} from "../../../components/global/ModelInterfaces";
import PlacesAutocomplete from "../../../components/PlacesAutoComplete";
import { useDispatch } from "react-redux";
import { addCompany } from "../../../store/nucleusCompanies";

const StyledLabel = styled.span`
  font-weight: ${(props) => props.theme.font_weight.bolder};
  font-size: ${(props) => props.theme.font_size.bodyMD};
`;

const CreateCompanyForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [companyName, setCompanyName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [errors, setErrors] = useState<GenericObject>({});
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const validateForm = () => {
    let tmpErrors = errors;
    if (!companyName) {
      tmpErrors = { ...tmpErrors, name: true };
    }
    if (!streetAddress) {
      tmpErrors = { ...tmpErrors, streetAddress: true };
    }
    if (!city) {
      tmpErrors = { ...tmpErrors, city: true };
    }
    if (!state) {
      tmpErrors = { ...tmpErrors, state: true };
    }
    if (!zipCode) {
      tmpErrors = { ...tmpErrors, zipCode: true };
    }
    setErrors(tmpErrors);
    return !Object.keys(tmpErrors).some((key: string) => !!tmpErrors[key]);
  };

  const clearError = (key: string) => {
    if (key === "all") {
      setErrors({});
    } else {
      let tmpErrors = errors;
      tmpErrors[key] = false;
      setErrors(tmpErrors);
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const successCallback = (newCompany: NucleusCompany) => {
        setSuccessMessage("Company created!");
        setCompanyName("");
        setStreetAddress("");
        setCity("");
        setState("");
        setZipCode("");
        dispatch(addCompany(newCompany));
        history.push(`/nucleus/company-overview/${newCompany.uuid}`);
      };
      const failureCallback = (errorMessage: string) => {
        setErrorMessage(errorMessage);
      };
      createCompany(
        companyName,
        streetAddress,
        city,
        state,
        zipCode,
        successCallback,
        failureCallback
      );
    }
  };

  return (
    <Div>
      <Row mt={{ default: 3 }}>
        <Div width={{ sm: 1, md: 1, lg: 1 }}>
          <Input
            label="Company Name"
            value={companyName}
            onChange={(e) => {
              clearError("name");
              setCompanyName(e.target.value);
            }}
            error={errors.name}
          />
        </Div>
      </Row>
      <Row mt={{ default: 3 }}>
        <Div width={{ sm: 1, md: 1, lg: 1 }}>
          <StyledLabel>Street Address</StyledLabel>
          <PlacesAutocomplete
            onSelect={(place: any) => {
              clearError("all");
              setCity(place.city);
              setStreetAddress(place.streetAddress);
              setZipCode(place.zipcode);
              setState(place.state);
            }}
            initialValue={streetAddress}
            onChange={(value: string) => {
              clearError("address");
              setStreetAddress(value);
            }}
            error={errors.address}
          />
        </Div>
      </Row>
      <Row mt={{ default: 3 }}>
        <Div width={{ sm: 1, md: 1, lg: 1 }}>
          <Input
            label="City"
            value={city}
            backgroundColor={"light"}
            onChange={(e) => {
              clearError("city");
              setCity(e.target.value);
            }}
            type="light"
            error={errors.city}
          />
        </Div>
      </Row>
      <Row mt={{ default: 3 }}>
        <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
          <Input
            label="State"
            value={state}
            backgroundColor={"light"}
            onChange={(e) => {
              clearError("state");
              setState(e.target.value);
            }}
            type="light"
            error={errors.state}
          />
        </Div>
        <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
          <Input
            label="Zip Code"
            value={zipCode}
            backgroundColor={"light"}
            onChange={(e) => {
              clearError("zipCode");
              setZipCode(e.target.value);
            }}
            type="light"
            error={errors.zipCode}
          />
        </Div>
      </Row>
      <Div width={{ default: 12 / 12 }} mt={{ default: 3 }}>
        <Button text="Create" onClick={handleSubmit} />
      </Div>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
    </Div>
  );
};

export default CreateCompanyForm;
