import { ReactElement } from "react";
import styled from "styled-components";
import { variant } from "styled-system";
import Div from "../../components/baseComponents/Div";
import Row from "../../components/baseComponents/Row";

interface NavlinkStyleProps {
  variant?: string;
  isActive: boolean;
}

const StyledSideNavLink = styled(Div)<NavlinkStyleProps>`
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.font_size.bodyLG};
  min-height: ${(props) => props.theme.space[6]};
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.grey};
  }
  background-color: ${(props) =>
    props.isActive
      ? props.theme.colors.backgroundSecondary
      : props.theme.colors.backgroundPrimary};
  ${(props) =>
    variant({
      variants: {
        primary: {
          backgroundColor: props.isActive
            ? props.theme.colors.backgroundSecondary
            : props.theme.colors.backgroundPrimary,
        },
        secondary: {
          backgroundColor: props.isActive
            ? props.theme.colors.backgroundTertiary
            : props.theme.colors.backgroundSecondary,
          borderBottom: "1px solid " + props.theme.colors.grey20,
        },
      },
    })}
`;

const StyledIcon = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineSM};
  align-items: center;
`;

interface Props {
  onClick: Function;
  isActive: boolean;
  isNavbarOpen?: boolean;
  linkText: string;
  icon?: ReactElement;
  variant?: string;
}
const SideNavLink = ({
  onClick,
  isActive,
  linkText,
  icon,
  isNavbarOpen = true,
  variant = "primary",
}: Props) => {
  return (
    <StyledSideNavLink
      p={{ md: 3 }}
      onClick={() => onClick()}
      isActive={isActive}
      variant={variant}
    >
      <Row flexWrap="nowrap">
        {icon && <StyledIcon>{icon}</StyledIcon>}
        {isNavbarOpen && <Div>{linkText}</Div>}
      </Row>
    </StyledSideNavLink>
  );
};

export default SideNavLink;
