import React, { useContext, useEffect, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import {
  animated,
  useSpring,
  config,
  useChain,
  useSpringRef,
} from "@react-spring/web";

const HiddenCheckbox = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledSVG = styled.svg`
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  border: 2px solid;
`;

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: any;
  className?: string;
  label?: string;
}

const Checkbox = ({ label, onChange, value, className }: Props) => {
  const themeContext = useContext(ThemeContext);
  const [isChecked, setIsChecked] = useState<boolean>(value);
  const [checkmarkLength, setCheckmarkLength] = useState<number | undefined>(
    undefined
  );
  const checkboxAnimationRef = useSpringRef();
  const checkboxAnimationStyle = useSpring({
    backgroundColor: isChecked
      ? themeContext.colors.primary
      : themeContext.colors.white,
    borderColor: isChecked
      ? themeContext.colors.primary
      : themeContext.colors.grey,
    config: config.gentle,
    ref: checkboxAnimationRef,
  });
  const checkmarkAnimationRef = useSpringRef();
  const checkmarkAnimationStyle = useSpring({
    x: isChecked ? 0 : checkmarkLength,
    config: config.gentle,
    ref: checkmarkAnimationRef,
  });

  const StyledAnimatedSVG = animated(StyledSVG);

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  useChain(
    isChecked
      ? [checkboxAnimationRef, checkmarkAnimationRef]
      : [checkmarkAnimationRef, checkboxAnimationRef],
    [0, 0] // -> Delay in animation by 0.1 seconds
  );

  return (
    <label>
      <HiddenCheckbox
        onChange={(e) => {
          setIsChecked((isChecked) => !isChecked);
          onChange(e);
        }}
        value={value}
        className={className}
        type="checkbox"
      />
      <StyledAnimatedSVG
        style={checkboxAnimationStyle}
        aria-hidden="true"
        viewBox="0 0 15 11"
        fill="none"
        cursor="pointer"
      >
        <animated.path
          d="M1 4.5L5 9L14 1"
          strokeWidth="2"
          stroke={themeContext.colors.white}
          strokeDasharray={checkmarkLength}
          strokeDashoffset={checkmarkAnimationStyle.x}
          ref={(ref) => {
            if (ref) {
              setCheckmarkLength(ref.getTotalLength());
            }
          }}
        />
      </StyledAnimatedSVG>
      {label && label}
    </label>
  );
};
export default Checkbox;
