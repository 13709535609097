import styled from "styled-components";
import Div from "../../baseComponents/Div";
import RentableCard from "../../baseComponents/RentableCard";
import HoorayCoin from "../../../images/HoorayCoin";

const StyledIntro = styled.span`
  font-size: 1.25rem;
`;

const IntroCard = () => {
  return (
    <RentableCard title={"Welcome To Rentable!"}>
      <Div alignItems="center" justifyContent="center">
        <HoorayCoin />
        <StyledIntro>
          When your security deposit is ready it will appear here on the
          dashboard. You can click the "My Account" button in the top header to
          update your personal and payment information. If you need assistance,
          please email us at{" "}
          <a href="mailto:support@rentable.com">support@rentable.com</a>.
        </StyledIntro>
      </Div>
    </RentableCard>
  );
};

export default IntroCard;
