export const FONT_SIZE = {
  headlineXL: "4.5rem",
  headlineLG: "2.5rem",
  headlineMD: "2rem",
  headlineSM: "1.5rem",
  headlineXS: "1.125rem",
  bodyLG: "1rem",
  bodyMD: ".875rem",
  bodySM: ".75rem",
  caption: ".563rem",
};
export const FONT_WEIGHT = {
  normal: "500",
  semibold: "600",
  bolder: "700",
};
