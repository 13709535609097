import Div from "../../baseComponents/Div";
import Button from "../../baseComponents/Button";
import WorkoutCoin from "../../../images/WorkoutCoin";
import styled from "styled-components";

const StyledCard = styled(Div)`
  padding: 2rem;
`;
const StyledExplanation = styled(Div)`
  font-size: 12;
  font-weight: 600;
  margin: 2rem 0rem;
`;

const ExplainVerification = () => {
  return (
    <StyledCard
      width={{ default: 1, lg: 1 / 2 }}
      alignItems="center"
      className="card border"
    >
      <WorkoutCoin />
      <StyledExplanation width={{ default: 1, lg: 3 / 4 }}>
        Due to the amount of your deposit and or last month's rent, we will need
        to verify your account and need a few more pieces of information to do
        so.
      </StyledExplanation>
      <Div
        width={{ default: 1, lg: 1 / 2 }}
        alignItems="center"
        justifyContent="center"
      >
        <Button
          text="Proceed to Verification"
          type="primary"
          onClick={() => {
            window.location.assign("/personal-verification");
          }}
        />
      </Div>
    </StyledCard>
  );
};

export default ExplainVerification;
