import { useState } from "react";
import { useAppDispatch } from "../../../../../store/hooks";
import styled from "styled-components";
import Button from "../../../../../components/baseComponents/Button";
import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";
import SuccessMessage from "../../../../../components/baseComponents/SuccessMessage";
import useCompanyFromParams from "../../../../utils/useCompanyFromParams";
import BeatLoader from "react-spinners/BeatLoader";
import {
  createLocationUserDefinedFields,
  createLocationRentableTrustAccount,
  createLocationRentableDeductionsChargeType,
  syncLocationPropertiesAndUnits,
} from "../../../../utils/rentManagerUtils";
import { updateCompanySection } from "../../../../../store/nucleusCompanies";
import {
  RentManagerLocation,
  GenericObject,
} from "../../../../../components/global/ModelInterfaces";
interface Props {
  location: RentManagerLocation;
}
const StyledLabel = styled.span`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const StyledSectionHeader = styled.span`
  font-weight: ${(props) => props.theme.font_weight.bolder};
  text-decoration: underline;
`;

const StyledLocationBorder = styled(Row)`
  border: ${(props) => props.theme.borders.tertiarySM};
  border-radius: ${(props) => props.theme.border_radius.SM};
`;
const LocationDetail = ({ location }: Props) => {
  const { loadingCompanies, company } = useCompanyFromParams();

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");

  const successCallback = (integrationDetails: GenericObject) => {
    dispatch(
      updateCompanySection(company.uuid, "integration_info", integrationDetails)
    );
    setLoading(false);
  };
  const locationToolFailureCallback = (errorMessage: string) => {
    setError(
      `${errorMessage} Please check rollbar for further details of this error.`
    );
    setLoading(false);
  };

  const handleCreateUserDefinedFields = () => {
    setLoading(true);
    createLocationUserDefinedFields(
      location.uuid,
      successCallback,
      locationToolFailureCallback
    );
  };

  const handleCreateRentableTrustAccount = () => {
    setLoading(true);
    createLocationRentableTrustAccount(
      location.uuid,
      successCallback,
      locationToolFailureCallback
    );
  };

  const handleCreateRentableDeductionsChargeType = () => {
    setLoading(true);
    createLocationRentableDeductionsChargeType(
      location.uuid,
      successCallback,
      locationToolFailureCallback
    );
  };

  const handlePropertiesAndUnitsSync = () => {
    setLoading(true);
    const successCallback = (integrationDetails: GenericObject) => {
      dispatch(
        updateCompanySection(
          company.uuid,
          "integration_info",
          integrationDetails
        )
      );
      setSuccessMessage(
        "Properties and Units are being created asynchronously check your email and the async dashboard for more details."
      );
      setLoading(false);
    };
    syncLocationPropertiesAndUnits(
      location.uuid,
      successCallback,
      locationToolFailureCallback
    );
  };

  return (
    <StyledLocationBorder
      addSpace={false}
      pt={{ default: 3 }}
      pb={{ default: 3 }}
      mb={{ default: 2 }}
    >
      <Div width={{ default: 6 / 12 }}>
        <Row addSpace={false}>
          <StyledSectionHeader>
            {location.name} - ID: {location.location_id}
          </StyledSectionHeader>
        </Row>
        <Row>
          <StyledLabel>Rent Manager Rentable Trust BankID:</StyledLabel>
          {location.rent_manager_rentable_trust_id ||
            "Rentable Trust has not been created or we have not been given the privilege to access it."}
        </Row>
        <Row>
          <StyledLabel>Rentable Trust Record:</StyledLabel>
          {location.rentable_trust_account_id ? (
            <>
              The Rentable Trust account ID in our DB is{" "}
              {location.rentable_trust_account_id}.
            </>
          ) : (
            <span>
              This rentable trust does not exist for this location in our
              database, please use the tool to create one.
            </span>
          )}
        </Row>
        {/* TODO: Remove ChargeTypeID once everyone uses Open Charges for disbursements */}
        <Row>
          <StyledLabel>Rentable Deductions ChargeTypeID:</StyledLabel>
          {location.rentable_deductions_charge_type_id ||
            "'Rentable Deductions' ChargeType has not been created, please use the tool to create one."}
        </Row>
        <Row>
          <StyledLabel>Disbursements Use Open Charges:</StyledLabel>
          {location.disbursements_use_open_charges.toString()}
        </Row>
        <Row>
          <StyledLabel>Rentable User Defined Fields:</StyledLabel>
          {location.rentable_user_defined_fields
            ? "The Rentable User Defined fields exist."
            : "The Rentable User Defined Fields do not exist on this location."}
        </Row>
        <Row>
          <StyledLabel>Properties:</StyledLabel>
          This location has {location.properties_count} properties in our DB.
        </Row>
        <Row>
          <StyledLabel>Units:</StyledLabel>
          This location has {location.units_count} units in our DB.
        </Row>
      </Div>
      <Div width={{ default: 6 / 12 }}>
        <Row addSpace={false} mb={{ default: 3 }}>
          {location.setup_complete ? (
            <SuccessMessage>
              This location setup is complete and is ready to process Invites
              and Disbursements.
            </SuccessMessage>
          ) : (
            <ErrorMessage>
              This location setup is not complete. Please use the tools below to
              finish.
            </ErrorMessage>
          )}
        </Row>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {!location.rent_manager_rentable_trust_id && (
          <Row mb={{ default: 3 }}>
            <ErrorMessage>
              Rentable Trust has not been created for this location or we do not
              have access to it, please check in with this company's account
              manager in Rent Manager to create one.
            </ErrorMessage>
          </Row>
        )}
        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
        {loading ? (
          <Row justifyContent="center">
            <BeatLoader />
          </Row>
        ) : (
          <>
            {!location.rentable_user_defined_fields && (
              <Row mb={{ default: 3 }}>
                <Button
                  text="Create User Defined Fields"
                  onClick={handleCreateUserDefinedFields}
                />
              </Row>
            )}
            {!location.rentable_trust_account_id && (
              <Row mb={{ default: 3 }}>
                <Button
                  text="Create Rentable Trust Account"
                  onClick={handleCreateRentableTrustAccount}
                />
              </Row>
            )}
            {/* TODO Remove tool to create DeductionsChargeType once everyone uses open charges for disbursements. */}
            {!location.rentable_deductions_charge_type_id && (
              <Row mb={{ default: 3 }}>
                <Button
                  text="Create Rentable Deductions ChargeType"
                  onClick={handleCreateRentableDeductionsChargeType}
                />
              </Row>
            )}
            <Row mb={{ default: 3 }}>
              <Button
                text="Sync Properties and Units"
                onClick={handlePropertiesAndUnitsSync}
              />
            </Row>
          </>
        )}
      </Div>
    </StyledLocationBorder>
  );
};

export default LocationDetail;
