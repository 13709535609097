import styled, { css } from "styled-components";
import { useState } from "react";
import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import Checkbox from "../../../../../components/baseComponents/Checkbox";
import Button from "../../../../../components/baseComponents/Button";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";
import SuccessMessage from "../../../../../components/baseComponents/SuccessMessage";
import InputFilters from "../../../../../components/baseComponents/InputFilters";
import BeatLoader from "react-spinners/BeatLoader";
import { GenericObject } from "../../../../../components/global/ModelInterfaces";
import { getSecurityDepositsHeld } from "../../../../utils/companyUtils";
import { find, some } from "lodash";

const TableStyle = css`
  border: 1px solid ${(props) => props.theme.colors.black};
  border-collapse: collapse;
  padding-left: 10px;
  font-size: ${(props) => props.theme.font_size.bodyMD};
`;

const StyledTableRow = styled.tr`
  table-layout: fixed;
`;

const StyledTableHeader = styled.th`
  ${TableStyle}
`;
const StyledTableSection = styled.td`
  ${TableStyle}
`;

interface Props {
  rentManagerCompany: GenericObject;
}
const SecurityDepositsHeld = ({ rentManagerCompany }: Props) => {
  const locations = rentManagerCompany.integration_info.locations;
  // combine location and property values into one array
  const setUpOptionList = () => {
    const tempProperties: Array<GenericObject> = [];
    locations.map((location: GenericObject) => {
      location.properties.map((property: GenericObject) => {
        tempProperties.push({
          location_name: property.location_name,
          location_id: String(property.location_id),
          property_name: property.name,
          property_uuid: String(property.uuid),
          property_id: String(property.rent_manager_property_id),
        });
      });
    });
    return tempProperties;
  };

  const originalList = setUpOptionList();
  const [optionList, setOptionList] =
    useState<Array<GenericObject>>(originalList);
  const [selectedOptions, setSelectedOptions] = useState<Array<GenericObject>>(
    []
  );
  const [loading, setLoading] = useState<Boolean>(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const toggleCheck = (locationID: String, propertyUUID: String) => {
    const selectedRow = find(selectedOptions, (row) => {
      return (
        row.location_id === locationID && row.property_uuid === propertyUUID
      );
    });
    // if row is in array, remove it
    if (selectedRow) {
      setSelectedOptions(
        selectedOptions.filter(
          (row) =>
            row.location_id !== selectedRow.location_id ||
            row.property_uuid !== selectedRow.property_uuid
        )
      );
      // if row is not in array, add it
    } else {
      setSelectedOptions([
        ...selectedOptions,
        {
          location_id: locationID,
          property_uuid: propertyUUID,
        },
      ]);
    }
  };

  const handleSelectAll = (checked: Boolean) => {
    // if select all is checked, copy over all property info to a new array and set selectedOptions to the new array
    if (checked) {
      let allRows: Array<GenericObject> = [];
      optionList.map((row: GenericObject) => {
        allRows.push({
          location_id: row.location_id,
          property_uuid: row.property_uuid,
        });
      });
      setSelectedOptions(allRows);
      // if select all is unchecked, clear selectedOptions
    } else {
      setSelectedOptions([]);
    }
  };

  const handleSubmit = () => {
    if (selectedOptions.length === 0) {
      setErrorMessage(
        "At least one row must be selected to get tenant deposits."
      );
      return;
    }
    const requestInfo = {
      company_uuid:
        rentManagerCompany.integration_info.rent_manager_company_uuid,
      properties: selectedOptions,
    };
    const successCallback = (data: GenericObject) => {
      setSuccessMessage(data.message);
      setLoading(false);
    };
    const failureCallback = (err: string) => {
      setErrorMessage(err);
      setLoading(false);
    };
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");
    getSecurityDepositsHeld(requestInfo, successCallback, failureCallback);
  };

  return (
    <Div mt={{ lg: 3 }}>
      <Row justifyContent="space-between" alignItems="center">
        <Div>
          <Row>
            <Checkbox
              value={false}
              onChange={(e) => handleSelectAll(e.target.checked)}
            />
            Select All
          </Row>
        </Div>
        <Div mb={{ lg: 2 }}>
          <InputFilters
            list={originalList}
            filters={["Property ID", "Property Name"]}
            onFilterUpdate={(filteredResults) => setOptionList(filteredResults)}
            onFilterClear={() => setOptionList(originalList)}
          />
        </Div>
      </Row>
      {successMessage && (
        <Row justifyContent="center" mb={{ lg: 1 }}>
          <SuccessMessage>{successMessage}</SuccessMessage>
        </Row>
      )}
      {errorMessage && (
        <Row justifyContent="center" mb={{ lg: 1 }}>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Row>
      )}
      <table>
        <StyledTableRow>
          <StyledTableHeader>Select</StyledTableHeader>
          <StyledTableHeader>Property Name</StyledTableHeader>
          <StyledTableHeader>Property ID</StyledTableHeader>
          <StyledTableHeader>Location Name</StyledTableHeader>
          <StyledTableHeader>Location ID</StyledTableHeader>
        </StyledTableRow>
        {optionList.length > 0 ? (
          optionList.map((row: GenericObject) => {
            return (
              <StyledTableRow>
                <StyledTableSection>
                  <Checkbox
                    // checkbox value set to true/false based on whether location & property values exist in selectedOptions
                    value={some(selectedOptions, {
                      location_id: row.location_id,
                      property_uuid: row.property_uuid,
                    })}
                    onChange={() =>
                      toggleCheck(row.location_id, row.property_uuid)
                    }
                  />
                </StyledTableSection>
                <StyledTableSection>{row.property_name}</StyledTableSection>
                <StyledTableSection>{row.property_id}</StyledTableSection>
                <StyledTableSection>{row.location_name}</StyledTableSection>
                <StyledTableSection>{row.location_id}</StyledTableSection>
              </StyledTableRow>
            );
          })
        ) : (
          <Div>No results to display after applying filters.</Div>
        )}
      </table>

      <Row justifyContent="center" mt={{ lg: 2 }}>
        {loading ? (
          <BeatLoader />
        ) : (
          <Div width={{ lg: 4 / 12 }}>
            <Button onClick={handleSubmit} text="Get Tenant Deposits" />
          </Div>
        )}
      </Row>
    </Div>
  );
};
export default SecurityDepositsHeld;
