import { Fee } from "../../global/ModelInterfaces";
import styled from "styled-components";
import { currencyFormatter } from "../../global/utils";

const StyledTableData = styled.th`
  padding-left: 2em;
  padding-right: 2em;
  font-weight: ${(props) => props.theme.font_weight.normal};
`;
interface Props {
  fee: Fee;
}
const FeeDetails = ({ fee }: Props) => {
  return (
    <tr>
      <StyledTableData>{fee.type}</StyledTableData>
      <StyledTableData>{currencyFormatter.format(fee.amount)}</StyledTableData>
      {fee.description && <StyledTableData>{fee.description}</StyledTableData>}
    </tr>
  );
};
export default FeeDetails;
