import styled from "styled-components";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import DropdownCard from "../../../components/baseComponents/DropdownCard";
import InviteLandlordForm from "../Forms/InviteLandlordForm";
import ButtonToForm from "../ButtonToForm";
import { Landlord } from "../../../components/global/ModelInterfaces";
interface Props {
  landlords: Landlord[];
}

const StyledLabel = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.bolder};
  font-size: ${(props) => props.theme.font_size.headlineSM};
`;

const CompanyLandlord = ({ landlords }: Props) => {
  return (
    <DropdownCard title="Current Landlords Info" initiallyOpen={false}>
      <Row>
        <Div width={{ default: 1, md: 6 / 12 }}>
          <StyledLabel>Current Landlords:</StyledLabel>
          <Row>
            {landlords.map((landlord: Landlord) => (
              <Row>{landlord.full_name}</Row>
            ))}
          </Row>
        </Div>
        <Div width={{ default: 1, md: 6 / 12 }}>
          <ButtonToForm
            formTitle={"Enter the Admin or Landlord Information"}
            buttonText={"Invite Admin or Landlord"}
          >
            <InviteLandlordForm />
          </ButtonToForm>
        </Div>
      </Row>
    </DropdownCard>
  );
};

export default CompanyLandlord;
