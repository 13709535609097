import { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import styled from "styled-components";

import Container from "../baseComponents/Container";
import BackButton from "../baseComponents/BackButton";
import Div from "../baseComponents/Div";
import Row from "../baseComponents/Row";
import DateInput from "../baseComponents/DateInput";
import PageLoader from "../PageLoader";
import Button from "../baseComponents/Button";
import Checkbox from "../baseComponents/Checkbox";
import ConfirmationModal from "../baseComponents/ConfirmationModal";
import ErrorMessage from "../baseComponents/ErrorMessage";
import useGetPrepayment from "../../utils/useGetPrepayment";
import prepaymentStatuses from "../../enums/prepaymentStatuses";
import { updatePrepayment } from "../../store/prepayments";
import { updateDepositDates } from "../../utils/depositUtils";
import { dateShortFormatter } from "../global/utils";
import { getStreetAddress } from "../../utils/globalUtils";
import { cssBreakpoints } from "../global/theme";
import { Prepayment } from "../global/ModelInterfaces";

const StyledContainer = styled(Div)`
  border: ${(props) => `1px solid ${props.theme.colors.black}`};
  padding: 2rem;
  border-radius: 0.5rem;
  ${cssBreakpoints("margin-bottom", [{ sm: "2rem" }])}
`;
const StyledTitle = styled(Row)`
  ${cssBreakpoints("margin-bottom", [{ sm: "2rem" }])}
`;

const StyledDetails = styled(Row)`
  margin-bottom: 2rem;
`;

const StyledLabel = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const MonthToMonthText = styled(Row)`
  font-size: ${(props) => props.theme.font_size.bodySM};
  color: ${(props) => props.theme.colors.grey60};
  margin-top: 0.5rem;
`;

const StyledButton = styled(Button)`
  ${cssBreakpoints("margin-top", [{ sm: "2rem" }])}
`;

const UpdatePrepaymentDatesView = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { selectedPrepayment, loadingPrepayments } = useGetPrepayment();
  const [loading, setLoading] = useState<boolean>(false);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [confirmDisabled, setConfirmDisabled] = useState<boolean>(true);
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const [newDepositStartDate, setNewDepositStartDate] = useState<string | null>(
    null
  );
  const [newDepositEndDate, setNewDepositEndDate] = useState<string | null>(
    null
  );
  const [monthToMonth, setMonthToMonth] = useState<boolean>(
    !selectedPrepayment?.end_date
  );

  const handleUpdatePrepaymentDates = () => {
    setLoading(true);
    const successCallback = (updatedPrepayment: Prepayment) => {
      dispatch(updatePrepayment(updatedPrepayment));
      history.push(`/dashboard/tenant/${updatedPrepayment.uuid}`);
      setLoading(false);
    };
    const errorCallBack = (errorMessage: string) => {
      setError(errorMessage);
      setDisplayError(true);
      setLoading(false);
    };
    updateDepositDates(
      selectedPrepayment?.uuid,
      newDepositStartDate,
      newDepositEndDate,
      monthToMonth,
      successCallback,
      errorCallBack
    );
    return;
  };

  const validateDates = () => {
    const startDate = newDepositStartDate
      ? newDepositStartDate
      : selectedPrepayment?.start_date;

    const endDate = newDepositEndDate
      ? newDepositEndDate
      : selectedPrepayment?.end_date;
    if (startDate && monthToMonth) {
      return true;
    } else if (startDate && endDate) {
      return !(startDate > endDate);
    } else return false;
  };

  const checkValidation = () => {
    setError("");
    if (
      !newDepositStartDate &&
      !newDepositEndDate &&
      ((monthToMonth && !selectedPrepayment?.end_date) ||
        (!monthToMonth && selectedPrepayment?.end_date))
    ) {
      setError("Please make a change before submitting.");
      return false;
    }

    if (!validateDates()) {
      setError("Invalid dates: start date must be before end date.");
      return false;
    }

    if (!monthToMonth && !newDepositEndDate && !selectedPrepayment?.end_date) {
      setError("Please enter a new end date.");
      return false;
    }

    return true;
  };

  useEffect(() => {
    const valid = checkValidation();
    setConfirmDisabled(!valid);
    setDisplayError(false);
  }, [newDepositStartDate, newDepositEndDate, monthToMonth]);

  const renderConfirmationText = () => {
    let confirmationText = ``;
    if (newDepositStartDate) {
      confirmationText += `Are you sure you would like to update the lease start date \n from: ${selectedPrepayment?.start_date} \n to: ${newDepositStartDate}? \n\n`;
    }
    if (newDepositEndDate && !selectedPrepayment?.end_date) {
      confirmationText += `Are you sure you would like to update the lease end date \n from: Month to month \n to: ${newDepositEndDate}?`;
    } else if (newDepositEndDate && !monthToMonth) {
      confirmationText += `Are you sure you would like to update the lease end date \n from: ${selectedPrepayment?.end_date} \n to: ${newDepositEndDate}?`;
    } else if (
      selectedPrepayment?.end_date &&
      !newDepositEndDate &&
      monthToMonth
    ) {
      confirmationText +=
        "Are you sure you would like to remove this lease's end date and make it month to month?";
    }
    return confirmationText;
  };

  if (!selectedPrepayment) {
    return <Redirect to="/dashboard" />;
  } else if (loadingPrepayments) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  } else {
    return (
      <>
        <Container>
          <BackButton />
          <Row justifyContent="center">
            <StyledContainer width={{ sm: 1, md: 1, lg: 6 / 12 }}>
              <StyledTitle>
                <Div width={{ default: 1 }} alignItems={"center"}>
                  <h2>Update Lease Date(s)</h2>
                </Div>
              </StyledTitle>
              <StyledDetails>
                <Row justifyContent="space-between">
                  <StyledLabel>Tenant Name:</StyledLabel>
                  <Div>{selectedPrepayment.tenant_name}</Div>
                </Row>
                <Row justifyContent="space-between">
                  <StyledLabel>Address:</StyledLabel>
                  <Div>
                    {getStreetAddress(
                      selectedPrepayment.address_1,
                      selectedPrepayment.address_2
                    )}
                  </Div>
                </Row>
                <Row justifyContent="space-between">
                  <StyledLabel>Current Start Date:</StyledLabel>
                  <Div>{dateShortFormatter(selectedPrepayment.start_date)}</Div>
                </Row>
                <Row justifyContent="space-between">
                  <StyledLabel>Current End Date:</StyledLabel>
                  <Div>
                    {selectedPrepayment.end_date ? (
                      <>{dateShortFormatter(selectedPrepayment.end_date)}</>
                    ) : (
                      <>This lease has no end date.</>
                    )}
                  </Div>
                </Row>
              </StyledDetails>
              <Row justifyContent="center">
                {prepaymentStatuses.PENDING ===
                  selectedPrepayment?.status_id && (
                  <Div width={{ sm: 1, md: 1 / 2, lg: 1 / 2 }}>
                    <DateInput
                      label={"New Start Date"}
                      onChange={(date: string) => {
                        setNewDepositStartDate(date);
                      }}
                    />
                  </Div>
                )}
                <Div
                  width={{ sm: 1, md: 1 / 2, lg: 1 / 2 }}
                  justifyContent="center"
                >
                  {!monthToMonth && (
                    <DateInput
                      label={"New End Date"}
                      onChange={(date: string) => {
                        setNewDepositEndDate(date);
                      }}
                    />
                  )}
                  <MonthToMonthText
                    alignItems="center"
                    justifyContent="flex-end"
                    flexWrap="nowrap"
                  >
                    <Div>Lease has no End Date and is month to month.</Div>
                    <Checkbox
                      value={monthToMonth}
                      onChange={() => {
                        setMonthToMonth((monthToMonth) => !monthToMonth);
                        setNewDepositEndDate(null);
                      }}
                    />
                  </MonthToMonthText>
                </Div>
                <Row></Row>
                <Row justifyContent="center">
                  <Div width={{ default: 1, lg: 10 / 12 }}>
                    <ErrorMessage>{displayError && error}</ErrorMessage>
                    <StyledButton
                      type="primary"
                      disabled={confirmDisabled}
                      text={`Update Lease Dates`}
                      onClick={() => setConfirmationOpen(true)}
                      onDisabledClick={() => setDisplayError(true)}
                      loading={loading}
                    />
                  </Div>
                </Row>
              </Row>
            </StyledContainer>
          </Row>
        </Container>
        <ConfirmationModal
          confirmationOpen={confirmationOpen}
          message={renderConfirmationText()}
          onClose={() => setConfirmationOpen(false)}
          onConfirm={handleUpdatePrepaymentDates}
        />
      </>
    );
  }
};

export default UpdatePrepaymentDatesView;
