import { useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../global/colors";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { RootState } from "../../..";
import Row from "../../baseComponents/Row";
import Div from "../../baseComponents/Div";
import Button from "../../baseComponents/Button";
import Deductions from ".././Disbursement/Deductions";
import BankAccountSelect from "../../BankAccountSelect";
import {
  getTotalDeductionAmount,
  calculateRefundAmount,
} from "../../../utils/disbursementUtils";
import {
  Prepayment,
  Deposit,
  Deduction,
  GenericObject,
  BankAccount,
} from "../../global/ModelInterfaces";
import { cssBreakpoints } from "../../global/theme";
import { getStreetAddress } from "../../../utils/globalUtils";
import { currencyFormatter } from "../../global/utils";

const StyledTitle = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.bolder};
  font-size: ${(props) => props.theme.font_size.headlineLG};
  color: ${COLORS.grey};
  line-height: ${(props) => props.theme.space[5]};
`;

const StyledDivider = styled(Div)`
  height: 1px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.16);
`;

const StyledSubTitle = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.bolder};
  font-size: ${(props) => props.theme.font_size.headlineSM};
  color: ${COLORS.grey};
  line-height: ${(props) => props.theme.space[4]};
`;

const StyledBankText = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineXS};
  color: ${COLORS.grey60};
  line-height: ${(props) => props.theme.space[4]};
`;

const StyledRemainingFunds = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineXS};
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;
interface SummaryTextProps {
  color: string;
}

const StyledSummaryText = styled(Div)<SummaryTextProps>`
  font-weight: ${(props) => props.theme.font_weight.bolder};
  color: ${(props) => props.color};
`;

interface Props {
  deductions: Deduction[];
  totalDeductionAmount: number;
  updateDeductions: Function;
  releaseBank: BankAccount | undefined;
  updateReleaseBank: Function;
  proceedToConfirmation: Function;
}

const DisbursementSetup = ({
  deductions,
  totalDeductionAmount,
  updateDeductions,
  updateReleaseBank,
  releaseBank,
  proceedToConfirmation,
}: Props) => {
  const [bankError, setBankError] = useState("");
  const { prepaymentUUID } = useParams<GenericObject>();
  const depositsSlice: { loading: Boolean; objects: Prepayment[] } =
    useAppSelector((state: RootState) => state.prepayments);

  const selectedPrepayment = depositsSlice.objects.find(
    (prepayment: Prepayment) => prepayment.uuid === prepaymentUUID
  );

  const validateDeductions = () => {
    return (
      selectedPrepayment?.deposits.security_deposit &&
      selectedPrepayment.deposits.security_deposit.amount >=
        totalDeductionAmount
    );
  };

  const validateDisbursement = () => {
    if (totalDeductionAmount === 0) {
      return true;
    } else return !!(releaseBank && validateDeductions());
  };

  const moveToConfirmation = () => {
    if (validateDisbursement()) {
      proceedToConfirmation();
    } else {
      if (totalDeductionAmount > 0 && !releaseBank) {
        setBankError(
          "Please select as bank account so that you may receive your portion of the funds."
        );
      }
    }
  };

  return (
    <Row justifyContent="center">
      <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
        <StyledTitle mb={{ default: "0.5rem", lg: "0.5rem" }}>
          Close Deposit Account
        </StyledTitle>
        <StyledSubTitle mb={{ default: "1.5rem", lg: "1.5rem" }}>
          Address:{" "}
          {getStreetAddress(
            selectedPrepayment?.address_1,
            selectedPrepayment?.address_2
          )}
        </StyledSubTitle>
        <Row
          mb={{ default: "1.5rem", lg: "1.5rem" }}
          justifyContent="space-between"
        >
          <Div>Deposit Amount:</Div>
          <StyledSummaryText color={COLORS.grey}>
            ${selectedPrepayment?.deposits.security_deposit?.amount}
          </StyledSummaryText>
        </Row>
        <StyledDivider mb={{ default: "1.5rem", lg: "1.5rem" }} />
        <Deductions
          deductions={deductions}
          updateDeductions={updateDeductions}
        />

        <StyledDivider />

        {/* TODO Implement new drop down here */}
        {!(getTotalDeductionAmount(deductions) === 0) && (
          <>
            <StyledBankText mb={{ default: "1.5rem", lg: "1.5rem" }}>
              Please choose which bank account you would like to return your
              portion of the funds to.
            </StyledBankText>
            <BankAccountSelect
              disabled={false}
              onSelectAccount={(e: any) => {
                updateReleaseBank(e);
                setBankError("");
              }}
              defaultValue={releaseBank}
            />
            <StyledSummaryText color={COLORS.danger}>
              {bankError}
            </StyledSummaryText>
            <StyledDivider />
          </>
        )}

        <Div>
          {calculateRefundAmount(
            selectedPrepayment?.deposits.security_deposit,
            deductions
          ) > 0 && (
            <StyledRemainingFunds pb={{ default: "0.5rem", lg: "0.5rem" }}>
              The remaining funds will be sent to{" "}
              {selectedPrepayment?.tenant_name}.
            </StyledRemainingFunds>
          )}
          <Row
            mb={{ default: "1.5rem", lg: "1.5rem" }}
            justifyContent="space-between"
          >
            <Div>Deposit Amount:</Div>
            <StyledSummaryText color={COLORS.grey}>
              {currencyFormatter.format(
                selectedPrepayment?.deposits.security_deposit?.amount
              )}
            </StyledSummaryText>
          </Row>
          <Row
            mb={{ default: "1.5rem", lg: "1.5rem" }}
            justifyContent="space-between"
          >
            <Div>Deduction Amount:</Div>
            <StyledSummaryText color={COLORS.danger}>
              {currencyFormatter.format(getTotalDeductionAmount(deductions))}
            </StyledSummaryText>
          </Row>
          {!validateDeductions() && (
            <Row
              mb={{ default: "1.5rem", lg: "1.5rem" }}
              justifyContent="center"
            >
              <StyledSummaryText color={COLORS.danger}>
                {
                  "Please check your deductions, their totals must be less than the deposit amount."
                }
              </StyledSummaryText>
            </Row>
          )}
          <Row
            mb={{ default: "1.5rem", lg: "1.5rem" }}
            justifyContent="space-between"
          >
            <StyledSubTitle mb={{ default: "1.5rem", lg: "1.5rem" }}>
              Refund Amount:
            </StyledSubTitle>
            <StyledSubTitle mb={{ default: "1.5rem", lg: "1.5rem" }}>
              {currencyFormatter.format(
                Math.max(
                  calculateRefundAmount(
                    selectedPrepayment?.deposits.security_deposit,
                    deductions
                  ),
                  0
                )
              )}
            </StyledSubTitle>
          </Row>
          <Div mb={{ default: "2rem", lg: "2rem" }}>
            <Button
              disabled={!validateDisbursement()}
              type="primary"
              text={`Proceed and review details.`}
              onClick={moveToConfirmation}
            />
          </Div>
        </Div>
      </Div>
    </Row>
  );
};

export default DisbursementSetup;
