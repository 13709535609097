import styled from "styled-components";

const StyledMinus = styled.svg`
  fill: ${(props) => props.theme.colors.grey15};
`;

interface Props {
  width?: string;
  height?: string;
}

const Minus = ({ width = "15", height = "15" }: Props) => {
  return (
    <StyledMinus
      width={width}
      height={height}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m35,30 h-35 v25 h35 h30 h35 v-25 h-35 z" />
    </StyledMinus>
  );
};

export default Minus;
