import { useEffect, useState } from "react";
import Div from "./Div";
import DownCaret from "../../images/DownCaret";
import UpCaret from "../../images/UpCaret";

interface Props {
  open: boolean;
  style?: string;
}

const DropdownCaret = ({ open }: Props) => {
  const [openCaret, setOpenCaret] = useState(false);

  useEffect(() => {
    setOpenCaret(open);
  });

  return <Div>{openCaret ? <UpCaret /> : <DownCaret />}</Div>;
};

export default DropdownCaret;
