import React from "react";
import styled from "styled-components";
import Div from "./Div";

const StyledWarning = styled(Div)`
  color: ${(props) => props.theme.colors.warning};
  font-weight: 600;
`;

const WarningMessage = ({ ...props }) => {
  return <StyledWarning {...props} />;
};

export default WarningMessage;
