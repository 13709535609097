import styled from "styled-components";
import { cssBreakpoints } from "../components/global/theme";

import Div from "../components/baseComponents/Div";
import SignUpForm from "../components/UnauthComponents/SignUpForm";

const StyledContainer = styled(Div)`
  border-radius: 0.75rem;
  ${cssBreakpoints("border", [
    { sm: "none" },
    { md: "1px solid #cfcfcf" },
    { lg: "1px solid #cfcfcf" },
  ])}
  ${cssBreakpoints("padding", [
    { sm: "1rem" },
    { md: "1.5rem" },
    { lg: "3rem" },
  ])}
  ${cssBreakpoints("margin-top", [
    { sm: "1rem" },
    { md: "1.5rem" },
    { lg: "1.5rem" },
  ])}
`;

const StyledTitle = styled.h5`
  font-size: ${(props) => props.theme.font_size.headlineMD};
  font-weight: ${(props) => props.theme.font_weight.bolder};
  margin-bottom: 2rem;
`;
const StyledDescription = styled(Div)`
  font-size: ${(props) => props.theme.font_size.bodyLG};
  text-align: center;
  margin-bottom: 2rem;
`;

const LoginPage = () => {
  return (
    <Div width={{ sm: 1, md: 1, lg: 1 }} alignItems="center">
      <StyledContainer width={{ sm: 1, md: 1, lg: 6 / 12 }} alignItems="center">
        <StyledTitle>Welcome Home!</StyledTitle>
        <StyledDescription width={{ sm: 1, md: 1, lg: 9 / 12 }}>
          Rentable is a move in platform that allows property managers,
          landlords, and tenants to save time and money by providing a simple,
          self-guided way to handle security deposits.
        </StyledDescription>
        <SignUpForm />
      </StyledContainer>
    </Div>
  );
};

export default LoginPage;
