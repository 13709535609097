import { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { head } from "lodash";
import {
  getPaymentData,
  confirmPaymentIntent,
  createPaymentIntent,
} from "../utils/depositUtils";
import { currencyFormatter } from "../components/global/utils";
import { getUserCreditCards, removeCreditCard } from "../utils/accountUtils";
import Div from "../components/baseComponents/Div";
import Row from "../components/baseComponents/Row";
import PageLoader from "../components/PageLoader";
import Button from "../components/baseComponents/Button";
import ErrorMessage from "../components/baseComponents/ErrorMessage";
import RentableCard from "../components/baseComponents/RentableCard";
import BackButton from "../components/baseComponents/BackButton";
import { Redirect, useParams } from "react-router-dom";
import {
  GenericObject,
  CreditCard,
} from "../components/global/ModelInterfaces";
import SaveCardForm from "../components/TenantComponents/SaveCardForm";
import { updateTenantCreditCards } from "../store/tenantInfo";
import { useAppDispatch, useAppSelector } from "../store/hooks";

const StyledHeader = styled.h3`
  padding: 1rem;
`;

const EmphasizedText = styled.strong`
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const PayWithCardPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { prepaymentUUID } = useParams<GenericObject>();
  const [loading, setLoading] = useState(true);
  const [loadingCard, setLoadingCard] = useState(false);
  const [paymentData, setPaymentData] = useState<GenericObject | null>(null);
  const [paymentIntent, setPaymentIntent] = useState<string | null>(null);
  const [cardSaved, setCardSaved] = useState(false);
  const [funding, setFunding] = useState(false);
  const [error, setError] = useState("");

  const creditCard: CreditCard | undefined = useAppSelector((state) => {
    return head(state.tenantInfo.objects.personalInfo?.credit_cards);
  });

  const fundDeposits = () => {
    setFunding(true);
    const successCallback = (data: GenericObject) => {
      // Give some time for the webhook to hit
      setTimeout(() => {
        setFunding(false);
        window.location.href = "/dashboard";
      }, 5000);
    };

    const failureCallback = (error: GenericObject) => {
      setError(error.data);
      setFunding(false);
    };

    confirmPaymentIntent(
      paymentIntent,
      prepaymentUUID,
      successCallback,
      failureCallback
    );
  };

  useEffect(() => {
    getCreditCardData();
  }, []);

  useEffect(() => {
    getBestDataFromBackend();
  }, [cardSaved]);

  const getBestDataFromBackend = () => {
    setLoadingCard(true);
    if (!cardSaved) {
      const successCallback = (data: GenericObject) => {
        setPaymentData(data.payment_data);
        setLoading(false);
        setLoadingCard(false);
      };
      const failureCallback = (error: any) => {
        setError(error.data);
        setLoading(false);
        setLoadingCard(false);
      };
      getPaymentData(prepaymentUUID, successCallback, failureCallback);
    } else {
      const successCallback = (data: GenericObject) => {
        setPaymentData(data.payment_data);
        setPaymentIntent(data.payment_intent);
        setLoading(false);
        setLoadingCard(false);
      };
      const failureCallback = (error: any) => {
        if (!!error.data) {
          setError(error.data);
        } else {
          setError(error);
        }
        setLoading(false);
        setLoadingCard(false);
      };
      createPaymentIntent(prepaymentUUID, successCallback, failureCallback);
    }
  };

  const getCreditCardData = () => {
    const successCallback = (data: GenericObject) => {
      dispatch(updateTenantCreditCards(data));
      setCardSaved(!!head(data.credit_cards));
    };
    const failureCallback = (error: any) => {
      setError(error.data);
    };
    getUserCreditCards(successCallback, failureCallback);
  };

  const handleRemovePaymentCard = () => {
    if (!creditCard) {
      setCardSaved(false);
    } else {
      const successCallback = (data: GenericObject) => {
        dispatch(updateTenantCreditCards(data));
        setCardSaved(!!head(data.credit_cards));
      };
      const failureCallback = () => {
        setError(
          "There was an error with our credit card processor, please refresh and try again."
        );
      };
      removeCreditCard(creditCard.uuid, successCallback, failureCallback);
    }
  };

  if (loading) {
    return <PageLoader />;
  } else if (!paymentData) {
    return <Redirect to={"/dashboard"} />;
  } else {
    return (
      <Div width={{ default: 1, lg: 4 / 12 }}>
        <RentableCard>
          <>
            {cardSaved && (
              <Row mb={{ default: 5 }}>
                <BackButton
                  text="Link a Different Card"
                  onClick={handleRemovePaymentCard}
                />
              </Row>
            )}
            <StyledHeader>Paying with a Credit Card</StyledHeader>
            <Div p={{ default: 2 }}>
              If you don't have a US bank account, you can still pay for your
              deposit using a credit card. Submit your payment via our secure
              form below. At the end of your lease, your deposit will be
              returned to this payment method.
            </Div>
            {loadingCard ? (
              <Row justifyContent="center">
                <PageLoader />
              </Row>
            ) : (
              <>
                <Row>
                  <Div mt={{ default: 2 }} width={{ default: 1 }}>
                    <EmphasizedText>
                      Due Today:{!cardSaved && "*"}
                    </EmphasizedText>
                  </Div>
                </Row>
                {paymentData.line_items &&
                  paymentData.line_items.map((lineItem: GenericObject) => {
                    return (
                      <Row>
                        <Div width={{ default: 1, lg: 4 / 12 }}>
                          {lineItem.price_data.product_data.name}:
                        </Div>
                        <Div width={"fill"}>
                          {currencyFormatter.format(
                            lineItem.price_data.unit_amount / 100
                          )}
                        </Div>
                      </Row>
                    );
                  })}

                <Row>
                  <Div mb={{ default: 4 }} width={{ default: 1, lg: 4 / 12 }}>
                    <EmphasizedText>Total:</EmphasizedText>
                  </Div>
                  <Div width={"fill"}>
                    <EmphasizedText>
                      {currencyFormatter.format(paymentData.total)}
                    </EmphasizedText>
                  </Div>
                </Row>
                {cardSaved ? (
                  <>
                    <Row>
                      <Div>
                        The amount above will be charged to your card ending in{" "}
                        {creditCard?.last_four}
                      </Div>
                    </Row>
                    <Row mt={{ default: 2 }}>
                      <Div width={{ default: 12 / 12 }} alignItems="center">
                        {error ? (
                          <ErrorMessage>{error}</ErrorMessage>
                        ) : (
                          <>
                            <Button
                              onClick={fundDeposits}
                              text={"Fund Deposits"}
                              loading={funding}
                              disabled={funding}
                            />
                          </>
                        )}
                      </Div>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                      <SaveCardForm
                        onSave={() => {
                          setLoadingCard(true);
                          setTimeout(() => {
                            getCreditCardData();
                          }, 3000);
                        }}
                        buttonText={"Verify Card"}
                      />
                    </Row>
                    <Row>
                      <span>
                        *Fees are estimates. A more accurate statement will be
                        presented before payment
                      </span>
                    </Row>
                  </>
                )}
              </>
            )}
          </>
        </RentableCard>
      </Div>
    );
  }
};

export default PayWithCardPage;
