import { useState, useEffect } from "react";
import { GenericObject } from "../../../components/global/ModelInterfaces";
import { dateShortFormatter } from "../../../components/global/utils";
import { useAppDispatch } from "../../../store/hooks";
import styled from "styled-components";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import DropdownCard from "../../../components/baseComponents/DropdownCard";
import UpdatePrepaymentDatesForm from "./UpdatePrepaymentDatesForm";
import MovePrepaymentToNewUnitForm from "./MovePrepaymentToNewUnitForm";
import ErrorMessage from "../../../components/baseComponents/ErrorMessage";
import Button from "../../../components/baseComponents/Button";
import ButtonToForm from "../ButtonToForm";
import { unrestrictUser } from "../../utils/prepaymentUtils";
import { updatePrepaymentSection } from "../../../store/nucleusPrepayments";
import { nucleusUserPermissions } from "../../../enums/nucleusPermissions";
import useNucleusPermissions from "../../../utils/useNucleusPermissions";
interface Props {
  generalInfo: GenericObject;
}

const StyledLabel = styled.span`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const GeneralInfo = ({ generalInfo }: Props) => {
  const dispatch = useAppDispatch();
  const [hasEditPermissions, setHasEditPermissions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { loadingPermissions, prepaymentOverviewPermission } =
    useNucleusPermissions();

  const handleUnrestrictUser = () => {
    setLoading(true);
    if (!generalInfo.prepayment_uuid) {
      setError("Please refresh the page.");
    }
    const successCallback = (res: GenericObject) => {
      dispatch(
        updatePrepaymentSection(
          generalInfo.prepayment_uuid,
          "general_info",
          res.general_info
        )
      );
      setLoading(false);
    };
    const failureCallback = (error: string) => {
      setError(`There was an issue unrestricting this user. ${error}`);
      setLoading(false);
    };
    unrestrictUser(
      generalInfo.prepayment_uuid,
      successCallback,
      failureCallback
    );
  };

  useEffect(() => {
    if (!loadingPermissions) {
      setHasEditPermissions(
        prepaymentOverviewPermission === nucleusUserPermissions.WRITE
      );
    }
  }, [prepaymentOverviewPermission]);

  return (
    <DropdownCard title="General Info">
      <Row>
        <Div width={{ md: 6 / 12, lg: 8 / 12 }}>
          <Row>
            <StyledLabel>Address:</StyledLabel>
            {generalInfo.address}
          </Row>
          <Row>
            <StyledLabel>Company:</StyledLabel>
            {generalInfo.company}
          </Row>
          <Row>
            <StyledLabel>Date of Birth:</StyledLabel>
            {dateShortFormatter(generalInfo.date_of_birth)}
          </Row>
          <Row>
            <StyledLabel>Email:</StyledLabel>
            {generalInfo.email}
          </Row>
          <Row>
            <StyledLabel>Invite Accepted:</StyledLabel>
            {generalInfo.invite_accepted}
          </Row>
          <Row>
            <StyledLabel>Lease Start Date:</StyledLabel>
            {dateShortFormatter(generalInfo.lease_start_date)}
          </Row>
          <Row>
            <StyledLabel>Lease End Date:</StyledLabel>
            {generalInfo.lease_end_date
              ? dateShortFormatter(generalInfo.lease_end_date)
              : "Month to Month"}
          </Row>
          <Row>
            <StyledLabel>Name:</StyledLabel>
            {generalInfo.name}
          </Row>
          <Row>
            <StyledLabel>Phone Number:</StyledLabel>
            {generalInfo.phone_number}
          </Row>
          <Row>
            <StyledLabel>Payment Processor Restricted:</StyledLabel>
            {generalInfo.payment_processor_restricted.toString()}
          </Row>
          <Row>
            <StyledLabel>Prepayment UUID:</StyledLabel>
            {generalInfo.prepayment_uuid}
          </Row>
        </Div>
        <Div width={{ md: 6 / 12, lg: 4 / 12 }} justifyContent="center">
          {hasEditPermissions && (
            <>
              <Row mb={{ default: 2 }}>
                <Div width={{ default: 1 }}>
                  <ButtonToForm
                    formTitle={"Change Prepayment Dates"}
                    buttonText={"Change Prepayment Dates"}
                  >
                    <UpdatePrepaymentDatesForm
                      prepaymentUUID={generalInfo.prepayment_uuid}
                      currentStartDate={generalInfo.lease_start_date}
                      currentEndDate={generalInfo.lease_end_date}
                    />
                  </ButtonToForm>
                </Div>
              </Row>
              <Row mb={{ default: 2 }}>
                <Div width={{ default: 1 }}>
                  <ButtonToForm
                    formTitle={"Change Prepayment Unit"}
                    buttonText={"Change Prepayment Unit"}
                  >
                    <MovePrepaymentToNewUnitForm
                      prepaymentUUID={generalInfo.prepayment_uuid}
                    />
                  </ButtonToForm>
                </Div>
              </Row>
              {generalInfo.payment_processor_restricted && (
                <Row>
                  <Div width={{ default: 1 }}>
                    <Button
                      text="Unrestrict User"
                      onClick={handleUnrestrictUser}
                      loading={loading}
                    />
                  </Div>
                </Row>
              )}
              {error && (
                <Row>
                  <Div width={{ default: 1 }}>
                    <ErrorMessage>{error}</ErrorMessage>
                  </Div>
                </Row>
              )}
            </>
          )}
        </Div>
      </Row>
    </DropdownCard>
  );
};

export default GeneralInfo;
