import styled from "styled-components";
import Row from "../../../components/baseComponents/Row";
import DropdownCard from "../../../components/baseComponents/DropdownCard";
import ChangeCompanySettingsForm from "../Forms/ChangeCompanySettingsForm";
import { CompanySettings } from "../../../components/global/ModelInterfaces";

interface Props {
  companySettings: CompanySettings;
}

const CompanySettingsSection = ({ companySettings }: Props) => {
  const dropdownCardProps = {
    title: "Company Settings Info",
    initiallyOpen: false,
  };
  return (
    <DropdownCard {...dropdownCardProps} initiallyOpen={false}>
      <Row justifyContent="center">
        <ChangeCompanySettingsForm companySettings={companySettings} />
      </Row>
    </DropdownCard>
  );
};

export default CompanySettingsSection;
