interface Props {
  width?: string;
  height?: string;
}
const BackArrow = ({ width = "24", height = "24" }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99609 17.5621L9.03516 16.3746C9.24297 16.0777 9.24297 15.6621 8.9832 15.3652L4.82695 10.7934L22.6988 10.7934C23.0625 10.7934 23.3223 10.4371 23.3223 10.0809L23.3223 8.41836C23.3223 8.00273 23.0625 7.70586 22.6988 7.70586L4.82695 7.70586L8.9832 3.07461C9.24297 2.77773 9.24297 2.36211 9.03516 2.06523L7.9961 0.877734C7.73633 0.640234 7.37266 0.640234 7.11289 0.877734L0.255079 8.71523C0.0472667 9.01211 0.0472667 9.42773 0.255079 9.72461L7.11289 17.5621C7.37266 17.7996 7.73633 17.7996 7.99609 17.5621Z"
        fill="#2F374B"
      />
    </svg>
  );
};

export default BackArrow;
