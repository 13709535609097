import React from "react";
import styled from "styled-components";
import Div from "./Div";

const StyledLabel = styled(Div)`
  font-weight: 600;
  font-size: ${(props) => props.theme.font_size.headlineXS};
`;

const Label = ({ ...props }) => {
  return <StyledLabel {...props} />;
};

export default Label;
