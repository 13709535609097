import { linkInviteToUser } from "../utils/accountUtils";

const SET_USER = "authentication/SET_USER";
const REMOVE_USER = "authentication/REMOVE_USER";
const EDIT_USER = "authentication/EDIT_USER";
const UPDATE_AUTH = "authentication/UPDATE_AUTH";
const axios = require("axios").default;

export const setUser = (user) => {
  return {
    type: SET_USER,
    user: user.user,
  };
};

export const removeUser = () => {
  return {
    type: REMOVE_USER,
  };
};

export const editUser = (user) => {
  return {
    type: EDIT_USER,
    user: user.user,
  };
};

export const updateAuth = (data) => {
  return {
    type: UPDATE_AUTH,
    data: data,
  };
};

export const login = (email, password, failureCallback) => {
  axios
    .post(
      "/auth/login/",
      { email, password },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      const data = res.data;
      localStorage.setItem("token", data["key"]);
      window.location.href = "/dashboard";
    })
    .catch((error) => {
      const data = error.data;
      if (data.non_field_errors && data.non_field_errors.length > 0) {
        failureCallback(data.non_field_errors[0]);
      } else if (data.detail && data.detail.length > 0) {
        failureCallback(data.detail);
      } else {
        failureCallback(data);
      }
    });
};

export const logout = () => {
  return async (dispatch) => {
    await axios.post("/auth/logout/").then(() => {
      dispatch(removeUser());
      localStorage.removeItem("token");
    });
    window.location.assign("/login");
  };
};

const redirectWithInvite = async (invite, inviteToken) => {
  linkInviteToUser(invite, inviteToken)
    .then(() => {
      window.location.href = "/dashboard";
    })
    .catch((err) => {
      return err;
    });
};

export const signup = (
  firstName,
  lastName,
  email,
  password,
  invite,
  inviteToken,
  failureCallback
) => {
  axios
    .post("/auth/registration/", {
      email: email,
      first_name: firstName,
      last_name: lastName,
      password1: password,
      invite: invite,
      invite_token: inviteToken,
    })
    .then((res) => {
      const data = res.data;
      localStorage.setItem("token", data["key"]);
      redirectWithInvite(invite, inviteToken).catch(() => {
        throw new Error();
      });
    })
    .catch((err) => {
      //Django rotate CSRF
      if (!invite) window.location.reload();
      const err_data = err.data;
      if (err_data.non_field_errors) {
        failureCallback(err_data.non_field_errors[0]);
      } else {
        for (const value of Object.values(err_data)) {
          failureCallback(value);
        }
      }
    });
};

export const updateUser = (
  firstName,
  lastName,
  email,
  successCallback,
  failureCallback
) => {
  const editUserBody = {};

  if (email) {
    editUserBody.email = email;
  }

  if (firstName) {
    editUserBody.first_name = firstName;
  }

  if (lastName) {
    editUserBody.last_name = lastName;
  }
  axios
    .post("api/user/update", editUserBody)
    .then((res) => {
      const data = res.data;
      successCallback(data);
    })
    .catch(() => {
      failureCallback("It seems your update didn't work, please try again!");
    });
};

export const updatePassword = async (
  oldPassword,
  newPassword,
  successCallback,
  failureCallback
) => {
  const updatePasswordBody = {
    old_password: oldPassword,
    new_password1: newPassword,
  };
  axios
    .post("/auth/password/change/", updatePasswordBody)
    .then(() => {
      successCallback("Password successfully updated!");
    })
    .catch((err) => {
      const data = err.data;
      let errorMessage =
        "There was an error updating your password, please try again.";
      if (data.non_field_errors) {
        errorMessage = err.data.non_field_errors[0];
      } else {
        for (const value of Object.values(data)) {
          errorMessage = value.toString();
        }
      }
      failureCallback(errorMessage);
    });
};

export const handlePasswordReset = async (
  uidb64,
  token,
  newPassword,
  successCallback,
  failureCallback
) => {
  const requestBody = {
    uid: uidb64,
    token: token,
    new_password1: newPassword,
  };
  axios
    .post(`/password/reset/confirm/${uidb64}/${token}`, requestBody)
    .then(() => {
      successCallback("Password successfully updated");
    })
    .catch((err) => {
      const data = err.data;
      if (data.non_field_errors) {
        failureCallback(data.non_field_errors[0]);
      } else {
        for (const key of Object.keys(data)) {
          if (key === "token") {
            failureCallback(
              "This session is expired. Please request a new password reset on the login page."
            );
          } else {
            failureCallback(data[key]);
          }
        }
      }
    });
};

export const requestPasswordResetEmail = async (
  email,
  successCallback,
  failureCallback
) => {
  axios
    .post("/password/reset/request/", { email: email })
    .then(() => {
      successCallback();
    })
    .catch(() => {
      failureCallback(
        "An error occurred. Contact support@rentable.com for troubleshooting help or try again."
      );
    });
};

const initialState = {
  authenticated: false,
  user: null,
  loading: true,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case EDIT_USER:
      return {
        ...state,
        user: action.user,
      };
    case SET_USER:
      return {
        ...state,
        authenticated: true,
        loading: false,
        user: action.user,
      };
    case REMOVE_USER:
      return initialState;
    case UPDATE_AUTH:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
