import styled from "styled-components";
import { cssBreakpoints } from "../global/theme";

interface Props {
  title: string;
  image?: string;
  subtitle?: string;
}

const StyledTitle = styled.div`
  font-style: normal;
  font-weight: bold;

  line-height: 1.375em;
  color: ${(props) => props.theme.colors.navy};
  ${cssBreakpoints("font-size", [{ sm: "2rem" }, { md: "2.5rem" }])}
`;

const StyledSubtitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 0.75em;
  line-height: 1em;
  color: ${(props) => props.theme.colors.grey80};
`;

const TitleHeaderBody = ({ title, subtitle }: Props) => {
  return (
    <div>
      <StyledTitle>{title}</StyledTitle>
      <StyledSubtitle>{subtitle}</StyledSubtitle>
    </div>
  );
};

export default TitleHeaderBody;
