import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import BusinessVerificationComponent from "../components/BusinessVerification";

export default function BusinessVerifyPage() {
	const currentUser = useSelector((state) => state.auth.user);
	if (!currentUser) return <Redirect to={`/login`} />;

  return (
    <div className="businessVerifyContainer d-flex flex-column align-items-center py-5 w-100 h-100">
		<h3>Create a Business Verified User Record</h3>
		<BusinessVerificationComponent />
    </div>
  );
}
