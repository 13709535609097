import styled from "styled-components";
import { useAppSelector } from "../../../store/hooks";
import { RootState } from "../../..";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import {
  GenericObject,
  TenantPrepaymentInfo,
} from "../../global/ModelInterfaces";
import { useEffect, useState } from "react";
import { head } from "lodash";
import { useParams } from "react-router-dom";
import useGetCurrentPrepayment from "../../../utils/useGetCurrentPrepayment";
import PageLoader from "../../PageLoader";

const StyledTitle = styled(Row)`
  margin-bottom: 1rem;
  font-size: ${(props) => props.theme.font_size.headlineLG};
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const StyledDescription = styled(Row)`
  margin-bottom: 2rem;
  font-size: ${(props) => props.theme.font_size.headlineXS};
`;

const OffboardingIntro = () => {
  const { prepaymentUUID } = useParams<GenericObject>();
  const { loadingTenantInfo, prepayment } =
    useGetCurrentPrepayment(prepaymentUUID);

  if (loadingTenantInfo) {
    return <PageLoader />;
  }

  return (
    <Div alignItems="center">
      <StyledTitle justifyContent="center">
        <span>Welcome to Rentable!</span>
      </StyledTitle>
      <StyledDescription justifyContent="center">
        <Div width={{ sm: 1, md: 1, lg: 7 / 12 }}>
          Your property manager is using Rentable to manage your security
          deposit at {prepayment.street_address}. There are some funds for you
          to claim. If you have any questions feel free to reach out to
          support@rentable.com.
        </Div>
      </StyledDescription>
    </Div>
  );
};

export default OffboardingIntro;
