import Div from "../../baseComponents/Div";
import Input from "../../baseComponents/Input";
import Row from "../../baseComponents/Row";
import styled from "styled-components";
import { GenericObject } from "../../global/ModelInterfaces";

interface Props {
  forwardingAddress: GenericObject;
  addressErrors: GenericObject;
  updateForwardingAddress: Function;
}
const StyledInstructions = styled(Row)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;
const ForwardingAddressForm = ({
  forwardingAddress,
  addressErrors,
  updateForwardingAddress,
}: Props) => {
  return (
    <Row>
      <Div width={{ default: 1 }}>
        <StyledInstructions justifyContent="center">
          <Row justifyContent="center">
            <span>
              Please enter a mailing address where you want your check to go to.
            </span>
          </Row>
        </StyledInstructions>
        <Row>
          <Div width={{ sm: 1, md: 1, lg: 1 }}>
            <Input
              label="Name"
              value={forwardingAddress.name}
              backgroundColor={"light"}
              error={addressErrors.name}
              onChange={(e) => {
                let tempAddress = { ...forwardingAddress };
                tempAddress["name"] = e.target.value;
                updateForwardingAddress(tempAddress);
              }}
              type="light"
            />
          </Div>
        </Row>
        <Row>
          <Div width={{ sm: 1, md: 1, lg: 9 / 12 }}>
            <Input
              label="Street Address"
              value={forwardingAddress.streetAddress}
              backgroundColor={"light"}
              error={addressErrors.streetAddress}
              onChange={(e) => {
                let tempAddress = { ...forwardingAddress };
                tempAddress["streetAddress"] = e.target.value;
                updateForwardingAddress(tempAddress);
              }}
              type="light"
            />
          </Div>
          <Div width={{ sm: 1, md: 1, lg: 3 / 12 }}>
            <Input
              label="Unit Address"
              value={forwardingAddress.unitAddress}
              backgroundColor={"light"}
              onChange={(e) => {
                let tempAddress = { ...forwardingAddress };
                tempAddress["unitAddress"] = e.target.value;
                updateForwardingAddress(tempAddress);
              }}
              type="light"
            />
          </Div>
        </Row>
        <Row>
          <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
            <Input
              label="City"
              value={forwardingAddress.city}
              backgroundColor={"light"}
              error={addressErrors.city}
              onChange={(e) => {
                let tempAddress = { ...forwardingAddress };
                tempAddress["city"] = e.target.value;
                updateForwardingAddress(tempAddress);
              }}
              type="light"
            />
          </Div>
          <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
            <Input
              label="State"
              value={forwardingAddress.state}
              backgroundColor={"light"}
              error={addressErrors.state}
              onChange={(e) => {
                let tempAddress = { ...forwardingAddress };
                tempAddress["state"] = e.target.value;
                updateForwardingAddress(tempAddress);
              }}
              type="light"
            />
          </Div>
        </Row>
        <Row>
          <Div width={{ sm: 1, md: 1, lg: 1 }}>
            <Input
              label="Zip Code"
              value={forwardingAddress.zipcode}
              backgroundColor={"light"}
              error={addressErrors.zipcode}
              onChange={(e) => {
                let tempAddress = { ...forwardingAddress };
                tempAddress["zipcode"] = e.target.value;
                updateForwardingAddress(tempAddress);
              }}
              type="light"
            />
          </Div>
        </Row>
      </Div>
    </Row>
  );
};

export default ForwardingAddressForm;
