import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import { orderBy } from "lodash";
import { DateTime } from "luxon";

import Div from "../baseComponents/Div";
import Row from "../baseComponents/Row";
import Container from "../baseComponents/Container";
import ListItem from "../baseComponents/ListItem";
import BackButton from "../baseComponents/BackButton";
import UserInitialsAvatar from "../baseComponents/UserInitialsAvatar";
import PageLoader from "../PageLoader";
import Filters from "../baseComponents/Filters";
import PrepaymentDashboardButtons from "./Dashboard/PrepaymentDashboardButtons";
import { RootState } from "../..";
import { useAppSelector } from "../../store/hooks";
import { Prepayment, PrepaymentFilters } from "../global/ModelInterfaces";
import {
  dateShortFormatter,
  currencyFormatter,
  navigateToTenant,
} from "../global/utils";
import { getStreetAddress } from "../../utils/globalUtils";
import { cssBreakpoints } from "../global/theme";

const StyledRow = styled(Row)`
  ${cssBreakpoints("margin-top", [{ sm: "2rem" }, { md: "0rem" }])}
`;

const StyledTitle = styled.div`
  font-weight: 700;
  line-height: 1.375em;
  color: ${(props) => props.theme.colors.navy};
  ${cssBreakpoints("font-size", [{ sm: "1.25rem" }, { md: "2.5rem" }])}
`;

const StyledPrepayments = styled(Div)`
  margin-top: 1rem;
`;

const StyledFilterRow = styled(Row)`
  min-height: 3rem;
  padding-top: 0.5rem;
`;

interface StyledLinkProps {
  variant?: string;
}

const variantLinkStyles = (theme: any, variant = "active") =>
  ({
    active: css`
      color: ${theme.colors.grey};
      text-decoration: underline;
    `,
    inactive: css`
      color: ${theme.colors.grey60};
    `,
  }[variant]);

const StyledLink = styled(Div)<StyledLinkProps>`
  font-weight: ${(props) => props.theme.font_weight.semibold};
  ${({ theme, variant }) => variantLinkStyles(theme, variant)}
  &:hover {
    cursor: pointer;
  }
`;

interface Props {
  prepaymentFilters: PrepaymentFilters;
}

const PrepaymentsView = ({ prepaymentFilters }: Props) => {
  const history = useHistory();
  const prepaymentSlice: { loading: Boolean; objects: Prepayment[] } =
    useAppSelector((state: RootState) => state.prepayments);

  const loadingPrepayments = prepaymentSlice.loading;
  const prepayments = prepaymentSlice.objects;

  const [orderByNewest, setOrderByNewest] = useState(true);
  const [filteredPrepayments, setFilteredPrepayments] =
    useState<Prepayment[]>(prepayments);

  const initialRef = useRef(true);

  const handleFilter = (newPrepayments: Prepayment[]) => {
    setFilteredPrepayments(newPrepayments);
  };

  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false;
    } else {
      const sortedPrepayments = orderBy(
        filteredPrepayments,
        (prepayment) => {
          return DateTime.fromFormat(prepayment.start_date, "yyyy-MM-dd");
        },
        orderByNewest ? ["desc"] : ["asc"]
      );
      setFilteredPrepayments(sortedPrepayments);
    }
  }, [orderByNewest]);

  useEffect(() => {}, [filteredPrepayments]);

  if (loadingPrepayments) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  }
  return (
    <Container>
      <BackButton returnUrl={`/dashboard`} text={`Go to Dashboard`} />
      <StyledRow justifyContent="center">
        <Div width={{ sm: 1, md: 3 / 4, lg: 3 / 4 }}>
          <Row
            flexWrap="no-wrap"
            justifyContent="space-between"
            alignItems="center"
          >
            <Div width={{ sm: 3 / 5, md: 3 / 4, lg: 1 / 2 }}>
              <StyledTitle>Deposits</StyledTitle>
            </Div>
            <PrepaymentDashboardButtons
              title={""}
              prepayments={filteredPrepayments}
            />
          </Row>
          <StyledFilterRow
            alignItems="space-between"
            justifyContent="space-between"
          >
            <Filters
              onFilterUpdate={handleFilter}
              initialFilters={prepaymentFilters}
            />
            <Div justifyContent="flex-end">
              {/* TODO ADD NEW DROPDOWN HERE TO ORDERBY */}
              <Row>
                Order By:
                <StyledLink
                  variant={orderByNewest ? "active" : "inactive"}
                  onClick={() => setOrderByNewest(true)}
                >
                  Newest
                </StyledLink>
                <StyledLink
                  variant={orderByNewest ? "inactive" : "active"}
                  onClick={() => setOrderByNewest(false)}
                >
                  Oldest
                </StyledLink>
              </Row>
            </Div>
          </StyledFilterRow>
          <StyledPrepayments>
            {filteredPrepayments.map((prepayment: any) => {
              let leaseEndDate = "";
              if (prepayment.end_date) {
                leaseEndDate = dateShortFormatter(prepayment.end_date);
              } else {
                leaseEndDate = "Month to month";
              }
              const prepaymentMetrics = [
                ["leaseStartDate", dateShortFormatter(prepayment.start_date)],
                ["leaseEndDate", leaseEndDate],
                ["leaseAmount", currencyFormatter.format(prepayment.amount)],
              ];
              return (
                <Row alignItems="center">
                  <Div width={{ default: 1 }}>
                    <ListItem
                      title={prepayment.tenant_name}
                      details={[
                        getStreetAddress(
                          prepayment.address_1,
                          prepayment.address_2
                        ),
                      ]}
                      Icon={() => (
                        <UserInitialsAvatar name={prepayment.tenant_name} />
                      )}
                      iconHeight={"100%"}
                      iconWidth={"100%"}
                      metrics={prepaymentMetrics}
                      onClick={() => navigateToTenant(prepayment.uuid, history)}
                    />
                  </Div>
                </Row>
              );
            })}
          </StyledPrepayments>
        </Div>
      </StyledRow>
    </Container>
  );
};

export default PrepaymentsView;
