/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";

import styled from "styled-components";
import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import Input from "../../../../../components/baseComponents/Input";
import Button from "../../../../../components/baseComponents/Button";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";
import PasswordInput from "../../../../../components/baseComponents/PasswordInput";
import HorizontalDivider from "../../../../../components/baseComponents/HorizontalDivider";

const StyledInstructions = styled.span`
  font-size: ${(props) => props.theme.font_size.bodyLG};
`;

/*
 * Form I. Connection settings
 *  Collects the connection settings and personal details for the Yardi integration
 */
const SetYardiConnectionForm = ({
  onCompletedCallback,
}: {
  onCompletedCallback: Function;
}) => {
  const [error, setError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [yardiCompanyCode, setYardiCompanyCode] = useState("");
  const [yardiLocation, setYardiLocation] = useState("");
  const [yardiUrl, setYardiUrl] = useState("");
  const [yardiLogin, setYardiLogin] = useState("");
  const [yardiPassword, setYardiPassword] = useState("");

  const handleConnectYardiCompany = () => {
    // Validate fields and call back to the parent component
    setError("");
    if (
      !yardiCompanyCode ||
      !yardiLocation ||
      !yardiUrl ||
      !yardiLogin ||
      !yardiPassword
    )
      setError(
        "You must provide a complete Yardi connection settings to continue."
      );
    else if (!firstName || !lastName || !companyEmail)
      setError(
        "You must provide company representative details to send an invite."
      );
    // Call back to the parent component with the connection settings
    else
      onCompletedCallback({
        companyCode: yardiCompanyCode,
        location: yardiLocation,
        companyEmail: companyEmail,
        url: yardiUrl,
        login: yardiLogin,
        password: yardiPassword,
        firstName: firstName,
        lastName: lastName,
        email: companyEmail,
      });
  };

  return (
    <Div width={{ default: 1 }}>
      <HorizontalDivider />
      <Div width={{ default: 1 }} mb={{ default: 3 }}>
        <StyledInstructions>
          <h2>Step I. Connection settings</h2>
        </StyledInstructions>
      </Div>
      <StyledInstructions>Company representative</StyledInstructions>
      <Row>
        <Div width={{ default: 6 / 12 }} mb={{ default: 3 }}>
          <Input
            label="First Name"
            value={firstName}
            onChange={(e) => {
              setError("");
              setFirstName(e.target.value);
            }}
            type="light"
            backgroundColor={"light"}
            error={Boolean(!firstName) && !!error}
          />
        </Div>
        <Div width={{ default: 6 / 12 }} mb={{ default: 3 }}>
          <Input
            label="Last Name"
            value={lastName}
            onChange={(e) => {
              setError("");
              setLastName(e.target.value);
            }}
            type="light"
            backgroundColor={"light"}
            error={Boolean(!lastName) && !!error}
          />
        </Div>
      </Row>
      <Row>
        <Div width={{ default: 12 / 12 }} mb={{ default: 3 }}>
          <Input
            label="email"
            value={companyEmail}
            onChange={(e) => {
              setError("");
              setCompanyEmail(e.target.value);
            }}
            type="light"
            backgroundColor={"light"}
            error={Boolean(!companyEmail) && !!error}
          />
        </Div>
      </Row>
      <StyledInstructions>Location identification</StyledInstructions>
      <Row>
        <Div width={{ default: 6 / 12 }} mb={{ default: 3 }}>
          <Input
            label="Yardi Company Code (8223tp7s7dev)"
            value={yardiCompanyCode}
            onChange={(e) => {
              setError("");
              setYardiCompanyCode(e.target.value);
            }}
            type="light"
            backgroundColor={"light"}
            error={Boolean(!yardiCompanyCode) && !!error}
          />
        </Div>
        <Div width={{ default: 6 / 12 }} mb={{ default: 3 }}>
          <Input
            label="Yardi Database code (afqoml_itf70dev7)"
            value={yardiLocation}
            onChange={(e) => {
              setError("");
              setYardiLocation(e.target.value);
            }}
            type="light"
            backgroundColor={"light"}
            error={Boolean(!yardiLocation) && !!error}
          />
        </Div>
      </Row>
      <StyledInstructions>Connection Settings</StyledInstructions>
      <Row>
        <Div width={{ default: 12 / 12 }} mb={{ default: 3 }}>
          <Input
            label="Yardi URL (https://www.yardipcv.com)"
            value={yardiUrl}
            onChange={(e) => {
              setError("");
              setYardiUrl(e.target.value);
            }}
            type="light"
            backgroundColor={"light"}
            error={Boolean(!yardiUrl) && !!error}
          />
        </Div>
      </Row>
      <Row>
        <Div width={{ default: 6 / 12 }} mb={{ default: 3 }}>
          <Input
            label="Login"
            value={yardiLogin}
            onChange={(e) => {
              setError("");
              setYardiLogin(e.target.value);
            }}
            type="light"
            backgroundColor={"light"}
            error={Boolean(!yardiLogin) && !!error}
          />
        </Div>

        <Div width={{ default: 6 / 12 }} mb={{ default: 3 }}>
          <PasswordInput
            value={yardiPassword}
            onChange={(e) => {
              setError("");
              setYardiPassword(e.target.value);
            }}
            backgroundColor={"light"}
            error={Boolean(!yardiPassword) && !!error}
          />
        </Div>
      </Row>

      {error && (
        <Div mb={{ default: 3 }}>
          <ErrorMessage>{error}</ErrorMessage>
        </Div>
      )}
      <Div width={{ default: 1 }}>
        <Button text="Test Connection..." onClick={handleConnectYardiCompany} />
      </Div>
    </Div>
  );
};

export default SetYardiConnectionForm;
