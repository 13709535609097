import axios from "axios";

const getLandlordBuildings = async () => {
  return axios.get("/api/building/landlordbuilding");
};

const addLandlordToBuilding = async (
  buildingUUID: string,
  landlordID: string,
  callBackSuccess: Function,
  failureCallback: Function
) => {
  const request = {
    building_uuid: buildingUUID,
    landlord_id: landlordID,
  };
  axios
    .post("/api/building/add-landlord", request)
    .then((res) => {
      callBackSuccess(res.data.values);
    })
    .catch(() => {
      failureCallback();
    });
};

const removeLandlordFromBuilding = async (
  buildingUUID: string,
  landlordID: string,
  callBackSuccess: Function,
  failureCallback: Function
) => {
  const request = {
    building_uuid: buildingUUID,
    landlord_id: landlordID,
  };
  axios
    .post("/api/building/remove-landlord", request)
    .then((res) => {
      callBackSuccess(res.data.values);
    })
    .catch(() => {
      failureCallback();
    });
};

const linkBankToBuilding = async (
  buildingUuid: string,
  bankUuid: string,
  successCallBack: Function
) => {
  const request = {
    buildingUuid: buildingUuid,
    bankUuid: bankUuid,
  };
  axios.post("/api/building/link-fee-bank", request).then((res) => {
    successCallBack(res.data.values);
  });
};

export {
  getLandlordBuildings,
  addLandlordToBuilding,
  removeLandlordFromBuilding,
  linkBankToBuilding,
};
