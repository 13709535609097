import { useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Div from "../baseComponents/Div";
import Col from "../baseComponents/Col";
import Row from "../baseComponents/Row";
import Container from "../baseComponents/Container";

import BuildingIcon from "../../images/BuildingIcon";
import BankIcon from "../../images/BankIcon";
import UserIcon from "../../images/UserIcon";

const WelcomeTitle = styled.h1`
  color: ${(props) => props.theme.colors.navy};
  padding: 2.25rem 0;
`;

const CompanyTitle = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineSM};
  color: ${(props) => props.theme.colors.navy};
`;

const Option = styled(Div)`
  background-color: ${(props) => props.theme.colors.grey10};
  border-radius: 0.5rem;
  font-size: ${(props) => props.theme.font_size.headlineXS};
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.backgroundSecondary};
  }
`;

const IconDiv = styled.h1`
  padding: 0.5rem;
`;

interface Props {}

const AdminHome = ({}: Props) => {
  const history = useHistory();
  const currentUser = useAppSelector((state) => state.auth.user);
  const canChargeFees = useAppSelector(
    (state) => state.companies.can_charge_fees
  );
  const [firstName, setFirstName] = useState(
    currentUser ? currentUser.first_name : ""
  );
  const [company, setCompany] = useState(
    currentUser ? currentUser.company : ""
  );

  const handleClick = (path: string) => {
    history.push(`${path}`);
  };

  return (
    <Container>
      <Row>
        <WelcomeTitle>👋 Welcome back, {firstName}</WelcomeTitle>
      </Row>
      <Row>
        <CompanyTitle>Info for {company}:</CompanyTitle>
      </Row>
      <Row justifyContent="center" mt={{ sm: 4 }}>
        <Col width={{ sm: 3 / 12 }}>
          <Option
            onClick={() => handleClick("/settings/invite-landlord")}
            alignItems="center"
            p={{ sm: 4 }}
          >
            <IconDiv>
              <UserIcon width={"48"} height={"48"} />
            </IconDiv>
            Invite New Property Manager
          </Option>
        </Col>
        <Col width={{ sm: 3 / 12 }}>
          <Option
            onClick={() => handleClick("/settings/buildings")}
            alignItems="center"
            p={{ sm: 4 }}
          >
            <IconDiv>
              <BuildingIcon width={"48"} height={"48"} />
            </IconDiv>
            Building Permissions
          </Option>
        </Col>
        <Col width={{ sm: 3 / 12 }}>
          <Option
            onClick={() => handleClick("/settings/bank-accounts")}
            alignItems="center"
            p={{ sm: 4 }}
          >
            <IconDiv>
              <BankIcon width={"48"} height={"48"} />
            </IconDiv>
            Company Bank Accounts
          </Option>
        </Col>
        {canChargeFees && (
          <Col width={{ sm: 3 / 12 }}>
            <Option
              onClick={() => handleClick("/settings/banks")}
              alignItems="center"
              p={{ sm: 4 }}
            >
              <IconDiv>
                <BankIcon width={"48"} height={"48"} />
              </IconDiv>
              Fees - Link Bank to Building
            </Option>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default AdminHome;
