import { createStore, compose, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import auth from "./auth";
import prepayments from "./nucleusPrepayments";
import companies from "./nucleusCompanies";

const appReducer = combineReducers({
  auth,
  prepayments,
  companies,
});

const rootReducer = (state, action) => {
  if (action.type === "authentication/REMOVE_USER") {
    state = undefined;
  }
  return appReducer(state, action);
};

let storeEnhancer;

if (process.env.NODE_ENV !== "production") {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  storeEnhancer = composeEnhancers(applyMiddleware(thunk));
} else {
  storeEnhancer = applyMiddleware(thunk);
}

export default function configureNucleusStore(initialState) {
  return createStore(rootReducer, initialState, storeEnhancer);
}
