import styled from "styled-components";
import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import DropdownCard from "../../../../../components/baseComponents/DropdownCard";
import { GenericObject } from "../../../../../components/global/ModelInterfaces";

const StyledLabel = styled.span`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;
interface Props {
  details: GenericObject;
}
const DetailsSection = ({ details }: Props) => {
  return (
    <DropdownCard title={"Details"}>
      <Div width={{ default: 12 / 12 }}>
        <Row>
          <StyledLabel>Rent Manager Company Code:</StyledLabel>
          {details.company_code}
        </Row>
        <Row>
          <StyledLabel>Rent Manager Company UUID:</StyledLabel>
          {details.rent_manager_company_uuid}
        </Row>
      </Div>
    </DropdownCard>
  );
};

export default DetailsSection;
