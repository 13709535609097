import React, { useState } from "react";
import styled from "styled-components";
import { variant } from "styled-system";
import Button from "./Button";
import { noop } from "lodash";
import ListItemTitle from "./ListItemTitle";
import ListItemMetrics from "./ListItemMetrics";
import Div from "./Div";
import Row from "./Row";
import { COLORS } from "../../components/global/colors";
import { cssBreakpoints } from "../../components/global/theme";
import XIcon from "../../images/XIcon";
import ConfirmationModal from "./ConfirmationModal";

interface ListItemStyleProps {
  backgroundColor?: string;
}

const StyledListItem = styled(Row)<ListItemStyleProps>`
  min-height: 5.85rem;
  border-radius: 1em;
  &:hover {
    cursor: pointer;
    box-shadow: ${(props) => props.theme.box_shadow.light};
  }
  ${variant({
    prop: "backgroundColor",
    variants: {
      default: {
        backgroundColor: COLORS.backgroundSecondary,
        "&:hover": {
          backgroundColor: COLORS.white,
        },
      },
      warning: {
        backgroundColor: COLORS.backgroundYellow,
        "&:hover": {
          backgroundColor: COLORS.yellow,
        },
      },
      error: {
        backgroundColor: COLORS.backgroundDanger,
        "&:hover": {
          backgroundColor: COLORS.danger,
        },
      },
    },
  })}
  ${cssBreakpoints("margin", [{ sm: "1rem 0" }, { md: "0.5rem 0" }])}
  ${cssBreakpoints("padding", [{ sm: "0" }, { md: "0.5rem 1.5rem" }])}
`;

const StyledIcon = styled(Div)`
  ${cssBreakpoints("padding-left", [{ sm: ".75rem" }, { md: "0rem" }])}
`;
interface IconProps {
  height: string;
  width: string;
}

/**
 * List Item react element
 *
 * @title { string } : Title of a card
 * @details { Array<string> } : Extra details
 * @metrics { Array<Array<any>>} : Numbers
 * @status { string} :
 * @onClick { () => void } : callback on click on Item
 * @Icon { React.FC<IconProps>} : icon on a Item
 * @iconWidth { string } : icon width
 * @iconHeight { string } : icon height
 * @isDeleteDisabled { boolean } : flag to show disabled delete button
 * @onDelete {() => void} : show delete button and a callback on press
 * @key {string} : a key
 *
 **/

interface Props {
  title: string;
  details?: Array<string>;
  metrics?: Array<Array<any>>;
  status?: string;
  onClick?: () => void;
  Icon?: React.FC<IconProps>;
  iconWidth?: string;
  iconHeight?: string;
  isDeleteDisabled?: boolean;
  onDelete?: (finish: () => void) => void;
  key?: string;
  onButtonClick?: () => void;
  buttonText?: string;
  buttonDisabled?: boolean;
}

const ListItem = ({
  title,
  details,
  metrics,
  status,
  onClick = noop,
  Icon,
  iconHeight,
  iconWidth,
  isDeleteDisabled,
  onDelete,
  key,
  onButtonClick,
  buttonText,
  buttonDisabled,
}: Props) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [stopPropagation, setStopPropagation] = useState(false);

  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setShowConfirmation(true);
    setStopPropagation(true);
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (onButtonClick) {
      onButtonClick();
    }
  };

  const handleCloseConfirmModal = () => {
    setStopPropagation(false);
    setShowConfirmation(false);
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete(handleCloseConfirmModal);
    }
  };

  const handleClick = () => {
    if (!stopPropagation && onClick) {
      onClick();
    }
  };

  return (
    <Div onClick={handleClick} width={{ default: 1 }} key={key}>
      <StyledListItem
        backgroundColor={status || "default"}
        alignItems="center"
        flexWrap="nowrap"
      >
        {Icon && (
          <StyledIcon
            display={{ lg: "flex", md: "flex", sm: "flex", default: "none" }}
            width={{ sm: 1 / 6, md: 1 / 16, lg: 1 / 20 }}
          >
            <Icon width={iconWidth ?? ""} height={iconHeight ?? ""} />
          </StyledIcon>
        )}
        <Div width={{ sm: 5 / 6, md: 5 / 6, lg: 9 / 20 }}>
          <ListItemTitle title={title} details={details} />
        </Div>
        {metrics && (
          <Div
            width={{ sm: 0, md: 0, lg: 6 / 12 }}
            display={{ sm: "none", md: "none", lg: "flex" }}
          >
            <ListItemMetrics metrics={metrics} />
          </Div>
        )}

        {onButtonClick && (
          <Div
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              handleButtonClick(event);
            }}
          >
            <Button disabled={buttonDisabled}>{buttonText}</Button>
          </Div>
        )}

        {onDelete && (
          <Div>
            {isDeleteDisabled ? (
              <Div>
                <XIcon color={COLORS.grey2} />
              </Div>
            ) : (
              <Div
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  handleDeleteClick(event)
                }
              >
                <XIcon color={COLORS.danger} />
              </Div>
            )}
            <ConfirmationModal
              confirmationOpen={showConfirmation}
              onClose={handleCloseConfirmModal}
              onConfirm={handleDelete}
              message={`Are you sure you would like to delete ${details}?`}
            />
          </Div>
        )}
      </StyledListItem>
    </Div>
  );
};

export default ListItem;
