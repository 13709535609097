import { useState, ChangeEvent } from "react";
import { first } from "lodash";
import styled from "styled-components";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import Input from "../../baseComponents/Input";
import Button from "../../baseComponents/Button";
import Dropdown from "../../baseComponents/Dropdown";
import FileUpload from "../../baseComponents/FileUpload";
import ErrorMessage from "../../baseComponents/ErrorMessage";
import { Deduction, Option } from "../../global/ModelInterfaces";
import { deductionCategories } from "../../../utils/disbursementUtils";
import CurrencyInput from "../../baseComponents/CurrencyInput";

const StyledDeductionCard = styled(Div)`
  min-height: 22.5rem;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.12);
  border-radius: 1rem;
  padding: 0.75rem;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-bottom: 1rem;
`;

interface Props {
  pendingDeduction: Deduction;
  addDeduction: Function;
  closeDeductionCard: Function;
}

const DeductionCard = ({
  pendingDeduction,
  addDeduction,
  closeDeductionCard,
}: Props) => {
  const [deductionError, setDeductionError] = useState("");
  const [pendingCategory, setPendingCategory] = useState<Option | null>(null);

  const updatePendingDeduction = (
    e: any,
    deduction: Deduction,
    type: string
  ) => {
    if (type === "description") {
      deduction.description = e.target.value;
    } else if (type === "category") {
      deduction.category = Number(e.id);
    } else if (type === "file") {
      deduction.documentation = first(e?.target.files);
    }
  };

  const updateDeductionAmount = (amount: number, deduction: Deduction) => {
    deduction.amount = amount;
  };

  const fileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    updatePendingDeduction(e, pendingDeduction, "file");
  };

  return (
    <StyledDeductionCard>
      {deductionError && (
        <ErrorMessage alignItems="center">{deductionError}</ErrorMessage>
      )}
      <Row>
        <Div width={{ sm: 1, md: 1, lg: 1 }}>
          <Input
            backgroundColor="light"
            label="Description"
            onChange={(e: any) => {
              updatePendingDeduction(e, pendingDeduction, "description");
            }}
          />
        </Div>
      </Row>
      <Row>
        <Div width={{ sm: 1, lg: 4 / 12 }}>
          <CurrencyInput
            label="Amount"
            onChange={(amount: number) => {
              updateDeductionAmount(amount, pendingDeduction);
            }}
          />
        </Div>
        <Div width={{ sm: 1, lg: 8 / 12 }}>
          <Dropdown
            name="Category"
            placeholder="Choose a category"
            options={deductionCategories}
            onClick={(e: any) => {
              updatePendingDeduction(e, pendingDeduction, "category");
              setPendingCategory(e);
            }}
            value={pendingCategory}
          />
        </Div>
      </Row>
      <FileUpload
        onUpload={fileUpload}
        onRemove={() => {
          updatePendingDeduction(null, pendingDeduction, "file");
        }}
        confirmUpload={false}
      />
      <Row>
        <Div width={{ sm: 1, md: 1, lg: 1 }}>
          <StyledButton
            type="primary"
            text={`Save Deduction`}
            onClick={() => {
              if (!pendingDeduction.amount) {
                setDeductionError("Please add an amount.");
              } else if (pendingDeduction.amount <= 0) {
                setDeductionError(
                  "Please choose an amount that is greater than $0"
                );
              } else if (!pendingDeduction.category) {
                setDeductionError("Please choose a category");
              } else {
                addDeduction();
                closeDeductionCard();
              }
            }}
          />
        </Div>
      </Row>
      <Row>
        <Div width={{ sm: 1, md: 1, lg: 1 }}>
          <Button
            type="secondary"
            text={`Cancel`}
            onClick={() => closeDeductionCard()}
          />
        </Div>
      </Row>
    </StyledDeductionCard>
  );
};

export default DeductionCard;
