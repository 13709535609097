import { Redirect } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../..";
import styled from "styled-components";
import PageLoader from "../PageLoader";
import Div from "../baseComponents/Div";

const StyledText = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineMD};
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const RentManagerSync = () => {
  const buildingSlice: { loading: Boolean } = useAppSelector(
    (state: RootState) => state.buildings
  );

  const unitSlice: { loading: Boolean } = useAppSelector(
    (state: RootState) => state.units
  );

  const loadingUnits = unitSlice.loading;
  const loadingBuildings = buildingSlice.loading;

  if (loadingBuildings || loadingUnits) {
    return (
      <Div justifyContent="center" alignItems="center">
        <PageLoader />
        <StyledText>
          Syncing all of your properties and units from your Rent Manager
          account.
        </StyledText>
      </Div>
    );
  } else {
    return <Redirect to={"/dashboard"} />;
  }
};

export default RentManagerSync;
