import styled from "styled-components";
import { DateTime, Interval } from "luxon";
import { orderBy } from "lodash";
import { COLORS } from "../../global/colors";
import { useAppSelector } from "../../../store/hooks";
import prepaymentStatuses from "../../../enums/prepaymentStatuses";
import Div from "../../baseComponents/Div";
import CouchIcon from "../../../images/CouchIcon";
import DashboardListItem from "./DashboardListItem";
import { Prepayment } from "../../global/ModelInterfaces";
import { currencyFormatter } from "../../global/utils";
import { cssBreakpoints } from "../../global/theme";

const StyledTitleDiv = styled(Div)`
  color: ${COLORS.navy};
  font-weight: 700;
  padding-bottom: 1.5rem;
  ${cssBreakpoints("font-size", [{ sm: "1.25rem" }, { md: "1.5rem" }])}
`;

const PaymentsDue = () => {
  const upcomingPaymentDeposits = useAppSelector((state) => {
    const filteredPrepayments = state.prepayments.objects.filter(
      (prepayment: Prepayment) => {
        return prepayment.status_id === prepaymentStatuses.PENDING;
      }
    );
    return orderBy(filteredPrepayments, (prepayment) => {
      return DateTime.fromFormat(prepayment.start_date, "yyyy-MM-dd");
    }).splice(0, 5);
  });

  const renderDaysDueText = (dueDateInterval: any, amount: string) => {
    if (Number.isNaN(Math.ceil(dueDateInterval.length("days")))) {
      return `${currencyFormatter.format(amount)} OVERDUE`;
    } else {
      return `${currencyFormatter.format(amount)} due in ${Math.ceil(
        dueDateInterval.length("days")
      )} days`;
    }
  };

  return (
    <>
      <StyledTitleDiv>
        <span>Upcoming payments due soon:</span>
      </StyledTitleDiv>
      <Div width={{ default: 1 }}>
        {upcomingPaymentDeposits.map((prepayment: Prepayment) => {
          const dueDateInterval = Interval.fromDateTimes(
            DateTime.now(),
            DateTime.fromFormat(prepayment.start_date, "yyyy-MM-dd")
          );
          return (
            <DashboardListItem
              icon={<CouchIcon />}
              prepayment={prepayment}
              itemDetail={`${renderDaysDueText(
                dueDateInterval,
                prepayment.amount
              )}`}
            />
          );
        })}
      </Div>
    </>
  );
};

export default PaymentsDue;
