interface Props {
  width?: string;
  height?: string;
}
const FilterIcon = ({ width = "24", height = "24" }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      fill="#2F374B"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.83333C0 0.82099 0.831652 1.67063e-07 1.85714 1.67063e-07H24.1429C25.1701 1.67063e-07 26 0.82099 26 1.83333C26 2.8474 25.1701 3.66667 24.1429 3.66667H1.85714C0.831652 3.66667 0 2.8474 0 1.83333ZM3.5 11C3.5 9.98594 4.33165 9.16667 5.35714 9.16667H20.6429C21.6701 9.16667 22.5 9.98594 22.5 11C22.5 12.0141 21.6701 12.8333 20.6429 12.8333H5.35714C4.33165 12.8333 3.5 12.0141 3.5 11ZM17.6429 22H8.35714C7.33165 22 6.5 21.1807 6.5 20.1667C6.5 19.1526 7.33165 18.3333 8.35714 18.3333H17.6429C18.6701 18.3333 19.5 19.1526 19.5 20.1667C19.5 21.1807 18.6701 22 17.6429 22Z"
        fill="#2F374B"
      />
    </svg>
  );
};

export default FilterIcon;
