import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import { currencyFormatter } from "../components/global/utils";
import styled from "styled-components";
import { DateTime } from "luxon";
import { first, noop } from "lodash";
import { cssBreakpoints } from "../components/global/theme";
import Container from "../components/baseComponents/Container";
import Div from "../components/baseComponents/Div";
import Row from "../components/baseComponents/Row";
import Button from "../components/baseComponents/Button";
import PageLoader from "../components/PageLoader";
import PlaidButton from "../components/PlaidButton";
import Label from "../components/baseComponents/Label";
import InsufficientFunds from "../components/TenantComponents/InsufficientFunds";
import ErrorMessage from "../components/baseComponents/ErrorMessage";
import responseStatuses from "../enums/responseStatuses";
import {
  fundAdditionalAmounts,
  getPendingAdditionalFundsRequests,
} from "../utils/depositUtils";
import { checkAdditionalFundsFinancials } from "../utils/accountUtils";
import {
  GenericObject,
  AdditionalFundsRequest,
  BankAccount,
} from "../components/global/ModelInterfaces";
import useGetBankAccount from "../utils/useGetBankAccount";
const StyledForm = styled(Row)`
  ${cssBreakpoints("margin-top", [{ sm: "2rem" }])}
`;

const StyledButton = styled(Button)`
  ${cssBreakpoints("margin-top", [{ sm: "2rem" }])}
`;
const StyledTitle = styled(Row)`
  ${cssBreakpoints("margin-bottom", [{ sm: "2rem" }])}
`;

const StyledContainer = styled(Div)`
  margin-top: 4rem;
`;

const StyledRequestsContainer = styled(Row)`
  margin-top: 1rem;
`;

const StyledButtonContainer = styled(Div)`
  margin-top: 1rem;
`;

const AdditionalFundsPayment = () => {
  const history = useHistory();
  const { prepaymentUUID } = useParams<GenericObject>();
  const { bankAccount, loadingBankAccounts } = useGetBankAccount();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [securityDepositRequests, setSecurityDepositRequests] = useState<
    AdditionalFundsRequest[]
  >([]);
  const [lastMonthsRentRequests, setLastMonthsRentRequests] = useState<
    AdditionalFundsRequest[]
  >([]);
  const [paying, setPaying] = useState(false);
  const [sufficientFunds, setSufficientFunds] = useState(true);
  const [error, setError] = useState("");
  const [total, setTotal] = useState(0);

  const handleFundDeposits = () => {
    setPaying(true);
    fundAdditionalAmounts(prepaymentUUID)
      .then(() => {
        setSuccess(true);
        setPaying(false);
      })
      .catch((error) => {
        setError(error.message);
        setPaying(false);
      });
  };

  useEffect(() => {
    if (bankAccount) {
      setLoading(true);
      getPendingAdditionalFundsRequests(prepaymentUUID)
        .then((res) => {
          setSecurityDepositRequests(res.security_deposit);
          setLastMonthsRentRequests(res.last_months_rent);
          checkAdditionalFundsFinancials(bankAccount.uuid, prepaymentUUID)
            .then((sufficientFunds) => {
              setSufficientFunds(sufficientFunds);
              setLoading(false);
            })
            .catch((err) => {
              if (err.status === responseStatuses.REQUIRES_PLAID_UPDATE) {
                history.push({
                  pathname: "/update-bank-credentials",
                  state: {
                    returnUrl: "/dashboard",
                    successUrl: `/tenant/additional-funds/${prepaymentUUID}`,
                  },
                });
              } else {
                setError(err.message);
                setLoading(false);
              }
            });
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    }
  }, [bankAccount]);

  useEffect(() => {
    let tmpTotal = 0;
    if (securityDepositRequests) {
      securityDepositRequests.forEach((request) => {
        tmpTotal += request.amount + request.fee;
      });
    }
    if (lastMonthsRentRequests) {
      lastMonthsRentRequests.forEach((request) => {
        tmpTotal += request.amount + request.fee;
      });
    }
    setTotal(tmpTotal);
  }, [securityDepositRequests, lastMonthsRentRequests]);

  if (loading || loadingBankAccounts) {
    return <PageLoader />;
  } else if (!bankAccount || bankAccount.pending_confirmation) {
    return (
      <StyledContainer>
        <Row justifyContent="center">
          <Label width={{ default: 1 }} alignItems="center">
            {!bankAccount
              ? "Please link a bank account so you can finish funding your deposits"
              : "Please confirm your micro-deposits before continuing"}
          </Label>
          <StyledButtonContainer>
            <PlaidButton
              includeAssets={true}
              onSuccess={noop}
              canPayByCard={true}
            />
          </StyledButtonContainer>
        </Row>
      </StyledContainer>
    );
  } else if (!sufficientFunds) {
    return (
      <StyledContainer>
        <Row justifyContent="center">
          <Div width={{ default: 1, lg: 6 / 12 }}>
            <InsufficientFunds amountDue={total} />
          </Div>
        </Row>
      </StyledContainer>
    );
  } else if (success) {
    return (
      <StyledContainer>
        <Row justifyContent="center">
          <Label width={{ default: 1 }} alignItems="center">
            You have successfully funded the remaining portions of your
            deposits.
          </Label>
          <Div width={{ default: 1, lg: 4 / 12 }}>
            <StyledButton
              text="Return to Dashboard"
              onClick={() => {
                window.location.href = "/dashboard";
              }}
            />
          </Div>
        </Row>
      </StyledContainer>
    );
  } else {
    return (
      <Container>
        <StyledForm justifyContent="center">
          <Div width={{ default: 1, lg: 1 / 2 }} alignItems={"center"}>
            <StyledTitle>
              <Div width={{ default: 1 }} alignItems={"center"}>
                <h2>Fund Additional Deposit Amounts</h2>
              </Div>
            </StyledTitle>
            <Row justifyContent="center">
              <Div width={{ default: 1, lg: 10 / 12 }}>
                Clicking the button below will fund all outstanding requests for
                additional funds. Each will appear as a separate line item in
                your bank account.
              </Div>
            </Row>
            {securityDepositRequests && (
              <StyledRequestsContainer justifyContent="center">
                <Label width={{ default: 1, lg: 1 / 3 }}>
                  Security Deposit Requests:
                </Label>
                <Div width={{ default: 1, lg: 1 / 2 }}>
                  {securityDepositRequests.map(
                    (request: AdditionalFundsRequest) => {
                      return (
                        <Div width={{ sm: 1 }}>
                          <Row>
                            Requested on{" "}
                            {`${DateTime.fromISO(request.requested_at).toFormat(
                              "MMMM dd, yyyy"
                            )}`}
                          </Row>
                          <Row>
                            Amount: {currencyFormatter.format(request.amount)}
                          </Row>
                          {request.fee ? (
                            <Row>
                              Fee: {currencyFormatter.format(request.fee)}
                            </Row>
                          ) : null}
                        </Div>
                      );
                    }
                  )}
                </Div>
              </StyledRequestsContainer>
            )}
            {lastMonthsRentRequests && (
              <StyledRequestsContainer justifyContent="center">
                <Label width={{ default: 1, lg: 1 / 3 }}>
                  Last Month's Rent Requests:
                </Label>
                <Div width={{ default: 1, lg: 1 / 2 }}>
                  {lastMonthsRentRequests.map(
                    (request: AdditionalFundsRequest) => {
                      return (
                        <Div width={{ sm: 1 }}>
                          <Row>
                            Requested on{" "}
                            {`${DateTime.fromISO(request.requested_at).toFormat(
                              "MMMM dd, yyyy"
                            )}`}
                          </Row>
                          <Row>
                            Amount: {currencyFormatter.format(request.amount)}
                          </Row>
                          {request.fee ? (
                            <Row>
                              Fee: {currencyFormatter.format(request.fee)}
                            </Row>
                          ) : null}
                        </Div>
                      );
                    }
                  )}
                </Div>
              </StyledRequestsContainer>
            )}
            <StyledRequestsContainer justifyContent="center">
              <Label width={{ default: 1, lg: 1 / 3 }}>Total:</Label>
              <Div width={{ default: 1, lg: 1 / 2 }}>
                {currencyFormatter.format(total)}
              </Div>
            </StyledRequestsContainer>
            <Row justifyContent="center">
              <Div width={{ default: 1, lg: 6 / 12 }}>
                <StyledButton
                  type="primary"
                  text={`Fund Deposits`}
                  onClick={handleFundDeposits}
                  loading={paying}
                />
              </Div>
            </Row>
            {error && (
              <Row justifyContent="center">
                <ErrorMessage>{error}</ErrorMessage>
              </Row>
            )}
          </Div>
        </StyledForm>
      </Container>
    );
  }
};

export default AdditionalFundsPayment;
