import { DateTime } from "luxon";
import { orderBy } from "lodash";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../..";
import Div from "../baseComponents/Div";
import PageLoader from "../PageLoader";
import { Invite } from "../global/ModelInterfaces";
import InviteDashboard from "./InviteDashboard";

const PendingInvitesView = () => {
  const inviteSlice: { loading: Boolean; objects: Invite[] } = useAppSelector(
    (state: RootState) => state.invites
  );

  const loadingInvites = inviteSlice.loading;
  const unsortedInvites = inviteSlice.objects;
  const pendingInvites = orderBy(unsortedInvites, (invite) => {
    return DateTime.fromFormat(invite.invited_at, "yyyy-MM-dd");
  });

  if (loadingInvites) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  }
  return (
    <InviteDashboard invites={pendingInvites} title={"Unaccepted Invites"} />
  );
};

export default PendingInvitesView;
