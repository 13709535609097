import { useState, useEffect } from "react";
import {
  GenericObject,
  TenantOnboardingObject,
} from "../../../components/global/ModelInterfaces";
import { dateShortFormatter } from "../../../components/global/utils";
import { useAppDispatch } from "../../../store/hooks";
import styled from "styled-components";
import { some, update } from "lodash";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import DropdownCard from "../../../components/baseComponents/DropdownCard";
import ErrorMessage from "../../../components/baseComponents/ErrorMessage";
import Button from "../../../components/baseComponents/Button";
import { markInsuranceStepCompleted } from "../../utils/prepaymentUtils";
import { updatePrepaymentSection } from "../../../store/nucleusPrepayments";
import { nucleusUserPermissions } from "../../../enums/nucleusPermissions";
import useNucleusPermissions from "../../../utils/useNucleusPermissions";

interface Props {
  onboardingInfo: TenantOnboardingObject;
}

const StyledLabel = styled.span`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const OnboardingInfo = ({ onboardingInfo }: Props) => {
  const dispatch = useAppDispatch();
  const [hasEditPermissions, setHasEditPermissions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { loadingPermissions, prepaymentOverviewPermission } =
    useNucleusPermissions();

  useEffect(() => {
    if (!loadingPermissions) {
      setHasEditPermissions(
        prepaymentOverviewPermission === nucleusUserPermissions.WRITE
      );
    }
  }, [prepaymentOverviewPermission]);

  const handleCompleteInsuranceStep = () => {
    const successCallback = (
      updated_onboarding_info: TenantOnboardingObject
    ) => {
      setLoading(false);
      dispatch(
        updatePrepaymentSection(
          onboardingInfo.prepayment_uuid,
          "onboarding_info",
          updated_onboarding_info
        )
      );
    };
    const failureCallback = (error_message: string) => {
      setLoading(false);
      setError(error_message);
    };
    setLoading(true);
    markInsuranceStepCompleted(
      onboardingInfo.uuid,
      successCallback,
      failureCallback
    );
  };

  return (
    <DropdownCard title="Onboarding Info">
      {some(onboardingInfo) ? (
        <Div>
          <Row>
            <StyledLabel>Introduction Step Completed At:</StyledLabel>
            {onboardingInfo.introduction_completed_at ? (
              <>
                {dateShortFormatter(onboardingInfo.introduction_completed_at)}
              </>
            ) : (
              <>Step not completed.</>
            )}
          </Row>
          <Row mt={{ default: 2 }}>
            <StyledLabel>Personal Info Step Completed At:</StyledLabel>
            {onboardingInfo.personal_info_completed_at ? (
              <>
                {dateShortFormatter(onboardingInfo.personal_info_completed_at)}
              </>
            ) : (
              <>Step not completed.</>
            )}
          </Row>
          <Row mt={{ default: 2 }}>
            <StyledLabel>Bank Link Step Completed At:</StyledLabel>
            {onboardingInfo.bank_link_completed_at ? (
              <>{dateShortFormatter(onboardingInfo.bank_link_completed_at)}</>
            ) : (
              <>Step not completed.</>
            )}
          </Row>
          <Row mt={{ default: 2 }} alignItems="center">
            <StyledLabel>Renters Insurance Step Completed At:</StyledLabel>
            {onboardingInfo.renters_insurance_completed_at ? (
              <>
                {dateShortFormatter(
                  onboardingInfo.renters_insurance_completed_at
                )}
              </>
            ) : (
              <>Step not completed.</>
            )}
            {!onboardingInfo.renters_insurance_completed_at &&
              hasEditPermissions && (
                <Div width={{ default: 4 / 12 }}>
                  <Button
                    text="Complete Renters Insurance Step"
                    loading={loading}
                    onClick={handleCompleteInsuranceStep}
                  ></Button>
                </Div>
              )}
          </Row>
          <Row mt={{ default: 2 }}>
            <StyledLabel>Payment Step Completed At:</StyledLabel>
            {onboardingInfo.payment_completed_at ? (
              <>{dateShortFormatter(onboardingInfo.payment_completed_at)}</>
            ) : (
              <>Step not completed.</>
            )}
          </Row>
        </Div>
      ) : (
        <Row>
          <ErrorMessage>
            Could not find this prepayment's onboarding information, please
            reload and try again.
          </ErrorMessage>
        </Row>
      )}
      {error && (
        <Row mt={{ default: 3 }}>
          <ErrorMessage>{error}</ErrorMessage>
        </Row>
      )}
    </DropdownCard>
  );
};

export default OnboardingInfo;
