import { useEffect } from "react";
import styled from "styled-components";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import Button from "../../../components/baseComponents/Button";
import DropdownCard from "../../../components/baseComponents/DropdownCard";
import ErrorMessage from "../../../components/baseComponents/ErrorMessage";
import ConfirmationModal from "../../../components/baseComponents/ConfirmationModal";
import useNucleusPermissions from "../../../utils/useNucleusPermissions";
import { nucleusUserPermissions } from "../../../enums/nucleusPermissions";
import { GenericObject } from "../../../components/global/ModelInterfaces";
import { some } from "lodash";
import { useState } from "react";
import { getLatestBalance, unlinkBankAccount } from "../../utils/bankLinkUtils";
import {
  currencyFormatter,
  dateTimeShortWithSecondsFormatter,
} from "../../../components/global/utils";
interface Props {
  bankInfo: GenericObject;
}
const StyledLabel = styled.span`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;
const StyledText = styled.span`
  font-size: ${(props) => props.theme.font_size.headlineMD};
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const CurrentBankInfo = ({ bankInfo }: Props) => {
  const [bankInformation, setBankInformation] = useState(bankInfo);
  const [latestBalance, setLatestBalance] = useState(
    currencyFormatter.format(bankInfo ? bankInfo.latest_balance : "")
  );
  const [timestamp, setTimestamp] = useState("");
  const [getBalanceMessage, setGetBalanceMessage] = useState(""); // Error Message for Get Latest Balance
  const [fetchingBalance, setFetchingBalance] = useState(false); // For Get Latest Balance button
  const [unlinkMessage, setUnlinkMessage] = useState(""); // Error Message for Unlink Bank Account
  const [requestUnlink, setRequestUnlink] = useState(false); // Confirmation model opens if true
  const [hasEditPermissions, setHasEditPermissions] = useState(false);
  const { loadingPermissions, prepaymentOverviewPermission } =
    useNucleusPermissions();

  useEffect(() => {
    if (!loadingPermissions) {
      setHasEditPermissions(
        prepaymentOverviewPermission === nucleusUserPermissions.WRITE
      );
    }
  }, [prepaymentOverviewPermission]);

  const handleGetBalance = () => {
    setFetchingBalance(true);
    const successCallback = (response: GenericObject) => {
      setFetchingBalance(true);
      setGetBalanceMessage("");
      if (response.latestBalance) {
        setLatestBalance(response.latestBalance);
      }
      if (response.balanceUpdatedAt) {
        setTimestamp(
          dateTimeShortWithSecondsFormatter(response.balanceUpdatedAt)
        );
      }
      setFetchingBalance(response.stillPolling);
    };
    const failureCallback = (error: string) => {
      setGetBalanceMessage(`${error}. Please try again.`);
      setFetchingBalance(false);
    };
    getLatestBalance(
      bankInformation.balance_updated_at,
      bankInformation.owner_email,
      successCallback,
      failureCallback
    );
  };

  const handleUnlinkAccount = () => {
    const successCallback = async () => {
      setUnlinkMessage("");
      setBankInformation({});
      setRequestUnlink(false);
    };
    const failureCallback = (error: string) => {
      setUnlinkMessage(error + " Please try again.");
      setRequestUnlink(false);
    };
    unlinkBankAccount(
      bankInformation.uuid,
      bankInformation.owner_email,
      successCallback,
      failureCallback
    );
  };

  return (
    <DropdownCard title="Current Bank Info">
      <Div width={{ default: 12 / 12 }}>
        {some(bankInformation) ? (
          <>
            {" "}
            <Row>
              <Div width={{ default: 8 / 12 }}>
                <Row>
                  <StyledLabel>Bank Account UUID:</StyledLabel>
                  {bankInformation.uuid}
                </Row>
                <Row>
                  <StyledLabel>Bank Name:</StyledLabel>
                  {bankInformation.bank_name}
                </Row>
                <Row>
                  <StyledLabel>Account Name:</StyledLabel>
                  {bankInformation.name}
                </Row>
                <Row>
                  <StyledLabel>Mask</StyledLabel>
                  {bankInformation.mask}
                </Row>
                <Row>
                  <StyledLabel>Link Token:</StyledLabel>
                  {bankInformation.link_token}
                </Row>
                <Row>
                  <StyledLabel>Asset Report Token:</StyledLabel>
                  {bankInformation.asset_report_token}
                </Row>
                <Row>
                  <StyledLabel>Pending Confirmation:</StyledLabel>
                  {bankInformation.pending_confirmation.toString()}
                </Row>
                <Row>
                  <StyledLabel>Manually Verified:</StyledLabel>
                  {bankInformation.manually_verified.toString()}
                </Row>
                <Row>
                  <StyledLabel>Payment Personal Verified:</StyledLabel>
                  {bankInformation.payment_personal_verified.toString()}
                </Row>
                <Row>
                  <StyledLabel>Plaid Link:</StyledLabel>
                  {bankInformation.plaid_link
                    ? bankInformation.plaidLink
                    : "N/A"}
                </Row>
                <Row>
                  <StyledLabel>Owner:</StyledLabel>
                  {bankInformation.owner}
                </Row>
                <Row>
                  <StyledLabel>Customer Payment ID:</StyledLabel>
                  {bankInformation.customer_payment_id}
                </Row>
                <Row pt={{ lg: 2 }}>
                  <StyledLabel>Balance Info:</StyledLabel>
                  {bankInformation.manually_verified ? (
                    <Div width={{ default: 1, lg: 4 / 12 }}>
                      Unable to retrieve balances for manually linked accounts
                    </Div>
                  ) : (
                    <>
                      <Div width={{ default: 1, lg: 4 / 12 }}>
                        <Row>
                          <StyledLabel>Latest Balance:</StyledLabel>
                          {latestBalance}
                        </Row>
                        <Row>
                          <StyledLabel>Last Updated:</StyledLabel>
                          {/* TODO USE REDUX */}
                          {timestamp ||
                            dateTimeShortWithSecondsFormatter(
                              bankInformation.balance_updated_at
                            )}
                        </Row>
                      </Div>
                      <Div width={{ default: 1, lg: 4 / 12 }}>
                        <Row>
                          <Button
                            text={"Get Latest Balance"}
                            onClick={handleGetBalance}
                            loading={fetchingBalance}
                            disabled={fetchingBalance}
                          />
                          {getBalanceMessage && (
                            <ErrorMessage>{getBalanceMessage}</ErrorMessage>
                          )}
                        </Row>
                      </Div>
                    </>
                  )}
                </Row>
              </Div>
              <Div width={{ default: 4 / 12 }}>
                {hasEditPermissions && (
                  <Row pt={{ default: 3 }} pb={{ default: 3 }}>
                    <Button
                      text="Unlink Bank Account"
                      onClick={() => setRequestUnlink(true)}
                    />
                    {unlinkMessage && (
                      <ErrorMessage>{unlinkMessage}</ErrorMessage>
                    )}
                  </Row>
                )}
              </Div>
            </Row>
          </>
        ) : (
          <Row>
            <StyledText>
              This user does not currently have a bank account linked
            </StyledText>
          </Row>
        )}
      </Div>
      {requestUnlink && (
        <ConfirmationModal
          confirmationOpen={requestUnlink}
          onClose={() => setRequestUnlink(false)}
          buttonText="Unlink Bank Account"
          onConfirm={handleUnlinkAccount}
          message={
            "Are you sure you want to unlink this bank account from the user?"
          }
        />
      )}
    </DropdownCard>
  );
};

export default CurrentBankInfo;
