import Div from "../../baseComponents/Div";
import PageLoader from "../../PageLoader";
import useCheckFinancials from "../../../utils/useCheckFinancials";
import LastMonthsRentPayment from "./LastMonthsRentPayment";
const LastMonthsRentPaymentSubstep = () => {
  const { financialInfo, loadingFinancialInfo, financialInfoError } =
    useCheckFinancials();

  if (loadingFinancialInfo) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  }

  if (!financialInfo) {
    return <Div>{financialInfoError}</Div>;
  }
  // If tenant is enrolled on a payment plan, but does not have enough to pay LMR + initial payment + platform fee
  return <LastMonthsRentPayment financialInfo={financialInfo} />;
};

export default LastMonthsRentPaymentSubstep;
