import { useEffect, useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../..";
import { v4 as uuid } from "uuid";
import Div from "../baseComponents/Div";
import Row from "../baseComponents/Row";
import DisbursementConfirmation from "./Disbursement/DisbursementConfirmation";
import DisbursementSetup from "./Disbursement/DisbursementSetup";
import PageLoader from "../PageLoader";
import {
  getSecurityDepositInfo,
  getTotalDeductionAmount,
} from "../../utils/disbursementUtils";
import {
  Deposit,
  Deduction,
  GenericObject,
  BankAccount,
  Prepayment,
} from "../global/ModelInterfaces";
import prepaymentStatuses from "../../enums/prepaymentStatuses";

const DisbursementView = () => {
  const [deductions, setDeductions] = useState<Deduction[]>([]);
  const [releaseBank, setReleaseBank] = useState<BankAccount>();
  const [securityDeposit, setSecurityDeposit] = useState<Deposit | null>(null);
  const [loadingTenantBank, setLoadingTenantBank] = useState(true);
  const [loadingError, setLoadingError] = useState("");
  const [confirmingDisbursement, setConfirmingDisbursement] = useState(false);

  const { prepaymentUUID } = useParams<GenericObject>();
  const prepaymentSlice: { loading: boolean; objects: Prepayment[] } =
    useAppSelector((state: RootState) => state.prepayments);

  const loadingDeposits = prepaymentSlice.loading;
  const selectedPrepayment = prepaymentSlice.objects.find(
    (prepayment: Prepayment) => prepayment.uuid === prepaymentUUID
  );

  const updateReleaseBank = (e: any) => {
    setReleaseBank(e.value);
  };

  const updateDeductions = (newDeductions: Deduction[]) => {
    setDeductions(newDeductions);
  };

  const proceedToConfirmation = () => {
    setConfirmingDisbursement(true);
  };

  const backToSetup = () => {
    setConfirmingDisbursement(false);
  };

  const loadSecurityDepositInfo = () => {
    getSecurityDepositInfo(prepaymentUUID)
      .then((securityDepositResponse) => {
        setSecurityDeposit(securityDepositResponse);
      })
      .catch((e) => {
        setLoadingError(
          "There was an error loading some details about this deposit, please refresh the page."
        );
      });
    setLoadingTenantBank(false);
  };

  useEffect(() => {
    loadSecurityDepositInfo();
  }, []);

  if (loadingDeposits || loadingTenantBank) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  }
  if (loadingError) {
    return (
      <Row alignItems="center">
        <Div>{loadingError}</Div>
      </Row>
    );
  } else if (!selectedPrepayment) {
    return <Redirect to={"/forbidden"} />;
  } else if (
    securityDeposit &&
    securityDeposit.status_id !== prepaymentStatuses.ACTIVE
  ) {
    return <Redirect to={`/dashboard/tenant/${prepaymentUUID}`} />;
  } else {
    return (
      <>
        {confirmingDisbursement ? (
          <DisbursementConfirmation
            prepayment={selectedPrepayment}
            deductions={deductions}
            totalDeductionAmount={getTotalDeductionAmount(deductions)}
            releaseBank={releaseBank}
            backToSetup={backToSetup}
          />
        ) : (
          <DisbursementSetup
            deductions={deductions}
            totalDeductionAmount={getTotalDeductionAmount(deductions)}
            updateDeductions={updateDeductions}
            proceedToConfirmation={proceedToConfirmation}
            releaseBank={releaseBank}
            updateReleaseBank={updateReleaseBank}
          />
        )}
      </>
    );
  }
};
export default DisbursementView;
