const SET_INVITES = "invites/SET_INVITES";
const ADD_INVITE = "invites/ADD_INVITE";
const UPDATE_INVITE = "invites/UPDATE_INVITE";
const REMOVE_INVITE = "invites/REMOVE_INVITE";

export const setInvites = (invites) => {
  return {
    type: SET_INVITES,
    invites,
  };
};

export const addInvite = (invite) => {
  return {
    type: ADD_INVITE,
    invite,
  };
};

export const updateInvite = (invite) => {
  return {
    type: UPDATE_INVITE,
    invite,
  };
};

export const removeInvite = (prepaymentUUID) => {
  return {
    type: REMOVE_INVITE,
    prepaymentUUID,
  };
};

const initialState = {
  loading: true,
  objects: [],
};

export default function invitesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_INVITES:
      return {
        ...state,
        loading: false,
        objects: action.invites,
      };
    case ADD_INVITE:
      return {
        ...state,
        objects: state.objects.concat(action.invite),
      };
    case UPDATE_INVITE:
      return {
        ...state,
        objects: state.objects.map((invite) => {
          if (action.invite.uuid === invite.uuid) {
            return action.invite;
          } else return invite;
        }),
      };
    case REMOVE_INVITE:
      return {
        ...state,
        objects: state.objects.filter(
          (invite) => invite.prepayment_uuid !== action.prepaymentUUID
        ),
      };
    default:
      return state;
  }
}
