import Input from "../baseComponents/Input";
import styled from "styled-components";
import Div from "../baseComponents/Div";
import SearchResultDisplay from "./SearchResultDisplay";
import { useState, useEffect, useRef } from "react";
import {
  useSearchBuildings,
  useSearchPrepayments,
  useSearchUnits,
} from "../../utils/searchHooks";

const StyledInputWrapper = styled.div`
  width: 100%;
`;

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const ref = useRef<any>();
  const buildingResults = useSearchBuildings(searchTerm);
  const prepaymentResults = useSearchPrepayments(searchTerm);
  const unitResults = useSearchUnits(searchTerm);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutsideSearch = (event: any) => {
      if (ref.current && ref.current.contains(event.target)) {
        event.target.value && setSearchTerm(event.target.value);
      } else {
        setSearchTerm("");
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutsideSearch);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutsideSearch);
    };
  }, [ref]);

  return (
    <Div width={{ default: 1 }} alignItems={"center"}>
      <StyledInputWrapper ref={ref}>
        <Input
          value={searchTerm}
          backgroundColor="light"
          placeholderText={"🔎 Let's find it..."}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
        {searchTerm && (
          <SearchResultDisplay
            buildingResults={buildingResults}
            prepaymentResults={prepaymentResults}
            unitResults={unitResults}
            onSelect={() => setSearchTerm("")}
          />
        )}
      </StyledInputWrapper>
    </Div>
  );
};
export default SearchBar;
