import { useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { noop } from "lodash";

import Button from "../../baseComponents/Button";
import PlaidButton from "../../PlaidButton";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import useGetBankAccount from "../../../utils/useGetBankAccount";
import InsufficientFunds from "../InsufficientFunds";
import PageLoader from "../../PageLoader";
import useGetCurrentPrepayment from "../../../utils/useGetCurrentPrepayment";
import { calculateDepositTotal } from "./tenantOnboardingUtils";
import {
  Deposit,
  TenantFinancialInfo,
  GenericObject,
} from "../../global/ModelInterfaces";
import { currencyFormatter } from "../../global/utils";
import { makeDepositPayment } from "../../../utils/depositUtils";
import { updateTenantDeposits } from "../../../store/tenantInfo";
import ErrorMessage from "../../baseComponents/ErrorMessage";
import PaymentProcessorRestricted from "../PaymentProcessorRestricted";
interface Props {
  financialInfo: TenantFinancialInfo;
  financialInfoLoading: boolean;
}

const SecurityDepositPayment = ({
  financialInfo,
  financialInfoLoading,
}: Props) => {
  const { prepaymentUUID } = useParams<GenericObject>();
  const dispatch = useAppDispatch();
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [paymentError, setPaymentError] = useState(false);

  const { bankAccount, loadingBankAccounts } = useGetBankAccount();

  const { prepayment } = useGetCurrentPrepayment(prepaymentUUID);
  const securityDeposit = prepayment.deposits.security_deposit;

  const paymentProcessorRestricted: Boolean = useAppSelector(
    (state) =>
      state.tenantInfo.objects.personalInfo.payment_processor_restricted
  );

  const handlePayment = () => {
    setPaymentProcessing(true);
    const successCallback = (deposits: Deposit[]) => {
      dispatch(updateTenantDeposits(deposits));
      setPaymentProcessing(false);
    };
    const failureCallback = () => {
      setPaymentProcessing(false);
      setPaymentError(true);
    };
    makeDepositPayment(
      bankAccount,
      securityDeposit.uuid,
      successCallback,
      failureCallback
    );
  };

  if (loadingBankAccounts || financialInfoLoading) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  }

  if (!bankAccount) {
    return (
      <Div>
        <p>
          In order to pay your deposits, you must have a bank account linked.
          Please click below to link your account.
        </p>
        <PlaidButton onSuccess={noop} />
      </Div>
    );
  }

  if (!securityDeposit) {
    return <Redirect to="/404" />;
  }

  if (!financialInfo.sufficientFundsFullPayment) {
    return (
      <InsufficientFunds
        amountDue={Number(calculateDepositTotal(securityDeposit))}
        paymentPlanApproved={financialInfo.paymentPlanApproval.is_approved}
      />
    );
  } else
    return (
      <>
        <Div>
          <Row>
            <h2>Confirm and Pay Security Deposit</h2>
          </Row>
          <Div>
            <Row>
              <h4>Amount Due</h4>
            </Row>
            <Row alignItems="center" justifyContent="space-between">
              <p>Security Deposit:</p>
              <p>{currencyFormatter.format(securityDeposit.amount)}</p>
            </Row>
            {!!securityDeposit.dwolla_fee && (
              <Row alignItems="center" justifyContent="space-between">
                <p>Platform Fee:</p>
                <p>{currencyFormatter.format(securityDeposit.dwolla_fee)}</p>
              </Row>
            )}
            <Row alignItems="center" justifyContent="space-between">
              <p>Total:</p>
              <p>${calculateDepositTotal(securityDeposit)}</p>
            </Row>
            <Row>
              <h4>Bank Account:</h4>
              <h4>
                {bankAccount.bank_name}
                &nbsp; •••• &nbsp;
                {bankAccount.mask}
              </h4>
            </Row>
            <Row justifyContent="center">
              <Div width={{ default: 1, lg: 6 / 12 }}>
                {paymentProcessorRestricted ? (
                  <PaymentProcessorRestricted />
                ) : (
                  <>
                    {paymentError && (
                      <ErrorMessage mb={{ default: 3 }} mt={{ default: 3 }}>
                        There was an error funding the deposit, please try again
                        or contact support@rentable.com if this issue persists.
                      </ErrorMessage>
                    )}
                    <Button
                      onClick={handlePayment}
                      loading={paymentProcessing}
                      disabled={paymentProcessing || paymentError}
                      text={"Fund Security Deposit"}
                    />
                  </>
                )}
              </Div>
            </Row>
          </Div>
        </Div>
      </>
    );
};

export default SecurityDepositPayment;
