import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../../store/hooks";
import styled from "styled-components";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import Button from "../../baseComponents/Button";
import PageLoader from "../../PageLoader";
import Input from "../../baseComponents/Input";
import { COLORS } from "../../global/colors";
import {
  disburseSecurityDeposit,
  showDeductionCategory,
  calculateRefundAmount,
} from "../../../utils/disbursementUtils";
import { updatePrepayment } from "../../../store/prepayments";
import {
  Prepayment,
  Deduction,
  BankAccount,
} from "../../global/ModelInterfaces";
import { getStreetAddress } from "../../../utils/globalUtils";
import { getStateRule, rulesList } from "../../global/StateRules/StateRules";

const StyledTitle = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.bolder};
  font-size: ${(props) => props.theme.font_size.headlineMD};
  color: ${COLORS.grey};
  line-height: ${(props) => props.theme.space[5]};
`;

const StyledSubTitle = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.bolder};
  font-size: ${(props) => props.theme.font_size.headlineSM};
  color: ${COLORS.grey};
  line-height: ${(props) => props.theme.space[4]};
`;

const StyledDeduction = styled(Div)`
  border: 2px solid ${COLORS.grey40};
  border-radius: 1.5rem;
`;

const StyledDivider = styled(Div)`
  height: 1px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.16);
`;

const SignatureField = styled(Input)`
  font-family: "Square Peg", cursive;
  font-size: 48px;
`;

const SignatureDisclosure = styled.div`
  font-size: 12px;
`;

const StyledTotalDeductions = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineXS};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  color: ${COLORS.danger};
`;

const StyledTotalDeposits = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineXS};
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const StyledTotalRefund = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineXS};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  color: ${COLORS.primary};
`;

interface Props {
  prepayment: Prepayment;
  deductions: Deduction[];
  totalDeductionAmount: number;
  releaseBank: BankAccount | undefined;
  backToSetup: Function;
}

const DisbursementConfirmation = ({
  prepayment,
  deductions,
  totalDeductionAmount,
  releaseBank,
  backToSetup,
}: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [disbursementError, setDisbursementError] = useState("");
  const [disburseLoading, setDisburseLoading] = useState(false);
  const [disburseComplete, setDisburseComplete] = useState(false);
  const [signature, setSignature] = useState("");
  const [signatureMissing, setSignatureMissing] = useState(false);

  const handleDisbursement = () => {
    if (mustSign() && !signature) {
      setSignatureMissing(true);
      return;
    }
    if (prepayment.deposits.security_deposit) {
      setDisburseLoading(true);
      disburseSecurityDeposit(
        prepayment.uuid,
        deductions,
        releaseBank,
        signature
      )
        .then((updatedPrepayment) => {
          dispatch(updatePrepayment(updatedPrepayment));
          setDisburseComplete(true);
        })
        .catch((e) => {
          setDisbursementError(`${e.message}`);
        })
        .finally(() => {
          setDisburseLoading(false);
        });
    } else {
      setDisbursementError("Security Deposit not found.");
    }
  };

  const handleSignatureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSignatureMissing(false);
    setSignature(e.target.value);
  };

  const mustSign = () => {
    return getStateRule({
      state: prepayment.state,
      rule: rulesList.MUST_SIGN_DEDUCTIONS,
    });
  };

  if (disburseLoading) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  } else if (disburseComplete) {
    return (
      <Row justifyContent="center">
        <Div width={{ default: 1 / 2 }}>
          <Div m={{ default: "0.8rem 0", lg: "0.8rem 0" }}>
            The Security Deposit was successfully disbursed!
          </Div>
          <Button
            text="Back to Tenant View"
            onClick={() => {
              history.push(`/dashboard/tenant/${prepayment.uuid}`);
            }}
            type="secondary"
          />
        </Div>
      </Row>
    );
  } else {
    return (
      <Row justifyContent="center">
        <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
          <StyledTitle mb={{ default: "0.5rem", lg: "0.5rem" }}>
            Confirm Details and Close Account
          </StyledTitle>
          <StyledSubTitle mb={{ default: "1.5rem", lg: "1.5rem" }}>
            Address:{" "}
            {getStreetAddress(prepayment.address_1, prepayment.address_2)}
          </StyledSubTitle>
          {deductions.length > 0 && (
            <>
              <StyledSubTitle mb={{ default: "1.5rem", lg: "1.5rem" }}>
                Deductions:
              </StyledSubTitle>
              <Div>
                {deductions.map((deduction) => {
                  return (
                    <StyledDeduction
                      p={{ default: "1rem", lg: "1rem" }}
                      mb={{ default: "0.5rem", lg: "0.5rem" }}
                    >
                      <Div>Category: {showDeductionCategory(deduction)}</Div>
                      <Div>Description: {deduction.description}</Div>
                      {deduction.documentation && (
                        <Div>Documentation: {deduction.documentation.name}</Div>
                      )}

                      <Div>Amount: ${deduction.amount}</Div>
                    </StyledDeduction>
                  );
                })}
                <StyledDivider m={{ default: "1rem 0", lg: "1rem 0" }} />
                <StyledTotalDeposits mb={{ default: "0.8rem", lg: "0.8rem" }}>
                  Security Deposit Amount: $
                  {prepayment.deposits.security_deposit.amount}
                </StyledTotalDeposits>
                <StyledTotalDeductions>
                  Total Deduction Amount: ${totalDeductionAmount}
                </StyledTotalDeductions>
              </Div>
            </>
          )}
          <Div m={{ default: "0.8rem 0", lg: "0.8rem 0" }}>
            {calculateRefundAmount(
              prepayment.deposits.security_deposit,
              deductions
            ) > 0 && (
              <StyledTotalRefund>
                Amount to be refunded to {prepayment.tenant_name} via ACH: $
                {calculateRefundAmount(
                  prepayment.deposits.security_deposit,
                  deductions
                )}
              </StyledTotalRefund>
            )}
            {disbursementError && (
              <Row justifyContent="center">
                <Div width={{ default: 1 / 2 }}>
                  <Div m={{ default: "0.8rem 0", lg: "0.8rem 0" }}>
                    {disbursementError}
                  </Div>
                </Div>
              </Row>
            )}
          </Div>
          {mustSign() && (
            <Row mb={{ default: "2rem", lg: "2rem" }}>
              <Div width={{ default: 1, lg: 1 / 2 }}>
                <div>Signature:</div>
                <SignatureField
                  onChange={handleSignatureChange}
                  value={signature}
                  error={signatureMissing}
                ></SignatureField>
                <SignatureDisclosure>
                  By typing your signature you certify that the information
                  above is correct.
                </SignatureDisclosure>
              </Div>
            </Row>
          )}
          <Row>
            <Div width={{ default: 1 / 2 }}>
              <Button
                type="secondary"
                text={"Back"}
                onClick={() => backToSetup()}
              />
            </Div>
            <Div width={{ default: 1 / 2 }}>
              <Button
                type="primary"
                text={"Close Account"}
                onClick={handleDisbursement}
              />
            </Div>
          </Row>
        </Div>
      </Row>
    );
  }
};

export default DisbursementConfirmation;
