import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { variant } from "styled-system";
import Div from "../baseComponents/Div";
import Button from "../baseComponents/Button";
import Modal from "../baseComponents/Modal";
import TitleHeader from "../baseComponents/TitleHeader";
import ErrorMessage from "../baseComponents/ErrorMessage";
import PageLoader from "../PageLoader";
import { RootState } from "../..";
import { GenericObject, Prepayment } from "../global/ModelInterfaces";
import { useAppSelector } from "../../store/hooks";
import { sendCustomTenantMessage } from "../../utils/accountUtils";
import { capitlizeString } from "../../utils/globalUtils";
import { COLORS } from "../global/colors";
import { cssBreakpoints } from "../global/theme";

const StyledContactRow = styled(Div)`
  border-bottom: 1px solid #cfcfcf;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  ${cssBreakpoints("font-size", [{ sm: ".75rem" }, { md: "1rem" }])}
`;

interface StyleInputProps {
  variant?: string;
  error?: boolean;
}

const StyledMessage = styled(Div)`
  border: 1px solid #cfcfcf;
  margin: 1rem 0;
  padding-top: 0.5rem;
  border-radius: 5px;
`;

const StyledText = styled(Div)`
  padding: 0 1rem;
  margin-bottom: 0.5rem;
`;

const StyledLink = styled.span`
  color: ${COLORS.purple};
  cursor: pointer;
`;

const StyledSuccess = styled(Div)`
  color: ${COLORS.primary};
  font-weight: 600;
  font-size: 24px;
`;

const StyledTextArea = styled.textarea<StyleInputProps>`
  width: 100%;
  min-height: 7.5rem;
  background-color: ${COLORS.grey10};
  border: none;
  border-radius: 5px;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  ${variant({
    prop: "error",
    variants: {
      true: {
        backgroundColor: COLORS.backgroundWarning,
      },
    },
  })}
`;

const ContactTenant = () => {
  const { prepaymentUUID } = useParams<GenericObject>();
  const prepaymentSlice: { loading: Boolean; objects: Prepayment[] } =
    useAppSelector((state: RootState) => state.prepayments);
  const loadingPrepayments = prepaymentSlice.loading;
  const selectedPrepayment = prepaymentSlice.objects.find(
    (prepayment: Prepayment) => prepayment.uuid === prepaymentUUID
  );
  const auth = useAppSelector((state) => state.auth);
  const currentUser = auth.user;
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState<GenericObject>({
    message: false,
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  if (loadingPrepayments) {
    return null;
  }

  const clearError = (key: string) => {
    let tmpErrors = errors;
    tmpErrors[key] = false;
    setErrors(tmpErrors);
    setErrorMessage(null);
  };

  const toggleModal = () => {
    setErrorMessage("");
    setMessage("");
    setSuccess(false);
    setShowModal((showModal) => !showModal);
  };

  const updateMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    clearError("message");
    setMessage(e.target.value);
  };

  const validateEmail = () => {
    let validForm = true;
    let tmpErrors = errors;
    if (!message) {
      tmpErrors = { ...tmpErrors, message: true };
      setErrorMessage("Please write a message for your email.");
      validForm = false;
      setErrors(tmpErrors);
    } else {
      setErrorMessage("");
    }
    return validForm;
  };

  const successCallback = () => {
    setSuccess(true);
    setSending(false);
  };
  const failureCallback = (error: string) => {
    setErrorMessage(error);
    setSending(false);
  };

  const handleSubmit = () => {
    if (validateEmail()) {
      setSending(true);
      sendCustomTenantMessage(
        prepaymentUUID,
        message,
        successCallback,
        failureCallback
      );
    }
  };

  const renderModalContent = () => {
    return (
      <Div width={{ default: 1, lg: 11 / 12 }}>
        <TitleHeader title="Send an email to your tenant!" />
        {errorMessage && (
          <ErrorMessage alignItems="center">{errorMessage}</ErrorMessage>
        )}
        {sending ? (
          <PageLoader />
        ) : (
          <>
            {success ? (
              <StyledSuccess alignItems="center">
                Your email was sent successfully!
              </StyledSuccess>
            ) : (
              <>
                <Div>
                  <StyledContactRow>
                    To: {selectedPrepayment?.tenant_email}
                  </StyledContactRow>
                  <StyledContactRow>
                    Subject: Message from your Property Manager
                  </StyledContactRow>
                </Div>
                <StyledMessage>
                  <StyledText>
                    Hello {capitlizeString(selectedPrepayment?.tenant_name)},
                  </StyledText>
                  <StyledTextArea
                    onChange={updateMessage}
                    value={message}
                    placeholder={"Write your message here"}
                    error={errors.message}
                  />
                  <StyledText>
                    {selectedPrepayment?.tenant_id ? (
                      <span>
                        You can login into your Rentable account{" "}
                        <StyledLink>here</StyledLink>.
                      </span>
                    ) : (
                      <span>
                        You can sign up for your Rentable account by clicking on
                        this <StyledLink>link</StyledLink>.
                      </span>
                    )}
                    <br />
                    If you have any questions or concerns, please reach out to
                    me at {currentUser.email} or the Rentable team at
                    support@rentable.com.
                  </StyledText>
                </StyledMessage>
                <Button
                  type="primary"
                  text="Send Email"
                  onClick={handleSubmit}
                />
              </>
            )}
          </>
        )}
      </Div>
    );
  };

  return (
    <>
      <Button type="secondary" text="Contact Tenant" onClick={toggleModal} />
      <Modal
        showModal={showModal}
        closeModal={toggleModal}
        children={renderModalContent()}
      />
    </>
  );
};

export default ContactTenant;
