import { GenericObject } from "../components/global/ModelInterfaces";
import { checkResponseStatus } from "./globalUtils";

const axios = require("axios").default;

export const createFee = async (
  prepaymenUUID: string,
  amount: Number,
  description: string,
  type: string
) => {
  const request = {
    prepaymenUUID: prepaymenUUID,
    amount: amount,
    description: description,
    type: type,
  };
  axios
    .post("/fee/create-fee", JSON.stringify(request))
    .then((res: GenericObject) => {
      const data = res.data;
      return {
        amount: data.amount,
        description: data.description,
        type: data.type,
        uuid: data.uuid,
      };
    });
};

export const makeFeesPayment = async (
  prepaymentUUID: string,
  callBackSuccess: Function,
  callBackFailure: Function
) => {
  axios
    .post(
      "/api/fee/pay-fees",
      JSON.stringify({
        prepayment_uuid: prepaymentUUID,
      })
    )
    .then((res: any) => {
      const data = res.data;
      callBackSuccess(data.fees);
    })
    .catch((err: any) => {
      checkResponseStatus(err.status);
      callBackFailure();
      throw new Error(err);
    });
};

// TODO? Add Fee/Delete Fee
export const setDefaultBank = (
  bankUuid: string,
  callBackSucces: Function,
  callBackFailure: Function
) => {
  axios
    .post(
      "/api/company/set-default-bank",
      JSON.stringify({
        bank_uuid: bankUuid,
      })
    )
    .then((res: any) => {
      callBackSucces(res.data.values);
    })
    .catch((err: any) => {
      checkResponseStatus(err.status);
      callBackFailure();
      throw new Error(err);
    });
};
