import BuildingPermissions from "./BuildingPermissions";
import Row from "../../../components/baseComponents/Row";

const CompanyBuildingsTab = () => {
  return (
    <>
      <Row mt={{ default: 3 }}>
        <BuildingPermissions />
      </Row>
    </>
  );
};

export default CompanyBuildingsTab;
