import React, { useState } from "react";
import styled from "styled-components";
import Div from "./Div";
import Row from "./Row";
import { COLORS } from "../global/colors";
import { GenericObject, Option } from "../global/ModelInterfaces";
import XIcon from "../../images/XIcon";
import Button from "./Button";
import DropdownCard from "./DropdownCard";
import AutocompleteDropdown from "./AutocompleteDropdown";
import ErrorMessage from "./ErrorMessage";

const StyledDropdownListItem = styled(Row)`
  background-color: ${(props) => props.theme.colors.grey10};
  padding: 16px;
  margin-top: 16px;
  border-radius: ${(props) => props.theme.border_radius.SM};
  align-items: center;
`;
const StyledRemoveButton = styled(Div)`
  &:hover {
    cursor: pointer;
  }
`;
interface IconProps {
  height: string;
  width: string;
}
interface Props {
  title: string;
  details?: Array<string>;
  onAdd: (itemToAdd: any) => void;
  onDelete?: (itemToDelete: any) => void;
  existingSelections?: Array<GenericObject> | null;
  searchOptions: Array<Option>;
  searchPlaceholderText: string;
  status?: string;
  dropdownParent?: any;
  dropdownContent?: Array<any>;
  searchContent?: Array<any>;
  Icon?: React.FC<IconProps>;
  iconWidth?: string;
  iconHeight?: string;
}

const DropdownListItem = ({
  title,
  details,
  onAdd,
  onDelete,
  existingSelections,
  searchOptions,
  searchPlaceholderText,
}: Props) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const handleAddUser = () => {
    onAdd(selectedItem);
    setSelectedItem(null);
  };

  return (
    <DropdownCard title={title} headerDetails={details} initiallyOpen={false}>
      {searchOptions && (
        <Row alignItems="center" justifyContent="center">
          <Div width={{ sm: 1, lg: 10 / 12 }}>
            <AutocompleteDropdown
              options={searchOptions}
              onClick={(selectedOption) => setSelectedItem(selectedOption)}
              placeholder={searchPlaceholderText}
              name={title}
              value={selectedItem}
            />
          </Div>
          <Div width={{ sm: 1, lg: 2 / 12 }}>
            <Button
              type="primary"
              text="+"
              disabled={!selectedItem}
              onClick={handleAddUser}
            />
          </Div>
        </Row>
      )}
      {existingSelections?.map((item: GenericObject) => {
        return (
          <StyledDropdownListItem key={item.key}>
            <Div width={{ sm: 1, lg: 4 / 12 }}>
              <h4>{item.title}</h4>
            </Div>
            {item.values.map((value: any, index: string) => {
              return (
                <Div key={index.toString()} width={{ sm: 1, lg: 3 / 12 }}>
                  {value}
                </Div>
              );
            })}
            {onDelete && (
              <StyledRemoveButton
                width={{ sm: 1, lg: 2 / 12 }}
                onClick={() => onDelete(item)}
              >
                <XIcon width="24" height="24" color={COLORS.danger} />
              </StyledRemoveButton>
            )}
          </StyledDropdownListItem>
        );
      })}
    </DropdownCard>
  );
};

export default DropdownListItem;
