import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import BeatLoader from "react-spinners/BeatLoader";
import { COLORS } from "./global/colors";
import Input from "./baseComponents/Input";

import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

const PlacesAutocomplete = ({
  onSelect,
  onChange,
  initialValue,
  customInputStyling = "",
  label = "",
  error,
}) => {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [active, setActive] = useState(null);
  const autocompleteRef = useRef();
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: "AIzaSyDpg2Sm-RRBhbzP40gmPb4K4JgMMnsZ2ME",
    debounce: 500,
    options: {
      types: ["geocode"],
      componentRestrictions: { country: "us" },
    },
  });

  const handleTextChange = (e) => {
    const text = e.target.value;
    onChange(text);
    setSelectedAddress(text);
    getPlacePredictions({ input: text });
  };
  const handleDetailedPlace = (detailedPlace) => {
    let street,
      number,
      city,
      state,
      zipcode = "";
    detailedPlace.address_components.forEach((attribute) => {
      if (attribute.types.includes("postal_code")) {
        zipcode = attribute.short_name;
      } else if (attribute.types.includes("administrative_area_level_1")) {
        state = attribute.short_name;
      } else if (attribute.types.includes("locality")) {
        city = attribute.short_name;
      } else if (attribute.types.includes("route")) {
        street = attribute.short_name;
      } else if (attribute.types.includes("street_number")) {
        number = attribute.short_name;
      }
    });
    const streetAddress = number + " " + street;
    setSelectedAddress(streetAddress);

    onSelect({
      streetAddress: streetAddress,
      city: city,
      state: state,
      zipcode: zipcode,
    });
  };
  const handleAddressSelect = (e) => {
    placesService.getDetails(
      { placeId: e.target.id, fields: ["address_components"] },
      handleDetailedPlace
    );
    setActive(false);
  };

  useEffect(() => {
    setActive(placePredictions.length > 0 || isPlacePredictionsLoading);
  }, [placePredictions, isPlacePredictionsLoading]);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutsideAutocomplete = (event) => {
      if (autocompleteRef.current) {
        setActive(autocompleteRef.current.contains(event.target));
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutsideAutocomplete);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutsideAutocomplete);
    };
  }, [autocompleteRef]);

  const AutocompleteOptions = styled.div`
    z-index: 99;
    border: 1px black solid;
    background: white;
    cursor: pointer;
    width: 100%;
    padding: 10px;
  `;

  return (
    <div ref={autocompleteRef}>
      <Input
        type="text"
        onChange={handleTextChange}
        value={selectedAddress ?? initialValue}
        backgroundColor={"light"}
        className={customInputStyling}
        error={error}
        label={label}
      />
      {active && (
        <AutocompleteOptions>
          {isPlacePredictionsLoading ? (
            <div className="w-50 text-center">
              <BeatLoader />
            </div>
          ) : (
            placePredictions.map((prediction) => {
              return (
                <div
                  key={prediction.place_id}
                  id={prediction.place_id}
                  onClick={handleAddressSelect}
                >
                  {prediction.description}
                </div>
              );
            })
          )}
        </AutocompleteOptions>
      )}
    </div>
  );
};

export default PlacesAutocomplete;
