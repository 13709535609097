import { useState } from "react";
import styled from "styled-components";
import { animated, useSpring } from "@react-spring/web";
import { countBy, values } from "lodash";
import { COLORS } from "../global/colors";
import { Prepayment, PrepaymentFilters } from "../global/ModelInterfaces";
import Div from "../baseComponents/Div";
import Row from "./Row";
import FilterIcon from "../../images/FilterIcon";
import FilterMenu from "./FilterMenu";
interface StyleProps {
  hidden?: boolean;
}
const StyledFilterButton = styled(Div)<StyleProps>`
  min-height: 3rem;
  ${(props) => props.hidden && "visibility: hidden"}
`;

const StyledFilterCounter = styled(Div)`
  color: white;
  font-weight: ${(props) => props.theme.font_weight.bolder};
  background-color: ${(props) => props.theme.colors.danger};
  margin: 0.75rem 0;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
`;

const StyledFilter = styled(Div)`
  margin-left: -1rem;
  padding-top: 0.5rem;
`;

interface Props {
  initialFilters: PrepaymentFilters;
  onFilterUpdate: (prepayments: Prepayment[]) => void;
}

const Filters = ({ initialFilters, onFilterUpdate }: Props) => {
  const prepaymentFilterArray = values(initialFilters);
  const filterCounter = countBy(prepaymentFilterArray, (filter) => {
    return filter;
  });

  const [showFilters, setShowFilters] = useState(false);
  const [filterCount, setFilterCount] = useState(filterCounter.true);

  const handleChange = (
    prepaymentFilters: PrepaymentFilters,
    prepayments: Prepayment[]
  ) => {
    const prepaymentFilterArray = values(prepaymentFilters);
    const filterCounter = countBy(prepaymentFilterArray, (filter) => {
      return filter;
    });
    setFilterCount(filterCounter.true);
    onFilterUpdate(prepayments);
  };

  const AnimatedStyles = useSpring({
    config: { tension: 600, friction: 45 },
    from: {
      width: "7.5rem",
      borderRadius: "8px",
      color: COLORS.navy,
      backgroundColor: COLORS.grey10,
      border: "3px solid " + COLORS.grey,
      fontSize: "1rem",
      cursor: "pointer",
    },
    to: {
      width: showFilters ? "15rem" : "8rem",
      position: "absolute",
    },
  });

  return (
    <>
      <animated.div style={AnimatedStyles}>
        <FilterMenu
          hidden={!showFilters}
          onClose={() => setShowFilters((showFilters) => !showFilters)}
          handleFilter={handleChange}
          initialFilters={initialFilters}
        />
        <StyledFilterButton
          hidden={showFilters}
          width={{ default: 1 }}
          justifyContent="center"
          onClick={() => setShowFilters((showFilters) => !showFilters)}
        >
          <Row flexWrap="nowrap" alignItems="center">
            <span>Filters</span>
            {filterCount > 0 ? (
              <StyledFilterCounter alignItems="center" justifyContent="center">
                {filterCount}
              </StyledFilterCounter>
            ) : (
              <StyledFilter justifyContent="center" alignItems="center">
                <FilterIcon width="28" height="28" />
              </StyledFilter>
            )}
          </Row>
        </StyledFilterButton>
      </animated.div>
      {/* This empty div is to create the space-between effect in the parent component between the filters and the OrderBy Component */}
      <div />
    </>
  );
};
export default Filters;
