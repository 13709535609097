import { useHistory, useParams } from "react-router-dom";
import { COLORS } from "../../global/colors";
import styled from "styled-components";
import Row from "../../baseComponents/Row";
import Col from "../../baseComponents/Col";
import Div from "../../baseComponents/Div";
import HoorayCoin from "../../../images/HoorayCoin";
import ChillCoin from "../../../images/ChillCoin";
import { GenericObject } from "../../global/ModelInterfaces";
import { cssBreakpoints } from "../../global/theme";
import useGetCurrentPrepayment from "../../../utils/useGetCurrentPrepayment";

const PopularBadge = styled(Div)`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  border-radius: 1rem;
  height: 2rem;
  width: 7.5rem;
  ${cssBreakpoints("width", [{ sm: "5rem" }, { md: "7.5rem" }])}
  ${cssBreakpoints("font-size", [{ sm: ".6rem" }, { md: "1rem" }])}
`;

const BankDisbursementChoice = styled(Div)`
  background-color: ${COLORS.navy};
  color: ${COLORS.white};
  border-radius: 1rem;
  min-height: 10rem;
  cursor: pointer;
  transition: box-shadow 0.3s;
  &:hover {
    box-shadow: ${(props) => props.theme.box_shadow.dark};
  }
  &:hover span {
    text-decoration: underline;
  }
`;

const CheckDisbursementChoice = styled(Div)`
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.grey20};
  border-radius: 1rem;
  min-height: 10rem;
  cursor: pointer;
  transition: box-shadow 0.3s;
  &:hover {
    box-shadow: ${(props) => props.theme.box_shadow.dark};
  }
  &:hover span {
    text-decoration: underline;
  }
`;

const StyledTitle = styled(Row)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
  font-size: ${(props) => props.theme.font_size.bodyLG};
  white-space: nowrap;
`;

const StyledListItem = styled.li`
  ${cssBreakpoints("font-size", [{ sm: ".75rem" }, { md: "1rem" }])}
  font-weight: ${(props) => props.theme.font_weight.normal};
`;

const DisbursementChoice = () => {
  const history = useHistory();
  const { prepaymentUUID } = useParams<GenericObject>();
  const { offboarding } = useGetCurrentPrepayment(prepaymentUUID);

  return (
    <Row>
      <Col width={{ sm: 1, lg: 1 / 2 }}>
        <BankDisbursementChoice
          p={{ default: 3 }}
          mb={{ sm: 3, lg: 0 }}
          onClick={() => {
            history.push(`/tenant/offboarding/bank-deposit/${prepaymentUUID}`);
          }}
        >
          <Row>
            <Col width={{ default: 3 / 12 }}>
              <Div width={{ default: 1 }} alignItems="center">
                <PopularBadge
                  mb={{ default: 2 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  Most Popular!
                </PopularBadge>
                <ChillCoin />
              </Div>
            </Col>
            <Col width={{ default: 9 / 12 }}>
              <Div>
                <StyledTitle justifyContent="center">
                  Receive via Direct Deposit!
                </StyledTitle>
                <Div mt={{ default: 2 }}>
                  <StyledListItem>Free of charge</StyledListItem>
                  <StyledListItem>
                    Secure transfer (2-4 Business Days)
                  </StyledListItem>
                  <StyledListItem>Direct ACH</StyledListItem>
                </Div>
              </Div>
            </Col>
          </Row>
        </BankDisbursementChoice>
      </Col>
      <Col width={{ sm: 1, lg: 1 / 2 }}>
        <CheckDisbursementChoice
          mb={{ sm: 3, lg: 0 }}
          p={{ default: 3 }}
          onClick={() => {
            history.push(`/tenant/offboarding/send-check/${prepaymentUUID}`);
          }}
        >
          <Row>
            <Col width={{ default: 3 / 12 }}>
              <Div alignItems="center" pt={{ default: 3 }}>
                <HoorayCoin />
              </Div>
            </Col>
            <Col width={{ default: 9 / 12 }}>
              <Div>
                <StyledTitle justifyContent="center">
                  Receive your funds via mail!
                </StyledTitle>
                <Div mt={{ default: 2 }}>
                  <StyledListItem>10-14 days via check by USPS</StyledListItem>
                  <StyledListItem>Priority Mail</StyledListItem>
                  {offboarding && offboarding.check_sending_fee > 0 && (
                    <StyledListItem>
                      Service Fee (${offboarding.check_sending_fee})
                    </StyledListItem>
                  )}
                </Div>
              </Div>
            </Col>
          </Row>
        </CheckDisbursementChoice>
      </Col>
    </Row>
  );
};

export default DisbursementChoice;
