import Row from "../../../../components/baseComponents/Row";
import DetailsSection from "./PrepaymentTabSections/DetailsSection";
import DepositTransactionsSection from "./PrepaymentTabSections/DepositTransactionsSection";
import DepositRefundSection from "./PrepaymentTabSections/DepositRefundSection";
import UserDefinedFields from "./PrepaymentTabSections/UserDefinedFields";
import usePrepaymentFromParams from "../../../utils/usePrepaymentFromParams";
import prepaymentStatuses from "../../../../enums/prepaymentStatuses";

const RentManagerPrepaymentTab = () => {
  const { prepayment } = usePrepaymentFromParams();
  return (
    <Row mt={{ default: 3 }} addSpace={false}>
      <DetailsSection />
      <UserDefinedFields />
      {prepayment.deposits_info.security_deposit &&
        [
          prepaymentStatuses.ACTIVE,
          prepaymentStatuses.DISBURSING,
          prepaymentStatuses.CLOSED,
        ].includes(prepayment.deposits_info.security_deposit.status_id) && (
          <DepositTransactionsSection />
        )}
      <DepositRefundSection />
    </Row>
  );
};

export default RentManagerPrepaymentTab;
