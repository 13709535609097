import { useEffect, useState } from "react";
import Input from "./Input";

interface Props {
  onChange?: (value: number) => void;
  error?: boolean;
  label?: string;
  defaultValue?: string;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const CurrencyInput = ({
  onChange,
  error,
  label,
  onKeyDown,
  onBlur,
  defaultValue = "",
}: Props) => {
  const [value, setValue] = useState<string>(defaultValue);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };
  useEffect(() => {
    if (onChange) {
      if (value) {
        onChange(parseFloat(value));
      } else {
        onChange(0);
      }
    }
  }, [value]);

  return (
    <>
      <Input
        onChange={handleChange}
        value={value}
        error={error}
        label={label}
        prefixSymbol={"$"}
        type="number"
        onKeyDown={onKeyDown}
        onBlur={onBlur}
      />
    </>
  );
};

export default CurrencyInput;
