const SET_PREPAYMENTS = "prepayments/SET_PREPAYMENTS";
const ADD_PREPAYMENT = "prepayments/ADD_PREPAYMENTS";
const UPDATE_PREPAYMENT = "prepayments/UPDATE_PREPAYMENT";
const DELETE_PREPAYMENT = "prepayments/DELETE_PREPAYMENTS";

export const setPrepayments = (prepayments) => {
  return {
    type: SET_PREPAYMENTS,
    prepayments,
  };
};

export const addPrepayment = (prepayment) => {
  return {
    type: ADD_PREPAYMENT,
    prepayment,
  };
};

export const updatePrepayment = (prepayment) => {
  return {
    type: UPDATE_PREPAYMENT,
    prepayment,
  };
};

export const deletePrepayment = (uuid) => {
  return {
    type: DELETE_PREPAYMENT,
    uuid,
  };
};

const initialState = {
  loading: true,
  objects: [],
};

export default function prepaymentReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PREPAYMENTS:
      return {
        ...state,
        loading: false,
        objects: action.prepayments,
      };
    case ADD_PREPAYMENT:
      return {
        ...state,
        objects: state.objects.concat(action.prepayment),
      };
    case UPDATE_PREPAYMENT:
      return {
        ...state,
        objects: state.objects.map((prepayment) => {
          if (action.prepayment.uuid === prepayment.uuid) {
            return action.prepayment;
          } else return prepayment;
        }),
      };
    case DELETE_PREPAYMENT:
      return {
        ...state,
        objects: state.objects.filter(
          (prepayment) => prepayment.uuid !== action.uuid
        ),
      };
    default:
      return state;
  }
}
