import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  GenericObject,
  TenantFinancialInfo,
} from "../components/global/ModelInterfaces";
import { checkFinancials } from "./accountUtils";
import useGetBankAccount from "./useGetBankAccount";
import responseStatuses from "../enums/responseStatuses";
import useGetCurrentPrepayment from "./useGetCurrentPrepayment";

const useCheckFinancials = () => {
  const history = useHistory();
  const { prepaymentUUID } = useParams<GenericObject>();

  const { prepayment } = useGetCurrentPrepayment(prepaymentUUID);
  const prepaymentInfo = prepayment;

  const { bankAccount, loadingBankAccounts } = useGetBankAccount();
  const [loadingFinancialInfo, setLoadingFinancialInfo] =
    useState<boolean>(true);
  const [financialInfoError, setFinancialInfoError] = useState<string>("");
  const [sufficientFundsFullPayment, setSufficientFundsFullPayment] =
    useState<boolean>(false);
  const [sufficientFundsMonthlyPayment, setSufficientFundsMonthlyPayment] =
    useState<boolean>(false);
  const [amountToPay, setAmountToPay] = useState(0);
  const [paymentPlanApproval, setPaymentPlanApproval] = useState<GenericObject>(
    {}
  );
  const [hasSavedCreditCard, setHasSavedCreditCard] = useState<boolean>(false);

  useEffect(() => {
    if (!loadingBankAccounts) {
      if (bankAccount) {
        checkFinancials(bankAccount?.uuid, prepaymentInfo.prepayment_uuid)
          .then((data) => {
            setHasSavedCreditCard(data.has_saved_credit_card);
            setSufficientFundsFullPayment(data.sufficient_funds_full_payment);
            setSufficientFundsMonthlyPayment(
              data.sufficient_funds_monthly_payment
            );
            setAmountToPay(data.total_amount_to_pay);
            setPaymentPlanApproval(data.payment_plan_approval);
            setLoadingFinancialInfo(false);
          })
          .catch((err) => {
            if (err.status === responseStatuses.REQUIRES_PLAID_UPDATE) {
              history.push({
                pathname: "/update-bank-credentials",
                state: {
                  returnUrl: "/dashboard",
                  successUrl: "/dashboard",
                },
              });
            } else {
              setFinancialInfoError(
                "Something went wrong. Please try again in a few minutes. If this problem persists please contact support@rentable.com"
              );
            }
            setLoadingFinancialInfo(false);
          });
      } else {
        setLoadingFinancialInfo(false);
      }
    }
  }, [bankAccount]);

  let financialInfo: null | TenantFinancialInfo = {
    sufficientFundsFullPayment,
    sufficientFundsMonthlyPayment,
    amountToPay,
    paymentPlanApproval,
    hasSavedCreditCard,
  };

  if (financialInfoError) {
    financialInfo = null;
  }

  return { financialInfo, loadingFinancialInfo, financialInfoError };
};

export default useCheckFinancials;
