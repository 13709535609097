import styled from "styled-components";
import { ComponentBreakpoints } from "../global/ModelInterfaces";
import {
  width,
  alignItems,
  justifyContent,
  textAlign,
  display,
  space,
} from "styled-system";

interface StyleProps {
  width?: ComponentBreakpoints;
  display: ComponentBreakpoints;
  flexDirection?: string;
  justifyContent?: string;
  alignItems?: string;
  textAlign?: string;
  fill?: boolean;
  p?: ComponentBreakpoints;
  pt?: ComponentBreakpoints;
  pb?: ComponentBreakpoints;
  pl?: ComponentBreakpoints;
  pr?: ComponentBreakpoints;
  m?: ComponentBreakpoints;
  mt?: ComponentBreakpoints;
  mb?: ComponentBreakpoints;
  ml?: ComponentBreakpoints;
  mr?: ComponentBreakpoints;
}

const StyledDiv = styled.div<StyleProps>`
  ${display}
  flex-direction: column;
  ${justifyContent}
  ${alignItems}
  ${width}
  ${textAlign}
  ${(props) => props.fill && "flex: 1;"}
  ${(props) => props.hidden && "visibility: hidden"}
  ${space}
`;

interface Props {
  children?: any;
  width?: ComponentBreakpoints | string;
  display?: ComponentBreakpoints;
  justifyContent?: string;
  alignItems?: string;
  textAlign?: string;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  key?: string;
  hidden?: boolean;
  pt?: ComponentBreakpoints;
  pb?: ComponentBreakpoints;
  pl?: ComponentBreakpoints;
  pr?: ComponentBreakpoints;
  p?: ComponentBreakpoints;
  mt?: ComponentBreakpoints;
  mb?: ComponentBreakpoints;
  ml?: ComponentBreakpoints;
  mr?: ComponentBreakpoints;
  m?: ComponentBreakpoints;
}

const Div = ({
  children,
  width,
  justifyContent,
  alignItems,
  textAlign,
  display,
  className,
  onClick,
  key,
  hidden,
  p,
  pt,
  pb,
  pl,
  pr,
  m,
  mt,
  mb,
  ml,
  mr,
}: Props) => {
  const fill = width === "fill";
  const validatedWidth = typeof width === "string" ? undefined : width;
  return (
    <StyledDiv
      width={validatedWidth}
      justifyContent={justifyContent}
      alignItems={alignItems}
      textAlign={textAlign || "start"}
      display={display || { default: "flex" }}
      className={className}
      onClick={onClick}
      key={key}
      fill={fill || false}
      hidden={hidden || false}
      p={p}
      pt={pt}
      pb={pb}
      pl={pl}
      pr={pr}
      m={m}
      mt={mt}
      mb={mb}
      ml={ml}
      mr={mr}
    >
      {children}
    </StyledDiv>
  );
};

export default Div;
