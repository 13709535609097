import { useState } from "react";
import styled from "styled-components";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import Input from "../../../components/baseComponents/Input";
import Button from "../../../components/baseComponents/Button";
import { cssBreakpoints } from "../../../components/global/theme";
import { DelayedDisbursement } from "../../../components/global/ModelInterfaces";
import { manuallySendDisbursementCheck } from "../../utils/prepaymentUtils";
import SuccessMessage from "../../../components/baseComponents/SuccessMessage";
import ErrorMessage from "../../../components/baseComponents/ErrorMessage";
import { useAppDispatch } from "../../../store/hooks";
import { updatePrepaymentSection } from "../../../store/nucleusPrepayments";

const StyledSubtitle = styled(Div)`
  ${(props) =>
    cssBreakpoints("font-size", [
      { sm: props.theme.font_size.headlineSM },
      { md: props.theme.font_size.headlineMD },
    ])}
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

interface Props {
  delayedDisbursement: DelayedDisbursement;
  prepaymentUUID: string;
}

const ManuallySendCheckForm = ({
  delayedDisbursement,
  prepaymentUUID,
}: Props) => {
  const dispatch = useAppDispatch();
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [sendingCheck, setSendingCheck] = useState(false);

  const handleCheckSend = () => {
    setErrorMessage("");
    setSuccessMessage("");
    setSendingCheck(true);
    if (!address1 || !city || !state || !zipCode) {
      setErrorMessage("Must include forwarding address address");
      return;
    }
    const successCallback = (data: any) => {
      setSuccessMessage("Successfully sent check");
      dispatch(
        updatePrepaymentSection(
          prepaymentUUID,
          "disbursement_info",
          data.disbursement_info
        )
      );
      setSendingCheck(false);
    };
    const failureCallback = (errData: any) => {
      setErrorMessage(errData);
      setSendingCheck(false);
    };
    manuallySendDisbursementCheck(
      delayedDisbursement.uuid,
      address1,
      address2,
      city,
      state,
      zipCode,
      successCallback,
      failureCallback
    );
  };

  return (
    <Div>
      <>
        <StyledSubtitle pb={{ lg: 2 }}>Forwarding Address</StyledSubtitle>
        <Row>
          <Div width={{ lg: 6 / 12 }}>
            <Input
              type="text"
              label="Address 1"
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
            />
          </Div>
          <Div width={{ lg: 6 / 12 }}>
            <Input
              type="text"
              label="Address 2"
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
            />
          </Div>
        </Row>
        <Row>
          <Div width={{ lg: 6 / 12 }}>
            <Input
              type="text"
              label="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Div>
          <Div width={{ lg: 6 / 12 }}>
            <Input
              type="text"
              label="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
          </Div>
        </Row>
        <Row>
          <Div width={{ lg: 6 / 12 }}>
            <Input
              type="text"
              label="Zip Code"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
          </Div>
        </Row>
        <Row pt={{ lg: 2 }} pb={{ lg: 2 }}>
          <Div width={{ lg: 12 / 12 }}>
            <Button
              onClick={() => handleCheckSend()}
              text="Send Check"
              loading={sendingCheck}
              disabled={sendingCheck}
            />
          </Div>
        </Row>
        {errorMessage && (
          <ErrorMessage justifyContent="center">{errorMessage}</ErrorMessage>
        )}
        {successMessage && (
          <SuccessMessage justifyContent="center">
            {successMessage}
          </SuccessMessage>
        )}
      </>
    </Div>
  );
};

export default ManuallySendCheckForm;
