import styled from "styled-components";
import { Children } from "react";
import { alignItems, justifyContent, flexWrap, space } from "styled-system";
import { ComponentBreakpoints } from "../global/ModelInterfaces";

interface StyleProps {
  justifyContent?: string;
  alignItems?: string;
  flexWrap?: string;
  numOfChildren: number;
  pt?: ComponentBreakpoints;
  pb?: ComponentBreakpoints;
  mt?: ComponentBreakpoints;
  mb?: ComponentBreakpoints;
  addSpace?: boolean;
}

const StyledRow = styled.div<StyleProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  ${flexWrap}
  ${justifyContent}
  ${alignItems}
  ${space}
  ${({ addSpace }) =>
    addSpace &&
    `> * {
    padding: 0 calc(1.5rem / 2);
  }`}
`;

interface Props {
  children?: any;
  justifyContent?: string;
  alignItems?: string;
  className?: string;
  flexWrap?: string;
  pt?: ComponentBreakpoints;
  pb?: ComponentBreakpoints;
  mt?: ComponentBreakpoints;
  mb?: ComponentBreakpoints;
  addSpace?: boolean;
}

const Row = ({
  children,
  justifyContent,
  alignItems,
  className,
  flexWrap,
  pt,
  pb,
  mt,
  mb,
  addSpace = true,
}: Props) => {
  const numOfChildren = Children.count(children);
  return (
    <StyledRow
      justifyContent={justifyContent}
      alignItems={alignItems}
      className={className}
      flexWrap={flexWrap || "wrap"}
      numOfChildren={numOfChildren}
      pt={pt}
      pb={pb}
      mt={mt}
      mb={mb}
      addSpace={addSpace}
    >
      {children}
    </StyledRow>
  );
};

export default Row;
