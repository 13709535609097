import axios from "axios";
const INTRO_STEP = "introStep";
const BANK_LINK_STEP = "bankLinkStep";
const PERSONAL_INFO_STEP = "personalInfoStep";
const RENTERS_INSURANCE_STEP = "rentersInsuranceStep";
const MAKE_PAYMENT_STEP = "makePaymentStep";

const stepTranslatorDict = {
  [INTRO_STEP]: "introduction",
  [BANK_LINK_STEP]: "bank_link",
  [PERSONAL_INFO_STEP]: "personal_info",
  [RENTERS_INSURANCE_STEP]: "renters_insurance",
  [MAKE_PAYMENT_STEP]: "payment",
};

const onboardingSteps = [
  {
    step: INTRO_STEP,
    label: "Introduction",
    completed_at_label: "introduction_completed_at",
  },
  {
    step: BANK_LINK_STEP,
    label: "Link Bank",
    completed_at_label: "bank_link_completed_at",
  },
  {
    step: PERSONAL_INFO_STEP,
    label: "Personal Information",
    completed_at_label: "personal_info_completed_at",
  },
  {
    step: RENTERS_INSURANCE_STEP,
    label: "Renters Insurance",
    completed_at_label: "renters_insurance_completed_at",
  },
  {
    step: MAKE_PAYMENT_STEP,
    label: "Pay Deposit",
    completed_at_label: "payment_completed_at",
  },
];

const findStepIndex = (onboardingStep) => {
  return onboardingSteps.findIndex((step) => step.step === onboardingStep);
};

const findNextIncompleteStep = (onboarding) => {
  const nextIncomplete = onboardingSteps.find((step) => {
    return onboarding[step.completed_at_label] === null;
  });
  return nextIncomplete ? nextIncomplete.step : undefined;
};

const totalSteps = () => {
  return onboardingSteps.length;
};

const isLastStep = (onboardingStep) => {
  return onboardingStep === onboardingSteps[totalSteps() - 1].step;
};

const checkStepCompleted = (onboardingStep, onboarding) => {
  const completedIndex = findStepIndex(onboardingStep);
  const completedAtLabel = onboardingSteps[completedIndex].completed_at_label;
  return !!onboarding[completedAtLabel];
};

const allStepsCompleted = (onboarding) => {
  let completed = findNextIncompleteStep(onboarding);
  return completed === undefined;
};

const completeStep = async (step, onboardingUUID) => {
  const onboarding = await axios
    .post(`/api/tenant/onboarding/${onboardingUUID}`, {
      step: stepTranslatorDict[step],
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {});
  return onboarding;
};

const checkStepLogicComplete = (step, onboardingUUID) => {
  return axios
    .get(`/api/tenant/onboarding/check/${onboardingUUID}`, {
      params: {
        step: stepTranslatorDict[step],
      },
    })
    .then(async (res) => {
      const response = await res;
      return response.data.completed;
    })
    .catch((error) => {});
};

const markPrepaymentRenterInsured = (prepayment, enrollment_type, callback) => {
  axios
    .post("/api/tenant/onboarding/insurance-enrollment", {
      prepayment_uuid: prepayment.prepayment_uuid,
      enrollment_type: enrollment_type,
    })
    .then((res) => {
      callback(res.data.enrollment);
    });
};

const reportCreditScore = (score, successCallback, errorCallback) => {
  axios
    .post("/api/tenant/onboarding/report-credit", {
      reported_score: score,
    })
    .then(() => {
      successCallback();
    })
    .catch(() => {
      errorCallback();
    });
};

const getRentersInsuranceURL = (
  successCallback,
  failureCallback,
  prepaymentUUID
) => {
  axios
    .post("/api/tenant/renters-insurance-url", {
      prepayment_uuid: prepaymentUUID,
    })
    .then((res) => {
      successCallback(res);
    })
    .catch((err) => {
      failureCallback(err);
    });
};

export {
  INTRO_STEP,
  BANK_LINK_STEP,
  PERSONAL_INFO_STEP,
  RENTERS_INSURANCE_STEP,
  MAKE_PAYMENT_STEP,
  onboardingSteps,
  completeStep,
  checkStepLogicComplete,
  isLastStep,
  allStepsCompleted,
  findNextIncompleteStep,
  checkStepCompleted,
  findStepIndex,
  markPrepaymentRenterInsured,
  getRentersInsuranceURL,
  reportCreditScore,
};
