import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import styled from "styled-components";
import Container from "../../components/baseComponents/Container";
import Div from "../../components/baseComponents/Div";
import Row from "../../components/baseComponents/Row";
import ErrorMessage from "../../components/baseComponents/ErrorMessage";
import AutocompleteDropdown from "../../components/baseComponents/AutocompleteDropdown";
import NucleusLoader from "../components/NucleusLoader";
import {
  Company,
  Option,
  GenericObject,
} from "../../components/global/ModelInterfaces";
import { RootState } from "../..";
import { retrieveCompanyList } from "../utils/companyUtils";
import { setCompanies } from "../../store/nucleusCompanies";
import ButtonToForm from "../components/ButtonToForm";
import CreateCompanyForm from "../components/Forms/CreateCompanyForm";

const StyledHeader = styled(Row)`
  font-size: ${(props) => props.theme.font_size.headlineMD};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const CompanySearch = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const companiesSlice: { loading: Boolean; objects: GenericObject } =
    useAppSelector((state: RootState) => state.companies);
  const loadingRedux = companiesSlice.loading;

  const [selectedCompany, setSelectedCompany] = useState<Option | null>(null);
  const [errorMessage, setErrorMessage] = useState("");

  const companyOptions = companiesSlice.objects.map((company: Company) => {
    return {
      label: company.name,
      id: company.uuid,
    };
  });

  const companyOnClick = (option: Option) => {
    if (!option) {
      setSelectedCompany(null);
    } else {
      history.push(`/nucleus/company-overview/${option.id}`);
    }
  };

  useEffect(() => {
    if (!companiesSlice.objects.length) {
      const successCallback = (companies: Company[]) => {
        dispatch(setCompanies(companies));
      };

      const failureCallback = (e: string) => {
        setErrorMessage(e);
      };
      retrieveCompanyList(successCallback, failureCallback);
    }
  }, []);

  if (loadingRedux) {
    return (
      <Row justifyContent="center">
        <Div width={{ default: 3 / 12 }}>
          <NucleusLoader />
        </Div>
      </Row>
    );
  }
  return (
    <Container>
      <Div alignItems="center">
        <Div
          width={{ default: 8 / 12 }}
          mt={{ default: 5 }}
          pl={{ default: 3 }}
          pr={{ default: 3 }}
        >
          <StyledHeader>Company Overview</StyledHeader>
          <StyledHeader>Select a company name for its overview.</StyledHeader>
          <Div width={{ default: 8 / 12 }} mt={{ default: 2 }}>
            <AutocompleteDropdown
              name="Company Name"
              options={companyOptions}
              placeholder="Enter Company Name"
              onClick={companyOnClick}
              value={
                selectedCompany
                  ? {
                      label: selectedCompany.label,
                      id: selectedCompany.id,
                    }
                  : null
              }
              error={false}
              limitedTo={5}
            />
          </Div>
          <Div width={{ default: 8 / 12 }} mt={{ default: 2 }}>
            Can't find the company you're looking for?
            <ButtonToForm
              formTitle={"Enter the information for the Company"}
              buttonText={"Create Company"}
            >
              <CreateCompanyForm />
            </ButtonToForm>
          </Div>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </Div>
      </Div>
    </Container>
  );
};

export default CompanySearch;
