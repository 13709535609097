import styled from "styled-components";
import { cssBreakpoints } from "../global/theme";
import Row from "./Row";
import Div from "./Div";

const StyledListItemMetricsContainer = styled(Row)`
  ${cssBreakpoints("padding", [{ sm: " 0 1rem 1rem 1rem" }])}
  ${cssBreakpoints("padding-top", [{ md: "1.5rem" }])}
`;

const StyledMetric = styled(Div)`
  margin-bottom: 1.25rem;
`;

const StyledListItemMetricsTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 0.75em;
  line-height: 1em;
  color: ${(props) => props.theme.colors.grey80};
`;

const StyledListItemMetricsValue = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  line-height: 1.25em;
  color: ${(props) => props.theme.colors.navy};
`;

interface Props {
  metrics?: Array<Array<any>>;
}

const ListItemMetrics = ({ metrics }: Props) => {
  const camelCaseConvert = (keyStr: string) => {
    return keyStr
      .replace(/([A-Z])/g, " $1")
      .replace(/_/g, " ")
      .replace(/^./, function (str) {
        return str.toUpperCase();
      });
  };

  const metricsArray = () => {
    if (!metrics) {
      return <div>No value</div>;
    }
    return metrics.map((metric: Array<any>) => {
      const title = camelCaseConvert(metric[0].toString());
      return (
        <StyledMetric>
          <StyledListItemMetricsTitle>{title}</StyledListItemMetricsTitle>
          {metrics && (
            <StyledListItemMetricsValue>{metric[1]}</StyledListItemMetricsValue>
          )}
        </StyledMetric>
      );
    });
  };

  return (
    <StyledListItemMetricsContainer alignItems="center" justifyContent="center">
      {metricsArray()}
    </StyledListItemMetricsContainer>
  );
};

export default ListItemMetrics;
