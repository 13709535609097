import styled from "styled-components";
import { cssBreakpoints } from "../global/theme";
import { COLORS } from "../global/colors";
import Div from "./Div";

const StyledCard = styled(Div)`
  width: 100%;
  border-radius: 1.5rem;
  background: linear-gradient(
    114.61deg,
    ${COLORS.primary} 22.23%,
    rgba(30, 184, 141, 0.1) 126.46%
  );
  color: ${COLORS.white};
  &:hover {
    cursor: pointer;
  }
  ${cssBreakpoints("min-height", [{ sm: "7rem" }, { md: "11rem" }])}
`;

const StyledIcon = styled(Div)`
  height: 2rem;
  width: 2rem;
`;

const StyledTitle = styled(Div)`
  font-weight: 700;
  ${cssBreakpoints("font-size", [{ sm: "1rem" }, { md: "1.45rem" }])}
`;

const StyledMetric = styled(Div)`
  ${cssBreakpoints("font-size", [{ sm: "1rem" }, { md: "2.5rem" }])}
`;

interface Props {
  icon?: any;
  cardTitle: string;
  cardMetric: any;
  onClick: () => void;
}

const CardComponent = ({ icon, cardTitle, cardMetric, onClick }: Props) => {
  return (
    <StyledCard onClick={onClick} p={{ sm: 3, md: 4 }} mb={{ sm: 2, lg: 0 }}>
      <StyledIcon justifyContent="flex-start" alignItems="center">
        {icon}
      </StyledIcon>
      <StyledTitle>{cardTitle}</StyledTitle>
      <StyledMetric>{cardMetric}</StyledMetric>
    </StyledCard>
  );
};

export default CardComponent;
