import { useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import styled from "styled-components";

import Row from "../baseComponents/Row";
import Div from "../baseComponents/Div";
import Input from "../baseComponents/Input";
import ConfirmationModal from "../baseComponents/ConfirmationModal";
import ErrorMessage from "../baseComponents/ErrorMessage";
import WarningMessage from "../baseComponents/WarningMessage";
import PencilIcon from "../../images/PencilIcon";
import TrashcanIcon from "../../images/TrashcanIcon";
import CheckIcon from "../../images/CheckIcon";
import { COLORS } from "../global/colors";
import { BankAccount } from "../global/ModelInterfaces";
import { updateBankAccountName } from "../../utils/accountUtils";
import { unlinkCompanyBankAccount } from "../../utils/accountUtils";
import { removeBankAccount, updateBankAccount } from "../../store/bankInfo";

const StyledBankRow = styled(Row)`
  background-color: ${(props) => props.theme.colors.grey2};
  min-height: 4rem;
  border-radius: 1rem;
`;

const StyledTextRow = styled(Row)`
  height: 3.5rem;
  font-size: ${(props) => props.theme.font_size.headlineSM};
`;

const StyledIcon = styled(Div)`
  cursor: pointer;
`;

interface Props {
  bankAccount: BankAccount;
}

const AdminBankAccountRow = ({ bankAccount }: Props) => {
  const dispatch = useAppDispatch();
  const [editNameOpen, setEditNameOpen] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>("");
  const [newNameError, setNewNameError] = useState<string>("");
  const [submittingNewName, setSubmittingNewName] = useState<boolean>(false);
  const [unlinkModalOpen, setUnlinkModalOpen] = useState<boolean>(false);
  const [unlinkError, setUnlinkError] = useState<string>("");

  const handleBankNameUpdate = async () => {
    if (!newName) {
      setEditNameOpen(false);
      return;
    }
    setSubmittingNewName(true);
    const successCallback = () => {
      dispatch(updateBankAccount(bankAccount, "name", newName));
      setEditNameOpen(false);
      setSubmittingNewName(false);
    };
    const failureCallback = (error: string) => {
      setNewNameError(`There was en error saving the new name: ${error}`);
      setEditNameOpen(false);
      setSubmittingNewName(false);
    };
    updateBankAccountName(
      bankAccount,
      newName,
      successCallback,
      failureCallback
    );
  };

  const handleUnlinkBank = () => {
    const successCallback = () => {
      setUnlinkModalOpen(false);
      dispatch(removeBankAccount(bankAccount));
    };
    const failureCallback = (errorMessage: string) => {
      setUnlinkError(errorMessage);
    };
    unlinkCompanyBankAccount(bankAccount, successCallback, failureCallback);
  };

  return (
    <>
      <StyledBankRow pt={{ sm: 3 }} pb={{ sm: 3 }} mb={{ sm: 3 }}>
        <Div width={{ sm: 1, md: 6 / 12, lg: 9 / 12 }} justifyContent="center">
          <StyledTextRow alignItems="center">
            {bankAccount.bank_name}
          </StyledTextRow>
          <StyledTextRow alignItems="center">
            {editNameOpen ? (
              <Div width={{ sm: 1, md: 6 / 12, lg: 9 / 12 }}>
                <Input
                  onChange={(e) => setNewName(e.target.value)}
                  placeholderText={bankAccount.name}
                />
              </Div>
            ) : (
              <>{bankAccount.name + " "}</>
            )}
            •••• {bankAccount.mask}
          </StyledTextRow>
          {newNameError && (
            <StyledTextRow alignItems="center">
              <ErrorMessage>{newNameError}</ErrorMessage>
            </StyledTextRow>
          )}
          {unlinkError && (
            <StyledTextRow alignItems="center">
              <ErrorMessage>{unlinkError}</ErrorMessage>
            </StyledTextRow>
          )}
          {bankAccount.pending_confirmation && (
            <StyledTextRow alignItems="center">
              <WarningMessage>
                {
                  'This account is still pending confirmation. Please use the "Confirm Deposits" button above to finish linking'
                }
              </WarningMessage>
            </StyledTextRow>
          )}
        </Div>
        <Div
          width={{ sm: 1, md: 6 / 12, lg: 3 / 12 }}
          alignItems="center"
          justifyContent="center"
        >
          <Row justifyContent="flex-end">
            {editNameOpen ? (
              <>
                <StyledIcon>
                  <Row>
                    <Div
                      alignItems="center"
                      justifyContent="center"
                      onClick={handleBankNameUpdate}
                    >
                      <CheckIcon />
                    </Div>
                  </Row>
                </StyledIcon>
              </>
            ) : (
              <StyledIcon
                justifyContent="center"
                alignItems="center"
                onClick={() => {
                  setEditNameOpen((editNameOpen) => !editNameOpen);
                }}
              >
                <PencilIcon height="40" width="40" />
              </StyledIcon>
            )}
            <StyledIcon onClick={() => setUnlinkModalOpen(true)}>
              <TrashcanIcon color={COLORS.danger} />
            </StyledIcon>
          </Row>
        </Div>
      </StyledBankRow>
      <ConfirmationModal
        confirmationOpen={unlinkModalOpen}
        onClose={() => {
          setUnlinkModalOpen(false);
        }}
        onConfirm={handleUnlinkBank}
        message={`Are you sure you would like to unlink the Bank Account: ${bankAccount.name}?`}
      />
    </>
  );
};

export default AdminBankAccountRow;
