import { useState } from "react";
import styled from "styled-components";
import axios from "axios";

import Div from "../components/baseComponents/Div";
import Row from "../components/baseComponents/Row";
import ErrorMessage from "../components/baseComponents/ErrorMessage";
import Input from "../components/baseComponents/Input";
import Button from "../components/baseComponents/Button";
import BackButton from "../components/baseComponents/BackButton";

import { cssBreakpoints } from "../components/global/theme";
import { emailRegex } from "../utils/accountUtils";
import { requestPasswordResetEmail } from "../store/auth";

const StyledContainer = styled(Div)`
  border-radius: 0.75rem;
  ${cssBreakpoints("border", [
    { sm: "none" },
    { md: "1px solid #cfcfcf" },
    { lg: "1px solid #cfcfcf" },
  ])}

  ${cssBreakpoints("padding", [
    { sm: "1.5rem" },
    { md: "1.5rem" },
    { lg: "3rem" },
  ])}

  ${cssBreakpoints("margin-top", [
    { sm: "1rem" },
    { md: "1.5rem" },
    { lg: "3rem" },
  ])}
`;

const StyledText = styled(Row)`
  margin-top: 4rem;
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const StyledRow = styled(Row)`
  margin: 1rem 0;
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const StyledSuccess = styled(Row)`
  color: ${(props) => `${props.theme.colors.success}`};
  margin-top: 3rem;
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const PasswordResetView = () => {
  const [email, setEmail] = useState("");
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [resetError, setResetError] = useState("");

  const handleSubmit = async () => {
    if (!emailRegex.test(email)) {
      setResetError("Please enter a valid email");
      return;
    }
    const successCallback = () => {
      setEmailLoading(false);
      setEmailSent(true);
      setResetError("");
    };

    const failureCallback = (errorMessage: string) => {
      setEmailLoading(false);
      setResetError(errorMessage);
    };
    setEmailLoading(true);
    requestPasswordResetEmail(email, successCallback, failureCallback);
  };

  return (
    <StyledContainer width={{ sm: 1, md: 1, lg: 4 / 12 }}>
      <Row alignItems="flex-start">
        <BackButton returnUrl="/login" text="Go Back" />
      </Row>
      {emailSent ? (
        <StyledSuccess>
          Check your email for a message from us!
          <br />- The Rentable Team
        </StyledSuccess>
      ) : (
        <>
          <StyledText>
            <span>Enter your email to reset your password.</span>
          </StyledText>
          <StyledRow>
            <Div width={{ sm: 1, md: 1, lg: 1 }}>
              <Input
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                error={!!resetError}
              />
            </Div>
          </StyledRow>
          {resetError && (
            <StyledRow>
              <ErrorMessage>{resetError}</ErrorMessage>
            </StyledRow>
          )}
          <StyledRow>
            <Button
              text="Send Password Reset Email"
              onClick={handleSubmit}
              loading={emailLoading}
            />
          </StyledRow>
        </>
      )}
    </StyledContainer>
  );
};

export default PasswordResetView;
