import { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import PasswordChangeModal from "./PasswordChangeModal";
import styled from "styled-components";

import Div from "./Div";
import Row from "./Row";
import Button from "./Button";
import Input from "./Input";
import { updateUser, editUser } from "../../store/auth";
import { GenericObject } from "../global/ModelInterfaces";

const StyledForm = styled(Div)`
  padding: 1rem 0;
`;

const StyledSuccess = styled(Div)`
  color: ${(props) => props.theme.colors.primary};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  padding: 0.5rem 0;
`;

const StyledError = styled(Div)`
  color: ${(props) => props.theme.colors.danger};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  padding: 0.5rem 0;
`;

const StyledPasswordChange = styled(Div)`
  padding: 0.5rem 1rem;
  cursor: pointer;
`;

const StyledButtonRow = styled(Div)`
  margin-bottom: 0.5rem;
  height: 3.5rem;
`;

function UpdateAccountForm() {
  const currentUser = useAppSelector((state) => state.auth.user);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [firstName, setFirstName] = useState<string>(
    currentUser ? currentUser.first_name : ""
  );
  const [lastName, setLastName] = useState<string>(
    currentUser ? currentUser.last_name : ""
  );
  const [email, setEmail] = useState<string>(
    currentUser ? currentUser.email : ""
  );

  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleSubmit = async () => {
    setSubmitting(true);
    const successCallback = (data: GenericObject) => {
      dispatch(editUser(data));
      setSuccessMessage("Account updated successfully");
      setSubmitting(false);
    };
    const failureCallback = (errorMessage: string) => {
      setErrorMessage(errorMessage);
      setSubmitting(false);
    };
    updateUser(firstName, lastName, email, successCallback, failureCallback);
  };

  return (
    <StyledForm width={{ default: 1 }} alignItems="center">
      {successMessage && <StyledSuccess>{successMessage}</StyledSuccess>}
      {errorMessage && <StyledError>{errorMessage}</StyledError>}
      <Div>
        <Row>
          <Div width={{ sm: 1, md: 6 / 12, lg: 6 / 12 }}>
            <Input
              label="First Name"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
            />
          </Div>
          <Div width={{ sm: 1, md: 6 / 12, lg: 6 / 12 }}>
            <Input
              label="Last Name"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
            />
          </Div>
        </Row>
        <Row>
          <Div width={{ sm: 1, md: 6 / 12, lg: 6 / 12 }}>
            <Input
              label="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </Div>
          <Div
            width={{ sm: 1, md: 6 / 12, lg: 6 / 12 }}
            justifyContent="flex-end"
          >
            <StyledButtonRow>
              <Button
                text={"Confirm"}
                onClick={handleSubmit}
                loading={submitting}
              />
            </StyledButtonRow>
          </Div>
        </Row>
        <Row justifyContent="flex-start">
          <StyledPasswordChange onClick={() => setChangePasswordOpen(true)}>
            <Row alignItems="center">
              Change password<i className="fas fa-arrow-right"></i>
            </Row>
          </StyledPasswordChange>
        </Row>
      </Div>

      <PasswordChangeModal
        open={changePasswordOpen}
        onClose={() => {
          setChangePasswordOpen(false);
        }}
      />
    </StyledForm>
  );
}

export default UpdateAccountForm;
