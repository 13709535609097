import BarLoader from "react-spinners/BarLoader";
import styled from "styled-components";
import { ThemeConsumer } from "styled-components";
import Div from "./Div";

const StyledText = styled(Div)`
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.font_size.headlineSM};
`;
interface Props {
  loadingText?: string;
}
const CustomBarLoader = ({ loadingText }: Props) => {
  return (
    <Div justifyContent="center" alignItems="center">
      <ThemeConsumer>
        {(theme) => <BarLoader color={theme.colors.primary} />}
      </ThemeConsumer>
      <StyledText>{loadingText ? loadingText : "Loading..."}</StyledText>
    </Div>
  );
};

export default CustomBarLoader;
