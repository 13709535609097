import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../store/hooks";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Button from "../../baseComponents/Button";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import RentableHugCoin from "../../../images/RentableHugCoin";
import { updateTenantOffboarding } from "../../../store/tenantInfo";
import { GenericObject } from "../../global/ModelInterfaces";
const StyledContainer = styled(Div)`
  margin-top: 1rem;
`;

const CheckDisbursementSuccess = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { prepaymentUUID } = useParams<GenericObject>();

  useEffect(() => {
    dispatch(updateTenantOffboarding(prepaymentUUID));
  });

  return (
    <Div alignItems="center">
      <Div width={{ sm: 1, md: 1, lg: 6 / 12 }} alignItems="center">
        <RentableHugCoin height={"200"} width={"180"} />
      </Div>
      <StyledContainer width={{ sm: 1, md: 1, lg: 6 / 12 }}>
        <Row>
          <span>
            You're all set! You should see your check in the mail in the next
            10-14 business days. If you have any questions, please reach out to
            support@rentable.com.
          </span>
        </Row>
      </StyledContainer>
      <StyledContainer width={{ sm: 1, md: 1, lg: 6 / 12 }}>
        <Row>
          <Button onClick={() => history.push("/dashboard")}>
            Return to Dashboard
          </Button>
        </Row>
      </StyledContainer>
    </Div>
  );
};

export default CheckDisbursementSuccess;
