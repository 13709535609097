import { DateTime, Interval } from "luxon";
import { orderBy } from "lodash";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../global/colors";
import { useAppSelector } from "../../../store/hooks";
import prepaymentStatuses from "../../../enums/prepaymentStatuses";
import TruckCouchIcon from "../../../images/TruckCouchIcon";
import DashboardListItem from "./DashboardListItem";
import { MenuItem, Prepayment } from "../../global/ModelInterfaces";
import { cssBreakpoints } from "../../global/theme";

import Div from "../../baseComponents/Div";

const StyledTitleDiv = styled(Div)`
  color: ${COLORS.navy};
  font-weight: 700;
  padding-bottom: 1.5rem;
  ${cssBreakpoints("font-size", [{ sm: "1.25rem" }, { md: "1.5rem" }])}
`;

const UpcomingDisbursements = () => {
  const history = useHistory();
  const UpcomingDisbursements = useAppSelector((state) => {
    const filteredDisbursements = state.prepayments.objects.filter(
      (prepayment: Prepayment) => {
        return prepayment.status_id === prepaymentStatuses.ACTIVE;
      }
    );

    return orderBy(filteredDisbursements, (disbursement) => {
      if (disbursement.end_date) {
        return DateTime.fromFormat(disbursement.end_date, "yyyy-MM-dd");
      }
    }).slice(0, 5);
  });

  return (
    <>
      <Div>
        <StyledTitleDiv>
          <span>Upcoming disbursements:</span>
        </StyledTitleDiv>
      </Div>
      <Div width={{ default: 1 }}>
        {UpcomingDisbursements.map((prepayment: Prepayment, index: number) => {
          let variant, itemDetail;
          if (prepayment.end_date === null) {
            variant = "default";
            itemDetail = "Lease has no end date";
          } else {
            const disbursementOverdueInterval = Interval.fromDateTimes(
              DateTime.fromFormat(prepayment.end_date, "yyyy-MM-dd"),
              DateTime.now()
            );

            const daysTilDisbursementInterval = Interval.fromDateTimes(
              DateTime.now(),
              DateTime.fromFormat(prepayment.end_date, "yyyy-MM-dd")
            );
            const daysTilDisbursementIntervalInDays = Math.ceil(
              daysTilDisbursementInterval.length("days")
            );
            itemDetail = `Lease ending in ${Math.ceil(
              daysTilDisbursementInterval.length("days")
            )} days`;
            // Have to check for NaN because Interval returns a NaN if the first date being compared is before the second date being compared

            if (isNaN(daysTilDisbursementIntervalInDays)) {
              variant = "overdue";
              itemDetail = `Lease ended ${Math.ceil(
                disbursementOverdueInterval.length("days")
              )} days ago`;
            } else if (daysTilDisbursementIntervalInDays < 30) {
              variant = "warning";
            } else {
              variant = "default";
            }
          }

          const menuItems: MenuItem[] = [];

          if (prepayment.is_disbursable) {
            menuItems.push({
              label: "Close Deposit",
              function: () => {
                history.push(`/dashboard/close-deposit/${prepayment.uuid}`);
              },
            });
          }
          if (prepayment.has_disbursable_rent) {
            menuItems.push({
              label: "Release Last Month's Rent",
              function: () => {
                history.push(`/dashboard/release-rent/${prepayment.uuid}`);
              },
            });
          }
          return (
            <DashboardListItem
              icon={<TruckCouchIcon />}
              menuItems={menuItems}
              prepayment={prepayment}
              itemDetail={itemDetail}
              variant={variant}
            />
          );
        })}
      </Div>
    </>
  );
};

export default UpcomingDisbursements;
