import GeneralInfoCard from "./GeneralInfo";
import CompanySettings from "./CompanySettings";
import CompanyLandlord from "./CompanyLandlords";
import Row from "../../../components/baseComponents/Row";
import { NucleusCompany } from "../../../components/global/ModelInterfaces";

interface Props {
  company: NucleusCompany;
}

const CompanyInfoTab = ({ company }: Props) => {
  return (
    <>
      <Row mt={{ default: 3 }}>
        <GeneralInfoCard generalInfo={company} />
      </Row>
      <Row mt={{ default: 3 }}>
        <CompanySettings companySettings={company.company_settings} />
      </Row>
      <Row mt={{ default: 3 }}>
        <CompanyLandlord landlords={company.landlords} />
      </Row>
    </>
  );
};

export default CompanyInfoTab;
