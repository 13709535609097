import styled from "styled-components";
import { Redirect } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { RootState } from "../../..";
import {
  TenantPrepaymentInfo,
  GenericObject,
} from "../../global/ModelInterfaces";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import { head } from "lodash";
interface Props {
  nextStep: () => void;
}

const StyledIntroduction = styled(Row)`
  padding: 1.5rem 0rem;
  font-size: ${(props) => props.theme.font_size.headlineMD};
`;

const StyledHeaderText = styled.span`
  font-weight: ${(props) => props.theme.font_weight.semibold};
  color: ${(props) => props.theme.colors.navy};
`;

const StyledRentableText = styled.span`
  font-weight: ${(props) => props.theme.font_weight.bolder};
  color: ${(props) => props.theme.colors.primary};
`;

const StyledIntroductionText = styled(Div)`
  padding: 1rem 0rem;
  font-size: ${(props) => props.theme.font_size.headlineSM};
`;

const StyledIntroductionButton = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineMD};
  font-weight: ${(props) => props.theme.font_weight.bolder};
  border: 2px solid ${(props) => props.theme.colors.primary};
  border-radius: 0.25rem;
  box-shadow: none;
  padding: 2rem 1rem;
  margin: 1rem 0rem;
  cursor: pointer;
  &:hover {
    box-shadow: 0 1rem 1rem 0 rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
  }
`;

const IntroductionStep = ({ nextStep }: Props) => {
  const currentUser = useAppSelector((state) => state.auth.user);
  const tenantInfoSlice: { loading: Boolean; objects: GenericObject } =
    useAppSelector((state: RootState) => state.tenantInfo);

  const firstTime: boolean = tenantInfoSlice.objects.first_time;
  const prepaymentInfo: TenantPrepaymentInfo | undefined = head(
    tenantInfoSlice.objects.prepaymentInfo
  );

  if (!prepaymentInfo) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Div width={{ default: 1 }} alignItems="center">
      <StyledIntroduction justifyContent="center">
        <StyledHeaderText>
          {currentUser.first_name}, welcome {!firstTime && "back"} to{" "}
          <StyledRentableText> Rentable!</StyledRentableText>
        </StyledHeaderText>
      </StyledIntroduction>
      <StyledIntroductionText
        textAlign="center"
        width={{ sm: 1, md: 1, lg: 8 / 12 }}
      >
        {firstTime
          ? "You've been invited to pay the deposit for your lease at the unit:"
          : "We've saved your progress so you can pick up right where you left off for the unit:"}
        <br />
        <StyledHeaderText>{prepaymentInfo.street_address}</StyledHeaderText>
      </StyledIntroductionText>
      <StyledIntroductionButton
        textAlign="center"
        onClick={nextStep}
        width={{ sm: 1, md: 3 / 4, lg: 1 / 2 }}
      >
        {firstTime ? "Let's get started!" : "Let's keep going!"}
      </StyledIntroductionButton>
    </Div>
  );
};

export default IntroductionStep;
