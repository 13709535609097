import React from "react";
import styled from "styled-components";

import Row from "./Row";
import Div from "./Div";
import Menu from "./Menu";
import TitleHeaderBody from "./TitleHeaderBody";
import ContactTenant from "../LandlordComponents/ContactTenant";
import { MenuItem } from "../global/ModelInterfaces";

interface IconProps {
  height: string;
  width: string;
}
interface Props {
  title: string;
  subtitle?: string;
  buttonOnClick?: () => void;
  menuItems?: MenuItem[];
  buttonText?: string;
  buttonStyle?: string;
  Icon?: React.FC<IconProps>;
  iconWidth?: string;
  iconHeight?: string;
  contactTenant?: boolean;
}

const StyledTitleHeaderContainer = styled(Row)`
  margin-bottom: 1rem;
`;

const StyledIcon = styled(Div)`
  padding: 0.5rem 0.5rem 0 0;
`;

const TitleHeader = ({
  title,
  subtitle,
  menuItems,
  Icon,
  iconHeight,
  iconWidth,
  contactTenant,
}: Props) => {
  return (
    <StyledTitleHeaderContainer>
      {Icon && (
        <Div display={{ sm: "none", md: "none", lg: "flex" }}>
          <StyledIcon>
            <Icon width={iconWidth ?? ""} height={iconHeight ?? ""} />
          </StyledIcon>
        </Div>
      )}
      <Div width={{ sm: 1, md: 10 / 12, lg: 10 / 12 }}>
        <TitleHeaderBody title={title} subtitle={subtitle} />
      </Div>
      <Div width={{ sm: 1, md: 3 / 12, lg: 3 / 12 }}>
        <Row alignItems="space-between">
          {contactTenant && <ContactTenant />}
          {menuItems && <Menu menuItems={menuItems} style={"ellipsis"} />}
        </Row>
      </Div>
    </StyledTitleHeaderContainer>
  );
};

export default TitleHeader;
