import { useEffect, useRef, useState, FormEvent } from "react";
import { createCardSetup } from "../../utils/depositUtils";
import Div from "../baseComponents/Div";
import Row from "../baseComponents/Row";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Stripe, StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import PageLoader from "../PageLoader";
import Button from "../baseComponents/Button";
import ErrorMessage from "../baseComponents/ErrorMessage";
import RentableCard from "../baseComponents/RentableCard";
import { useParams } from "react-router-dom";
import { GenericObject } from "../global/ModelInterfaces";

interface Props {
  onSave: Function;
  buttonText?: string;
}

const SaveCardForm = ({ onSave, buttonText = "Save" }: Props) => {
  const { prepaymentUUID } = useParams<GenericObject>();
  const [options, setOptions] = useState<StripeElementsOptions | undefined>();
  const [loading, setLoading] = useState(true);
  const [stripe, setStripe] = useState<Promise<Stripe | null> | null>(null);
  const [returnUrl, setReturnUrl] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const successCallback = (data: GenericObject) => {
      setOptions({
        clientSecret: data.client_secret,
      });
      setStripe(
        loadStripe(data.publishable_key, {
          stripeAccount: data.stripe_account,
        })
      );
      setReturnUrl(data.payment_data.return_url);
      setLoading(false);
    };
    const failureCallback = (error: any) => {
      setError(error.data);
      setLoading(false);
    };
    if (!stripe) {
      createCardSetup(prepaymentUUID, successCallback, failureCallback);
    }
  }, []);

  if (loading) {
    return (
      <Row justifyContent="center">
        <PageLoader />;
      </Row>
    );
  } else {
    return (
      <Div width={{ default: 1 }}>
        <RentableCard>
          <Div width={{ default: 1 }}>
            <Elements stripe={stripe} options={options}>
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <CardForm
                returnUrl={returnUrl}
                onSave={onSave}
                buttonText={buttonText}
              />
            </Elements>
          </Div>
        </RentableCard>
      </Div>
    );
  }
};

interface FormProps {
  returnUrl: string;
  onSave: Function;
  buttonText: string;
}

const CardForm = ({ returnUrl, onSave, buttonText }: FormProps) => {
  const elements = useElements();
  const stripeHook = useStripe();
  const formRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(true);
  const [paying, setPaying] = useState(false);
  const [error, setError] = useState("");

  const handlePayment = async (e: FormEvent) => {
    e.preventDefault();
    setPaying(true);
    if (elements === null) {
      setError("Please refresh and try again");
    } else if (stripeHook) {
      const { error: confirmError } = await stripeHook.confirmSetup({
        elements,
        redirect: "if_required",
        confirmParams: {
          return_url: returnUrl,
        },
      });

      if (confirmError) {
        setError(
          confirmError.message ||
            "There was a problem processing your request. Please reach out to support@rentable.com for assistance."
        );
      } else {
        onSave();
      }
    } else {
      setError("Unable to pay via Stripe");
    }

    setPaying(false);
  };
  return (
    <Div width={{ default: 1 }}>
      <form onSubmit={handlePayment}>
        <PaymentElement onReady={() => setLoading(false)} />
        {/* Hidden submit because we don't have access to the form elements of PaymentElement */}
        <input type="submit" style={{ display: "none" }} ref={formRef} />
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Div mt={{ default: 4 }}>
          {!loading && (
            <Button
              onClick={() => {
                if (formRef.current) {
                  formRef.current.click();
                  setPaying(true);
                }
              }}
              disabled={paying}
              loading={paying}
            >
              {buttonText}
            </Button>
          )}
        </Div>
      </form>
    </Div>
  );
};

export default SaveCardForm;
