import { NavLink, Route, Switch } from "react-router-dom";
import styled from "styled-components";

import Div from "../baseComponents/Div";
import Row from "../baseComponents/Row";
import Button from "../baseComponents/Button";
import RentableLogo from "../baseComponents/RentableLogo";
import { cssBreakpoints } from "../global/theme";

const StyledNav = styled.nav`
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid rgba(197, 203, 219, 0.31) !important;
  min-height: 100px;
  ${cssBreakpoints("padding", [{ sm: "0 1rem" }, { lg: "0 2.5rem" }])}
`;

const StyledBtnWrapper = styled.a`
  width: 10rem;
  margin-left: 2rem;
`;

function NavBarUnauthenticated() {
  return (
    <StyledNav>
      <Row justifyContent={"space-between"} alignItems={"center"}>
        <Div width={{ sm: 1 / 2, md: 1 / 3, lg: 2 / 5 }}>
          <NavLink to={"/"}>
            <RentableLogo />
          </NavLink>
        </Div>
        <Div width={{ sm: 1 / 2, md: 1 / 3, lg: 3 / 5 }}>
          <Switch>
            <Route exact path={["/user/signup"]}>
              <Row justifyContent={"flex-end"} alignItems={"center"}>
                <Div
                  display={{
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                    default: "none",
                  }}
                >
                  Already have an account?
                </Div>
                <StyledBtnWrapper href={"/login"}>
                  <Button text="Login" type={"primary"} />
                </StyledBtnWrapper>
              </Row>
            </Route>
            <Route path="/*">
              <Row justifyContent={"flex-end"} alignItems={"center"}>
                <Div
                  display={{
                    lg: "flex",
                    md: "flex",
                    sm: "none",
                    default: "none",
                  }}
                >
                  Don't have an account?
                </Div>
                <StyledBtnWrapper href={"https://www.rentable.com/contact"}>
                  <Button text="Sign Up" type={"primary"} />
                </StyledBtnWrapper>
              </Row>
            </Route>
          </Switch>
        </Div>
      </Row>
    </StyledNav>
  );
}

export default NavBarUnauthenticated;
