import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { noop } from "lodash";
import Button from "../../baseComponents/Button";
import { Fee, GenericObject } from "../../global/ModelInterfaces";
import useGetBankAccount from "../../../utils/useGetBankAccount";
import useCheckFinancials from "../../../utils/useCheckFinancials";
import InsufficientFunds from "../InsufficientFunds";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import PlaidButton from "../../PlaidButton";
import PageLoader from "../../PageLoader";
import ConfusedCoin from "../../../images/ConfusedCoin";
import { useAppDispatch } from "../../../store/hooks";
import { updateFees } from "../../../store/tenantInfo";
import { makeFeesPayment } from "../../../utils/feeUtils";
import { currencyFormatter } from "../../global/utils";
import useGetCurrentPrepayment from "../../../utils/useGetCurrentPrepayment";
import feeStatuses from "../../../enums/feeStatuses";

const DescriptionDiv = styled(Div)`
  padding-left: 2em;
`;

const FeePayment = () => {
  const { prepaymentUUID } = useParams<GenericObject>();

  const dispatch = useAppDispatch();
  const { prepayment } = useGetCurrentPrepayment(prepaymentUUID);

  const fees = prepayment.fees.filter(
    (fee: Fee) => fee.status === feeStatuses.PENDING
  );

  const { financialInfo, loadingFinancialInfo, financialInfoError } =
    useCheckFinancials();

  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [error, setError] = useState("");
  const [feeTotal, setFeeTotal] = useState(0);
  const [platformFees, setPlatformFees] = useState(0);
  const { bankAccount, loadingBankAccounts } = useGetBankAccount();

  useEffect(() => {
    const calculatePlatformFees = (fees: Fee[]) => {
      return fees.reduce(
        (sumValue: number, fee: Fee) => sumValue + fee.platform_charge,
        0
      );
    };

    const calculateFees = (fees: Fee[]) => {
      const feeSum = fees.reduce(
        (sumValue: number, fee: Fee) => sumValue + fee.amount,
        0
      );
      return feeSum;
    };
    setPlatformFees(calculatePlatformFees(fees));
    setFeeTotal(calculateFees(fees));
  }, []);

  const makeFeesPaymentSuccessCallBack = (updatedFees: Fee[]) => {
    dispatch(updateFees(updatedFees));
    setPaymentProcessing(false);
  };
  const makeFeesPaymentFailureCallBack = () => {
    setError("Error in making Fee Payment");
    setPaymentProcessing(false);
  };
  const handleFeePayment = async (prepaymentUUID: string) => {
    setPaymentProcessing(true);
    makeFeesPayment(
      prepaymentUUID,
      makeFeesPaymentSuccessCallBack,
      makeFeesPaymentFailureCallBack
    );
  };
  if (loadingBankAccounts || loadingFinancialInfo) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  }

  if (error) {
    <Div>
      <p>
        There was an error with your payment: {error}. Please refresh and try
        again or reach out to support@rentable.com for help.
      </p>
    </Div>;
  }
  if (!bankAccount) {
    return (
      <Div>
        <p>
          In order to pay your deposits, you must have a bank account linked.
          Please click below to link your account.
        </p>
        <PlaidButton onSuccess={noop} />
      </Div>
    );
  }
  if (!financialInfo) {
    return <Div>{financialInfoError}</Div>;
  }
  if (!financialInfo.sufficientFundsFullPayment) {
    return <InsufficientFunds amountDue={feeTotal} />;
  }
  if (!financialInfo) {
    return (
      <Div alignItems="center">
        <ConfusedCoin />
        {financialInfoError}
      </Div>
    );
  }
  return (
    <Div width={{ sm: 1, lg: 9 / 12 }}>
      <Row>
        <h2>Confirm and Pay Fees</h2>
      </Row>
      <Div>
        <ol>
          {fees.map((fee: Fee) => {
            return (
              <li>
                <Row alignItems="center" justifyContent="space-between">
                  <p>{fee.type}</p>
                  <p>{currencyFormatter.format(fee.amount)}</p>
                </Row>
                {fee.description && (
                  <Row alignItems="center">
                    <DescriptionDiv>{fee.description}</DescriptionDiv>
                  </Row>
                )}
              </li>
            );
          })}
        </ol>
        {!!platformFees && (
          <Row alignItems="center" justifyContent="space-between">
            <p>Platform Fee:</p>
            <p>{currencyFormatter.format(platformFees)}</p>
          </Row>
        )}
        <Row alignItems="center" justifyContent="space-between">
          <p>Total:</p>
          <p>{currencyFormatter.format(feeTotal + platformFees)}</p>
        </Row>
        <Row justifyContent={"center"}>
          <Div width={{ default: 1, lg: 6 / 12 }}>
            <Button
              onClick={() => handleFeePayment(prepaymentUUID)}
              loading={paymentProcessing}
              disabled={paymentProcessing}
              text={"Confirm and Pay Fees"}
            />
          </Div>
        </Row>
      </Div>
    </Div>
  );
};
export default FeePayment;
