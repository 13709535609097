import { useEffect, useState } from "react";
import { useParams, Redirect, useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { RootState } from "../../..";

import styled from "styled-components";
import { currencyFormatter } from "../../global/utils";
import { COLORS } from "../../global/colors";

import Row from "../../baseComponents/Row";
import Div from "../../baseComponents/Div";
import Button from "../../baseComponents/Button";
import BankAccountSelect from "../../BankAccountSelect";
import PageLoader from "../../PageLoader";

import {
  getLastMonthsRentInfo,
  disburseLastMonthsRent,
} from "../../../utils/disbursementUtils";
import {
  Deposit,
  GenericObject,
  BankAccount,
  Prepayment,
} from "../../global/ModelInterfaces";
import { getStreetAddress } from "../../../utils/globalUtils";
import { updatePrepayment } from "../../../store/prepayments";
import prepaymentStatuses from "../../../enums/prepaymentStatuses";

const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3.25rem;
  color: ${COLORS.grey};
  margin-bottom: 0.5rem;
`;

const StyledDivider = styled(Div)`
  height: 1px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.16);
  margin-bottom: 1.5rem;
`;

const StyledSubTitle = styled.div`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.875rem;
  color: ${COLORS.grey};
  margin-bottom: 1.5rem;
`;

const StyledBankText = styled(Div)`
  font-size: 1.125rem;
  color: ${COLORS.grey60};
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
`;

const StyledSummaryRow = styled(Row)`
  margin-bottom: 1.5rem;
`;
interface SummaryTextProps {
  color: string;
}

const StyledSummaryText = styled(Div)<SummaryTextProps>`
  font-weight: 700;
  color: ${(props) => props.color};
`;

const ReleaseRentView = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { prepaymentUUID } = useParams<GenericObject>();
  const [releaseBank, setReleaseBank] = useState<BankAccount>();
  const [bankError, setBankError] = useState("");
  const [disbursementError, setDisbursementError] = useState("");
  const [rentDeposit, setRentDeposit] = useState<Deposit | null>(null);
  const [loadingLMR, setLoadingLMR] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<string>("");
  const [disbursing, setDisbursing] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const prepaymentsSlice: { loading: Boolean; objects: Prepayment[] } =
    useAppSelector((state: RootState) => state.prepayments);

  const selectedPrepayment = prepaymentsSlice.objects.find(
    (prepayment: Prepayment) => prepayment.uuid === prepaymentUUID
  );

  const updateReleaseBank = (e: any) => {
    setReleaseBank(e.value);
  };

  const loadLastMonthsRentInfo = () => {
    getLastMonthsRentInfo(prepaymentUUID)
      .then((lastMonthsRent) => {
        setRentDeposit(lastMonthsRent);
      })
      .catch((e) => {
        setLoadingError(
          "There was an error loading some details about this deposit, please refresh the page."
        );
      })
      .finally(() => {
        setLoadingLMR(false);
      });
  };

  const handleReleaseRent = () => {
    setDisbursing(true);
    disburseLastMonthsRent(prepaymentUUID, releaseBank)
      .then((updated_prepayment) => {
        setSuccess(true);
        dispatch(updatePrepayment(updated_prepayment));
      })
      .catch((e) => {
        setSuccess(false);
        setDisbursementError(e.message);
      })
      .finally(() => {
        setDisbursing(false);
      });
  };

  useEffect(() => {
    loadLastMonthsRentInfo();
  }, []);

  if (loadingLMR) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  }

  if (loadingError) {
    return (
      <Row alignItems="center">
        <Div>{loadingError}</Div>
      </Row>
    );
  } else if (!rentDeposit) {
    return <Redirect to={"/forbidden"} />;
  } else if (rentDeposit.status_id !== prepaymentStatuses.ACTIVE) {
    return <Redirect to={`/dashboard/tenant/${prepaymentUUID}`} />;
  } else {
    return (
      <Row justifyContent="center">
        <Div width={{ default: 1 / 2 }}>
          <StyledTitle>Release Last Month's Rent</StyledTitle>
          <StyledSubTitle>
            Address:{" "}
            {getStreetAddress(
              selectedPrepayment?.address_1,
              selectedPrepayment?.address_2
            )}
          </StyledSubTitle>
          <StyledSummaryRow justifyContent="space-between">
            <Div>Last Month's Rent Amount:</Div>
            <StyledSummaryText color={COLORS.grey}>
              ${rentDeposit.amount}
            </StyledSummaryText>
          </StyledSummaryRow>
          <StyledDivider />
          {success ? (
            <Div alignItems="center">
              <StyledSubTitle>The rent release was successful!</StyledSubTitle>
              <Button
                type={"secondary"}
                onClick={() =>
                  history.push(`/dashboard/tenant/${prepaymentUUID}`)
                }
              >
                Return to deposit summary
              </Button>
            </Div>
          ) : (
            <>
              <StyledBankText>
                Please choose which bank account you would like to release the
                last month's rent funds to.
              </StyledBankText>
              <BankAccountSelect
                disabled={false}
                onSelectAccount={(e: any) => {
                  updateReleaseBank(e);
                  setBankError("");
                }}
                defaultValue={releaseBank}
              />
              <StyledSummaryText color={COLORS.danger}>
                {bankError}
              </StyledSummaryText>
              <StyledDivider />
              <Div>
                {disbursementError ? (
                  <Div>
                    The following error occured while releasing your rent:
                    <br />
                    <strong>{disbursementError}</strong>
                    <br />
                    Please contact support@rentable.com if this issue persists
                  </Div>
                ) : (
                  <Button
                    disabled={!releaseBank}
                    type="primary"
                    onClick={handleReleaseRent}
                    loading={disbursing}
                  >
                    {`Proceed to release ${currencyFormatter.format(rentDeposit.amount)}`}
                  </Button>
                )}
              </Div>
            </>
          )}
        </Div>
      </Row>
    );
  }
};

export default ReleaseRentView;
