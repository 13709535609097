interface Props {
  width?: string;
  height?: string;
}
const EyeOpen = ({ width = "40", height = "40" }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2912_25174)">
        <path
          d="M20.0001 11.25C19.2641 11.2629 18.5335 11.3945 17.8306 11.6406C18.1558 12.2838 18.3289 13.0101 18.3334 13.75C18.3334 14.3245 18.2328 14.8934 18.0374 15.4242C17.842 15.955 17.5555 16.4373 17.1944 16.8436C16.8333 17.2498 16.4046 17.5721 15.9327 17.792C15.4609 18.0118 14.9552 18.125 14.4445 18.125C13.7868 18.1199 13.1413 17.9251 12.5695 17.5594C12.1184 19.3194 12.171 21.1944 12.7198 22.9189C13.2686 24.6434 14.2858 26.13 15.6273 27.168C16.9688 28.206 18.5666 28.7429 20.1944 28.7025C21.8221 28.6622 23.3974 28.0467 24.6969 26.9432C25.9965 25.8397 26.9545 24.3042 27.4353 22.5542C27.916 20.8042 27.8951 18.9283 27.3756 17.1924C26.856 15.4565 25.8641 13.9484 24.5404 12.8819C23.2167 11.8154 21.6283 11.2445 20.0001 11.25ZM39.7584 18.8594C35.9924 10.593 28.5369 5 20.0001 5C11.4633 5 4.00564 10.5969 0.241751 18.8602C0.082891 19.2137 0.00012207 19.6043 0.00012207 20.0004C0.00012207 20.3965 0.082891 20.7871 0.241751 21.1406C4.00772 29.407 11.4633 35 20.0001 35C28.5369 35 35.9945 29.4031 39.7584 21.1398C39.9173 20.7863 40 20.3957 40 19.9996C40 19.6035 39.9173 19.2129 39.7584 18.8594ZM20.0001 31.25C13.1494 31.25 6.86883 26.9531 3.47717 20C6.86883 13.0469 13.1487 8.75 20.0001 8.75C26.8515 8.75 33.1313 13.0469 36.523 20C33.132 26.9531 26.8515 31.25 20.0001 31.25Z"
          fill="#222A3E"
        />
      </g>
      <defs>
        <clipPath id="clip0_2912_25174">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EyeOpen;
// 222A3E
