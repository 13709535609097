import axios from "axios";

export const loadTenantInfo = async () => {
  return axios
    .get("/api/tenant/dashboard")
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw new Error(e.data);
    });
};

export const checkOnboardingComplete = (onboardingInfo) => {
  return (
    onboardingInfo.personal_info_completed_at !== null &&
    onboardingInfo.payment_completed_at !== null &&
    onboardingInfo.bank_link_completed_at !== null &&
    onboardingInfo.introduction_completed_at !== null &&
    onboardingInfo.renters_insurance_completed_at !== null
  );
};
