interface Props {
  color?: string;
}
const DollarIcon = ({ color = "#2F374B" }: Props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.625 8.5H2.875C1.82031 8.5 1 9.37891 1 10.375V29.125C1 30.1797 1.82031 31 2.875 31H36.625C37.6211 31 38.5 30.1797 38.5 29.125V10.375C38.5 9.37891 37.6211 8.5 36.625 8.5ZM35.6875 24.4375C33.5781 24.4375 31.9375 26.1367 31.9375 28.1875H7.5625C7.5625 26.1367 5.86328 24.4375 3.8125 24.4375V15.0625C5.86328 15.0625 7.5625 13.4219 7.5625 11.3125H31.9375C31.9375 13.4219 33.5781 15.0625 35.6875 15.0625V24.4375ZM19.75 14.125C17.1133 14.125 15.0625 16.6445 15.0625 19.75C15.0625 22.9141 17.1133 25.375 19.75 25.375C22.3281 25.375 24.4375 22.9141 24.4375 19.75C24.4375 16.6445 22.3281 14.125 19.75 14.125Z"
        fill={color}
      />
    </svg>
  );
};
export default DollarIcon;
