export const nucleusUserPermissions = {
  NONE: 0,
  READ: 1,
  WRITE: 2,
};

export const nucleusUserRoles = {
  READ: 1,
  WRITE: 2,
  ADMIN: 3,
};
