import styled from "styled-components";

const StyledDownCaret = styled.svg`
  fill: ${(props) => props.theme.colors.dropdownCaret};
`;

interface Props {
  width?: string;
  height?: string;
}
const DownCaret = ({ width = "24", height = "24" }: Props) => {
  return (
    <StyledDownCaret
      width={width}
      height={height}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.54758 13H29.4524C30.8294 13 31.5179 14.7848 30.5432 15.8307L20.5946 26.5144C19.9912 27.1619 19.0088 27.1619 18.4053 26.5144L8.4568 15.8307C7.48206 14.7848 8.17056 13 9.54758 13Z" />
    </StyledDownCaret>
  );
};

export default DownCaret;
