import { useState } from "react";
import styled from "styled-components";
import { Option } from "../../../components/global/ModelInterfaces";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import Dropdown from "../../../components/baseComponents/Dropdown";

import integrationType from "../../../enums/integrationType";
import RentManagerCompanyForm from "../../pages/Integrations/rent_manager/components/CreateRentManagerCompanyForm";
import YardiCompanyForm from "../../pages/Integrations/yardi/components/CreateYardiCompanyForm";

const StyledInstructions = styled.span`
  font-size: ${(props) => props.theme.font_size.headlineSM};
`;

const CreateIntegration = () => {
  const [selectedIntegration, setSelectedIntegration] = useState<Option | null>(
    null
  );

  const integrationOptions: Array<Option> = [
    { label: "Rent Manager", id: integrationType.RENT_MANAGER.toString() },
    { label: "Yardi", id: integrationType.YARDI.toString() },
  ];
  const integrationOnSelect = (option: Option) => {
    setSelectedIntegration(option);
  };
  return (
    <Row justifyContent="center">
      <Div width={{ default: 8 / 12 }}>
        {!selectedIntegration && (
          <Row mt={{ default: 3 }}>
            <StyledInstructions>
              Add/update an integration to this company.
              <br />
              Start by selecting an integration type from the dropdown below.
            </StyledInstructions>
          </Row>
        )}
        <Div width={{ default: 1 }}>
          <Dropdown
            name="Integration Type"
            options={integrationOptions}
            placeholder="Select an Integration to continue."
            onClick={integrationOnSelect}
            value={
              selectedIntegration
                ? {
                    label: selectedIntegration.label,
                    id: selectedIntegration.id,
                  }
                : null
            }
          />
        </Div>
        {selectedIntegration &&
          Number(selectedIntegration.id) === integrationType.RENT_MANAGER && (
            <RentManagerCompanyForm />
          )}
        {selectedIntegration &&
          Number(selectedIntegration.id) === integrationType.YARDI && (
            <YardiCompanyForm />
          )}
      </Div>
    </Row>
  );
};

export default CreateIntegration;
