import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import styled from "styled-components";

import Div from "../baseComponents/Div";
import Row from "../baseComponents/Row";
import Input from "../baseComponents/Input";
import Button from "../baseComponents/Button";
import PasswordInput from "../baseComponents/PasswordInput";
import ErrorMessage from "../baseComponents/ErrorMessage";

import { RootState } from "../..";
import { emailRegex } from "../../utils/accountUtils";
import { login } from "../../store/auth";
import { GenericObject } from "../global/ModelInterfaces";

const StyledButtonRow = styled(Row)`
  margin-top: 2.5rem;
`;

const StyledForgotPassword = styled.a`
  text-decoration: none;
  text-align: right;
  color: ${(props) => props.theme.colors.black};
`;
const LoginForm = () => {
  const authenticated = useAppSelector(
    (state: RootState) => state.auth.authenticated
  );
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loginErrors, setLoginErrors] = useState<GenericObject>({
    email: false,
    password: false,
  });

  const [loading, setLoading] = useState<boolean>(false);

  const validateLogin = () => {
    if (
      (email.length === 0 || !emailRegex.test(email)) &&
      password.length === 0
    ) {
      setErrorMessage("Please enter a valid email and a password.");
      setLoading(false);
      setLoginErrors({
        password: true,
        email: true,
      });
      return false;
    }
    if (emailRegex.test(email) && password.length === 0) {
      setErrorMessage("Please enter a password.");
      setLoading(false);
      setLoginErrors({
        password: true,
        email: false,
      });
      return false;
    }
    if ((email.length === 0 || !emailRegex.test(email)) && password) {
      setErrorMessage("Please enter a valid email.");
      setLoading(false);
      setLoginErrors({
        password: false,
        email: true,
      });
      return false;
    }
    setLoading(false);
    return true;
  };

  const handleSubmit = () => {
    const failureCallback = (errorMessage: string) => {
      setLoading(false);
      setErrorMessage(errorMessage);
    };
    if (validateLogin()) {
      setLoading(true);
      login(email, password, failureCallback);
    }
  };

  useEffect(() => {
    const handleEventListener = (e: any) => {
      if (e.key === "Enter") {
        handleSubmit();
      }
    };
    // Bind the event listener
    document.addEventListener("keypress", handleEventListener);
    // Unbind the event listener
    return () => {
      document.removeEventListener("keypress", handleEventListener);
    };
  }, [handleSubmit]);

  if (authenticated)
    return (
      <>
        <Redirect to={`/dashboard`} />
      </>
    );

  return (
    <Div width={{ default: 1 }}>
      <Row>
        <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
          <Input
            label="Email"
            error={loginErrors.email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Div>
        <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
          <PasswordInput
            error={loginErrors.password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <StyledForgotPassword href="/password/reset">
            Forgot password?
          </StyledForgotPassword>
        </Div>
      </Row>
      <Row justifyContent="center">
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </Row>
      <StyledButtonRow justifyContent="center">
        <Div width={{ sm: 1, lg: 1 / 2 }}>
          <Button
            text="Log In"
            loading={loading}
            disabled={loading}
            onClick={handleSubmit}
          />
        </Div>
      </StyledButtonRow>
    </Div>
  );
};

export default LoginForm;
