import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import styled from "styled-components";
import Container from "../baseComponents/Container";
import Div from "../baseComponents/Div";
import Row from "../baseComponents/Row";
import BackButton from "../baseComponents/BackButton";
import ListItem from "../baseComponents/ListItem";
import EnvelopeIcon from "../../images/EnvelopeIcon";
import Button from "../baseComponents/Button";
import { Invite, Prepayment } from "../global/ModelInterfaces";
import { navigateToTenant } from "../global/utils";
import { cssBreakpoints } from "../global/theme";
import {
  deleteTenantInvite,
  sendInviteReminder,
} from "../../utils/depositUtils";
import { removeInvite, updateInvite } from "../../store/invite";
import { DateTime } from "luxon";
import { deletePrepayment } from "../../store/prepayments";

interface Props {
  invites: Invite[];
  title: string;
}

const StyledRow = styled(Row)`
  ${cssBreakpoints("margin-top", [{ sm: "2rem" }, { md: "0rem" }])}
`;

const StyledTitle = styled.div`
  font-weight: 700;
  line-height: 1.375em;
  color: ${(props) => props.theme.colors.navy};
  ${cssBreakpoints("font-size", [{ sm: "1.25rem" }, { md: "2.5rem" }])}
`;

const StyledInvites = styled(Div)`
  margin-top: 1rem;
`;

const InviteDashboard = ({ invites, title }: Props) => {
  const [error, setError] = useState("");
  const history = useHistory();
  const dispatch = useAppDispatch();

  const deleteInviteCallback = (
    deleted_invite: Invite,
    deleted_prepayment: Prepayment
  ) => {
    dispatch(removeInvite(deleted_invite.prepayment_uuid));
    dispatch(deletePrepayment(deleted_prepayment.uuid));
  };

  const handleDelete = (invite: Invite) => {
    deleteTenantInvite(invite, deleteInviteCallback);
  };

  const reminderSuccessCallback = (invite: Invite) => {
    dispatch(updateInvite(invite));
  };

  const reminderFailureCallback = (error: string) => {
    setError(error);
  };

  const handleResendInvite = (invite: Invite) => {
    setError("");
    sendInviteReminder(
      invite,
      reminderSuccessCallback,
      reminderFailureCallback
    );
  };

  const disableReminderButton = (last_reminder_sent: string) => {
    const last_reminder_date = DateTime.fromISO(last_reminder_sent);
    return last_reminder_date >= yesterday;
  };

  const yesterday = DateTime.now().plus({ days: -1 });

  return (
    <Container>
      <BackButton returnUrl={`/dashboard`} text={`Go to Dashboard`} />
      <StyledRow justifyContent="center">
        <Div width={{ sm: 1, md: 3 / 4, lg: 3 / 4 }}>
          <Row
            flexWrap="no-wrap"
            justifyContent="space-between"
            alignItems="center"
          >
            <Div width={{ sm: 3 / 5, md: 3 / 4, lg: 1 / 2 }}>
              <StyledTitle>{title}</StyledTitle>
            </Div>
            <Div width={{ sm: 2 / 5, md: 1 / 4, lg: 1 / 4 }}>
              <Button
                type="primary"
                text="Invite Tenant"
                onClick={() => history.push("/dashboard/invite")}
              />
            </Div>
          </Row>
          <StyledInvites>
            {invites.map((invite: Invite) => {
              const inviteMetrics = [
                ["leaseStartDate", invite.lease_start_date],
                ["inviteSentOn", invite.invited_at.split("T")[0]],
                ["inviteAccepted", invite.accepted ? "Yes" : "No"],
                [
                  "lastReminderSent",
                  invite.last_reminder_sent
                    ? invite.last_reminder_sent.toString().split(/[\sT]+/)[0] //regex to split date string on the 'T' or on a space
                    : "None sent",
                ],
              ];

              const disableButton = disableReminderButton(
                invite.last_reminder_sent
              );

              return (
                <Row alignItems="center">
                  <Div width={{ default: 1 }}>
                    <ListItem
                      title={invite.tenant_name}
                      details={[invite.street_address]}
                      Icon={EnvelopeIcon}
                      iconHeight={"100%"}
                      iconWidth={"100%"}
                      metrics={inviteMetrics}
                      onClick={() =>
                        navigateToTenant(invite.prepayment_uuid, history)
                      }
                      onDelete={() => handleDelete(invite)}
                      key={invite.uuid}
                      onButtonClick={() => handleResendInvite(invite)}
                      buttonText={"Send Reminder"}
                      buttonDisabled={disableButton}
                    />
                  </Div>
                  {error && <Div>{error}</Div>}
                </Row>
              );
            })}
          </StyledInvites>
        </Div>
      </StyledRow>
    </Container>
  );
};

export default InviteDashboard;
