import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { variant } from "styled-system";
import { COLORS } from "../../global/colors";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import Menu from "../../baseComponents/Menu";
import { MenuItem, Prepayment } from "../../global/ModelInterfaces";
import { cssBreakpoints } from "../../global/theme";
import { getStreetAddress } from "../../../utils/globalUtils";

const StyledListItem = styled(Row)`
  height: 5rem;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
  background-color: ${COLORS.backgroundSecondary};
  &:hover {
    box-shadow: ${(props) => props.theme.box_shadow.light};
    background-color: ${COLORS.white};
  }
  ${cssBreakpoints("padding", [{ sm: "0 " }, { lg: "0" }])}
`;

const StyledUnitDetails = styled(Div)`
  ${cssBreakpoints("margin-left", [{ sm: "0" }, { md: "1rem" }])}
`;

const StyledAddress = styled(Div)`
  font-size: 0.875rem;
  font-weight: 700;
  color: ${COLORS.navy};
`;
const StyledName = styled(Div)`
  font-size: 0.625rem;
  font-weight: 600;
  color: ${COLORS.grey40};
`;

const StyledListItemLink = styled(Div)`
  cursor: pointer;
  height: 100%;
`;

interface ListItemDetailProps {
  variant?: string;
}

const ListItemDetail = styled(Div)<ListItemDetailProps>`
  font-size: 0.875rem;
  font-weight: 600;
  white-space: nowrap;
  ${variant({
    variants: {
      default: {
        color: COLORS.navy,
      },
      warning: {
        color: COLORS.yellow,
      },
      overdue: {
        color: COLORS.danger,
      },
    },
  })}
`;

interface Props {
  icon: any;
  menuItems?: MenuItem[];
  prepayment: Prepayment;
  itemDetail: string;
  variant?: string;
}

const DashboardListItem = ({
  icon,
  menuItems,
  prepayment,
  itemDetail,
  variant = "default",
}: Props) => {
  const history = useHistory();
  return (
    <StyledListItem alignItems="center" justifyContent="space-between">
      <StyledListItemLink
        width={{ sm: 1, md: 11 / 12, lg: 11 / 12 }}
        onClick={() => {
          history.push(`/dashboard/tenant/${prepayment.uuid}`);
        }}
        justifyContent="center"
      >
        <Row
          flexWrap="no-wrap"
          alignItems="center"
          justifyContent="space-between"
        >
          <Div display={{ sm: "none", md: "flex", lg: "flex" }}>{icon}</Div>
          <StyledUnitDetails width={{ sm: 2 / 3, md: 1, lg: 1 }}>
            <StyledAddress>
              {getStreetAddress(prepayment.address_1, prepayment.address_2)}
            </StyledAddress>
            <StyledName>
              {prepayment.tenant_name} • {prepayment.tenant_email}
            </StyledName>
          </StyledUnitDetails>
          <Div
            width={{ sm: 1 / 3, md: 1 / 3, lg: 1 / 2 }}
            justifyContent="center"
            alignItems="flex-end"
          >
            <ListItemDetail variant={variant}>{itemDetail}</ListItemDetail>
          </Div>
        </Row>
      </StyledListItemLink>

      {menuItems && (
        <Div
          width={{ default: 1 / 12 }}
          alignItems="center"
          justifyContent="flex-end"
          display={{ sm: "none", md: "flex", lg: "flex" }}
        >
          <Menu style="ellipsis" menuItems={menuItems} />
        </Div>
      )}
    </StyledListItem>
  );
};

export default DashboardListItem;
