import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import styled from "styled-components";
import { onboardingSteps } from "../../../utils/onboardingUtils";

const Base = styled(Div)`
  height: 1.5rem;
  border-radius: 0.5rem;
  transition: width 0.5s ease-in-out;
  position: relative;
`;

const Background = styled(Base)`
  background-color: ${(props) => props.theme.colors.grey20};
  position: absolute;
`;

const Progress = styled(Base)`
  background-color: ${(props) => props.theme.colors.primary};
  position: absolute;
  z-index: 5;
`;

const StyledText = styled(Div)`
  color: ${(props) => props.theme.colors.grey40};
`;

interface Props {
  onboarding: any;
}

const ProgressBar = ({ onboarding }: Props) => {
  const calculateStepsCompleted = () => {
    let stepsCompleted = 0;
    onboardingSteps.forEach((step) => {
      if (onboarding[step.completed_at_label] !== null) {
        stepsCompleted += 1;
      }
    });
    return stepsCompleted;
  };

  const stepsCompleted = calculateStepsCompleted();

  return (
    <Div
      width={{ default: 1 }}
      mb={{ sm: 4 }}
      display={{ sm: "none", lg: "flex" }}
    >
      <Row justifyContent="space-between">
        <StyledText>
          Progress:{" "}
          {((stepsCompleted / onboardingSteps.length) * 100).toFixed(0)}%
        </StyledText>
      </Row>
      <Base width={{ default: 1 }}>
        <Progress
          width={{ default: stepsCompleted / onboardingSteps.length }}
        />
        <Background width={{ default: 1 }} />
      </Base>
    </Div>
  );
};

export default ProgressBar;
