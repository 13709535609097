interface Props {
  width?: string;
  height?: string;
  color?: string;
}

const CalendarIcon = ({
  width = "40",
  height = "40",
  color = "#2F374B",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5625 26.3125H18.1875C18.6562 26.3125 19.125 25.9023 19.125 25.375V19.75C19.125 19.2812 18.6562 18.8125 18.1875 18.8125H12.5625C12.0352 18.8125 11.625 19.2812 11.625 19.75V25.375C11.625 25.9023 12.0352 26.3125 12.5625 26.3125ZM29.4375 8.5H26.625V5.6875C26.625 5.21875 26.1562 4.75 25.6875 4.75H23.8125C23.2852 4.75 22.875 5.21875 22.875 5.6875V8.5H15.375V5.6875C15.375 5.21875 14.9062 4.75 14.4375 4.75H12.5625C12.0352 4.75 11.625 5.21875 11.625 5.6875V8.5H8.8125C7.23047 8.5 6 9.78906 6 11.3125V31.9375C6 33.5195 7.23047 34.75 8.8125 34.75H29.4375C30.9609 34.75 32.25 33.5195 32.25 31.9375V11.3125C32.25 9.78906 30.9609 8.5 29.4375 8.5ZM29.4375 31.5859C29.4375 31.8203 29.2617 31.9375 29.0859 31.9375H9.16406C8.92969 31.9375 8.8125 31.8203 8.8125 31.5859V14.125H29.4375V31.5859Z"
        fill={color}
      />
    </svg>
  );
};

export default CalendarIcon;
