import { useState } from "react";

import styled from "styled-components";
import Container from "../baseComponents/Container";
import Div from "../baseComponents/Div";
import Row from "../baseComponents/Row";
import BackButton from "../baseComponents/BackButton";
import Input from "../baseComponents/Input";
import Button from "../baseComponents/Button";
import Checkbox from "../baseComponents/Checkbox";

import { emailRegex } from "../../utils/accountUtils";
import { inviteLandlord } from "../../utils/depositUtils";

const InviteLandlordTitle = styled(Div)`
  color: ${(props) => props.theme.colors.navy};
  font-size: ${(props) => props.theme.font_size.headlineLG};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const SuccessSection = styled(Div)`
  color: ${(props) => props.theme.colors.primary};
`;

const FailureSection = styled(Div)`
  color: ${(props) => props.theme.colors.danger};
`;

const InviteLandlordView = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [validationError, setValidationError] = useState("");

  const validateForm = () => {
    if (email.length === 0 || !emailRegex.test(email)) {
      setValidationError("Please enter a valid email.");
      return false;
    }
    if (firstName.length === 0) {
      setValidationError("Please enter a first name.");
      return false;
    }
    if (lastName.length === 0) {
      setValidationError("Please enter a last name.");
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    setSuccessMessage("");
    setErrorMessage("");
    if (validateForm()) {
      const successCallback = () => {
        setSuccessMessage(`Invite email sent to ${email}`);
      };
      const failureCallback = (error_message: string) => {
        setErrorMessage(`Invite failed. Error: ${error_message}`);
      };
      inviteLandlord(
        email,
        firstName,
        lastName,
        successCallback,
        failureCallback
      );
    }
  };

  return (
    <Container>
      <BackButton returnUrl="/settings" text="Settings Home" />
      <Row justifyContent="space-between">
        <InviteLandlordTitle mt={{ lg: 4 }} justifyContent="space-between">
          Invite New Landlord
        </InviteLandlordTitle>
      </Row>
      <Row justifyContent="center">
        <Row>
          {successMessage && <SuccessSection>{successMessage}</SuccessSection>}
          {errorMessage && <FailureSection>{errorMessage}</FailureSection>}
          {validationError && (
            <FailureSection>{validationError}</FailureSection>
          )}
        </Row>
        <Div width={{ lg: 8 / 12 }}>
          <Input
            type="text"
            label="Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <Row pt={{ lg: 3 }}>
            <Div width={{ lg: 6 / 12 }}>
              <Input
                type="text"
                label="First Name"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
            </Div>
            <Div width={{ lg: 6 / 12 }}>
              <Input
                type="text"
                label="Last Name"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
            </Div>
          </Row>
          <Div pt={{ lg: 3 }}>
            <Button onClick={handleSubmit}>Invite Property Manager</Button>
          </Div>
        </Div>
      </Row>
    </Container>
  );
};

export default InviteLandlordView;
