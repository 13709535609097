import styled from "styled-components";
import Div from "./Div";

const StyledDivider = styled(Div)`
  height: 1px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.16);
  margin-bottom: 1.5rem;
`;

const HorizontalDivider = () => {
  return <StyledDivider />;
};

export default HorizontalDivider;
