import React from "react";
import ReactDOM from "react-dom";
import App from "./AppMain";
import configureStore from "./store/configureStore";
import configureNucleusStore from "./store/configureNucleusStore";
import { Provider as ReduxProvider } from "react-redux";

import "./index.css";
import NucleusApp from "./AppNucleus";

declare global {
  interface Window {
    store: {
      auth: object;
      bankInfo: object;
      deposits: object;
      units: object;
      buildings: object;
      invites: object;
    };
    sessionRewind: any;
    SessionRewindConfig: object;
  }
}

let store = configureStore();

if (window.location.href.includes("/nucleus")) {
  const store = configureNucleusStore();
  if (process.env.Node_ENV !== "production") {
    window.store = store;
  }
  ReactDOM.render(
    <React.StrictMode>
      <ReduxProvider store={store}>
        <NucleusApp />
      </ReduxProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
} else {
  if (process.env.Node_ENV !== "production") {
    window.store = store;
  }
  ReactDOM.render(
    <React.StrictMode>
      <ReduxProvider store={store}>
        <App />
      </ReduxProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
