import { PropsWithChildren, useState } from "react";
import styled from "styled-components";
import Div from "../../components/baseComponents/Div";
import Row from "../../components/baseComponents/Row";
import Button from "../../components/baseComponents/Button";
import XButton from "../../components/baseComponents/XButton";

const StyledForm = styled(Div)`
  border: ${(props) => props.theme.borders.primarySM};
  border-radius: ${(props) => props.theme.border_radius.SM};
`;

const StyledTitle = styled(Div)`
  font-size: ${(props) => props.theme.font_size.bodyLG};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

interface Props {
  formTitle: string;
  buttonText: string;
}

const ButtonToForm = ({
  formTitle,
  buttonText,
  children,
}: PropsWithChildren<Props>) => {
  const [open, setOpen] = useState(false);

  const toggleForm = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <Div>
      {open ? (
        <StyledForm p={{ default: 3 }}>
          <Row justifyContent="space-between">
            <StyledTitle width={"fill"}>{formTitle}</StyledTitle>
            <XButton width="24" height="24" onClick={toggleForm} />
          </Row>
          <Div>{children}</Div>
        </StyledForm>
      ) : (
        <Button onClick={toggleForm} text={buttonText} />
      )}
    </Div>
  );
};

export default ButtonToForm;
