export const feeTypes = [
  { id: "1", label: "Pet fee" },
  { id: "2", label: "Key fee" },
  { id: "3", label: "Utilities fee" },
  { id: "4", label: "First Month's Rent" },
  { id: "5", label: "Application fee" },
  { id: "6", label: "Move in fee" },
  {
    id: "7",
    label: "Facilities/Parking fee",
  },
  { id: "8", label: "Broker Fee" },
  { id: "9", label: "General fee" },
];

export default feeTypes;
