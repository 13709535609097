import { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../..";
import { Prepayment, PrepaymentFilters } from "../global/ModelInterfaces";
import prepaymentStatuses from "../../enums/prepaymentStatuses";
import styled from "styled-components";
import Div from "./Div";
import Row from "./Row";
import XButton from "./XButton";
import Checkbox from "./Checkbox";

interface StyleProps {
  hidden?: boolean;
}

const StyledMenu = styled.div<StyleProps>`
  border-radius: 1.5rem;
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.grey10};
  ${(props) => props.hidden && "visibility: hidden"}
`;

const StyledTitleRow = styled(Row)`
  margin-bottom: 1.5rem;
`;

const StyledTitle = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineSM};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  color: ${(props) => props.theme.colors.navy};
`;

const StyledSectionTitle = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
  font-size: ${(props) => props.theme.font_size.headlineXS};
  color: ${(props) => props.theme.colors.navy};
`;

const StyledHR = styled.hr`
  margin: 0.5rem 0;
`;

interface Props {
  hidden?: boolean;
  onClose: () => void;
  handleFilter: (
    prepaymentFilters: PrepaymentFilters,
    prepayments: Prepayment[]
  ) => void;
  initialFilters: PrepaymentFilters;
}

const FilterMenu = ({
  hidden = true,
  onClose,
  handleFilter,
  initialFilters,
}: Props) => {
  const [activeFilter, setActiveFilter] = useState(initialFilters.active);
  const [pendingFilter, setPendingFilter] = useState(initialFilters.pending);
  const [processingFilter, setProcessingFilter] = useState(
    initialFilters.processing
  );
  const [closedFilter, setClosedFilter] = useState(initialFilters.closed);
  const [disbursingFilter, setDisbursingFilter] = useState(
    initialFilters.disbursing
  );

  const prepaymentSlice: { loading: Boolean; objects: Prepayment[] } =
    useAppSelector((state: RootState) => state.prepayments);
  const prepayments = prepaymentSlice.objects;

  const filterPrepayments = () => {
    const tempFilteredPrepayments: Prepayment[] = [];
    if (
      !activeFilter &&
      !processingFilter &&
      !pendingFilter &&
      !disbursingFilter &&
      !closedFilter
    ) {
      return prepayments;
    }
    if (activeFilter) {
      tempFilteredPrepayments.push(
        ...prepayments.filter((prepayment: Prepayment) => {
          return prepayment.status_id === prepaymentStatuses.ACTIVE;
        })
      );
    }
    if (closedFilter) {
      tempFilteredPrepayments.push(
        ...prepayments.filter((prepayment: Prepayment) => {
          return prepayment.status_id === prepaymentStatuses.CLOSED;
        })
      );
    }
    if (pendingFilter) {
      tempFilteredPrepayments.push(
        ...prepayments.filter((prepayment: Prepayment) => {
          return prepayment.status_id === prepaymentStatuses.PENDING;
        })
      );
    }
    if (disbursingFilter) {
      tempFilteredPrepayments.push(
        ...prepayments.filter((prepayment: Prepayment) => {
          return prepayment.status_id === prepaymentStatuses.DISBURSING;
        })
      );
    }
    if (processingFilter) {
      tempFilteredPrepayments.push(
        ...prepayments.filter((prepayment: Prepayment) => {
          return prepayment.status_id === prepaymentStatuses.PROCESSING;
        })
      );
    }
    return tempFilteredPrepayments;
  };

  useEffect(() => {
    const filteredPrepayments = filterPrepayments();
    handleFilter(
      {
        active: activeFilter,
        closed: closedFilter,
        processing: processingFilter,
        pending: pendingFilter,
        disbursing: disbursingFilter,
      },
      filteredPrepayments
    );
  }, [
    activeFilter,
    pendingFilter,
    closedFilter,
    processingFilter,
    disbursingFilter,
    prepayments,
  ]);

  return (
    <StyledMenu hidden={hidden}>
      <StyledTitleRow
        flexWrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
      >
        <StyledTitle>Filters</StyledTitle>
        <XButton onClick={() => onClose()} width="1.5rem" />
      </StyledTitleRow>
      <Row>
        <StyledSectionTitle>Status</StyledSectionTitle>
      </Row>
      <StyledHR />
      <Row alignItems="center">
        <Checkbox
          value={pendingFilter}
          onChange={() => setPendingFilter((pendingFilter) => !pendingFilter)}
          label={"Pending"}
        />
      </Row>
      <Row alignItems="center">
        <Checkbox
          value={processingFilter}
          onChange={() =>
            setProcessingFilter((processingFilter) => !processingFilter)
          }
          label={"Processing"}
        />
      </Row>
      <Row alignItems="center">
        <Checkbox
          value={activeFilter}
          onChange={() => setActiveFilter((activeFilter) => !activeFilter)}
          label={"Active"}
        />
      </Row>
      <Row alignItems="center">
        <Checkbox
          value={disbursingFilter}
          onChange={() =>
            setDisbursingFilter((disbursingFilter) => !disbursingFilter)
          }
          label={"Disbursing"}
        />
      </Row>
      <Row alignItems="center">
        <Checkbox
          value={closedFilter}
          onChange={() => setClosedFilter((closedFilter) => !closedFilter)}
          label={"Closed"}
        />
      </Row>
    </StyledMenu>
  );
};

export default FilterMenu;
