import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Container from "../../../components/baseComponents/Container";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import Button from "../../../components/baseComponents/Button";

const StyledHeader = styled(Row)`
  font-size: ${(props) => props.theme.font_size.headlineLG};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;
const Integrations = () => {
  const history = useHistory();

  return (
    <Container>
      <StyledHeader
        justifyContent="center"
        mt={{ default: 5 }}
        mb={{ default: 5 }}
      >
        Welcome to Integrations!
      </StyledHeader>
      <Row justifyContent="center">
        <Div width={{ default: 4 / 12 }} mt={{ default: 3 }}>
          <Button
            onClick={() =>
              history.push("/nucleus/integrations/rent-manager/dashboard")
            }
          >
            <>
              <img
                src="https://rentable-public-assets.s3.us-east-2.amazonaws.com/rent-manager.png"
                alt="Rent Manager"
                style={{
                  marginRight: "0.5em",
                  verticalAlign: "middle",
                  height: "1.6em",
                }}
              />
              Rent Manager
            </>
          </Button>
        </Div>
      </Row>
      <Row justifyContent="center">
        <Div width={{ default: 4 / 12 }} mt={{ default: 3 }}>
          <Button onClick={() => history.push("/nucleus/integrations/yardi")}>
            <>
              <img
                src="https://rentable-public-assets.s3.us-east-2.amazonaws.com/yardi.png"
                alt="Yardi"
                style={{
                  marginRight: "0.5em",
                  verticalAlign: "middle",
                  height: "1em",
                }}
              />
              Yardi
            </>
          </Button>
        </Div>
      </Row>
    </Container>
  );
};

export default Integrations;
