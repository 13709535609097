const set_localstore_item = (key, value) => {
  localStorage.setItem(key, value);
}

const get_localstore_item = (key) => {
  // TODO manage key does not exist.
  return localStorage.getItem(key);
}

const delete_localstore_item = (key) => {
  localStorage.removeItem(key)
}

export { set_localstore_item, get_localstore_item, delete_localstore_item };