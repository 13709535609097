import Div from "./Div";
import { ComponentBreakpoints } from "../global/ModelInterfaces";

interface Props {
  children?: any;
  width?: ComponentBreakpoints | string;
}

const Col = ({ children, width }: Props) => {
  return <Div width={width}>{children}</Div>;
};

export default Col;
