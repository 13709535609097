import Row from "./Row";
import Div from "./Div";
import SadCoin from "../../images/SadCoin";

interface Props {
  errorMessage?: string;
}
const ErrorView = ({ errorMessage }: Props) => {
  return (
    <Row justifyContent="center">
      <Div alignItems="center" width={{ default: 1 / 2 }}>
        <SadCoin />
        {errorMessage ??
          "It Looks like something went wrong, please refresh the page and try again, or contact support@rentable.com if this issue persists."}
      </Div>
    </Row>
  );
};

export default ErrorView;
