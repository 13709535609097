// React, Redux, Hooks
import { useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import styled from "styled-components";
import useGetPrepayment from "../../utils/useGetPrepayment";
import { updatePrepayment } from "../../store/prepayments";

// Base Components
import Div from "../baseComponents/Div";
import Row from "../baseComponents/Row";
import Button from "../baseComponents/Button";
import ErrorMessage from "../baseComponents/ErrorMessage";

// Utilities
import { selfFundAdditionalRequests } from "../../utils/depositUtils";
import { currencyFormatter } from "../global/utils";

// Types, Enums
import prepaymentStatuses from "../../enums/prepaymentStatuses";
import {
  AdditionalFundsRequest,
  BankAccount,
  Prepayment,
} from "../global/ModelInterfaces";

const StyledSummaryText = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.bolder};
  color: ${(props) => props.theme.colors.grey};
`;
const StyledSubTitle = styled(Div)`
  line-height: 1.875rem;
  color: ${(props) => props.theme.colors.grey};
  font-weight: ${(props) => props.theme.font_weight.bolder};
  font-size: ${(props) => props.theme.font_size.headlineSM};
`;
interface Props {
  bankAccount?: BankAccount;
}

const FundAdditionalRequests = ({ bankAccount }: Props) => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState("");
  const [funding, setFunding] = useState(false);
  const { selectedPrepayment } = useGetPrepayment();

  const pending_security_deposit_additional_funds_requests =
    selectedPrepayment?.deposits.security_deposit?.additional_funds_requests.filter(
      (request: AdditionalFundsRequest) =>
        request.status_id == prepaymentStatuses.PENDING
    ) || [];
  const pending_last_months_rent_addtional_funds_requests =
    selectedPrepayment?.deposits.last_months_rent?.additional_funds_requests.filter(
      (request: AdditionalFundsRequest) =>
        request.status_id == prepaymentStatuses.PENDING
    ) || [];

  const getAdditionalRequestsTotal = () => {
    let deposit_additional_funds_total = 0;
    if (pending_security_deposit_additional_funds_requests.length > 0) {
      deposit_additional_funds_total =
        pending_security_deposit_additional_funds_requests.reduce(
          (total: number, additional_funds_request: AdditionalFundsRequest) =>
            total +
            additional_funds_request.amount +
            additional_funds_request.processor_fee,
          0
        );
    }

    let last_months_rent_additional_funds_total = 0;
    if (pending_last_months_rent_addtional_funds_requests.length > 0) {
      last_months_rent_additional_funds_total =
        pending_last_months_rent_addtional_funds_requests.reduce(
          (total: number, additional_funds_request: AdditionalFundsRequest) =>
            total +
            additional_funds_request.amount +
            additional_funds_request.processor_fee,
          0
        );
    }

    return (
      deposit_additional_funds_total + last_months_rent_additional_funds_total
    );
  };

  const handleFundAdditionalAmounts = () => {
    if (!bankAccount) {
      setError("Please select a bank account.");
      return;
    }
    setFunding(true);
    const successCallback = (prepayment: Prepayment) => {
      dispatch(updatePrepayment(prepayment));
    };
    const failureCallback = (error: string) => {
      setFunding(false);
      setError(error);
    };
    selfFundAdditionalRequests(
      selectedPrepayment?.uuid,
      bankAccount,
      successCallback,
      failureCallback
    );
  };

  return (
    <>
      {pending_security_deposit_additional_funds_requests.map(
        (additional_funds_request: AdditionalFundsRequest) => {
          return (
            <>
              <Row mb={{ default: "1.5rem" }} justifyContent="space-between">
                <Div>Additional Funds Request for Security Deposit:</Div>
                <StyledSummaryText>
                  {currencyFormatter.format(additional_funds_request.amount)}
                </StyledSummaryText>
              </Row>
              <DisplayProcessorFee
                processorFee={additional_funds_request.processor_fee}
              />
            </>
          );
        }
      )}
      {pending_last_months_rent_addtional_funds_requests.map(
        (additional_funds_request: AdditionalFundsRequest) => {
          return (
            <>
              <Row mb={{ default: "1.5rem" }} justifyContent="space-between">
                <Div>Additional Funds Request for Last Month's Rent:</Div>
                <StyledSummaryText>
                  {currencyFormatter.format(additional_funds_request.amount)}
                </StyledSummaryText>
              </Row>
              <DisplayProcessorFee
                processorFee={additional_funds_request.processor_fee}
              />
            </>
          );
        }
      )}

      <Row mb={{ default: "1.5rem" }} justifyContent="space-between">
        <StyledSubTitle mb={{ default: "1.5rem" }}>Total:</StyledSubTitle>
        <StyledSubTitle mb={{ default: "1.5rem" }}>
          {currencyFormatter.format(getAdditionalRequestsTotal())}
        </StyledSubTitle>
      </Row>
      {error && (
        <Row
          mb={{ default: "1.5rem" }}
          alignItems="center"
          justifyContent="center"
        >
          <ErrorMessage>{error}</ErrorMessage>
        </Row>
      )}
      <Div mb={{ default: "2rem" }}>
        <Button
          disabled={!bankAccount}
          type="primary"
          text={`Fund Deposit`}
          onClick={handleFundAdditionalAmounts}
          loading={funding}
        />
      </Div>
    </>
  );
};

interface ProcessorFeeProps {
  processorFee: number;
}

const DisplayProcessorFee = ({ processorFee }: ProcessorFeeProps) => {
  if (processorFee > 0) {
    return (
      <Row mb={{ default: "1.5rem" }} justifyContent="space-between">
        <Div>Processor Fee:</Div>
        <StyledSummaryText>
          {currencyFormatter.format(processorFee)}
        </StyledSummaryText>
      </Row>
    );
  } else {
    return <></>;
  }
};

export default FundAdditionalRequests;
