import { useState } from "react";
import useCalculatePaymentAmounts from "../../../utils/useCalculatePaymentAmounts";
import Button from "../../baseComponents/Button";
import Div from "../../baseComponents/Div";
import ErrorMessage from "../../baseComponents/ErrorMessage";
import Row from "../../baseComponents/Row";
import { currencyFormatter } from "../../global/utils";
import InsufficientFunds from "../InsufficientFunds";
import PaymentProcessorRestricted from "../PaymentProcessorRestricted";
import {
  Deposit,
  GenericObject,
  TenantFinancialInfo,
} from "../../global/ModelInterfaces";
import { Redirect, useParams } from "react-router-dom";
import useGetBankAccount from "../../../utils/useGetBankAccount";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { updateTenantDeposits } from "../../../store/tenantInfo";
import { makeDepositPayment } from "../../../utils/depositUtils";
import useGetCurrentPrepayment from "../../../utils/useGetCurrentPrepayment";
import PlaidButton from "../../PlaidButton";
import { noop } from "lodash";
import PageLoader from "../../PageLoader";

interface Props {
  financialInfo: TenantFinancialInfo;
}
const LastMonthsRentPayment = ({ financialInfo }: Props) => {
  const dispatch = useAppDispatch();
  const { prepaymentUUID } = useParams<GenericObject>();
  const { prepayment } = useGetCurrentPrepayment(prepaymentUUID);
  const [paymentError, setPaymentError] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const { bankAccount, loadingBankAccounts } = useGetBankAccount();
  const lastMonthsRentDeposit = prepayment.deposits.last_months_rent;
  const { loading, lastMonthsRentWithFees, totalAmountDueWithFee } =
    useCalculatePaymentAmounts(prepayment);
  const paymentProcessorRestricted: Boolean = useAppSelector(
    (state) =>
      state.tenantInfo.objects.personalInfo.payment_processor_restricted
  );

  if (loading || loadingBankAccounts) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  }

  if (!lastMonthsRentDeposit) {
    return <Redirect to="/404" />;
  }

  if (!bankAccount) {
    return (
      <Div>
        <p>
          In order to pay your deposits, you must have a bank account linked.
          Please click below to link your account.
        </p>
        <PlaidButton onSuccess={noop} />
      </Div>
    );
  }

  if (financialInfo.paymentPlanApproval.is_approved) {
    if (!financialInfo.sufficientFundsMonthlyPayment) {
      return <InsufficientFunds amountDue={Number(totalAmountDueWithFee)} />;
    }
  } else {
    // If tenant is NOT enrolled on a payment plan and does not have enough fund to pay in full
    if (!financialInfo.sufficientFundsFullPayment) {
      return <InsufficientFunds amountDue={Number(totalAmountDueWithFee)} />;
    }
  }

  const handlePayment = () => {
    setPaymentProcessing(true);
    const successCallback = (deposits: Deposit[]) => {
      dispatch(updateTenantDeposits(deposits));
      setPaymentProcessing(false);
    };

    const failureCallback = () => {
      setPaymentProcessing(false);
      setPaymentError(true);
    };
    makeDepositPayment(
      bankAccount,
      lastMonthsRentDeposit.uuid,
      successCallback,
      failureCallback
    );
  };

  return (
    <Div>
      <Row>
        <h2>Confirm and Pay Last Month's Rent</h2>
      </Row>
      <Row>
        <h4>Amount Due</h4>
      </Row>
      <Row alignItems="center" justifyContent="space-between">
        <p>Last Month's Rent:</p>
        <p>{currencyFormatter.format(lastMonthsRentDeposit.amount)}</p>
      </Row>
      {!!lastMonthsRentDeposit.dwolla_fee && (
        <Row alignItems="center" justifyContent="space-between">
          <p>Platform Fee:</p>
          <p>{currencyFormatter.format(lastMonthsRentDeposit.dwolla_fee)}</p>
        </Row>
      )}
      <Row alignItems="center" justifyContent="space-between">
        <p>Total:</p>
        <p>${Number(lastMonthsRentWithFees)}</p>
      </Row>
      <Row>
        <h4>Bank Account:</h4>
        <h4>
          {bankAccount.bank_name}
          &nbsp; •••• &nbsp;
          {bankAccount.mask}
        </h4>
      </Row>
      <Div mt={{ default: 3 }} justifyContent="center" alignItems="center">
        <Div width={{ default: 1, lg: 6 / 12 }}>
          {paymentProcessorRestricted ? (
            <PaymentProcessorRestricted />
          ) : (
            <>
              {paymentError && (
                <ErrorMessage mb={{ default: 3 }} mt={{ default: 3 }}>
                  There was an error funding the deposit, please try again or
                  contact support@rentable.com if this issue persists.
                </ErrorMessage>
              )}
              <Button
                onClick={handlePayment}
                loading={paymentProcessing}
                disabled={paymentProcessing || paymentError}
                text={"Fund Last Month's Rent"}
              />
            </>
          )}
        </Div>
      </Div>
    </Div>
  );
};

export default LastMonthsRentPayment;
