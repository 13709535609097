import styled from "styled-components";
import Div from "../../components/baseComponents/Div";
import Row from "../../components/baseComponents/Row";
import ConfusedCoin from "../../images/ConfusedCoin";

const StyledTitle = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineLG};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const ForbiddenPage = () => {
  return (
    <Row justifyContent="center">
      <Div alignItems="center">
        <ConfusedCoin />
        <StyledTitle mt={{ default: 3 }}>Sorry!</StyledTitle>
        <h4>You don't have the permissions to access this page.</h4>
        <h4>Contact your manager if you have any questions.</h4>
      </Div>
    </Row>
  );
};

export default ForbiddenPage;
