import Div from "./Div";
import styled from "styled-components";
import XIcon from "../../images/XIcon";
import { COLORS } from "../global/colors";
interface Props {
  onClick: () => void;
  height?: string;
  width?: string;
  color?: string;
}

const StyledIcon = styled(Div)`
  cursor: pointer;
`;

const XButton = ({ onClick, height, width, color = COLORS.navy }: Props) => {
  return (
    <StyledIcon onClick={() => onClick()}>
      <XIcon height={height} width={width} color={color} />
    </StyledIcon>
  );
};

export default XButton;
