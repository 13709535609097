import { useAppSelector } from "../store/hooks";
import { RootState } from "..";
import {
  Building,
  Prepayment,
  Unit,
} from "../components/global/ModelInterfaces";

const useSearchBuildings = (searchTerm: string, limit: Number = 5) => {
  return useAppSelector((state: RootState) => {
    if (searchTerm === "") {
      return [];
    }
    return state.buildings.objects.filter(
      (building: Building) =>
        building.address_1.toLowerCase().includes(searchTerm.toLowerCase()) ||
        building.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
        building.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }).slice(0, limit);
};

const useSearchPrepayments = (searchTerm: string, limit: Number = 5) => {
  const cleanForSearch = (string: string) => {
    return string.replace(/\s/g, "").toLowerCase();
  };
  return useAppSelector((state: RootState) => {
    if (searchTerm === "") {
      return [];
    }
    const cleanSearchTerm = cleanForSearch(searchTerm);
    return state.prepayments.objects.filter(
      (prepayment: Prepayment) =>
        cleanForSearch(prepayment.address_1).includes(cleanSearchTerm) ||
        prepayment.address_2.toLowerCase().includes(cleanSearchTerm) ||
        prepayment.city.toLowerCase().includes(cleanSearchTerm) ||
        prepayment.amount.toString().includes(cleanSearchTerm) ||
        cleanForSearch(prepayment.tenant_name ?? "").includes(
          cleanSearchTerm
        ) ||
        cleanForSearch(prepayment.tenant_email ?? "").includes(cleanSearchTerm)
    );
  }).slice(0, limit);
};

const useSearchUnits = (searchTerm: string, limit: Number = 5) => {
  return useAppSelector((state: RootState) => {
    if (searchTerm === "") {
      return [];
    }
    return state.units.objects.filter(
      (unit: Unit) =>
        unit.street_address.toLowerCase().includes(searchTerm.toLowerCase()) ||
        unit.city.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }).slice(0, limit);
};

export { useSearchBuildings, useSearchPrepayments, useSearchUnits };
