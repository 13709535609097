import { useState } from "react";
import { useAppDispatch } from "../../../../../store/hooks";
import styled from "styled-components";
import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";
import Input from "../../../../../components/baseComponents/Input";
import Button from "../../../../../components/baseComponents/Button";
import { updateCompanySection } from "../../../../../store/nucleusCompanies";
import { createRentManagerCompany } from "../../../../utils/rentManagerUtils";
import { GenericObject } from "../../../../../components/global/ModelInterfaces";
import integrationType from "../../../../../enums/integrationType";
import useCompanyFromParams from "../../../../utils/useCompanyFromParams";
import NucleusLoader from "../../../../components/NucleusLoader";
const StyledInstructions = styled.span`
  font-size: ${(props) => props.theme.font_size.bodyLG};
`;
const RentManagerCompanyForm = () => {
  const { company } = useCompanyFromParams();
  const dispatch = useAppDispatch();
  const [error, setError] = useState("");
  const [rentManagerCompanyCode, setRentManagerCompanyCode] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCreateRentManagerCompany = () => {
    setLoading(true);
    const successCallback = (integrationDetails: GenericObject) => {
      dispatch(
        updateCompanySection(
          company.uuid,
          "integration_info",
          integrationDetails
        )
      );
      dispatch(
        updateCompanySection(
          company.uuid,
          "integration_type",
          integrationType.RENT_MANAGER
        )
      );
      dispatch(
        updateCompanySection(company.uuid, "integration_name", "Rent Manager")
      );
      setLoading(false);
    };
    const failureCallback = (error: string) => {
      setError(error);
      setLoading(false);
    };
    if (!rentManagerCompanyCode) {
      setError(
        "You must provide a Rent Manager Company Code before submitting."
      );
      setLoading(false);
      return;
    }
    createRentManagerCompany(
      company.uuid,
      rentManagerCompanyCode,
      successCallback,
      failureCallback
    );
  };

  if (loading) {
    return (
      <Row justifyContent="center">
        <NucleusLoader loadingText="Creating Rent Manager Company and Locations..." />
      </Row>
    );
  }

  return (
    <Div width={{ default: 1 }}>
      <Div width={{ default: 1 }} mb={{ default: 3 }}>
        <StyledInstructions>
          This tool will create a Rent Manager Company record in our database.
          <br />
          It will also create a Rent Manager Location record for each location
          that we have access to.
          <br />
          Once created, each location must be set up individually using the Rent
          Manger Tab.
        </StyledInstructions>
      </Div>

      <Div width={{ default: 1 }} mb={{ default: 3 }}>
        <Input
          label="Rent Manager Company Code"
          value={rentManagerCompanyCode}
          onChange={(e) => {
            setError("");
            setRentManagerCompanyCode(e.target.value);
          }}
          type="light"
          backgroundColor={"light"}
          error={Boolean(!rentManagerCompanyCode)}
        />
      </Div>
      {error && (
        <Div mb={{ default: 3 }}>
          <ErrorMessage>{error}</ErrorMessage>
        </Div>
      )}
      <Div width={{ default: 1 }}>
        <Button
          text="Create Rent Manager Company"
          onClick={handleCreateRentManagerCompany}
          loading={loading}
          disabled={!rentManagerCompanyCode}
        />
      </Div>
    </Div>
  );
};
export default RentManagerCompanyForm;
