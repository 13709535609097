import React from "react";

const CheckIcon = ({ height = "40", width = "40" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7589 30.2589L32.1339 15.8839C32.622 15.3958 32.622 14.6043 32.1339 14.1162L30.3662 12.3484C29.878 11.8602 29.0866 11.8602 28.5984 12.3484L16.875 24.0717L11.4016 18.5984C10.9135 18.1102 10.122 18.1102 9.63383 18.5984L7.86609 20.3661C7.37797 20.8542 7.37797 21.6457 7.86609 22.1338L15.9911 30.2588C16.4793 30.747 17.2707 30.747 17.7589 30.2589Z"
        fill="#222A3E"
      />
    </svg>
  );
};

export default CheckIcon;
