import React from "react";

interface Props {
  classes?: string;
  styles?: object;
}

export default function PageLoader({ classes, styles }: Props) {
  return (
    <div
      className={`pageLoaderContainer ${classes}`}
      style={styles ? styles : { width: "350px", height: "350px" }}
    />
  );
}
