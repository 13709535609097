import RentableCard from "../../baseComponents/RentableCard";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import { GenericObject } from "../../global/ModelInterfaces";
import { monthDayYearFormatter } from "../../global/utils";
interface Props {
  disbursementInfo: GenericObject;
}

const DisbursementInfoCard = ({ disbursementInfo }: Props) => {
  return (
    <RentableCard title="Disbursement Information">
      <>
        <Row>
          <Div>Disbursement Date:</Div>
          <Div>{monthDayYearFormatter(disbursementInfo.disbursement_date)}</Div>
        </Row>
        <Row>
          <Div>Disbursement Type:</Div>
          <Div>{disbursementInfo.disbursement_type}</Div>
        </Row>
        {disbursementInfo.bank_account && (
          <>
            <Row>
              <Div>Bank Name: {disbursementInfo.bank_account.bank_name}</Div>
            </Row>
            <Row>
              <Div>
                Account: {disbursementInfo.bank_account.name} ••••{" "}
                {disbursementInfo.bank_account.mask}
              </Div>
            </Row>
          </>
        )}
        {disbursementInfo.forwarding_address && (
          <Row>
            <Div>Forwarding Address:</Div>
            <Div>{disbursementInfo.forwarding_address}</Div>
          </Row>
        )}
      </>
    </RentableCard>
  );
};

export default DisbursementInfoCard;
