import styled from "styled-components";
import { cssBreakpoints } from "../global/theme";

const StyledLogo = styled.div`
  height: 2.313rem;
  ${cssBreakpoints("width", [{ sm: "9rem" }, { lg: "11rem" }])}
  background-image: url(https://rentable-public-assets.s3.us-east-2.amazonaws.com/logo_whole.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: ${(props) => props.theme.space[4]};
`;

const RentableLogo = () => {
  return <StyledLogo />;
};

export default RentableLogo;
