import { useState } from "react";
import Button from "../../../components/baseComponents/Button";
import ConfirmationModal from "../../../components/baseComponents/ConfirmationModal";
import Div from "../../../components/baseComponents/Div";
import ErrorMessage from "../../../components/baseComponents/ErrorMessage";
import Input from "../../../components/baseComponents/Input";
import Row from "../../../components/baseComponents/Row";
import { editExistingPaymentPlan } from "../../utils/paymentPlanUtils";
import SuccessMessage from "../../../components/baseComponents/SuccessMessage";
import { useAppDispatch } from "../../../store/hooks";
import { updatePrepaymentSection } from "../../../store/nucleusPrepayments";

interface Props {
  prepaymentUUID: string;
}

const EditExistingPaymentPlanForm = ({ prepaymentUUID }: Props) => {
  const dispatch = useAppDispatch();
  const [approvedMonths, setApprovedMonths] = useState<number | null>(null);
  const [confirmApprove, setConfirmApprove] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validateForm = () => {
    if (!approvedMonths) {
      setErrorMessage("Please enter a valid number of months.");
      return false;
    } else {
      return true;
    }
  };

  const handleOpenModal = () => {
    if (validateForm()) {
      setConfirmApprove(true);
      setErrorMessage("");
    }
  };

  const handleApprove = () => {
    const successCallback = (res: any) => {
      setConfirmApprove(false);
      setSuccessMessage("Payment Plan Changed!");
      setErrorMessage("");
      setApprovedMonths(null);
      dispatch(
        updatePrepaymentSection(
          prepaymentUUID,
          "payment_plan_info",
          res.payment_plan_info
        )
      );
    };
    const failureCallback = (errorMessage: string) => {
      setConfirmApprove(false);
      setErrorMessage(errorMessage);
    };
    editExistingPaymentPlan(
      approvedMonths,
      prepaymentUUID,
      successCallback,
      failureCallback
    );
  };

  return (
    <Div>
      <Row>
        <Div width={{ default: 1, md: 12 / 12 }}>
          <Input
            type="number"
            label="Number of Months"
            value={String(approvedMonths)}
            onChange={(e) => setApprovedMonths(Number(e.target.value))}
          />
        </Div>
        <Div width={{ default: 12 / 12 }} mt={{ default: 3 }}>
          <Button type="primary" text="Submit" onClick={handleOpenModal} />
        </Div>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
      </Row>
      <ConfirmationModal
        confirmationOpen={confirmApprove}
        onClose={() => setConfirmApprove(false)}
        onConfirm={handleApprove}
        message="Are you sure you would like to change the number of payments on this payment plan?"
      />
    </Div>
  );
};

export default EditExistingPaymentPlanForm;
