import styled from "styled-components";
import Div from "./Div";
import Row from "./Row";

const StyledRadioButton = styled.input`
  cursor: pointer;
`;

interface Props {
  name: string;
  value: string;
  label?: string;
  onSelect?: (value: string) => void;
}

const RadioButton = ({ label, name, value, onSelect }: Props) => {
  const handleClick = () => {
    if (onSelect) {
      onSelect(value);
    }
  };
  return (
    <Row>
      <StyledRadioButton type={"radio"} name={name} onClick={handleClick} />
      <Div>{label}</Div>
    </Row>
  );
};

export default RadioButton;
