import { useHistory } from "react-router-dom";
import styled from "styled-components";
import BackArrow from "../../images/BackArrow";
import Div from "./Div";
import Row from "./Row";
import { COLORS } from "../global/colors";

const StyledButton = styled(Div)`
  cursor: pointer;
  width: max-content;
  position: absolute;
`;

const StyledText = styled(Div)`
  color: ${COLORS.disabledText};
  font-size: ${(props) => props.theme.font_size.bodyLG};
`;

interface Props {
  returnUrl?: string;
  onClick?: Function;
  text?: string;
}
const BackButton = ({ returnUrl, onClick, text }: Props) => {
  const history = useHistory();
  const handleOnClick = (url: string | undefined) => {
    if (onClick) {
      onClick();
    } else if (!!url) {
      history.push(url);
    } else history.goBack();
  };
  return (
    <StyledButton onClick={() => handleOnClick(returnUrl)} alignItems="center">
      <Row>
        <Div>
          <BackArrow />
        </Div>
        {text && <StyledText justifyContent="center">{text}</StyledText>}
      </Row>
    </StyledButton>
  );
};

export default BackButton;
