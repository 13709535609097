import Navbar from "../baseComponents/Navbar";
import { useHistory } from "react-router-dom";
import { logout } from "../../store/auth";
import { useAppDispatch } from "../../store/hooks";

function NavbarTenant() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };
  const menuItems = [
    {
      label: "Dashboard",
      function: () => {
        history.push("/dashboard");
      },
    },
    {
      label: "My Account",
      function: () => {
        history.push("/myaccount");
      },
    },
    {
      label: "Log Out",
      function: handleLogout,
    },
  ];
  return <Navbar menuItems={menuItems} />;
}

export default NavbarTenant;
