import styled from "styled-components";
import { COLORS } from "../../global/colors";
import Row from "../../baseComponents/Row";
import Div from "../../baseComponents/Div";
import XIcon from "../../../images/XIcon";
import { Deduction } from "../../global/ModelInterfaces";
import { showDeductionCategory } from "../../../utils/disbursementUtils";
import { cssBreakpoints } from "../../global/theme";

const StyledDeductionRow = styled(Row)`
  min-height: 6rem;
  background-color: ${COLORS.grey2};
  border-radius: 1rem;
  margin-bottom: 1.5rem;
  ${cssBreakpoints("padding", [{ sm: "1rem .25rem" }, { md: "1rem 1.5rem" }])};
`;

const StyledCategory = styled(Div)`
  font-weight: 700;
  color: ${COLORS.navy};
  font-size: 1rem;
  text-decoration: underline;
`;

const StyledAmountTitle = styled(Div)`
  font-weight: 700;
  font-size: 1rem;
  color: ${COLORS.grey40};
`;

const StyledLabel = styled(Div)`
  font-weight: 600;
`;

const StyledAmount = styled(Div)`
  font-weight: 600;
  font-size: 1rem;
  color: ${COLORS.danger};
`;

const StyledCancelIcon = styled(Div)`
  cursor: pointer;
`;
interface Props {
  deduction: Deduction;
  removeDeduction: Function;
}

const DeductionRow = ({ deduction, removeDeduction }: Props) => {
  return (
    <StyledDeductionRow alignItems="center" flexWrap="no-wrap">
      <Div width={{ sm: 1, md: 1, lg: 1 }}>
        <StyledCategory>{showDeductionCategory(deduction)}</StyledCategory>
        {deduction.description && (
          <>
            <StyledLabel>Description:</StyledLabel>{" "}
            <span>{deduction.description}</span>
          </>
        )}
        {deduction.documentation && (
          <>
            <StyledLabel>Documentation:</StyledLabel>
            <span>{deduction.documentation.name}</span>
          </>
        )}
      </Div>
      <Div width={{ sm: 1 / 3, md: 1 / 4, lg: 1 / 4 }} alignItems="flex-end">
        <StyledAmountTitle>Amount</StyledAmountTitle>
        <StyledAmount>${deduction.amount}</StyledAmount>
      </Div>
      <StyledCancelIcon
        onClick={() => {
          removeDeduction(deduction.uuid);
        }}
        width={{ sm: 1 / 4, md: 1 / 8, lg: 1 / 8 }}
        alignItems="flex-end"
      >
        <XIcon />
      </StyledCancelIcon>
    </StyledDeductionRow>
  );
};

export default DeductionRow;
