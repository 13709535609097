import { useState } from "react";
import { Option, PendingFee } from "../global/ModelInterfaces";
import Div from "../baseComponents/Div";
import Row from "../baseComponents/Row";
import AutocompleteDropdown from "../baseComponents/AutocompleteDropdown";
import ErrorMessage from "../baseComponents/ErrorMessage";
import Input from "../baseComponents/Input";
import Button from "../baseComponents/Button";
import { feeTypes } from "../../enums/feeTypes";
import { v4 as uuidv4 } from "uuid";
interface Props {
  onAdd: (fee: PendingFee) => void;
}

const CreateFeeCard = ({ onAdd }: Props) => {
  const [amount, setAmount] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [type, setType] = useState<Option>();
  const [error, setError] = useState("");

  const resetFee = () => {
    setAmount("");
    setDescription("");
    setType(undefined);
  };
  const submitFee = () => {
    if (!type) {
      setError("No fee type chosen");
      return;
    }
    if (!amount) {
      setError("No fee amount entered");
      return;
    }
    if (type.label === "General fee" && !description) {
      setError("General fee must have description");
      return;
    }
    setError("");
    const fee = {
      amount: parseFloat(amount),
      type: type.label,
      type_id: parseInt(type.id),
      description: description || "",
      uuid: uuidv4(),
    };
    onAdd(fee);
    resetFee();
  };

  return (
    <Div>
      <AutocompleteDropdown
        name={"Type"}
        options={feeTypes}
        onClick={setType}
        placeholder={"Enter Fee Type"}
        value={type}
      />
      <Row>
        <Div width={{ default: 1, lg: 1 / 2 }}>
          <Input
            backgroundColor="light"
            label={"Fee Amount"}
            placeholderText={"Enter Fee Amount"}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            value={amount}
            type="number"
          />
        </Div>
        <Div width={{ default: 1, lg: 1 / 2 }}>
          <Input
            backgroundColor="light"
            label={"Fee Description"}
            placeholderText={"Enter Fee Desc. (optional)"}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            value={description}
            type="text"
          />
        </Div>
      </Row>
      <Div>
        <Button
          text="Add Fee"
          type="primary"
          onClick={submitFee}
          alignItems="center"
        />
        {error && (
          <Row justifyContent="center">
            <ErrorMessage>{error}</ErrorMessage>
          </Row>
        )}
      </Div>
    </Div>
  );
};
export default CreateFeeCard;
