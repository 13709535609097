import { useState, useEffect } from "react";
import styled from "styled-components";
import {
  createUserPersonalInfo,
  setUserCitizenship,
} from "../../../utils/accountUtils";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import Button from "../../baseComponents/Button";
import ErrorMessage from "../../baseComponents/ErrorMessage";
import IDVerificationButton from "../../IDVerificationButton";
import CreditSelfReport from "./CreditSelfReport";
import { RootState } from "../../..";
import { updateTenantUserInfo } from "../../../store/tenantInfo";
import { cssBreakpoints } from "../../global/theme";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { GenericObject } from "../../global/ModelInterfaces";

const StyledStepContainer = styled(Div)`
  ${cssBreakpoints("padding", [{ sm: "1rem .5rem" }, { lg: "2rem 3em" }])}
`;

const StyledTitle = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.bolder};
  font-size: ${(props) => props.theme.font_size.headlineMD};
  margin-bottom: 2rem;
`;

const StyledButtonRow = styled(Row)`
  margin-bottom: 1rem;
`;

interface Props {
  nextStep: () => void;
}

const PersonalInfo = ({ nextStep }: Props) => {
  const dispatch = useAppDispatch();
  const [readyForVerification, setReadyForVerification] =
    useState<boolean>(false);
  const [isCitizen, setIsCitizen] = useState<boolean | null>(null);
  const [failedVerification, setFailedVerification] = useState<boolean>(false);
  const [showCreditReport, setShowCreditReport] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const tenantInfoSlice: { loading: Boolean; objects: GenericObject } =
    useAppSelector((state: RootState) => state.tenantInfo);
  const personalInfo = tenantInfoSlice.objects.personalInfo;
  const handleIDVerification = (link_session_id: string) => {
    createUserPersonalInfo(link_session_id)
      .then((res) => {
        dispatch(updateTenantUserInfo(res.user_personal_info));
        if (res.verified) {
          setShowCreditReport(!!isCitizen);
          dispatch(updateTenantUserInfo(res.user_personal_info));
          if (!isCitizen) {
            nextStep();
          }
        } else {
          setFailedVerification(true);
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleCitizenshipClick = (isCitizen: boolean) => {
    setIsCitizen(isCitizen);
    setUserCitizenship(isCitizen)
      .then(() => {
        setReadyForVerification(true);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  useEffect(() => {
    if (
      !!Object.keys(personalInfo).length &&
      personalInfo.reported_credit_score !== null &&
      isCitizen === null
    ) {
      nextStep();
    }
  }, [personalInfo]);

  if (showCreditReport) {
    return <CreditSelfReport onSuccess={nextStep} />;
  }
  return (
    <StyledStepContainer width={{ default: 1 }} alignItems="center">
      <StyledTitle>Personal Information</StyledTitle>
      {failedVerification ? (
        <Row>
          We are having trouble verifying your identity. Please reach out to
          support@rentable.com for help.
        </Row>
      ) : (
        <Div width={{ default: 1 }} alignItems="center">
          <p>Are you a U.S. Citizen?</p>
          <Div width={{ sm: 1, lg: 6 / 12 }}>
            <StyledButtonRow>
              <Div width={{ default: 6 / 12 }}>
                <Button
                  type={"secondary"}
                  disabled={isCitizen !== null && isCitizen}
                  onClick={() => {
                    handleCitizenshipClick(true);
                  }}
                >
                  Yes
                </Button>
              </Div>
              <Div width={{ default: 6 / 12 }}>
                <Button
                  type={"secondary"}
                  disabled={isCitizen !== null && !isCitizen}
                  onClick={() => {
                    handleCitizenshipClick(false);
                  }}
                >
                  No
                </Button>
              </Div>
            </StyledButtonRow>
            {error && (
              <ErrorMessage>
                The following error occurred, please try again in a few minutes:{" "}
                {error}
              </ErrorMessage>
            )}
            {readyForVerification && (
              <Row justifyContent="center">
                <IDVerificationButton onSuccess={handleIDVerification} />
              </Row>
            )}
          </Div>
        </Div>
      )}
    </StyledStepContainer>
  );
};
export default PersonalInfo;
