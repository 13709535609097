import styled from "styled-components";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import { DeferredPaymentTransaction } from "../../../components/global/ModelInterfaces";

interface Props {
  deferredPayments: DeferredPaymentTransaction[];
}
const TableHeader = styled(Row)`
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  color: ${(props) => props.theme.colors.white};
`;
const StyledLabel = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const StyledTableRow = styled(Row)`
  background-color: ${(props) => props.theme.colors.grey20};
  border-bottom: ${(props) => props.theme.borders.primarySM};
`;

const DeferredPaymentsTable = ({ deferredPayments }: Props) => {
  return (
    <>
      <Row addSpace={false} mt={{ default: 3 }}>
        <TableHeader alignItems="center">
          <StyledLabel width={{ default: 3.5 / 12 }}>
            Deferred Payment UUID:
          </StyledLabel>
          <StyledLabel width={{ default: 2 / 12 }}>
            Transaction Date:
          </StyledLabel>
          <StyledLabel width={{ default: 1 / 12 }}>Amount:</StyledLabel>
          <StyledLabel width={{ default: 0.75 / 12 }}>Fee:</StyledLabel>
          <StyledLabel width={{ default: 3.5 / 12 }}>
            Processor Transfer ID:
          </StyledLabel>
          <StyledLabel width={{ default: 1 / 12 }}>Manual Payment:</StyledLabel>
        </TableHeader>
        {deferredPayments.map((deferredPayment) => {
          return (
            <StyledTableRow pt={{ default: 2 }} pb={{ default: 2 }}>
              <StyledLabel width={{ default: 3.5 / 12 }}>
                {deferredPayment.uuid}
              </StyledLabel>
              <StyledLabel width={{ default: 2 / 12 }}>
                {deferredPayment.transaction_date}
              </StyledLabel>
              <StyledLabel width={{ default: 1 / 12 }}>
                {deferredPayment.amount}
              </StyledLabel>
              <StyledLabel width={{ default: 0.75 / 12 }}>
                {deferredPayment.fee}
              </StyledLabel>
              <StyledLabel width={{ default: 3.5 / 12 }}>
                {deferredPayment.transfer_id}
              </StyledLabel>
              <StyledLabel width={{ default: 1 / 12 }}>
                {deferredPayment.is_manual_payment.toString()}
              </StyledLabel>
            </StyledTableRow>
          );
        })}
      </Row>
    </>
  );
};

export default DeferredPaymentsTable;
