import { useState, useEffect } from "react";
import { useAppDispatch } from "../../../store/hooks";
import useCompanyFromParams from "../../utils/useCompanyFromParams";
import Row from "../../../components/baseComponents/Row";
import BuildingManagement from "../../../components/baseComponents/BuildingManagement";
import NucleusLoader from "../NucleusLoader";
import { updateCompanyBuilding } from "../../../store/nucleusCompanies";
import {
  addLandlordToBuilding,
  removeLandlordFromBuilding,
} from "../../utils/buildingUtils";
import {
  LandlordBuilding,
  Landlord,
} from "../../../components/global/ModelInterfaces";

const BuildingPermissions = () => {
  const dispatch = useAppDispatch();
  const [buildingPermissions, setBuildingPermissions] = useState<
    LandlordBuilding[] | null
  >();
  const { company, loadingCompanies } = useCompanyFromParams();
  const landlordBuildingsObjects = company.buildings;
  const landlords = company.landlords;

  const handleAddLandlord = (
    buildingUUID: string,
    landlordID: string,
    failureCallback: Function
  ) => {
    const successCallback = (building: LandlordBuilding) => {
      dispatch(updateCompanyBuilding(company.uuid, building));
    };
    addLandlordToBuilding(
      buildingUUID,
      landlordID,
      successCallback,
      failureCallback
    );
  };

  const handleRemoveLandlord = (
    buildingUUID: string,
    landlordID: string,
    failureCallback: Function
  ) => {
    const successCallback = (building: LandlordBuilding) => {
      dispatch(updateCompanyBuilding(company.uuid, building));
    };
    removeLandlordFromBuilding(
      buildingUUID,
      landlordID,
      successCallback,
      failureCallback
    );
  };

  useEffect(() => {
    if (!!landlordBuildingsObjects) {
      setBuildingPermissions(landlordBuildingsObjects);
    }
  }, [landlordBuildingsObjects]);

  if (loadingCompanies || !buildingPermissions) {
    return (
      <Row>
        <NucleusLoader />
      </Row>
    );
  }

  return (
    <Row mt={{ default: 3 }} addSpace={false}>
      <BuildingManagement
        landlordBuildings={buildingPermissions}
        landlords={landlords}
        onAdd={handleAddLandlord}
        onRemove={handleRemoveLandlord}
      />
    </Row>
  );
};

export default BuildingPermissions;
