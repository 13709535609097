import styled, { ThemeContext } from "styled-components";
import Row from "../../components/baseComponents/Row";
import Div from "../../components/baseComponents/Div";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useContext, useState } from "react";
import * as FaIcons from "react-icons/fa";
import useNucleusPermissions from "../../utils/useNucleusPermissions";
import { nucleusUserPermissions } from "../../enums/nucleusPermissions";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../store/hooks";
import { removeUser } from "../../store/auth";
import { logOut } from "../utils/auth";
import SideNavLink from "./SideNavLink";
import ErrorMessage from "../../components/baseComponents/ErrorMessage";

const StyledNav = styled(Div)`
  min-height: 100vh;
  flex: 0 0 auto;
  font-size: ${(props) => props.theme.font_size.bodyMD};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  border-right: ${(props) => "2px solid " + props.theme.colors.grey20};
`;

const StyledLogOut = styled(Div)`
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.grey};
  }
`;

const StyledFunctionIcon = styled(Row)`
  font-size: ${(props) => props.theme.space[4]};
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.grey};
  }
`;

const StyledPageIcon = styled(Div)`
  font-size: ${(props) => props.theme.space[4]};
  align-items: center;
`;

interface Props {
  onOpenSidebar: Function;
}

const SideNavbar = ({ onOpenSidebar }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const currentUser = useAppSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    prepaymentOverviewPermission,
    companyOverviewPermission,
    adminSettingsPermission,
    integrationsAccess,
  } = useNucleusPermissions();

  const handleSidebarOpen = (isOpen: boolean) => {
    setIsSidebarOpen(isOpen);
    onOpenSidebar(isOpen);
  };

  const handleLogout = (email: string) => {
    const successCallback = () => {
      dispatch(removeUser());
      history.push("/nucleus/login");
    };
    const failureCallback = (error: string) => {
      setErrorMessage(`Failed to log out. Error: ${error}`);
    };
    logOut(email, successCallback, failureCallback);
  };

  const navbarItems = [
    {
      title: "Home",
      path: "/nucleus/dashboard",
      activePath: "/nucleus/dashboard",
      icon: <FaIcons.FaHome />,
      hasPermission: true,
    },
    {
      title: "Admin",
      path: "/nucleus/admin",
      activePath: "/nucleus/admin",
      icon: <FaIcons.FaUser />,
      hasPermission: adminSettingsPermission !== nucleusUserPermissions.NONE,
    },
    {
      title: "Tenants",
      path: "/nucleus/prepayment-search",
      activePath: "/nucleus/prepayment",
      icon: <FaIcons.FaHouseUser />,
      hasPermission:
        prepaymentOverviewPermission !== nucleusUserPermissions.NONE,
    },
    {
      title: "Companies",
      path: "/nucleus/company-search",
      activePath: "/nucleus/company",
      icon: <FaIcons.FaRegBuilding />,
      hasPermission: companyOverviewPermission !== nucleusUserPermissions.NONE,
    },
    {
      title: "Integrations",
      path: "/nucleus/integrations",
      activePath: "/nucleus/integrations",
      icon: <FaIcons.FaPlug />,
      hasPermission: integrationsAccess !== nucleusUserPermissions.NONE,
    },
    {
      title: "Async",
      path: "/nucleus/async",
      activePath: "/nucleus/async",
      icon: <FaIcons.FaBuffer />,
      hasPermission: integrationsAccess !== nucleusUserPermissions.NONE,
    },
  ];

  return (
    <>
      <StyledNav justifyContent="space-between">
        <Div>
          <Div onClick={() => handleSidebarOpen(!isSidebarOpen)}>
            <StyledFunctionIcon
              pt={{ md: 4 }}
              pb={{ md: 4 }}
              justifyContent="center"
            >
              {isSidebarOpen ? (
                <Div width={{ md: 10 / 12 }}>
                  <FaIcons.FaAngleLeft />
                </Div>
              ) : (
                <Div>
                  <FaIcons.FaBars />
                </Div>
              )}
            </StyledFunctionIcon>
          </Div>
          {navbarItems.map((item) => {
            if (item.hasPermission) {
              return (
                <SideNavLink
                  linkText={item.title}
                  onClick={() => history.push(item.path)}
                  isActive={location.pathname.includes(item.activePath)}
                  icon={item.icon}
                  isNavbarOpen={isSidebarOpen}
                />
              );
            }
          })}
        </Div>
        {isSidebarOpen ? (
          <StyledLogOut onClick={() => handleLogout(currentUser.email)}>
            <Div pl={{ md: 3 }} pr={{ md: 3 }}>
              <Row pt={{ md: 4 }} pb={{ md: 4 }} justifyContent="start">
                <StyledPageIcon>
                  <FaIcons.FaUserCircle />
                </StyledPageIcon>
                <Div>Log Out</Div>
              </Row>
            </Div>
          </StyledLogOut>
        ) : (
          <Div onClick={() => handleSidebarOpen(true)}>
            <StyledFunctionIcon
              pt={{ md: 4 }}
              pb={{ md: 4 }}
              justifyContent="center"
            >
              <Div>
                <FaIcons.FaUserCircle />
              </Div>
            </StyledFunctionIcon>
          </Div>
        )}
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </StyledNav>
    </>
  );
};

export default SideNavbar;
