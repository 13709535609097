import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { addBuilding } from "../../store/buildings";
import { GenericObject } from "../global/ModelInterfaces";
import { createBuilding } from "../../utils/unitUtils";
import Row from "../baseComponents/Row";
import Div from "../baseComponents/Div";
import Input from "../baseComponents/Input";
import styled from "styled-components";
import Button from "../baseComponents/Button";
import PlacesAutocomplete from "../PlacesAutoComplete";
import { cssBreakpoints } from "../global/theme";

const StyledTitle = styled.div`
  font-weight: 700;
  ${cssBreakpoints("font-size", [{ sm: "2rem" }, { md: "2.8rem" }])};
`;

const StyledRow = styled(Row)`
  margin: 1rem 0;
`;

const StyledLabel = styled.span`
  font-weight: 700;
  font-size: 0.875rem;
`;

const CreateBuilding = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [stateAbbr, setStateAbbr] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [errors, setErrors] = useState<GenericObject>({});

  const handleSubmit = () => {
    if (validateForm()) {
      createBuilding(name, address, city, stateAbbr, zipcode).then(
        (newBuilding) => {
          dispatch(addBuilding(newBuilding));
          history.push(`/dashboard/building/${newBuilding.uuid}`);
        }
      );
    }
  };

  const validateForm = () => {
    let tmpErrors = errors;
    if (!name) {
      tmpErrors = { ...tmpErrors, name: true };
    }
    if (!address) {
      tmpErrors = { ...tmpErrors, address: true };
    }
    if (!city) {
      tmpErrors = { ...tmpErrors, city: true };
    }
    if (!stateAbbr) {
      tmpErrors = { ...tmpErrors, stateAbbr: true };
    }
    if (!zipcode) {
      tmpErrors = { ...tmpErrors, zipcode: true };
    }
    setErrors(tmpErrors);
    return !Object.keys(tmpErrors).some((key: string) => !!tmpErrors[key]);
  };

  const clearError = (key: string) => {
    if (key === "all") {
      setErrors({});
    } else {
      let tmpErrors = errors;
      tmpErrors[key] = false;
      setErrors(tmpErrors);
    }
  };

  return (
    <Row justifyContent="center">
      <Div width={{ sm: 1, lg: 1 / 2 }} alignItems="center">
        <Row>
          <StyledTitle>Add a new building</StyledTitle>
        </Row>
        <Row>
          <Div width={{ sm: 1, md: 1, lg: 1 }}>
            <Input
              label="Name"
              value={name}
              backgroundColor={"light"}
              onChange={(e) => {
                clearError("name");
                setName(e.target.value);
              }}
              type="light"
              error={errors.name}
            />
          </Div>
        </Row>
        <Row>
          <Div width={{ sm: 1, md: 1, lg: 1 }}>
            <StyledLabel>Street Address</StyledLabel>
            <PlacesAutocomplete
              onSelect={(place: any) => {
                clearError("all");
                setCity(place.city);
                setAddress(place.streetAddress);
                setZipcode(place.zipcode);
                setStateAbbr(place.state);
              }}
              initialValue={address}
              onChange={(value: string) => {
                clearError("address");
                setAddress(value);
              }}
              error={errors.address}
            />
          </Div>
        </Row>
        <Row>
          <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
            <Input
              label="City"
              value={city}
              backgroundColor={"light"}
              onChange={(e) => {
                clearError("city");
                setCity(e.target.value);
              }}
              type="light"
              error={errors.city}
            />
          </Div>
          <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
            <Input
              label="State"
              value={stateAbbr}
              backgroundColor={"light"}
              onChange={(e) => {
                clearError("stateAbbr");
                setStateAbbr(e.target.value);
              }}
              type="light"
              error={errors.stateAbbr}
            />
          </Div>
        </Row>
        <Row>
          <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
            <Input
              label="Zip Code"
              value={zipcode}
              backgroundColor={"light"}
              onChange={(e) => {
                clearError("zipcode");
                setZipcode(e.target.value);
              }}
              type="light"
              error={errors.zipcode}
            />
          </Div>
        </Row>
        <StyledRow justifyContent="center">
          <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
            <Button type="primary" text="Add building" onClick={handleSubmit} />
          </Div>
        </StyledRow>
      </Div>
    </Row>
  );
};

export default CreateBuilding;
