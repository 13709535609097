// Libraries
import styled from "styled-components";

// Global
import Div from "../../../../components/baseComponents/Div";
import Row from "../../../../components/baseComponents/Row";
import Container from "../../../../components/baseComponents/Container";

import YardiLogo from "./components/YardiLogo";
/**
 * The main Yardi page.
 *
 * Shows a general overview of the Yardi integration options
 * and allows managing Yardi companies and settings.
 *
 */

const StyledHeader = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineMD};
  text-align: center;
`;

const YardiDashboard = () => {
  return (
    <Container>
      <StyledHeader
        justifyContent="center"
        mt={{ default: 5 }}
        mb={{ default: 5 }}
      >
        <Row justifyContent="center">
          <Div>
            <YardiLogo />
          </Div>
        </Row>
      </StyledHeader>
    </Container>
  );
};

export default YardiDashboard;
