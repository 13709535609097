import { useAppSelector } from "../store/hooks";

import { NucleusUser } from "../components/global/ModelInterfaces";
import { RootState } from "..";

const useNucleusPermissions = () => {
  const authSlice: { loading: Boolean; user: NucleusUser } = useAppSelector(
    (state: RootState) => state.auth
  );
  const loadingPermissions = authSlice.loading;
  const permissions = authSlice.user.permissions;
  const prepaymentOverviewPermission = permissions.prepayment_overview;
  const companyOverviewPermission = permissions.company_overview;
  const adminSettingsPermission = permissions.admin_settings;
  const integrationsAccess = permissions.integrations_access;
  const engineeringAccess = permissions.engineering_access;

  return {
    loadingPermissions,
    prepaymentOverviewPermission,
    companyOverviewPermission,
    adminSettingsPermission,
    integrationsAccess,
    engineeringAccess,
  };
};

export default useNucleusPermissions;
