import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Container from "../../../components/baseComponents/Container";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import DropdownCaret from "../../../components/baseComponents/DropdownCaret";
import NucleusLoader from "../../components/NucleusLoader";
import ErrorMessage from "../../../components/baseComponents/ErrorMessage";
import { getAsyncTaskList } from "../../utils/asyncUtils";
import WarningMessage from "../../../components/baseComponents/WarningMessage";
import Button from "../../../components/baseComponents/Button";

// Maximum number of parent tasks to retrieve on root view
// There is no limit for children tasks
const maxParentTasks = 250;

interface StatusColorProps {
  status: string; // "Created", "In Process", "Completed", "Cancelled", "Failed"
}

const TableStyle = css`
  border: 1px solid ${(props) => props.theme.colors.black};
  border-collapse: collapse;
  padding-left: 10px;
`;

const StyledTableRow = styled.tr`
  table-layout: fixed;
  word-wrap: break-word;
`;

const StyledTableHeader = styled.th`
  ${TableStyle}
`;
const StyledTableData = styled.td`
  ${TableStyle}
`;

const StyledHeader = styled(Row)`
  font-size: ${(props) => props.theme.font_size.headlineLG};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const StyledBody = styled(Div)<{ expanded: boolean }>`
  transition: ${(props) =>
    props.expanded ? "margin-top 0.05s ease-out" : "margin-top 0.1s ease-out"};
  margin-top: ${(props) => (props.expanded ? "0" : "-200%")};
`;

const StyledBodyContainer = styled(Div)`
  background-color: ${(props) => props.theme.colors.grey10};
  border: 1px dotted ${(props) => props.theme.colors.grey50};
  overflow: hidden;
`;

const StatusColor = styled.span<StatusColorProps>`
  color: ${(props) => {
    switch (props.status) {
      case "Created":
        return props.theme.colors.info;
      case "In Process":
        return props.theme.colors.primary;
      case "Completed":
        return props.theme.colors.success;
      case "Cancelled":
        return props.theme.colors.cancel;
      case "Failed":
        return props.theme.colors.danger;
      default:
        return props.theme.colors.black;
    }
  }};
`;

const AsyncDashboard = () => {
  const [error, setError] = useState("");
  const [parentTasks, setParentTasks] = useState<Array<string>>([]);
  const [asyncTasks, setAsyncTasks] = useState<Array<any>>([]);
  const [loader, setLoader] = useState(false);
  const [expandedRows, setExpandedRows] = useState<Array<string>>([]);

  // Retrieve 250 higher-level tasks on the initial load
  useEffect(() => {
    retrieveTasks([], maxParentTasks);
  }, []);

  const handleShowResultsClick = (taskId: string) => {
    // Manages the expanded rows state for showing/hiding task results
    if (!taskId) {
      // All rows
      if (expandedRows.length === 0) {
        setExpandedRows(asyncTasks.map((task) => task.uuid)); // Open
      } else {
        setExpandedRows([]); // Close
      }
      return;
    }
    // Toggle a single row
    if (expandedRows.includes(taskId)) {
      setExpandedRows(expandedRows.filter((uuid) => uuid !== taskId)); // Close
    } else {
      setExpandedRows([...expandedRows, taskId]); // Open
    }
  };

  const getSubTasks = (taskId: string) => {
    // Go level down to sub-tasks, keeps stack of parent tasks
    setExpandedRows([]); // Close all expanded rows
    if (parentTasks.length > 0) {
      setParentTasks([...parentTasks, taskId]);
    } else {
      setParentTasks([taskId]);
    }
    retrieveTasks([taskId]); // Retrieve all children tasks
  };

  const handleParentTaskClick = () => {
    // Go level up to parent task
    setExpandedRows([]); // Close all expanded rows
    parentTasks.pop(); // Remove current task ID from the list
    if (parentTasks.length === 0) {
      retrieveTasks([], maxParentTasks); // Top level view
      return;
    }
    // Get the parent task ID and retrieve its children
    retrieveTasks([parentTasks[parentTasks.length - 1]]);
  };

  const isRowExpanded = (taskId: string) => {
    // This allows to show/hide task results individually
    return expandedRows.includes(taskId); // True - show, False - hide
  };

  const retrieveTasks = (uuid: string[], limit?: number) => {
    setLoader(true);
    setError("");
    getAsyncTaskList(
      (data: any) => {
        setLoader(false);
        setAsyncTasks(data.tasks);
      },
      (err: any) => {
        setLoader(false);
        setError(err);
      },
      uuid, // List, if empty retrieves all parents, otherwise retrieve children tasks of the given task IDs
      limit // limits the number of tasks to retrieve, optional, if not provided, all tasks are retrieved
    );
  };

  /*
   * Show expandable table of async tasks
   */
  return (
    <Container>
      <StyledHeader
        justifyContent="center"
        mt={{ default: 5 }}
        mb={{ default: 5 }}
      >
        Welcome to Async Tasks Dashboard!
      </StyledHeader>
      {error && (
        <Row justifyContent="center" mb={{ default: 3 }}>
          <ErrorMessage>{error}</ErrorMessage>
        </Row>
      )}
      {loader && <NucleusLoader />}
      {asyncTasks.length === 0 && (
        <Row justifyContent="center">
          <WarningMessage>
            No Async Tasks available at the moment.
          </WarningMessage>
        </Row>
      )}
      {!loader && (
        <Row justifyContent="center">
          <Div width={{ default: 11 / 12 }}>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <StyledTableRow>
                  <StyledTableHeader
                    onClick={() => handleParentTaskClick()}
                    style={{ cursor: "pointer" }}
                  >
                    🔼
                  </StyledTableHeader>
                  <StyledTableHeader>Title</StyledTableHeader>
                  <StyledTableHeader>Notes</StyledTableHeader>
                  <StyledTableHeader>Initiated By</StyledTableHeader>
                  <StyledTableHeader>Created At</StyledTableHeader>
                  <StyledTableHeader>Updated At</StyledTableHeader>
                  <StyledTableHeader>
                    Results
                    <Button
                      type="secondary_slim"
                      onClick={() => handleShowResultsClick("")}
                    >
                      <DropdownCaret open={expandedRows.length > 0} />
                    </Button>
                  </StyledTableHeader>
                  <StyledTableHeader>Completed</StyledTableHeader>
                  <StyledTableHeader>Originator Name</StyledTableHeader>
                  <StyledTableHeader>Status</StyledTableHeader>
                </StyledTableRow>
              </thead>
              <tbody>
                {asyncTasks.map((task) => (
                  <React.Fragment key={task.uuid}>
                    <StyledTableRow>
                      <StyledTableData
                        onClick={() => getSubTasks(task.uuid)}
                        style={{ cursor: "pointer" }}
                      >
                        ⤵️
                      </StyledTableData>
                      <StyledTableData
                        style={{ whiteSpace: "pre-wrap" }}
                        align="left"
                      >
                        {task.title}
                      </StyledTableData>
                      <StyledTableData align="left">
                        {task.notes}
                      </StyledTableData>
                      <StyledTableData>{task.nucleus_user}</StyledTableData>
                      <StyledTableData align="left">
                        {new Date(task.created_at).toLocaleString()}
                      </StyledTableData>
                      <StyledTableData align="left">
                        {new Date(task.updated_at).toLocaleString()}
                      </StyledTableData>
                      <StyledTableData>
                        {task.outcome && task.outcome.length > 0 ? (
                          <Button
                            type="primary_slim"
                            onClick={() => handleShowResultsClick(task.uuid)}
                          >
                            <DropdownCaret open={isRowExpanded(task.uuid)} />
                          </Button>
                        ) : (
                          <Div>Empty</Div>
                        )}
                      </StyledTableData>
                      <StyledTableData>
                        {task.completed ? "Yes" : "No"}
                      </StyledTableData>
                      <StyledTableData>{task.originator_name}</StyledTableData>
                      <StyledTableData>
                        <StatusColor status={task.status}>
                          {task.status}
                        </StatusColor>
                      </StyledTableData>
                    </StyledTableRow>

                    {/* Expandable row for the results details */}
                    <StyledTableRow>
                      <td colSpan={10}>
                        <StyledBodyContainer>
                          <StyledBody
                            p={{ lg: 2 }}
                            expanded={isRowExpanded(task.uuid)}
                          >
                            {task.outcome}
                          </StyledBody>
                        </StyledBodyContainer>
                      </td>
                    </StyledTableRow>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </Div>
        </Row>
      )}
    </Container>
  );
};

export default AsyncDashboard;
