import { useState } from "react";
import styled from "styled-components";
import { sendInvite } from "../utils/auth";
import { emailRegex } from "../../utils/accountUtils";
import Container from "../../components/baseComponents/Container";
import Div from "../../components/baseComponents/Div";
import Row from "../../components/baseComponents/Row";
import Input from "../../components/baseComponents/Input";
import Button from "../../components/baseComponents/Button";
import ErrorMessage from "../../components/baseComponents/ErrorMessage";
import {
  nucleusUserPermissions,
  nucleusUserRoles,
} from "../../enums/nucleusPermissions";
import Dropdown from "../../components/baseComponents/Dropdown";
import { Option } from "../../components/global/ModelInterfaces";
import useNucleusPermissions from "../../utils/useNucleusPermissions";
import { Redirect } from "react-router-dom";
import NucleusLoader from "../components/NucleusLoader";
import BackButton from "../../components/baseComponents/BackButton";
const StyledInviteContainer = styled(Div)`
  border: ${(props) => props.theme.borders.primarySM};
  border-radius: ${(props) => props.theme.border_radius.MD};
`;

const StyledInviteHeader = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineMD};
  text-align: center;
`;

const StyledSuccessMessage = styled(Div)`
  color: ${(props) => props.theme.colors.success};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const StyledLoading = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineLG};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const InvitePage = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [permissionsRole, setPermissionsRole] = useState<Option | null>(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [failureMessage, setFailureMessage] = useState("");
  const [validationErrors, setValidationErrors] = useState({
    email: "",
    firstName: "",
    lastName: "",
    permissionsRole: "",
  });

  const { loadingPermissions, adminSettingsPermission } =
    useNucleusPermissions();

  const permissionOptions: Array<Option> = [
    { label: "Read", id: nucleusUserRoles.READ.toString() },
    { label: "Write", id: nucleusUserRoles.WRITE.toString() },
    { label: "Admin", id: nucleusUserRoles.ADMIN.toString() },
  ];

  const validateInvite = () => {
    const tempErrors = {
      email: "",
      firstName: "",
      lastName: "",
      permissionsRole: "",
    };
    let valid = true;
    if (email.length === 0 || !emailRegex.test(email)) {
      setValidationErrors({
        ...tempErrors,
        email: "Please enter a valid email.",
      });
      valid = false;
    } else if (firstName.length === 0) {
      setValidationErrors({
        ...tempErrors,
        firstName: "Please enter a valid first name.",
      });
      valid = false;
    } else if (lastName.length === 0) {
      setValidationErrors({
        ...tempErrors,
        lastName: "Please enter a valid last name.",
      });
      valid = false;
    } else if (!permissionsRole) {
      setValidationErrors({
        ...tempErrors,
        permissionsRole: "Please choose a role for this user.",
      });
      valid = false;
    }
    return valid;
  };

  const handleSubmit = () => {
    setSuccessMessage("");
    setFailureMessage("");
    setLoading(true);
    const successCallback = () => {
      setSuccessMessage(
        "Invite sent! The invited user should check their email for next steps."
      );
      setFirstName("");
      setLastName("");
      setEmail("");
      setPermissionsRole(null);
      setLoading(false);
    };
    const failureCallback = (errorMessage: string) => {
      setLoading(false);
      setFailureMessage(errorMessage);
    };
    if (validateInvite()) {
      setValidationErrors({
        email: "",
        firstName: "",
        lastName: "",
        permissionsRole: "",
      });
      const permissionsRoleEnum = permissionsRole
        ? Number(permissionsRole.id)
        : nucleusUserRoles.READ;
      sendInvite(
        email,
        firstName,
        lastName,
        permissionsRoleEnum,
        successCallback,
        failureCallback
      );
    } else {
      setLoading(false);
    }
  };

  if (loading || loadingPermissions) {
    return (
      <Row justifyContent="center">
        <StyledLoading width={{ default: 3 / 12 }}>
          <NucleusLoader />
        </StyledLoading>
      </Row>
    );
  }

  if (adminSettingsPermission !== nucleusUserPermissions.WRITE) {
    return <Redirect to={"/nucleus/forbidden"} />;
  }

  return (
    <Container>
      <Row mt={{ default: 5 }}>
        <BackButton returnUrl="/nucleus/admin" text="Go Back" />
      </Row>
      <Row justifyContent="center">
        <StyledInviteContainer width={{ default: 8 / 12 }} mt={{ default: 5 }}>
          <StyledInviteHeader mt={{ default: 4 }}>
            Invite a new user:
          </StyledInviteHeader>
          <Row justifyContent="center">
            <Div width={{ default: 9 / 12 }}>
              <Input
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                error={!!validationErrors.email}
              />
            </Div>
          </Row>

          <Row justifyContent="center">
            <Div width={{ default: 9 / 12 }}>
              <Input
                label="First Name"
                onChange={(e) => setFirstName(e.target.value)}
                error={!!validationErrors.firstName}
              />
            </Div>
          </Row>
          <Row justifyContent="center">
            <Div width={{ default: 9 / 12 }}>
              <Input
                label="Last Name"
                onChange={(e) => setLastName(e.target.value)}
                error={!!validationErrors.lastName}
              />
            </Div>
          </Row>
          <Row justifyContent="center">
            <Div width={{ default: 9 / 12 }}>
              <Dropdown
                onClick={(e) => {
                  setPermissionsRole(e);
                }}
                name={"Permissions Role"}
                placeholder={"Select a role"}
                options={permissionOptions}
                value={permissionsRole}
              />
            </Div>
          </Row>

          {validationErrors && (
            <Row
              justifyContent="center"
              mt={{ default: 3 }}
              mb={{ default: 3 }}
            >
              <Div>
                <ErrorMessage>{Object.values(validationErrors)}</ErrorMessage>
              </Div>
            </Row>
          )}
          {failureMessage && (
            <Row
              justifyContent="center"
              mt={{ default: 3 }}
              mb={{ default: 3 }}
            >
              <Div>
                <ErrorMessage>{failureMessage}</ErrorMessage>
              </Div>
            </Row>
          )}
          {successMessage && (
            <Row
              justifyContent="center"
              mt={{ default: 3 }}
              mb={{ default: 3 }}
            >
              <StyledSuccessMessage>{successMessage}</StyledSuccessMessage>
            </Row>
          )}

          <Row justifyContent="center" mb={{ default: 5 }}>
            <Div width={{ default: 9 / 12 }}>
              <Button
                text="Invite User"
                onClick={handleSubmit}
                loading={loading}
              />
            </Div>
          </Row>
        </StyledInviteContainer>
      </Row>
    </Container>
  );
};

export default InvitePage;
