import { History } from "history";
import { DateTime } from "luxon";

const transitionSlowly = (history: any, url: string) => {
  setTimeout(() => history.push(url), 300);
};

const formatDateAsValue = (formattedDate: string) => {
  return new Date(Date.parse(formattedDate)).toISOString().slice(0, 10);
};

const formatDateValueAsLocale = (dateValue: string) => {
  return new Date(Date.parse(dateValue)).toLocaleDateString("en-us", {
    timeZone: "UTC",
  });
};

const navigateToTenant = (prepaymentUUID: string, history: History) => {
  history.push(`/dashboard/tenant/${prepaymentUUID}`);
};

interface CurrencyDisplayParams {
  [key: string]: string | number | undefined;
}

class CurrencyNumberFormat {
  formatter: Intl.NumberFormat;

  constructor(isoCurrency: string, params: CurrencyDisplayParams) {
    params["style"] = "currency";
    params["currency"] = params["currency"] ?? "USD";
    this.formatter = new Intl.NumberFormat(isoCurrency, params);
  }

  private parseOutput(value: string): string {
    return value !== "$NaN" ? value : "$0";
  }

  format(value: number | string | bigint) {
    let rawValue;
    if (typeof value === "string") {
      rawValue = this.parseOutput(this.formatter.format(parseFloat(value)));
    } else {
      rawValue = this.parseOutput(this.formatter.format(value));
    }
    if (rawValue.slice(-3) === ".00") {
      rawValue = rawValue.slice(0, -3);
    }
    return rawValue;
  }
}

const currencyFormatter = new CurrencyNumberFormat("en-US", {
  currency: "USD",
});

const parseFloatFromCurrency = (value: string) => {
  return parseFloat(value.replace(/[^0-9.]/g, ""));
};

// Takes in a date with a format of YYYY-mm-dd
const monthDayYearFormatter = (dateString: string) => {
  const formattedDate = DateTime.fromISO(dateString).toLocaleString(
    DateTime.DATETIME_MED
  );
  return formattedDate.slice(0, formattedDate.lastIndexOf(","));
};

// Takes in a date with a format of YYYY-mm-dd
const dateShortFormatter = (dateString: string) => {
  const formattedDate = DateTime.fromISO(dateString, {
    zone: "UTC",
  }).toLocaleString(DateTime.DATE_SHORT);
  return formattedDate;
};

// Takes in a date with a format of YYYY-mm-dd
const dateTimeShortWithSecondsFormatter = (dateWithSecondsString: string) => {
  const formattedDate = DateTime.fromISO(dateWithSecondsString, {
    zone: "UTC",
  }).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
  return formattedDate;
};

const snakeCaseConvert = (key: string) => {
  let temp = key.replaceAll("_", " ");
  return temp.replace(
    /\w\S*/g,
    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
};

const orderSearch = (
  inputString: string,
  inputSearchTerm: string,
  caseSensitive = false
): boolean => {
  const searchTerm = caseSensitive
    ? inputSearchTerm
    : inputSearchTerm.toLowerCase();
  const string = caseSensitive ? inputString : inputString.toLowerCase();
  if (searchTerm.length === 0) {
    // Every string contains an empty string
    return true;
  } else if (searchTerm.length === 1) {
    return string.includes(searchTerm);
  } else {
    const charIndex = string.search(searchTerm[0]);
    if (charIndex === -1) {
      // if the first character isn't in the string return false
      return false;
    }
    // Otherwise, recursively search the rest of the string for the rest of the characters
    return orderSearch(
      string.substring(charIndex),
      searchTerm.substring(1),
      caseSensitive
    );
  }
};

export {
  transitionSlowly,
  formatDateAsValue,
  formatDateValueAsLocale,
  navigateToTenant,
  currencyFormatter,
  parseFloatFromCurrency,
  monthDayYearFormatter,
  dateShortFormatter,
  dateTimeShortWithSecondsFormatter,
  snakeCaseConvert,
  orderSearch,
};
