interface Props {
  width?: string;
  height?: string;
}

const CloseIcon = ({ width = "40", height = "40" }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5697 22L31.6488 13.9211C32.1161 13.4539 32.1161 12.6956 31.6488 12.2277L29.7715 10.3504C29.3043 9.88319 28.546 9.88319 28.078 10.3504L19.9996 18.4301L11.9205 10.3512C11.4532 9.88394 10.695 9.88394 10.227 10.3512L8.35044 12.2277C7.88319 12.6949 7.88319 13.4531 8.35044 13.9211L16.4296 22L8.35044 30.0789C7.88319 30.5461 7.88319 31.3044 8.35044 31.7723L10.2277 33.6496C10.695 34.1168 11.4532 34.1168 11.9212 33.6496L19.9996 25.5699L28.0788 33.6488C28.546 34.1161 29.3043 34.1161 29.7723 33.6488L31.6496 31.7716C32.1168 31.3044 32.1168 30.5461 31.6496 30.0781L23.5697 22Z"
        fill="#2F374B"
      />
    </svg>
  );
};

export default CloseIcon;
