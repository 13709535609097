import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { useParams, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { first, List } from "lodash";
import { RootState } from "../..";
import { GenericObject, Building, Unit } from "../global/ModelInterfaces";
import { createUnits } from "../../utils/unitUtils";
import { addUnit } from "../../store/units";
import Row from "../baseComponents/Row";
import Div from "../baseComponents/Div";
import PageLoader from "../PageLoader";
import Input from "../baseComponents/Input";
import styled from "styled-components";
import Button from "../baseComponents/Button";
import ErrorView from "../baseComponents/ErrorView";
import PencilIcon from "../../images/PencilIcon";
import CheckIcon from "../../images/CheckIcon";
import UnitIcon from "../../images/UnitIcon";
import { COLORS } from "../global/colors";
import { cssBreakpoints } from "../global/theme";

const CreateUnit = () => {
  const history = useHistory();
  const { buildingUUID } = useParams<GenericObject>();
  const dispatch = useAppDispatch();
  const [stateAbbr, setStateAbbr] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [address1, setAddress1] = useState("");
  const [unitString, setUnitString] = useState("");
  const [unitList, setUnitList] = useState<Array<string>>([]);
  const [errors, setErrors] = useState<GenericObject>({});
  const [creationError, setCreationError] = useState(false);
  const [city, setCity] = useState("");
  const { objects, loading } = useAppSelector((state: RootState) => {
    return state.buildings;
  });
  const [editBuilding, setEditBuilding] = useState(false);
  const [viewPreview, setViewPreview] = useState(false);
  const parentBuilding = objects.find((building: Building) => {
    return building.uuid === buildingUUID;
  });

  useEffect(() => {
    if (parentBuilding) {
      setStateAbbr(parentBuilding.state);
      setZipcode(parentBuilding.zip_code);
      setAddress1(parentBuilding.address_1);
      setCity(parentBuilding.city);
    }
  }, [parentBuilding]);

  const validateForm = () => {
    let tmpErrors = errors;
    if (!address1) {
      tmpErrors = { ...tmpErrors, address1: true };
    }
    if (!unitString) {
      tmpErrors = { ...tmpErrors, unitList: true };
    }
    if (!city) {
      tmpErrors = { ...tmpErrors, city: true };
    }
    if (!stateAbbr) {
      tmpErrors = { ...tmpErrors, stateAbbr: true };
    }
    if (!zipcode) {
      tmpErrors = { ...tmpErrors, zipcode: true };
    }
    setErrors(tmpErrors);
    return !Object.keys(tmpErrors).some((key: string) => !!tmpErrors[key]);
  };

  const clearError = (key: string) => {
    let tmpErrors = errors;
    tmpErrors[key] = false;
    setErrors(tmpErrors);
  };

  const parseUnits = () => {
    const tempUnitList = unitString.split(";");
    const trimmedList = tempUnitList.map((unit) => unit.trim());
    const filteredList = trimmedList.filter((unit) => unit !== "");
    return filteredList;
  };

  const renderUnitList = () => {
    return unitList.map((unit: string) => {
      return (
        <Row justifyContent="center">
          <StyledUnit width={{ default: 1, lg: 1 / 3 }}>
            <Row alignItems="center">
              <UnitIcon />
              <b>Unit: </b>
              {unit}
            </Row>
          </StyledUnit>
        </Row>
      );
    });
  };

  const handleSubmit = () => {
    if (validateForm()) {
      createUnits(
        unitList,
        address1,
        city,
        stateAbbr,
        zipcode,
        parentBuilding?.uuid
      )
        .then((newUnitList: Unit[]) => {
          newUnitList.forEach((unit: Unit) => {
            dispatch(addUnit(unit));
          });
          const newUnit = first(newUnitList);
          if (newUnitList.length === 1 && newUnit) {
            history.push(`/dashboard/unit/${newUnit.uuid}`);
          } else {
            history.push(`/dashboard/building/${buildingUUID}`);
          }
        })
        .catch(() => {
          setCreationError(true);
        });
    }
  };

  if (loading) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  }

  if (creationError) {
    return (
      <Div>
        <ErrorView errorMessage="There was a problem in the creation process, please refresh and try again, or if this issue persists please contact support@rentable.com" />
      </Div>
    );
  }

  const StyledHeader = styled.div`
    font-weight: 700;
    margin-bottom: 1.5rem;
    padding: 0 0.75rem
      ${cssBreakpoints("font-size", [{ sm: "2rem" }, { md: "3rem" }])};
  `;

  const StyledBuildingName = styled(Div)`
    font-size: 1rem;
    font-weight: 700;
  `;
  const StyledTitle = styled.div`
    font-weight: 700;
    padding: unset;
    ${cssBreakpoints("font-size", [{ sm: "1.5rem" }, { md: "2rem" }])};
  `;
  const StyledBuildingDetails = styled.div`
    font-size: 1rem;
    font-weight: 600;
  `;
  const StyledIcon = styled(Div)`
    cursor: pointer;
    margin-left: 0.5rem;
  `;
  const StyledBuildingInfo = styled(Div)`
    margin-bottom: 2rem;
  `;

  const StyledUnit = styled(Div)`
    background-color: ${COLORS.grey5};
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
  `;

  const SubmitButtonRow = styled(Row)`
    margin-top: 2rem;
  `;

  return (
    <Row justifyContent="center">
      <Div width={{ default: 1, lg: 1 / 2 }}>
        <StyledHeader>Add a new Unit</StyledHeader>

        <Div width={{ sm: 1, lg: 1 }}>
          {parentBuilding ? (
            <StyledBuildingInfo>
              <Row>
                <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
                  <StyledTitle>Building</StyledTitle>
                  <StyledBuildingName>{parentBuilding.name}</StyledBuildingName>
                </Div>
                <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
                  <Row>
                    <StyledTitle>Unit Address</StyledTitle>
                    <Div justifyContent="center">
                      {editBuilding ? (
                        <StyledIcon onClick={() => setEditBuilding(false)}>
                          <CheckIcon height={"24"} width={"24"} />
                        </StyledIcon>
                      ) : (
                        <StyledIcon onClick={() => setEditBuilding(true)}>
                          <PencilIcon />
                        </StyledIcon>
                      )}
                    </Div>
                  </Row>
                  {!editBuilding && (
                    <StyledBuildingDetails>
                      <Div>{address1}</Div>
                      <Div>{city + ", " + stateAbbr + ", " + zipcode} </Div>
                    </StyledBuildingDetails>
                  )}
                </Div>
              </Row>
            </StyledBuildingInfo>
          ) : (
            <div>TODO add building dropdown here</div>
          )}
        </Div>
        {editBuilding && (
          <>
            <Row>
              <Div width={{ default: 1, lg: 1 / 2 }}>
                <Input
                  label="Address 1"
                  value={address1}
                  onChange={(e) => {
                    clearError("address1");
                    setAddress1(e.target.value);
                  }}
                  backgroundColor="light"
                  error={errors.address1}
                />
              </Div>
              <Div width={{ default: 1, lg: 1 / 2 }}>
                <Input
                  label="City"
                  value={city}
                  onChange={(e) => {
                    clearError("city");
                    setCity(e.target.value);
                  }}
                  backgroundColor="light"
                  error={errors.city}
                />
              </Div>
            </Row>
            <Row>
              <Div width={{ default: 1, lg: 1 / 2 }}>
                <Input
                  label="State"
                  value={stateAbbr}
                  onChange={(e) => {
                    clearError("stateAbbr");
                    setStateAbbr(e.target.value);
                  }}
                  backgroundColor="light"
                  error={errors.stateAbbr}
                />
              </Div>
              <Div width={{ default: 1, lg: 1 / 2 }}>
                <Input
                  label="Zip Code"
                  value={zipcode}
                  onChange={(e) => {
                    clearError("zipcode");
                    setZipcode(e.target.value);
                  }}
                  backgroundColor="light"
                  error={errors.zipcode}
                />
              </Div>
            </Row>
          </>
        )}
        {viewPreview ? (
          renderUnitList()
        ) : (
          <Row justifyContent="center">
            <Div width={{ default: 1, lg: 2 / 3 }}>
              <Input
                label="Unit"
                onChange={(e) => {
                  clearError("unitList");
                  setUnitString(e.target.value);
                }}
                value={unitString}
                backgroundColor="light"
                error={errors.unitList}
              />
            </Div>
          </Row>
        )}
        <SubmitButtonRow justifyContent="center">
          <Div width={{ default: 1, lg: 1 / 2 }}>
            {viewPreview ? (
              <Row>
                <Div width={{ sm: 1 / 3, md: 1 / 3, lg: 1 / 3 }}>
                  <Button
                    type="secondary"
                    onClick={() => {
                      setViewPreview(false);
                    }}
                  >
                    Back
                  </Button>
                </Div>
                <Div width={{ sm: 2 / 3, md: 2 / 3, lg: 2 / 3 }}>
                  <Button type="primary" onClick={handleSubmit}>
                    Submit
                  </Button>
                </Div>
              </Row>
            ) : (
              <Button
                type="primary"
                onClick={() => {
                  setViewPreview(true);
                  setUnitList(parseUnits());
                }}
                disabled={!unitString}
              >
                View Preview
              </Button>
            )}
          </Div>
        </SubmitButtonRow>
      </Div>
    </Row>
  );
};

export default CreateUnit;
