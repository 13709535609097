import styled from "styled-components";

const StyledPlus = styled.svg`
  fill: ${(props) => props.theme.colors.grey15};
`;

interface Props {
  width?: string;
  height?: string;
}

const Plus = ({ width = "15", height = "15" }: Props) => {
  return (
    <StyledPlus
      width={width}
      height={height}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m35,0 v35 h-35 v20 h35 v35 h20 v-35 h35 v-20 h-35 v-35 z" />
    </StyledPlus>
  );
};

export default Plus;
