import axios from "axios";
import { checkResponseStatus } from "./globalUtils";
import {
  addBankAccount,
  removeBankAccount,
  updateBankAccount,
} from "../store/bankInfo";
import responseStatuses from "../enums/responseStatuses";

export const loadPlaidLinkToken = async ({
  includeAssets = false,
  idVerification = false,
}) => {
  return axios
    .post("/api/payments/plaid/token/link", {
      include_assets: includeAssets,
      idVerification: idVerification,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw Error(e.data);
    });
};

export const loadUpdatePlaidLinkToken = async (bankUUID = "") => {
  return axios
    .post("/api/payments/plaid-update/token/link", {
      bank_account_uuid: bankUUID,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw Error(e.data);
    });
};

export const handlePlaidSuccess = async (
  metadata,
  dispatch,
  includeAssets = false
) => {
  const res = await linkBankAccount(metadata, includeAssets);
  const data = await res(dispatch);
  return data;
};

// This function was named setFundingSource in the past
export const linkBankAccount = async (metadata, includeAssets = false) => {
  return async (dispatch) => {
    let url = "/api/payments/funding/setup/bank";
    if (
      metadata.account.verification_status === "pending_manual_verification"
    ) {
      url = "/api/payments/funding/manual-verification";
    } else if (
      metadata.account.verification_status === "pending_automatic_verification"
    ) {
      url = "/api/payments/funding/automatic-verification";
    }
    let requestBody = {
      public_token: metadata.public_token,
      account_id: metadata.account_id,
      account_name: metadata.account.name,
      account: metadata.account,
      institution: metadata.institution,
      include_assets: includeAssets,
      manually_verified:
        metadata.account.verification_status === "manually_verified",
    };
    return axios
      .post(url, requestBody)
      .then((res) => {
        const data = res.data;
        // Need to remove the unverified account if a user is verifying through micro deposits, then add it back in once the data is all filled in
        dispatch(removeBankAccount(data.account));
        dispatch(addBankAccount(data.account));
        return data;
      })
      .catch((err) => {
        checkResponseStatus(err.status);
        const text = err.data;
        throw new Error(text);
      });
  };
};

// This function was named setFundingSource in the past
export const linkProcessorToBank = async (bankAccountUUID) => {
  return async (dispatch) => {
    const url = "/api/payments/funding/link/processor";
    const requestBody = {
      bank_account_uuid: bankAccountUUID,
    };
    return axios
      .post(url, requestBody)
      .then((res) => {
        const data = res.data;
        // Need to remove the unverified account if a user is verifying through micro deposits, then add it back in once the data is all filled in
        dispatch(removeBankAccount(data.account));
        dispatch(addBankAccount(data.account));
        return data;
      })
      .catch((err) => {
        checkResponseStatus(err.status);
        const text = err.data;
        throw new Error(text);
      });
  };
};

export const unlinkBankAccount = async (
  accountToUnlink,
  successCallback,
  failureCallback
) => {
  axios
    .post("/api/payments/funding/unlink-bank-account", {
      uuid: accountToUnlink.uuid,
    })
    .then((res) => {
      successCallback();
    })
    .catch((err) => {
      checkResponseStatus(err.status);
      const err_data = err.data;
      failureCallback(err_data);
    });
};

export const unlinkCompanyBankAccount = async (
  accountToUnlink,
  successCallback,
  failureCallback
) => {
  axios
    .post("/api/payments/funding/unlink-company-bank-account", {
      uuid: accountToUnlink.uuid,
    })
    .then((res) => {
      successCallback();
    })
    .catch((err) => {
      checkResponseStatus(err.status);
      const err_data = err.data;
      failureCallback(err_data);
    });
};

export const updateBankAccountName = async (
  accountToUpdate,
  newValue,
  successCallback,
  failureCallback
) => {
  return axios
    .post("/api/payments/bank-account/update-name", {
      bankUUID: accountToUpdate.uuid,
      value: newValue,
    })
    .then(() => {
      successCallback();
      return;
    })
    .catch((err) => {
      checkResponseStatus(err.status);
      const err_data = err.data;
      failureCallback(err_data);
      return;
    });
};

export const checkFinancials = async (bankAccountUUID, prepaymentUUID) => {
  return axios
    .post("/api/payments/bank-account/check-financials", {
      bank_account_uuid: bankAccountUUID,
      prepayment_uuid: prepaymentUUID,
    })
    .then((res) => {
      return res.data;
    });
};

export const getLatestBalanceForUser = async () => {
  return axios
    .get("/api/payments/bank-account/latest-user-balance")
    .then((res) => {
      return res.data;
    });
};

export const checkAdditionalFundsFinancials = async (
  bankAccountUUID,
  prepaymentUUID
) => {
  return axios
    .post("/api/payments/bank-account/check-additional-financials", {
      bank_account_uuid: bankAccountUUID,
      prepayment_uuid: prepaymentUUID,
    })
    .then((res) => {
      return res.data.sufficient_funds;
    });
};

export const getUserCreditCards = (successCallback, failureCallback) => {
  axios
    .get("/api/payments/credit/user/cards")
    .then((res) => {
      successCallback(res.data);
    })
    .catch((error) => {
      failureCallback(error);
    });
};

export const removeCreditCard = (
  creditCardUUID,
  successCallback,
  failureCallback
) => {
  return axios
    .post("/api/payments/credit/remove-card", {
      credit_card_uuid: creditCardUUID,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch(() => {
      failureCallback();
    });
};

export const markBankValidated = async (bankAccountUUID, field) => {
  return axios
    .post("/api/payments/bank-account/verify-account", {
      bank_uuid: bankAccountUUID,
      field,
    })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const isUserFunded = (bankAccountList) => {
  if (!bankAccountList || !bankAccountList.keys) {
    return false;
  }
  return (
    bankAccountList.filter((account) => {
      return !account.pending_confirmation;
    }).length > 0
  );
};

export const hasPendingBankAccounts = (bankAccountList) => {
  const pendingAccounts = getPendingBankAccounts(bankAccountList);
  if (!pendingAccounts) {
    return false;
  } else {
    return pendingAccounts.length > 0;
  }
};

export const getPendingBankAccounts = (bankAccountList) => {
  if (!bankAccountList || !bankAccountList.keys) {
    return null;
  }
  return bankAccountList.filter((account) => {
    return account.pending_confirmation;
  });
};

export const hasPendingManualBankAccounts = (bankAccountList) => {
  const pendingAccounts = getPendingManualBankAccounts(bankAccountList);
  if (!pendingAccounts) {
    return false;
  } else {
    return pendingAccounts.length > 0;
  }
};

export const getPendingManualBankAccounts = (bankAccountList) => {
  if (!bankAccountList || !bankAccountList.keys) {
    return null;
  }
  return bankAccountList.filter((account) => {
    return account.pending_confirmation && account.manually_verified;
  });
};

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const passwordRegex =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[()[\]{}|\\`~!@#$%^&*_\-+=;:\'",<>./?]).+$/;

export const validatePassword = (password) => {
  return password.length >= 12 && passwordRegex.test(password);
};

export const passwordErrorMessage =
  "Your password must contain at least 12 characters and include 1 digit (0-9), 1 symbol, and 1 letter.";

export const linkInviteToUser = async (invite_uuid, token) => {
  return axios
    .post("/api/invite/link", { invite: invite_uuid, token: token })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      checkResponseStatus(err.status);
      throw new Error(err.data);
    });
};

export const retrieveInvite = async (invite_uuid, token) => {
  return axios
    .post("/api/invite/uuid", {
      invite_uuid: invite_uuid,
      password: token,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      checkResponseStatus(err.status);
      return err;
    });
};

export const createUserPersonalInfo = async (link_session_id) => {
  return axios
    .post("/api/user/add-personal-info", { link_session_id })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((e) => {
      throw new Error(
        "There was an error saving your information, please try again"
      );
    });
};

export const sendCustomTenantMessage = async (
  prepayment_uuid,
  message,
  successCallback,
  failureCallback
) => {
  const messageReq = { prepayment_uuid, message };
  return axios
    .post("/api/tenant/send-message", messageReq)
    .then(() => {
      successCallback();
    })
    .catch((err) => {
      if (err.status === responseStatuses.BAD_REQUEST) {
        failureCallback(err.data);
      }
      checkResponseStatus(err.status);
    });
};

export const setUserCitizenship = async (isUSCitizen) => {
  return axios
    .post("/api/user/set-citizenship", { is_usa_citizen: isUSCitizen })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw new Error(e);
    });
};
