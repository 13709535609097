import { Route, Switch } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import AccountPage from "../../pages/AccountPage";
import AdditionalFundsView from "./AdditionalFundsView";
import AdminBankAccountsView from "../AdminComponents/BankAccountsView";
import AdminBanktoBuilding from "../AdminComponents/BanktoBuildingLink";
import AdminBuildingsView from "../AdminComponents/BuildingsView";
import AdminHome from "../AdminComponents/AdminHome";
import AdminInviteLandlord from "../AdminComponents/InviteLandlordView";
import BusinessVerifyPage from "../../pages/BusinessVerifyPage";
import BuildingDetailsView from "./BuildingDetailsView";
import BuildingsView from "./BuildingsView";
import CreateBuilding from "./CreateBuilding";
import CreateRefundView from "./CreateRefundView";
import CreateUnit from "./CreateUnit";
import DashboardView from "./DashboardView";
import DisbursementView from "./DisbursementView";
import ForbiddenView from "../baseComponents/ForbiddenView";
import FundPrepaymentView from "./FundPrepaymentView";
import InviteTenantView from "../../components/LandlordComponents/InviteTenantView";
import MissingPage from "../../pages/MissingPage";
import PendingInvitesView from "./PendingInvitesView";
import PrepaymentDashboard from "./PrepaymentDashboard";
import ReleaseRentView from "./Disbursement/ReleaseRentView";
import RenewLeaseView from "./RenewLeaseView";
import RentManagerSync from "./RentManagerSyncView";
import TestPage from "../../components/baseComponents/TestPage";
import TenantView from "./TenantView";
import UnitView from "./UnitView";
import UpdatePrepaymentDatesView from "./UpdatePrepaymentDatesView";
import UpdatePrepaymentUnitView from "./UpdatePrepaymentUnitView";

const RoutesLandlord = () => {
  const currentUser = useAppSelector((state) => state.auth.user);

  return (
    <Switch>
      <Route exact path="/myaccount" component={AccountPage} />
      <Route
        exact={true}
        path="/business-verification"
        component={BusinessVerifyPage}
      />
      <Route exact path="/new-components/TestPage" component={TestPage} />
      <Route exact path="/dashboard/invite/" component={InviteTenantView} />
      <Route
        exact
        path="/dashboard/invite/:unitUUID"
        component={InviteTenantView}
      />
      <Route
        exact
        path="/dashboard/tenant/:prepaymentUUID"
        component={TenantView}
      />
      <Route exact path="/dashboard/unit/:unitUUID" component={UnitView} />
      <Route
        exact
        path="/dashboard/unit/create/:buildingUUID"
        component={CreateUnit}
      />
      <Route
        exact
        path="/dashboard/building/create"
        component={CreateBuilding}
      />
      <Route
        path="/dashboard/close-deposit/:prepaymentUUID"
        component={DisbursementView}
      />
      <Route
        path="/dashboard/release-rent/:prepaymentUUID"
        component={ReleaseRentView}
      />
      <Route
        exact
        path="/dashboard/building/:buildingUUID"
        component={BuildingDetailsView}
      />
      <Route exact path="/dashboard/buildings" component={BuildingsView} />
      <Route
        exact
        path="/dashboard/fund-deposit/:prepaymentUUID"
        component={FundPrepaymentView}
      />
      <Route
        exact
        path="/dashboard/request-additional-funds/:prepaymentUUID"
        component={AdditionalFundsView}
      />
      <Route
        exact
        path="/dashboard/create-refund/:prepaymentUUID"
        component={CreateRefundView}
      />
      <Route
        exact
        path="/dashboard/upcoming-payments"
        component={() => (
          <PrepaymentDashboard
            prepaymentFilters={{
              active: false,
              pending: true,
              processing: false,
              closed: false,
              disbursing: false,
            }}
          />
        )}
      />
      <Route
        exact
        path="/dashboard/deposits"
        component={() => (
          <PrepaymentDashboard
            prepaymentFilters={{
              active: false,
              pending: false,
              processing: false,
              closed: false,
              disbursing: false,
            }}
          />
        )}
      />
      <Route
        exact
        path="/dashboard/pending-invites"
        component={PendingInvitesView}
      />
      <Route
        exact
        path="/dashboard/update-unit/:prepaymentUUID"
        component={UpdatePrepaymentUnitView}
      />
      <Route
        exact
        path="/dashboard/update-dates/:prepaymentUUID"
        component={UpdatePrepaymentDatesView}
      />
      <Route
        exact
        path="/dashboard/renew-lease/:prepaymentUUID"
        component={RenewLeaseView}
      />
      <Route
        exact
        path="/dashboard/active-deposits"
        component={() => (
          <PrepaymentDashboard
            prepaymentFilters={{
              active: true,
              pending: false,
              processing: false,
              closed: false,
              disbursing: false,
            }}
          />
        )}
      />
      <Route
        exact
        path="/dashboard/rent-manager-sync"
        component={RentManagerSync}
      />
      <Route path="/dashboard" component={DashboardView} />
      <Route path="/forbidden" component={ForbiddenView} />
      <Route exact={true} path="/404" component={MissingPage} />
      {/* TODO: figure out a better way to separate these routes from the landlord route without problems */}
      {currentUser.role === "admin" && (
        <Route exact path="/settings" component={AdminHome} />
      )}
      {currentUser.role == "admin" && (
        <Route
          exact
          path="/settings/buildings"
          component={AdminBuildingsView}
        />
      )}
      {currentUser.role == "admin" && (
        <Route exact path="/settings/banks" component={AdminBanktoBuilding} />
      )}
      {currentUser.role == "admin" && (
        <Route
          exact
          path="/settings/bank-accounts"
          component={AdminBankAccountsView}
        />
      )}
      {currentUser.role == "admin" && (
        <Route
          exact
          path="/settings/invite-landlord"
          component={AdminInviteLandlord}
        />
      )}
      <Route exact path="*" component={DashboardView} />
    </Switch>
  );
};

export default RoutesLandlord;
