import { useState } from "react";
import styled from "styled-components";
import { emailRegex } from "../../utils/accountUtils";
import Container from "../../components/baseComponents/Container";
import Div from "../../components/baseComponents/Div";
import Row from "../../components/baseComponents/Row";
import Input from "../../components/baseComponents/Input";
import Button from "../../components/baseComponents/Button";
import ErrorMessage from "../../components/baseComponents/ErrorMessage";
import { sendTestEmail } from "../utils/adminUtils";
import { Redirect } from "react-router-dom";
import useNucleusPermissions from "../../utils/useNucleusPermissions";
import { nucleusUserPermissions } from "../../enums/nucleusPermissions";
import NucleusLoader from "../components/NucleusLoader";
import BackButton from "../../components/baseComponents/BackButton";
import SuccessMessage from "../../components/baseComponents/SuccessMessage";

const StyledInviteContainer = styled(Div)`
  border: ${(props) => props.theme.borders.primarySM};
  border-radius: ${(props) => props.theme.border_radius.MD};
`;

const StyledInviteHeader = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineMD};
  text-align: center;
`;

const StyledLoading = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineLG};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const SendTestEmail = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [failureMessage, setFailureMessage] = useState("");
  const [error, setError] = useState(false);

  const { loadingPermissions, adminSettingsPermission } =
    useNucleusPermissions();

  const validateSend = () => {
    return emailRegex.test(email) && email.length > 0;
  };

  const handleSend = () => {
    setSuccessMessage("");
    setFailureMessage("");
    setLoading(true);
    const successCallback = () => {
      setSuccessMessage(
        "Test Email Send! The recipient should check their email."
      );
      setEmail("");
      setLoading(false);
    };
    const failureCallback = (errorMessage: string) => {
      setLoading(false);
      setFailureMessage(errorMessage);
    };
    if (validateSend()) {
      sendTestEmail(email, successCallback, failureCallback);
    } else {
      setLoading(false);
      setError(true);
    }
  };

  if (loading || loadingPermissions) {
    return (
      <Row justifyContent="center">
        <StyledLoading width={{ default: 3 / 12 }}>
          <NucleusLoader />
        </StyledLoading>
      </Row>
    );
  }

  if (adminSettingsPermission !== nucleusUserPermissions.WRITE) {
    return <Redirect to={"/nucleus/forbidden"} />;
  }

  return (
    <Container>
      <Row mt={{ default: 5 }}>
        <BackButton returnUrl="/nucleus/admin" text="Go Back" />
      </Row>
      <Row justifyContent="center">
        <StyledInviteContainer width={{ default: 8 / 12 }} mt={{ default: 5 }}>
          <StyledInviteHeader mt={{ default: 4 }}>
            Send a Test Email:
          </StyledInviteHeader>
          <Row justifyContent="center">
            <Div width={{ default: 9 / 12 }}>
              <Input
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                error={error}
              />
            </Div>
          </Row>
          {failureMessage && (
            <Row
              justifyContent="center"
              mt={{ default: 3 }}
              mb={{ default: 3 }}
            >
              <Div>
                <ErrorMessage>{failureMessage}</ErrorMessage>
              </Div>
            </Row>
          )}
          {successMessage && (
            <Row
              justifyContent="center"
              mt={{ default: 3 }}
              mb={{ default: 3 }}
            >
              <SuccessMessage>{successMessage}</SuccessMessage>
            </Row>
          )}

          <Row justifyContent="center" mb={{ default: 5 }}>
            <Div width={{ default: 9 / 12 }}>
              <Button text="Send" onClick={handleSend} loading={loading} />
            </Div>
          </Row>
        </StyledInviteContainer>
      </Row>
    </Container>
  );
};

export default SendTestEmail;
