import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Div from "./Div";
import Row from "./Row";
import BeatLoader from "react-spinners/BeatLoader";
import { Building, Prepayment, Unit } from "../global/ModelInterfaces";
import { transitionSlowly } from "../global/utils";
import BuildingIcon from "../../images/BuildingIcon";
import UserIcon from "../../images/UserIcon";
import UnitIcon from "../../images/UnitIcon";
interface Props {
  buildingResults: Building[];
  prepaymentResults: Prepayment[];
  unitResults: Unit[];
  onSelect: Function;
}

const StyledDropdown = styled(Div)`
  position: absolute;
  z-index: 100;
  top: 5rem;
  left: 18.5rem;
  background-color: white;
  border: 2px #b5b9c2 solid;
  border-top: none;
`;

const StyledTitle = styled(Div)`
  font-weight: bold;
  border: 2px #b5b9c2 solid;
  border-left: none;
  border-right: none;
  padding-left: 0.5rem;
`;

const StyledResult = styled(Div)`
  padding: 1rem;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const SearchResultDisplay = ({
  buildingResults,
  prepaymentResults,
  unitResults,
  onSelect,
}: Props) => {
  const history = useHistory();
  const handleSelect = (url: string) => {
    onSelect();
    transitionSlowly(history, url);
  };
  return (
    <Div>
      {!buildingResults && !prepaymentResults && !unitResults && (
        <Row>
          <BeatLoader />
        </Row>
      )}
      <StyledDropdown width={{ lg: 2 / 5 }}>
        {buildingResults.length > 0 && (
          <div>
            <StyledTitle justifyContent="center">Buildings:</StyledTitle>
            {buildingResults.map((building: Building) => {
              return (
                <StyledResult
                  onClick={() =>
                    handleSelect(`/dashboard/building/${building.uuid}`)
                  }
                >
                  <Row>
                    <Div>
                      <BuildingIcon height={"30"} width={"30"} />
                    </Div>
                    {building.name && `${building.name} -`} {building.address_1}
                    ,{building.city}, {building.state}
                  </Row>
                </StyledResult>
              );
            })}
          </div>
        )}
        {prepaymentResults.length > 0 && (
          <div>
            <StyledTitle>Tenants:</StyledTitle>
            {prepaymentResults.map((prepayment: Prepayment) => {
              return (
                <StyledResult
                  onClick={() =>
                    //TODO: update redirect once Tadeo's redux changes are deployed
                    handleSelect(`/dashboard/tenant/${prepayment.uuid}`)
                  }
                >
                  <Row>
                    <Div>
                      <UserIcon height={"30"} width={"30"} />
                    </Div>
                    ${prepayment.amount}- {prepayment.tenant_name}
                  </Row>
                </StyledResult>
              );
            })}
          </div>
        )}
        {unitResults.length > 0 && (
          <div>
            <StyledTitle>Units:</StyledTitle>
            {unitResults.map((unit: Unit) => {
              return (
                <StyledResult
                  onClick={() => handleSelect(`/dashboard/unit/${unit.uuid}`)}
                >
                  <Row>
                    <Div>
                      <UnitIcon height={"30"} width={"30"} />
                    </Div>
                    {unit.street_address}, {unit.city}, {unit.state}
                  </Row>
                </StyledResult>
              );
            })}
          </div>
        )}
      </StyledDropdown>
    </Div>
  );
};

export default SearchResultDisplay;
