import { find } from "lodash";

const SET_COMPANIES = "company/SET_COMPANIES";
const ADD_COMPANY = "company/ADD_COMPANY";
const UPDATE_COMPANY = "company/UPDATE_COMPANY";
const UPDATE_COMPANY_SECTION = "company/UPDATE_SECTION";
const UPDATE_COMPANY_BUILDING = "company/UPDATE_BUILDING";
// Adds all Companies to objects list
export const setCompanies = (companies) => {
  return {
    type: SET_COMPANIES,
    companies,
  };
};

export const addCompany = (newCompany) => {
  return {
    type: ADD_COMPANY,
    newCompany,
  };
};

// Adds updates a Company within objects list with all details
export const updateCompany = (company) => {
  return {
    type: UPDATE_COMPANY,
    company,
  };
};

// Updates Company Section
export const updateCompanySection = (companyUUID, sectionKey, updatedInfo) => {
  return {
    type: UPDATE_COMPANY_SECTION,
    companyUUID,
    sectionKey,
    updatedInfo,
  };
};

export const updateCompanyBuilding = (companyUUID, updatedBuilding) => {
  return {
    type: UPDATE_COMPANY_BUILDING,
    companyUUID,
    updatedBuilding,
  };
};

const initialState = {
  loading: true,
  objects: [],
};
export default function companyReducer(state = initialState, action) {
  switch (action.type) {
    case SET_COMPANIES:
      return {
        ...state,
        loading: false,
        objects: action.companies,
      };
    case ADD_COMPANY:
      return {
        ...state,
        loading: false,
        objects: state.objects.concat(action.newCompany),
      };
    case UPDATE_COMPANY_SECTION:
      return {
        ...state,
        loading: false,
        objects: state.objects.map((company) => {
          if (action.companyUUID === company.uuid) {
            let tempCompany = company;
            tempCompany[action.sectionKey] = action.updatedInfo;
            return tempCompany;
          } else return company;
        }),
      };
    case UPDATE_COMPANY_BUILDING:
      let tempCompany = find(state.objects, function (company) {
        return action.companyUUID === company.uuid;
      });

      const tempCompanyBuildings = tempCompany.buildings.map((building) => {
        if (action.updatedBuilding.uuid === building.uuid) {
          return action.updatedBuilding;
        } else return building;
      });
      tempCompany.buildings = tempCompanyBuildings;
      return {
        ...state,
        loading: false,
        objects: state.objects.map((company) => {
          return company.uuid === action.companyUUID ? tempCompany : company;
        }),
      };
    case UPDATE_COMPANY:
      return {
        ...state,
        loading: false,
        objects: state.objects.map((company) => {
          return company.uuid === action.company.uuid
            ? action.company
            : company;
        }),
      };
    default:
      return state;
  }
}
