import axios from "axios";

export const getIdentity = (
  successCallback: Function,
  failureCallback?: Function
) => {
  axios
    .get("/api/identity")
    .then((res: any) => {
      successCallback(res.data);
    })
    .catch((e) => {
      if (failureCallback) {
        failureCallback(e.message);
      }
    });
};

export const getRollbarConfig = (
  successCallback: Function,
  failureCallback?: Function
) => {
  axios
    .get("/api/rollbar-config")
    .then((res: any) => {
      successCallback(res.data);
    })
    .catch((e) => {
      if (failureCallback) {
        failureCallback(e.message);
      }
    });
};
