import { BrowserRouter } from "react-router-dom";
import Div from "./components/baseComponents/Div";
import NavbarUnauthenticated from "./components/UnauthComponents/NavbarUnauthenticated";
import RoutesUnauth from "./components/UnauthComponents/RoutesUnauth";

function UnauthApp() {
  return (
    <BrowserRouter>
      <NavbarUnauthenticated />
      <Div justifyContent={"center"} alignItems={"center"}>
        <RoutesUnauth />
      </Div>
    </BrowserRouter>
  );
}

export default UnauthApp;
