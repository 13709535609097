import { useState } from "react";
import { useHistory } from "react-router-dom";
import Div from "../components/baseComponents/Div";
import Row from "../components/baseComponents/Row";
import PlaidButton from "../components/PlaidButton";
import BackButton from "../components/baseComponents/BackButton";
import WorkoutCoin from "../images/WorkoutCoin";
import Button from "../components/baseComponents/Button";

interface Props {
  returnUrl: string;
  successUrl: string;
}

const UpdatePlaidCredentialsPage = ({ returnUrl, successUrl }: Props) => {
  const [success, setSuccess] = useState(false);
  const history = useHistory();
  return (
    <Div>
      <Row>
        <BackButton text="Back" returnUrl={returnUrl} />
      </Row>
      <Row justifyContent="center">
        <Div alignItems="center" width={{ lg: 1 / 2 }}>
          <WorkoutCoin />
          {success ? (
            <>
              You've successfully updated your bank information!
              <Button
                text={"Continue"}
                onClick={() => {
                  history.push(successUrl);
                }}
              />
            </>
          ) : (
            <>
              It looks like it's been a while since you linked your bank
              account, please update your credentials.
              <PlaidButton
                includeAssets={true}
                onSuccess={() => {
                  setSuccess(true);
                }}
                updateMode={true}
              />
            </>
          )}
        </Div>
      </Row>
    </Div>
  );
};

export default UpdatePlaidCredentialsPage;
