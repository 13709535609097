import { useState } from "react";
import { useAppDispatch } from "../../../store/hooks";
import { useParams } from "react-router-dom";
import Button from "../../../components/baseComponents/Button";
import Div from "../../../components/baseComponents/Div";
import CurrencyInput from "../../../components/baseComponents/CurrencyInput";
import { createAdditionalFundsRequest } from "../../utils/prepaymentUtils";
import { updatePrepaymentSection } from "../../../store/nucleusPrepayments";
import { GenericObject } from "../../../components/global/ModelInterfaces";
import ErrorMessage from "../../../components/baseComponents/ErrorMessage";

interface Props {
  hasSecurityDeposit: boolean;
  hasLastMonthsRent: boolean;
}

const CreateAdditionalFundsRequestForm = ({
  hasSecurityDeposit,
  hasLastMonthsRent,
}: Props) => {
  const dispatch = useAppDispatch();
  const { prepaymentUUID } = useParams<GenericObject>();
  const [securityDepositAmount, setSecurityDepositAmount] = useState(0.0);
  const [lastMonthsRentAmount, setLastMonthsRentAmount] = useState(0.0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const validateAmounts = () => {
    if (!securityDepositAmount && !lastMonthsRentAmount) {
      setError(
        "You must fill out at least a Security Deposit or Last Month's Rent amount."
      );
      return false;
    }
    return true;
  };
  const handleAdditionalFundsCreation = () => {
    if (validateAmounts()) {
      setLoading(true);
      setError("");
      const successCallback = (res: GenericObject) => {
        setLoading(false);
        dispatch(
          updatePrepaymentSection(
            prepaymentUUID,
            "additional_funds_info",
            res.additional_funds_info
          )
        );
        setLoading(false);
      };
      const failureCallback = (errorMessage: string) => {
        setLoading(false);
        setError(errorMessage);
      };

      createAdditionalFundsRequest(
        prepaymentUUID,
        securityDepositAmount,
        lastMonthsRentAmount,
        successCallback,
        failureCallback
      );
    }
  };
  return (
    <Div mt={{ default: 3 }}>
      {hasSecurityDeposit && (
        <CurrencyInput
          label="Security Deposit Amount"
          onChange={(value) => {
            setSecurityDepositAmount(value);
          }}
        />
      )}
      {hasLastMonthsRent && (
        <CurrencyInput
          label="Last Months Rent Amount"
          onChange={(value) => {
            setLastMonthsRentAmount(value);
          }}
        />
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Div mt={{ default: 3 }}>
        <Button
          text="Submit Funds Request"
          loading={loading}
          disabled={!securityDepositAmount && !lastMonthsRentAmount}
          onClick={handleAdditionalFundsCreation}
        />
      </Div>
    </Div>
  );
};
export default CreateAdditionalFundsRequestForm;
