import axios from "axios";

export const retrieveUnitByUUID = (
  uuid: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/retrieve-unit-by-uuid";

  axios
    .post(url, {
      unit_uuid: uuid,
    })
    .then((resp) => {
      const data = resp.data;
      successCallback(data.unit);
    })
    .catch((err) => {
      failureCallback(err.data);
    });
};

export const retrieveUnitList = (
  buildingUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/retrieve-unit-list";

  axios
    .post(url, {
      building_uuid: buildingUUID,
    })
    .then((resp) => {
      successCallback(resp.data.units);
    })
    .catch(() => {
      failureCallback();
    });
};

export const createUnit = (
  buildingUUID: string,
  unitAddress: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const unitInfo = {
    buildingUUID: buildingUUID,
    unitAddress: unitAddress,
  };
  const url = "/nucleus/api/create-single-unit";
  axios
    .post(url, unitInfo)
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err.data);
    });
};

export const uploadUnitCSV = (
  csv: File,
  companyUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const csvData = new FormData();
  csvData.append("file", csv);
  csvData.append("company_uuid", companyUUID);
  const url = "/nucleus/api/upload-unit-csv";
  axios
    .post(url, csvData)
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err.data);
    });
};
