interface Props {
  width?: string;
  height?: string;
}

const PiggyIcon = ({ width = "40", height = "40" }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.5526 17.2632C24.5526 16.3513 25.2987 15.6053 26.2105 15.6053C27.1224 15.6053 27.8684 16.3513 27.8684 17.2632C27.8684 18.175 27.1224 18.9211 26.2105 18.9211C25.2987 18.9211 24.5526 18.175 24.5526 17.2632ZM12.9474 15.6053H21.2368V12.2895H12.9474V15.6053ZM36.1579 13.1184V24.6739L31.4826 26.2324L28.6974 35.5H19.5789V32.1842H16.2632V35.5H7.14474C7.14474 35.5 3 21.4742 3 16.4342C3 11.3942 7.07842 7.31579 12.1184 7.31579H20.4079C21.9166 5.30974 24.3205 4 27.0395 4C28.4155 4 29.5263 5.11079 29.5263 6.48684C29.5263 6.835 29.46 7.15 29.3274 7.44842C29.0953 8.0121 28.8963 8.65868 28.7968 9.355L32.5603 13.1184H36.1579ZM32.8421 16.4342H31.1842L25.3816 10.6316C25.3816 9.55395 25.5308 8.4929 25.8126 7.465C24.2045 7.87947 22.8947 9.07316 22.3476 10.6316H12.1184C8.91868 10.6316 6.31579 13.2345 6.31579 16.4342C6.31579 19.5511 8.33842 27.4592 9.64816 32.1842H12.9474V28.8684H22.8947V32.1842H26.2271L28.7968 23.6461L32.8421 22.2866V16.4342Z"
        fill="#1EB88D"
      />
    </svg>
  );
};

export default PiggyIcon;
