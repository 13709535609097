import { useState } from "react";
import styled from "styled-components";
import Div from "./Div";
import Row from "./Row";
import DropdownCard from "./DropdownCard";
import ToggleButton from "./ToggleButton";
import ErrorMessage from "./ErrorMessage";
import { Landlord, LandlordBuilding } from "../global/ModelInterfaces";
import NucleusLoader from "../../nucleus/components/NucleusLoader";

const StyledBuildingName = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineXS};
`;

interface Props {
  landlord: Landlord;
  buildings: LandlordBuilding[];
  onAdd: Function;
  onRemove: Function;
}
const LandlordPermissionsCard = ({
  landlord,
  buildings,
  onAdd,
  onRemove,
}: Props) => {
  const [error, setError] = useState("");
  const findPermission = (landlordBuilding: LandlordBuilding) => {
    const landlords = landlordBuilding.landlords;
    const existingLandlord = landlords.find(
      (landlordPermission) => landlordPermission.id === landlord.id
    );
    return Boolean(existingLandlord);
  };

  const handleToggleClick = (
    givePermission: Boolean,
    building: LandlordBuilding,
    onAdd: Function,
    onRemove: Function
  ) => {
    const failureCallback = () => {
      setError(
        "There was an error updating the permissions please refresh the page and try again."
      );
    };

    if (!givePermission) {
      onRemove(building.uuid, landlord.id, failureCallback);
    } else {
      onAdd(building.uuid, landlord.id, failureCallback);
    }
  };

  return (
    <DropdownCard title={`${landlord.full_name}'s Building Permissions`}>
      {error && (
        <Row justifyContent="center" mb={{ default: 5 }}>
          <ErrorMessage>{error}</ErrorMessage>
        </Row>
      )}
      <Div width={"fill"}>
        {buildings.map((building) => {
          const hasPermission = findPermission(building);
          return (
            <Row mb={{ default: 1 }}>
              <Div width={"fill"} ml={{ default: 5 }}>
                <StyledBuildingName>{building.name}</StyledBuildingName>
              </Div>
              <Div width={{ default: 6 / 12 }}>
                <ToggleButton
                  initialToggleState={hasPermission}
                  onToggle={(givePermission: boolean) => {
                    handleToggleClick(
                      givePermission,
                      building,
                      onAdd,
                      onRemove
                    );
                  }}
                />
              </Div>
            </Row>
          );
        })}
      </Div>
    </DropdownCard>
  );
};

export default LandlordPermissionsCard;
