import styled from "styled-components";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";

import { v4 } from "uuid";
import { GenericObject, PendingFee } from "../global/ModelInterfaces";
import Container from "../baseComponents/Container";
import Row from "../baseComponents/Row";
import Div from "../baseComponents/Div";
import Input from "../baseComponents/Input";
import Button from "../baseComponents/Button";
import ErrorMessage from "../baseComponents/ErrorMessage";
import { emailRegex } from "../../utils/accountUtils";
import { cssBreakpoints } from "../global/theme";
import FeeCard from "./FeeCard";
import CurrencyInput from "../baseComponents/CurrencyInput";
import { getStateRule, rulesList } from "../global/StateRules/StateRules";

const StyledDiv = styled(Div)`
  box-shadow: ${(props) => props.theme.box_shadow.light};
  border-radius: ${(props) => props.theme.border_radius.MD};
  margin: 0.625em 0em;
  ${cssBreakpoints("padding", [{ sm: "1rem 0rem" }, { md: "1.5rem" }])}
`;

const SpacedDiv = styled(Div)`
  padding: 0.75rem;
`;

interface Props {
  onAdd: (tenant: any) => void;
  onCancel: () => void;
  showCancel: boolean;
  unitState?: string | null;
}
const AddTenantCard = ({ onAdd, onCancel, showCancel, unitState }: Props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [depositAmount, setDepositAmount] = useState<number | null>(null);
  const [lastMonthRentAmount, setLastMonthRentAmount] = useState<number | null>(
    null
  );
  const [errors, setErrors] = useState<GenericObject>({});
  const [fees, setFees] = useState<Array<PendingFee>>([]);

  const canChargeFees = useAppSelector(
    (state) => state.companies.can_charge_fees
  );

  const defaultFeeBankAccount = useAppSelector(
    (state) => state.companies.default_bank_account
  );

  const addFee = (fee: PendingFee) => {
    setFees([...fees, fee]);
  };

  const removeFee = (uuid: string) => {
    setFees((fees) =>
      fees.filter((fee) => {
        return fee.uuid !== uuid;
      })
    );
  };

  const validateForm = () => {
    let validForm = true;
    let tmpErrors = errors;
    if (!firstName) {
      tmpErrors = { ...tmpErrors, firstName: true };
      validForm = false;
    }
    if (!lastName) {
      tmpErrors = { ...tmpErrors, lastName: true };
      validForm = false;
    }
    if (!emailRegex.test(email)) {
      tmpErrors = { ...tmpErrors, validEmail: true };
      validForm = false;
    }
    if (!email) {
      tmpErrors = { ...tmpErrors, email: true };
      validForm = false;
    }
    if (!depositAmount && !lastMonthRentAmount) {
      tmpErrors = {
        ...tmpErrors,
        depositAmount: true,
        lastMonthRentAmount: true,
      };
      validForm = false;
    }

    setErrors(tmpErrors);
    return validForm;
  };

  const saveTenant = () => {
    if (validateForm()) {
      const depositList = [];
      if (depositAmount) {
        depositList.push({ type: 1, amount: depositAmount, id: 0 });
      }
      if (lastMonthRentAmount) {
        depositList.push({ type: 2, amount: lastMonthRentAmount, id: 1 });
      }
      const tenant = {
        uuid: v4(),
        firstName: firstName,
        lastName: lastName,
        name: `${firstName} ${lastName}`,
        email: email,
        depositList: depositList,
        feeList: fees,
      };
      onAdd(tenant);
    } else {
      if (errors.firstName || errors.lastName) {
        setErrorMessage("Full name required");
      } else if (errors.email) {
        setErrorMessage("Email required");
      } else if (errors.validEmail) {
        setErrorMessage("Please enter a valid email");
      } else if (errors.depositAmount) {
        setErrorMessage("Deposit amount required");
      } else {
        setErrorMessage("Please fill in required fields");
      }
    }
  };

  const clearError = (key: string) => {
    let tmpErrors = errors;
    tmpErrors[key] = false;
    setErrors(tmpErrors);
    setErrorMessage(null);
  };

  //TODO get verification status of landlord also see if building has default bank account
  const isLandlordAbleToChargeFees = () => {
    return defaultFeeBankAccount && canChargeFees;
  };
  return (
    <Container>
      <StyledDiv>
        <Row>
          <Div width={{ default: 1 }}>
            <Input
              backgroundColor="light"
              label={"Email"}
              placeholderText={""}
              onChange={(e) => {
                setEmail(e.target.value);
                clearError("email");
              }}
              error={errors.email || errors.validEmail}
            />
          </Div>
        </Row>
        <Row alignItems="center" justifyContent="space-between">
          <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
            <Input
              backgroundColor="light"
              label={"First Name(s)"}
              placeholderText={""}
              onChange={(e) => {
                setFirstName(e.target.value);
                clearError("firstName");
              }}
              error={errors.firstName}
            />
          </Div>
          <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
            <Input
              backgroundColor="light"
              label={"Last Name(s)"}
              placeholderText={""}
              onChange={(e) => {
                setLastName(e.target.value);
                clearError("lastName");
              }}
              error={errors.lastName}
            />
          </Div>
        </Row>
        <Row alignItems="center">
          <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
            <CurrencyInput
              label={"Deposit Amount"}
              onChange={(value) => {
                setDepositAmount(value);
                clearError("depositAmount");
                clearError("lastMonthRentAmount");
              }}
              error={errors.depositAmount}
            />
          </Div>
          {getStateRule({
            state: unitState || "",
            rule: rulesList.CAN_CREATE_LAST_MONTHS_RENT,
          }) && (
            <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
              <CurrencyInput
                label={"Last Month Rent Amount"}
                onChange={(value) => {
                  setLastMonthRentAmount(value);
                  clearError("depositAmount");
                  clearError("lastMonthRentAmount");
                }}
                error={errors.lastMonthRentAmount}
              />
            </Div>
          )}
        </Row>
        {isLandlordAbleToChargeFees() && (
          <Row justifyContent="center">
            <FeeCard fees={fees} onAdd={addFee} onRemove={removeFee} />
          </Row>
        )}
        <Row alignItems="center">
          <SpacedDiv width={{ default: 1 }}>
            <Button text={"Save"} type={"primary"} onClick={saveTenant} />
          </SpacedDiv>
        </Row>
        {showCancel && (
          <Row alignItems="center">
            <Div width={{ default: 1 }}>
              <Button
                text={"Cancel"}
                type={"secondary"}
                onClick={onCancel}
              ></Button>
            </Div>
          </Row>
        )}
        <Row>
          <Div>
            {errorMessage && <ErrorMessage>Error: {errorMessage}</ErrorMessage>}
          </Div>
        </Row>
      </StyledDiv>
    </Container>
  );
};

export default AddTenantCard;
