import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";

import Button from "../../baseComponents/Button";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";

import CSVButton from "../../baseComponents/CSVButton";
import { Prepayment } from "../../global/ModelInterfaces";
import prepaymentStatuses from "../../../enums/prepaymentStatuses";

interface Props {
  prepayments: Prepayment[];
  title: string;
}

const PrepaymentDashboardButtons = ({ title, prepayments }: Props) => {
  const history = useHistory();
  const getStatusLabel = (status_id: number) => {
    switch (status_id) {
      case prepaymentStatuses.PENDING:
        return "Pending";
      case prepaymentStatuses.PROCESSING:
        return "Processing";
      case prepaymentStatuses.ACTIVE:
        return "Active";
      case prepaymentStatuses.DISBURSING:
        return "Disbursing";
      case prepaymentStatuses.CLOSED:
        return "Closed";
      default:
        return "Error: Status Undefined";
    }
  };
  const filteredColumnPrepayments = prepayments.map(
    ({
      building_name,
      address_1,
      address_2,
      city,
      state,
      zip_code,
      status_id,
      start_date,
      end_date,
      amount,
      tenant_name,
      tenant_email,
      security_deposit_amount,
      last_months_rent_amount,
      ...excludedColumns
    }) => {
      const status = getStatusLabel(status_id);
      return {
        building_name: building_name,
        street_address: address_1,
        unit: address_2,
        city,
        state,
        zip_code,
        status,
        start_date,
        end_date,
        tenant_name,
        tenant_email,
        security_deposit_amount,
        last_months_rent_amount,
      };
    }
  );

  return (
    <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
      <Row justifyContent="flex-end">
        <Div width={{ sm: 2 / 5, md: 1 / 4, lg: 1 / 2 }}>
          <CSVButton
            data={filteredColumnPrepayments}
            fileName={
              title.toLowerCase().replace(" ", "_") +
              DateTime.now().toFormat("_yyyy_MM_dd")
            }
          >
            Download Deposits
          </CSVButton>
        </Div>
        <Div width={{ sm: 2 / 5, md: 1 / 4, lg: 1 / 2 }}>
          <Button
            type="primary"
            text="Invite Tenant"
            onClick={() => history.push("/dashboard/invite")}
          />
        </Div>
      </Row>
    </Div>
  );
};

export default PrepaymentDashboardButtons;
