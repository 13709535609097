interface Props {
  width?: string;
  height?: string;
}

const PencilIcon = ({ width = "40", height = "40" }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.70483 24.8904C9.89983 24.2295 10.2575 23.6248 10.7455 23.1373L25.0016 8.87884C26.1734 7.70705 28.0766 7.70705 29.2484 8.87884L31.0953 10.7271C31.2406 10.8734 31.3672 11.0313 31.4797 11.1977C32.2484 12.3621 32.1219 13.9451 31.0953 14.9716L16.8359 29.2263C16.775 29.2873 16.7141 29.306 16.6484 29.4045C16.1984 29.8029 15.6641 30.056 15.0828 30.267L9.44233 31.9263C9.04765 32.0435 8.62061 31.9357 8.32952 31.6029C8.03842 31.3545 7.92958 30.9279 8.04574 30.5295L9.70483 24.8904ZM10.7862 29.1888L14.45 28.1107C14.5578 28.0779 14.6656 27.9935 14.7687 27.9841L13.0672 27.642C12.6969 27.567 12.4048 27.2763 12.3308 26.906L11.9905 25.2045C11.9384 25.3076 11.8958 25.4154 11.8634 25.5232L10.7862 29.1888ZM16.4703 26.4091L25.9531 16.9263L23.0047 13.9779L13.5641 23.5029L14.0047 25.9263L16.4703 26.4091Z"
        fill="#2F374B"
      />
    </svg>
  );
};

export default PencilIcon;
