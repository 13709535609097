import { useState } from "react";
import Row from "./Row";
import DropdownListItem from "./DropdownListItem";
import ErrorMessage from "./ErrorMessage";
import {
  GenericObject,
  LandlordBuilding,
  Landlord,
} from "../global/ModelInterfaces";

interface Props {
  building: LandlordBuilding;
  landlords: Landlord[];
  onAdd: Function;
  onRemove: Function;
}
const LandlordBuildingPermissions = ({
  building,
  landlords,
  onAdd,
  onRemove,
}: Props) => {
  const [error, setError] = useState("");

  const unaddedLandlords = landlords.filter(
    (companyLandlord: any) =>
      !building.landlords.some(
        (buildingLandlord: any) => buildingLandlord.id === companyLandlord.id
      )
  );

  const landlordOptions = unaddedLandlords.map((input: any) => {
    return {
      id: input.id.toString(),
      label: input.full_name + " " + input.email,
    };
  });

  const existingLandlords = building.landlords.map(
    (landlord: GenericObject) => {
      return {
        key: landlord.id,
        title: landlord.full_name,
        values: [landlord.full_name, landlord.email],
      };
    }
  );

  const handleRemovalofLandlordFromBuilding = (
    landlordBuilding: LandlordBuilding,
    landlord: GenericObject
  ) => {
    const buildingUuid = landlordBuilding.uuid;
    const landlordId = landlord.key;
    const failureCallback = () => {
      setError("Could not remove property manager from building.");
    };
    onRemove(buildingUuid, landlordId, failureCallback);
  };
  const handleAddingLandlord = (
    building: LandlordBuilding,
    landlord: GenericObject
  ) => {
    const buildingUuid = building.uuid;
    const landlordId = landlord.id;
    const failureCallback = () => {
      setError("Could not add property manager to building.");
    };
    onAdd(buildingUuid, landlordId, failureCallback);
  };

  return (
    <Row justifyContent="center">
      {error && (
        <Row justifyContent="center" mb={{ default: 3 }}>
          <ErrorMessage>{error}</ErrorMessage>
        </Row>
      )}
      <DropdownListItem
        key={building.id}
        title={building.name}
        details={[
          `
            ${building.address_1}
            ${building.city}, ${building.state}
            ${building.zip_code}
          `,
          `
            Associated Property Managers: ${building.landlords.length.toString()}
          `,
        ]}
        onAdd={(landlord) => handleAddingLandlord(building, landlord)}
        onDelete={(landlord) =>
          handleRemovalofLandlordFromBuilding(building, landlord)
        }
        existingSelections={existingLandlords}
        searchOptions={landlordOptions}
        searchPlaceholderText={"Add Property Manager to Building"}
      />
    </Row>
  );
};

export default LandlordBuildingPermissions;
