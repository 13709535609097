import styled from "styled-components";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import RentableCard from "../../baseComponents/RentableCard";
import FeeDetails from "./FeeDetails";
import { Fee } from "../../global/ModelInterfaces";
const StyledTableHeader = styled.th`
  padding-left: 2em;
  padding-right: 2em;
`;
interface Props {
  fees: Fee[];
}
const FeeTable = ({ fees }: Props) => {
  return (
    <Div width={{ sm: 1, md: 1 / 3, lg: 1 }}>
      <RentableCard title={"Fees"}>
        <Div width={{ sm: 1, lg: 1 }} justifyContent="center" alignItems="left">
          <table>
            <StyledTableHeader>Fee Type</StyledTableHeader>
            <StyledTableHeader>Amount</StyledTableHeader>
            <StyledTableHeader>Description</StyledTableHeader>
            {fees.map((fee: Fee) => {
              return (
                <>
                  <FeeDetails fee={fee} />
                </>
              );
            })}
          </table>
        </Div>
      </RentableCard>
    </Div>
  );
};

export default FeeTable;
