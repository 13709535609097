import { useEffect, useState } from "react";
import styled from "styled-components";
import { reject } from "lodash";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import DropdownCard from "../../../components/baseComponents/DropdownCard";
import DepositSection from "./DepositSection";
import AddDepositForm from "../Forms/AddDepositForm";
import { GenericObject } from "../../../components/global/ModelInterfaces";
import { depositOptions } from "../../utils/prepaymentUtils";
import ButtonToForm from "../ButtonToForm";
import { nucleusUserPermissions } from "../../../enums/nucleusPermissions";
import useNucleusPermissions from "../../../utils/useNucleusPermissions";

const StyledSubTitle = styled(Row)`
  font-size: ${(props) => props.theme.font_size.headlineSM};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

interface Props {
  depositInfo: GenericObject;
}

const DepositInfo = ({ depositInfo }: Props) => {
  const [hasEditPermissions, setHasEditPermissions] = useState(false);
  const { loadingPermissions, prepaymentOverviewPermission } =
    useNucleusPermissions();

  const [securityDeposit, setSecurityDeposit] = useState<GenericObject>(
    depositInfo.security_deposit
  );
  const [lastMonthsRent, setLastMonthsRent] = useState<GenericObject>(
    depositInfo.last_months_rent
  );

  const setDeposits = (updatedDepositsInfo: GenericObject) => {
    setSecurityDeposit(updatedDepositsInfo.security_deposit);
    setLastMonthsRent(updatedDepositsInfo.last_months_rent);
  };

  const displayAddDepositForm = () => {
    return !securityDeposit || !lastMonthsRent;
  };

  const filteredOptions = () => {
    let filteredOptions = depositOptions;
    if (securityDeposit) {
      filteredOptions = reject(depositOptions, [
        "id",
        securityDeposit.type.toString(),
      ]);
    }
    if (lastMonthsRent) {
      filteredOptions = reject(depositOptions, [
        "id",
        lastMonthsRent.type.toString(),
      ]);
    }
    return filteredOptions;
  };

  useEffect(() => {
    if (!loadingPermissions) {
      setHasEditPermissions(
        prepaymentOverviewPermission === nucleusUserPermissions.WRITE
      );
    }
  }, [prepaymentOverviewPermission]);

  return (
    <DropdownCard title="Deposits Info">
      <Div width={{ default: 12 / 12 }}>
        <Row>
          <Div width={"fill"}>
            <Row>
              {securityDeposit && (
                <Div width={"fill"}>
                  <StyledSubTitle>Security Deposit Details</StyledSubTitle>
                  <DepositSection deposit={securityDeposit} />
                </Div>
              )}
              {lastMonthsRent && (
                <Div width={"fill"}>
                  <StyledSubTitle>Last Month's Rent Details</StyledSubTitle>
                  <DepositSection deposit={lastMonthsRent} />
                </Div>
              )}
            </Row>
          </Div>
          {displayAddDepositForm() && hasEditPermissions && (
            <Div width={{ default: 4 / 12 }}>
              <ButtonToForm
                formTitle={"Add a Deposit"}
                buttonText={"Add Deposit"}
              >
                <AddDepositForm
                  setDeposits={setDeposits}
                  depositOptions={filteredOptions()}
                />
              </ButtonToForm>
            </Div>
          )}
        </Row>
      </Div>
    </DropdownCard>
  );
};

export default DepositInfo;
