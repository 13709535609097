import { useEffect, useState } from "react";
import { useParams, Redirect, useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { RootState } from "../..";
import styled from "styled-components";
import Container from "../baseComponents/Container";
import BackButton from "../baseComponents/BackButton";
import Button from "../baseComponents/Button";
import Div from "../baseComponents/Div";
import Row from "../baseComponents/Row";
import PageLoader from "../PageLoader";
import ConfirmationModal from "../baseComponents/ConfirmationModal";
import AutocompleteDropdown from "../baseComponents/AutocompleteDropdown";
import ErrorMessage from "../baseComponents/ErrorMessage";
import { updatePrepayment } from "../../store/prepayments";
import { updatePrepaymentUnit } from "../../utils/depositUtils";
import {
  GenericObject,
  Prepayment,
  Unit,
  Building,
  Option,
} from "../global/ModelInterfaces";
import { getStreetAddress } from "../../utils/globalUtils";
import HorizontalDivider from "../baseComponents/HorizontalDivider";

const StyledTitle = styled(Row)`
  font-size: ${(props) => props.theme.font_size.headlineLG};
  font-weight: ${(props) => props.theme.font_weight.bolder};
  color: ${(props) => props.theme.colors.grey};
  line-height: 3.25rem;
  margin-bottom: 0.5rem;
`;
const StyledSubTitle = styled.div`
  font-size: ${(props) => props.theme.font_size.headlineSM};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  color: ${(props) => props.theme.colors.grey};
  line-height: 1.875rem;
  margin-bottom: 1.5rem;
`;
const StyledSummaryRow = styled(Row)`
  margin-bottom: 1rem;
`;

const StyledSummaryText = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
  color: ${(props) => props.theme.colors.grey};
`;

const StyledInstructionText = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineXS};
  color: ${(props) => props.theme.colors.grey60};
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
`;

const StyledSuccess = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineSM};
  color: ${(props) => props.theme.colors.primary};
  margin: 1.5rem 0;
`;

const UpdatePrepaymentUnitView = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [buildingSelectError, setBuildingSelectError] =
    useState<boolean>(false);
  const [unitSelectError, setUnitSelectError] = useState<boolean>(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [unitOptions, setUnitOptions] = useState<
    {
      label: string;
      identifier: string | undefined;
      id: string;
    }[]
  >([]);

  const { prepaymentUUID } = useParams<GenericObject>();
  const prepaymentSlice: { loading: boolean; objects: Prepayment[] } =
    useAppSelector((state: RootState) => state.prepayments);

  const loadingDeposits = prepaymentSlice.loading;
  const selectedPrepayment = prepaymentSlice.objects.find(
    (prepayment: Prepayment) => prepayment.uuid === prepaymentUUID
  );

  const unitsSlice: { loading: Boolean; objects: Unit[] } = useAppSelector(
    (state: RootState) => state.units
  );
  const loadingUnits = unitsSlice.loading;
  const buildingSlice: { loading: Boolean; objects: Building[] } =
    useAppSelector((state: RootState) => state.buildings);
  const [currentUnit, setCurrentUnit] = useState<Unit | null>(null);
  const [selectedUnit, setSelectedUnit] = useState<Unit | null>(null);
  const [selectedBuilding, setSelectedBuilding] = useState<Building | null>(
    null
  );

  const buildingOnClick = (option: Option | null) => {
    setBuildingSelectError(false);
    if (!option) {
      setSelectedBuilding(null);
    } else {
      const building = buildingSlice.objects.find(
        (building: Building) => building.uuid === option.id
      );
      if (building) {
        setSelectedBuilding(building);
        setSelectedUnit(null);
      }
    }
  };

  const unitOnClick = (option: Option | null) => {
    setUnitSelectError(false);
    if (!option) {
      setSelectedUnit(null);
    } else {
      let unit = unitsSlice.objects.find(
        (unit: Unit) => unit.uuid === option.id
      );
      if (unit) {
        setSelectedUnit(unit);
      }
    }
  };

  const renderConfirmationText = () => {
    return `Are you sure you would like to move the current deposit(s) \n from: ${getStreetAddress(
      currentUnit?.street_address
    )} \n to: ${getStreetAddress(selectedUnit?.street_address)}`;
  };

  const handleModalOpen = () => {
    if (!selectedBuilding) {
      setBuildingSelectError(true);
      return;
    } else if (!selectedUnit) {
      setUnitSelectError(true);
      return;
    } else if (currentUnit?.uuid === selectedUnit.uuid) {
      setUnitSelectError(true);
      setErrorMessage(
        "You must choose a different unit to transfer these deposits to other than the current unit."
      );
    } else {
      setErrorMessage("");
      setConfirmationOpen(true);
    }
  };

  const handlePrepaymentUnitUpdate = () => {
    setLoading(true);
    const successCallback = (updatedPrepayment: GenericObject) => {
      dispatch(updatePrepayment(updatedPrepayment));
      setConfirmationOpen(false);
      setLoading(false);
      setSuccess(true);
    };
    const errorCallback = (errorMessage: string) => {
      setErrorMessage(errorMessage);
      setConfirmationOpen(false);
      setLoading(false);
    };
    updatePrepaymentUnit(
      selectedPrepayment?.uuid,
      selectedUnit?.uuid,
      successCallback,
      errorCallback
    );
  };

  const returnToPrepayment = () => {
    if (selectedPrepayment) {
      history.push(`/dashboard/tenant/${selectedPrepayment.uuid}`);
    } else {
      history.push(`/dashboard`);
    }
  };

  const buildingOptions = buildingSlice.objects.map((building) => {
    return {
      label: building.name,
      id: building.uuid,
      identifier: building.name,
    };
  });

  useEffect(() => {
    if (selectedPrepayment?.unit_uuid) {
      const unit = unitsSlice.objects.find(
        (unit: Unit) => unit.uuid === selectedPrepayment.unit_uuid
      );
      if (unit) {
        setCurrentUnit(unit);
        const building = buildingSlice.objects.find((building: Building) => {
          return building.id === unit.building_id;
        });
        if (building) {
          setSelectedBuilding(building);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (selectedBuilding) {
      const units: Unit[] = unitsSlice.objects.filter(
        (unit: Unit) => unit.building_id === selectedBuilding.id
      );
      const newUnitOptions = units.map((unit) => {
        return {
          label: unit.street_address,
          identifier: unit.address_2,
          id: unit.uuid,
        };
      });
      setUnitOptions(newUnitOptions);
    } else {
      setUnitOptions([]);
    }
  }, [selectedBuilding]);

  if (loadingDeposits || loadingUnits || loading) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  }

  if (!selectedPrepayment) {
    return <Redirect to={"/forbidden"} />;
  }
  if (!selectedPrepayment.deposits) {
    returnToPrepayment();
  } else if (success) {
    return (
      <Container>
        <BackButton
          text={"Back to Deposit"}
          returnUrl={`/dashboard/tenant/${selectedPrepayment.uuid}`}
        />
        <Row justifyContent="center">
          <Div width={{ default: 1, lg: 8 / 12 }} alignItems="center">
            <StyledSuccess width={{ default: 1, lg: 1 }} alignItems="center">
              Successfully moved deposit(s) to new unit:
              <br />
              {getStreetAddress(selectedUnit?.street_address)}
            </StyledSuccess>
            <Div width={{ default: 1, lg: 1 / 3 }} alignItems="center">
              <Button
                type="primary"
                text={`Return to Deposit`}
                onClick={returnToPrepayment}
              />
            </Div>
          </Div>
        </Row>
      </Container>
    );
  } else {
    return (
      <Container>
        <BackButton text={"Back to Deposit"} />
        <Row justifyContent="center">
          <Div width={{ sm: 1, md: 1, lg: 6 / 12 }}>
            <StyledTitle>Transfer Deposit(s) to Another Unit</StyledTitle>
            <StyledSubTitle>Deposit Details:</StyledSubTitle>
            <StyledSummaryRow justifyContent="space-between">
              <Div>Tenant Name:</Div>
              <StyledSummaryText>
                {selectedPrepayment.tenant_name}
              </StyledSummaryText>
            </StyledSummaryRow>
            <StyledSummaryRow justifyContent="space-between">
              <Div>Current Address:</Div>
              <StyledSummaryText>
                {getStreetAddress(
                  selectedPrepayment.address_1,
                  selectedPrepayment.address_2
                )}
              </StyledSummaryText>
            </StyledSummaryRow>
            {selectedPrepayment.deposits.security_deposit && (
              <StyledSummaryRow justifyContent="space-between">
                <Div>Security Deposit Amount:</Div>
                <StyledSummaryText>
                  {selectedPrepayment.deposits.security_deposit.amount}
                </StyledSummaryText>
              </StyledSummaryRow>
            )}
            {selectedPrepayment.deposits.last_months_rent && (
              <StyledSummaryRow justifyContent="space-between">
                <Div>Security Deposit Amount:</Div>
                <StyledSummaryText>
                  {selectedPrepayment.deposits.last_months_rent.amount}
                </StyledSummaryText>
              </StyledSummaryRow>
            )}
            <StyledSummaryRow justifyContent="space-between">
              <Div>Lease Start Date:</Div>
              <StyledSummaryText>
                {selectedPrepayment.start_date}
              </StyledSummaryText>
            </StyledSummaryRow>
            <StyledSummaryRow justifyContent="space-between">
              <Div>Lease End Date:</Div>
              <StyledSummaryText>
                {selectedPrepayment.end_date}
              </StyledSummaryText>
            </StyledSummaryRow>
            <HorizontalDivider />
            <StyledInstructionText>
              Please choose which unit you would like to move this account to.
            </StyledInstructionText>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <Div>
              <AutocompleteDropdown
                name="Building"
                placeholder="Search for a Building"
                options={buildingOptions}
                onClick={buildingOnClick}
                value={
                  selectedBuilding
                    ? {
                        label: selectedBuilding.name,
                        id: selectedBuilding.uuid,
                      }
                    : null
                }
                error={buildingSelectError}
              />
            </Div>
            <Div>
              {!!selectedBuilding && (
                <Div width={{ default: 1 }}>
                  <AutocompleteDropdown
                    name="Unit Number"
                    placeholder="Enter Unit Number"
                    options={unitOptions}
                    onClick={unitOnClick}
                    value={
                      selectedUnit
                        ? {
                            label: selectedUnit.street_address,
                            id: selectedUnit.uuid,
                          }
                        : null
                    }
                    error={unitSelectError}
                  />
                </Div>
              )}
              <Div width={{ default: 1 }}>
                <Button
                  text={"Update Unit"}
                  type={"primary"}
                  onClick={handleModalOpen}
                  loading={loading}
                  disabled={false}
                ></Button>
              </Div>
            </Div>
          </Div>
        </Row>
        <ConfirmationModal
          message={renderConfirmationText()}
          confirmationOpen={confirmationOpen}
          onClose={() => setConfirmationOpen(false)}
          onConfirm={handlePrepaymentUnitUpdate}
        />
      </Container>
    );
  }
  return <></>;
};
export default UpdatePrepaymentUnitView;
