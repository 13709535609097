import { PropsWithChildren, useState } from "react";
import styled from "styled-components";
import Div from "./Div";
import Row from "./Row";
import DropdownCaret from "./DropdownCaret";
import { cssBreakpoints } from "../global/theme";

const StyledCard = styled(Div)`
  border-radius: ${(props) => props.theme.border_radius.SM};
  border: ${(props) => props.theme.borders.secondaryMD};
  background-color: ${(props) => props.theme.colors.white};
`;

const StyledHeader = styled(Row)`
  background-color: ${(props) => props.theme.colors.backgroundSecondary};
  &:hover {
    cursor: pointer;
  }
`;

const StyledTitle = styled(Div)`
  background-color: ${(props) => props.theme.colors.backgroundSecondary};
  white-space: nowrap;
  ${cssBreakpoints("font-size", [{ sm: "1.5rem" }, { md: "2rem" }])}
  font-style: normal;
  font-weight: bold;
  line-height: 1.375em;
`;

const StyledBody = styled(Div)<{ expanded: boolean }>`
  transition: ${(props) =>
    props.expanded ? "margin-top 0.3s ease-out" : "margin-top 1.4s ease-out"};
  margin-top: ${(props) => (props.expanded ? "0" : "-200%")};
`;

const StyledBodyContainer = styled(Div)`
  overflow: hidden;
`;

interface Props {
  title: string;
  headerDetails?: Array<string>;
  initiallyOpen?: boolean;
}

const DropdownCard = ({
  title,
  headerDetails,
  initiallyOpen = true,
  children,
}: PropsWithChildren<Props>) => {
  const [openDropdown, setOpenDropdown] = useState(initiallyOpen);

  const handleDropdown = () => {
    setOpenDropdown((oldState) => !oldState);
  };

  return (
    <Div width={{ lg: 12 / 12 }} mb={{ lg: 4 }}>
      <StyledCard>
        <Div onClick={handleDropdown}>
          <StyledHeader
            pt={{ default: 2 }}
            pb={{ default: 2 }}
            alignItems="center"
          >
            <Row
              alignItems="center"
              flexWrap="nowrap"
              justifyContent="space-between"
            >
              <StyledTitle width={{ default: 6 / 12 }}>{title}</StyledTitle>
              <Div>
                <Row>
                  {headerDetails &&
                    headerDetails?.map((detail) => {
                      return (
                        <Div width={{ lg: 4 / 12 }} justifyContent="center">
                          {detail}
                        </Div>
                      );
                    })}
                </Row>
              </Div>
              <DropdownCaret open={openDropdown} />
            </Row>
          </StyledHeader>
        </Div>
        <StyledBodyContainer>
          <StyledBody p={{ lg: 3 }} expanded={openDropdown}>
            {children}
          </StyledBody>
        </StyledBodyContainer>
      </StyledCard>
    </Div>
  );
};

export default DropdownCard;
