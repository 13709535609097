import Row from "../baseComponents/Row";
import Div from "../baseComponents/Div";
import SadCoin from "../../images/SadCoin";
import { currencyFormatter } from "../global/utils";

interface Props {
  onBack?: () => void;
  amountDue: number;
  paymentPlanApproved?: boolean;
}

const InsufficientFunds = ({
  onBack,
  amountDue,
  paymentPlanApproved,
}: Props) => {
  return (
    <Div alignItems="center">
      {onBack && (
        <Row justifyContent="flex-start">
          <a onClick={onBack}>Back</a>
        </Row>
      )}
      <SadCoin />
      <br />
      <Div>
        Uh oh! The amount due: {currencyFormatter.format(amountDue)}, is more
        than the available funds in the account that you linked.
        <br />
        <br />
        Please note, if you recently transferred funds into this account, it can
        take up to a day for us to see those funds. If you are still getting
        this message 24 hours after transferring funds, please reach out to
        support@rentable.com and someone from our team will reach out as soon as
        possible.
        {paymentPlanApproved && (
          <>
            <br />
            <br />
            You have enough funds to make an initial payment on a Rentable
            Payment Plan for your deposit. Press the back button and select the
            payment plan option to learn more.
          </>
        )}
      </Div>
    </Div>
  );
};

export default InsufficientFunds;
