// Custom Theme for React-Select component
export function reactSelectTheme(theme) {
  return {
    ...theme,
    borderRadius: ".25rem",
    colors: {
      ...theme.colors,
      primary25: "#BCEADD",
      primary: "#1EB88D",
    },
  };
}

// Custom style for React-Select component
export const reactSelectStyles = {
  control: (base) => ({
    ...base,
    fontWeight: "700",
  }),
  menu: (base) => ({
    ...base,
    fontWeight: "700",
  }),
};
