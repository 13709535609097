import { first } from "lodash";
import { GenericObject } from "../global/ModelInterfaces";
import Button from "./Button";

interface Props {
  data: GenericObject[];
  disabled?: boolean;
  fileName: string;
  children: JSX.Element | string;
}

const CSVButton = ({ data, children, fileName, disabled = false }: Props) => {
  const firstDatum = first(data) || {};

  let csvContent =
    Object.keys(firstDatum).join(",") +
    "\n" +
    data
      .map((row) =>
        Object.values(row)
          .map((value: string) =>
            typeof value === "string" ? value.replace(",", "") : value
          )
          .join(",")
      )
      .join("\n");
  const blob = new Blob([csvContent], { type: "text/csv" });
  let href = window.URL.createObjectURL(blob);
  if (disabled) href = "";

  return (
    <a
      id="downloadRef"
      href={href}
      download={fileName}
      style={{ textDecoration: "none" }}
    >
      <Button type="secondary" disabled={disabled}>
        {children}
      </Button>
    </a>
  );
};

export default CSVButton;
